<template>
    <el-row>
        <el-dialog
                :title="appInfo && appInfo.appId?'修改应用':'添加应用'"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="appDialog"
                @close="cancelAddAppInfo"
        >
            <el-row>
                <el-form ref="appInfoForm" :rules="appInfoRule" :model="appInfoDetail" label-width="80px">
                    <el-form-item prop="appManagementName" label="应用名">
                        <el-col :span="12">
                            <el-input v-model="appInfoDetail.appManagementName" placeholder="请输入应用名"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="appContext" label="应用文根">
                        <el-col :span="12">
                            <el-input v-model="appInfoDetail.appContext" placeholder="请输入应用文根"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="appPort" label="端口号">
                        <el-col :span="12">
                            <el-input v-model="appInfoDetail.appPort" placeholder="请输入应用端口号"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="appRemark" label="备注">
                        <el-input type="textarea" v-model="appInfoDetail.appRemark" placeholder="应用名备注信息"></el-input>
                    </el-form-item>
                </el-form>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddAppInfo">取 消</el-button>
                    <el-button type="primary" @click="addApp('appInfoForm')">确 定</el-button>
            </span>
        </el-dialog>
    </el-row>
</template>

<script>
    import {addAppInfo,updateAppInfo} from '@/api/app';
    export default {
        name: "addAppInfo",
        props:['addAppDialog','appInfo'],
        data(){
            return{
                appInfoDetail:{},
                appInfoRule:{
                    appManagementName:[
                        { required: true, message: '应用名不能为空', trigger: 'blur' },
                    ],
                    appContext:[
                        { required: true, message: '应用文根不能为空', trigger: 'blur' },
                    ],
                    appPort:[
                        { required: true, message: '端口号不能为空', trigger: 'blur' },
                    ]
                },
                appDialog:false
            }
        },
        mounted(){
            this.appDialog = this.addAppDialog;
            if(this.appInfo && this.appInfo.appId){
                this.appInfoDetail = this.appInfo;
            }
        },
        methods:{
            cancelAddAppInfo:function () {
               this.$emit("cancelAddAppInfo");
            },
            addApp:function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.appInfoDetail.appId){
                            updateAppInfo(this.appInfoDetail,success=>{
                                this.$message({type:"success",message:"修改应用信息成功",duration:1500});
                                this.$emit("editAppSuccess");
                            });
                        }else{
                            addAppInfo(this.appInfoDetail,success=>{
                                this.$message({type:"success",message:"新增应用信息成功",duration:1500});
                                this.$emit("addAppSuccess");
                            });
                        }
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>