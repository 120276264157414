<template>
  <el-container>
    <el-row class="goods-category-tree">
      <el-row class="goods-category-search">
        <el-input
            placeholder="输入关键字进行过滤"
            v-model="goodsCategoryFilter">
        </el-input>
      </el-row>
      <el-tree
          class="filter-tree"
          :data="goodsCategoryTree"
          @node-click="selectGoodsCategory"
          node-key="categoryId"
          default-expand-all
          :expand-on-click-node="false"
          highlight-current
          :filter-node-method="filterNode"
          ref="tree"
      >
                <span class="goods-category-tree-node" slot-scope="{node, data }">
                    <span @click="expandGoodsCategoryNode(node)">{{ data.categoryName }}</span>
                    <span>
                  <el-button
                      type="text"
                      size="mini"
                      icon="el-icon-document-add"
                      style="font-size: 16px"
                      v-if="data.categoryId"
                      @click.stop="() => addChildren(node,data)">
                  </el-button>
                  <el-button
                      v-if="!data.children || data.children.length==0"
                      type="text"
                      size="mini"
                      icon="el-icon-delete"
                      style="font-size: 16px"
                      @click.stop="() => removeNode(node, data)">
                  </el-button>
                    </span>
                </span>
      </el-tree>
    </el-row>
    <el-row class="goods-category-info">
      <el-form :model="goodsCategoryInfo"  ref="goodsCategoryInfoForm" :rules="goodsCategoryRules"  class="goods-category-info-form">
        <el-form-item label="父类别名称" label-width="100px" prop="categoryName">
          <el-input v-model="goodsCategoryInfo.parentCategoryName" disabled></el-input>
        </el-form-item>
        <el-form-item label="类别名称" label-width="100px" prop="categoryName">
          <el-input v-model="goodsCategoryInfo.categoryName"></el-input>
        </el-form-item>
        <el-form-item label="类别属性" label-width="100px" prop="categoryProperty">
          <el-input  v-model="goodsCategoryInfo.categoryProperty" ></el-input>
        </el-form-item>
        <el-form-item label="类别图标" label-width="100px">
          <imageUpload :key="goodsCategoryInfo.categoryId" v-bind:file="goodsCategoryInfo.categoryPhoto" v-bind:limit="1" v-bind:type="'image'"   @uploadSuccess="categoryPhotoUploadSuccess"></imageUpload>
        </el-form-item>
        <el-form-item label="类别图片" label-width="100px">
          <imageUpload :key="goodsCategoryInfo.categoryId" v-bind:file="goodsCategoryInfo.categoryPic" v-bind:limit="1"  v-bind:type="'image'"  @uploadSuccess="categoryPicUploadSuccess"></imageUpload>
        </el-form-item>
        <el-form-item >
          <el-row class="goods-category-info-submit">
            <el-button type="primary" @click="saveGoodsCategory('goodsCategoryInfoForm')" round>保存</el-button>
            <el-button type="warning" @click="resetGoodsCategory()" round>清空</el-button>
          </el-row>
        </el-form-item>
      </el-form>
    </el-row>
  </el-container>
</template>

<script>
import {findGoodsCategoryTreeList,deleteGoodsCategory,saveGoodsCategory} from '@/api/goodsCategory'
import imageUpload from '@/components/image-upload';
export default {
  name: "goodsCategoryTree",
  watch: {
    goodsCategoryFilter(val) {
      this.$refs.tree.filter(val);
    }
  },
  data(){
    return{
      goodsCategoryFilter:'',
      goodsCategoryTree:[],
      goodsCategoryInfo:{categoryParentId:-1},
      goodsCategoryRules:{
        categoryName:[
          { required: true, message: '类别名称不能为空', trigger: 'blur' },
        ],
        categoryProperty:[
          { required: true, message: '类别属性不能为空', trigger: 'blur' },
        ],
        categoryPhotoPath:[
          { required: true, message: '类别图标不能为空', trigger: 'blur' },
        ]
      }
    }
  },
  mounted(){
    findGoodsCategoryTreeList(success=>{
      this.goodsCategoryTree = success;
    })
  },
  methods:{
    filterNode(value, data) {
      if (!value){
        return true;
      }
      return data.categoryName.indexOf(value) !== -1;
    },
    categoryPhotoUploadSuccess:function (imageList){
      this.goodsCategoryInfo.categoryPhotoPath =this.getIdStrings(imageList);
      this.goodsCategoryInfo.categoryPhoto=imageList[0];
    },
    categoryPicUploadSuccess:function (imageList){
      this.goodsCategoryInfo.categoryPicPath
          =this.getIdStrings(imageList);
      this.goodsCategoryInfo.categoryPic=imageList[0];
    },
    getIdStrings:function(imageList){
      let Ids = '';
      if(imageList && imageList.length>0){
        imageList.forEach(item=>{
          Ids=Ids+item.id+',';
        });
      }
      return Ids;
    },
    resetGoodsCategory:function(){
      this.goodsCategoryInfo = {categoryParentId:-1};
    },
    saveGoodsCategory:function(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveGoodsCategory(this.goodsCategoryInfo,success=>{
            if(success.obj){
              success.obj.children=[];
              this.removeGoodsCategoryTreeData(this.goodsCategoryTree,success.obj);
              if(success.obj.categoryParentId == -1){
                this.goodsCategoryTree.push(success.obj);
              }else{
                this.addGoodsCategoryChild(this.goodsCategoryTree,success.obj);
              }
            }
            this.$message({
              type:'success',
              message:'保存成功',
              duration:1500
            });
          })
        } else {
          return false;
        }
      });
    },
    addGoodsCategoryChild(tree,data){
      for(let index=0;index<tree.length;index++){
        let item = tree[index];
        if(item.categoryId == data.categoryParentId){
          if(!item.children){
            item.children = [];
          }
          item.children.unshift(data);
        }
        if(item.children && item.children.length>0){
          this.addGoodsCategoryChild(item.children,data);
        }
      }
    },
    removeGoodsCategoryTreeData(tree,data){
      tree.forEach((item,index)=>{
        if(item.categoryId == data.categoryId){
          tree.splice(index, 1);
        }
        if(item.children && item.children.length>0){
          this.removeGoodsCategoryTreeData(item.children,data)
        }
      });
    },
    selectGoodsCategory:function (data) {
      this.goodsCategoryInfo = data;
      this.initParentCategoryName(this.goodsCategoryTree,this.goodsCategoryInfo);
    },
    initParentCategoryName:function (tree,data){
      if(data.categoryParentId == -1){
        this.goodsCategoryInfo.parentCategoryName='无';
        return;
      }
      tree.forEach(item=>{
        if(item.categoryId == data.categoryParentId){
           data.parentCategoryName = item.categoryName;
        }
        if(item.children && item.children.length>0){
          this.initParentCategoryName(item.children,data)
        }
      });
    },
    expandGoodsCategoryNode:function(node){
      if(node.expanded){
        node.expanded=false;
      }else {
        node.expanded=true;
      }
    },
    getDefaultGoodsCategory:function(data){
      const defaultGoodsCategory= { categoryParentId:-1,children: []};
      if(data){
        defaultGoodsCategory.dictionaryName = "新增类目";
      }
      if(data && data.categoryId){
        defaultGoodsCategory.categoryParentId=data.categoryId;
      }
      return defaultGoodsCategory;
    },
    addChildren:function (node,data) {
      this.goodsCategoryInfo =this.getDefaultGoodsCategory(data);
      this.initParentCategoryName(this.goodsCategoryTree,this.goodsCategoryInfo)
    },
    removeNode:function (node,data) {
      this.$confirm('是否删除该数据?删除后不可恢复', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteGoodsCategory(data,success=>{
              this.$message({
                type:'success',
                message:'删除成功',
                duration:1500
              })
              const parent = node.parent;
              const children = parent.data.children && parent.data.children.length>0?
                  parent.data.children: parent.data ;
              const index = children.findIndex(d => d.id === data.id);
              children.splice(index, 1);
              this.goodsCategoryInfo = {categoryParentId:-1};
            })
          })
          .catch(() => {

          })
    }
  },
  components:{
    imageUpload
  }
}
</script>
<style>
.el-checkbox>.el-checkbox__label{
  display: none;
}
</style>
<style scoped>
.filter-tree{
  margin-top: 50px;
}
.goods-category-search{
  position: fixed;
  z-index: 1;
  width: 16VW;
}
.goods-category-tree{
  width: 18vw;
  height: 68vh;
  overflow: auto;
  border: 3px solid whitesmoke;
  padding: 10px;
}

.goods-category-info{
  display: flex;
  justify-content: center;
  width: 40vw;
  height: 68vh;
  overflow: auto;
  border: 3px solid whitesmoke;
  padding: 10px;
}
.goods-category-info-form{
  width: 38vw;
  height: 50vh;
}
.goods-category-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.goods-category-info-submit{
  text-align: center;
}
</style>