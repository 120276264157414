import {dataFormat} from "@/util/date";
import {cache} from '@/store/store';
export default {
    install (Vue, options) {
        Vue.prototype.$formatterDateToStr = function (date) {
            if(date){
                return  dataFormat.dateToStr(date);
            }
            return '---';
        },
        Vue.prototype.$formatTableCellDate = function (row, column, cellValue, index) {
            if(cellValue){
                return  dataFormat.dateToStr(cellValue);
            }
            return '---';
        },
        Vue.prototype.$successMessage = function (message) {
            this.$message({type:"success",message:message,duration:1500})
        },
        Vue.prototype.$errorMessage = function (message) {
            this.$message({type:"error",message:message,duration:1500})
        },
        Vue.prototype.$warningMessage = function (message) {
            this.$message({type:"warning",message:message,duration:1500})
        },
        Vue.prototype.$lookupFormatter = function (row, column, cellValue,lookup) {
           if(!cellValue){
               return '';
           }
           let lookupInfo = cache.getMemoryCache("lookupInfo");
            if(!lookupInfo ||  lookupInfo.length==0){
                return ''
            }
           let result = ''
            lookupInfo.forEach(item=>{
                if(item.classifyCode == lookup){
                    let itemList  =  item.itemVoList;
                    itemList.forEach(lookupItem=>{
                        if(lookupItem.itemCode == cellValue){
                            result = lookupItem.itemValue;
                        }
                    })
                }
            })
            return result;
        },
        Vue.prototype.$lookupInfo = function (lookup) {
            if(!lookup){
                return [];
            }
            let lookupInfo = cache.getMemoryCache("lookupInfo");
            if(!lookupInfo ||  lookupInfo.length==0){
                return ''
            }
            let itemList = []
            lookupInfo.forEach(item=>{
                if(item.classifyCode == lookup){
                    itemList  =  item.itemVoList;
                }
            })
            return itemList;
        }
    }
}
