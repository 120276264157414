<template>
    <el-row>
    <el-button type="primary" icon="el-icon-plus" @click="addSystemUserDialog = true">添加</el-button>
    <el-dialog
        title="添加用户"
        width="36%"
        :destroy-on-close="true"
        :close-on-click-modal="false"
        :visible.sync="addSystemUserDialog"
        @close="init"
    >

        <el-row class="user-info-form">
            <el-form ref="userInfoForm" :model="userInfo" :rules="userInfoRule" label-width="80px">
              <el-form-item label="账号"  prop="userAccount">
                <el-input v-model="userInfo.userAccount" ></el-input>
              </el-form-item>
              <el-form-item label="密码"  prop="userPwd">
                <el-input v-model="userInfo.userPwd" ></el-input>
              </el-form-item>
                <el-form-item label="昵称"  prop="userName">
                   <el-input v-model="userInfo.userName" ></el-input>
                </el-form-item>
                <el-form-item label="姓名"  prop="userRealName">
                    <el-input v-model="userInfo.userRealName" ></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="userSex">
                    <el-radio v-model="userInfo.userSex" label="1">男</el-radio>
                    <el-radio v-model="userInfo.userSex" label="0">女</el-radio>
                </el-form-item>
                <el-form-item label="身份证号"  prop="userCard">
                    <el-input v-model="userInfo.userCard" ></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="userPhone">
                    <el-input v-model="userInfo.userPhone" ></el-input>
                </el-form-item>
                <el-form-item label="备注信息"  >
                    <el-input type="textarea" v-model="userInfo.userRemark" ></el-input>
                </el-form-item>
            </el-form>
        </el-row>

        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelAddSubmitUser">取 消</el-button>
            <el-button type="primary" @click="submitUser('userInfoForm')">确 定</el-button>
          </span>
    </el-dialog>
    </el-row>
</template>

<script>
    import {addWxHelperUser} from "@/api/user"
    export default {
        name: "addUser",
        props:{
          userType:{
            type:String,
            default:'system'
          }
        },
        data(){
            return{
                addSystemUserDialog:false,
                userInfo:{
                    userSex:'1'
                },
                userInfoRule:{
                    userAccount:[
                      { required: true, message: '账号不能为空', trigger: 'blur' },
                    ],
                    userPwd:[
                      { required: true, message: '密码不能为空', trigger: 'blur' },
                    ],
                    userName:[
                        { required: true, message: '昵称不能为空', trigger: 'blur' },
                    ],
                    userRealName:[
                        { required: true, message: '姓名不能为空', trigger: 'blur' },
                    ],
                    userSex:[
                        { required: true, message: '性别不能为空', trigger: 'blur' },
                    ],
                    userCard:[
                        { required: true, message: '身份证不能为空', trigger: 'blur' },
                        { pattern: /(^\d{15}$)|(^\d{17}(x|X|\d)$)/, message: '身份证不合法' }
                    ],
                    userPhone:[
                        { required: true, message: '手机号不能为空', trigger: 'blur' },
                        { pattern: /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/, message: '手机号码不合法' }
                    ]
                }
            }
        },
        methods:{
            init:function(){
                this.addSystemUserDialog = false;
                this.userInfo={
                    userSex:'1'
                }
            },
            cancelAddSubmitUser:function(){
                this.init();
            },
            addSystemUser:function (){
              addWxHelperUser(this.userInfo,success=>{
                this.$successMessage('添加成功');
                this.init();
                this.$emit("addUserSuccess",success.obj)
              })
            },
            submitUser:function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.addSystemUser();
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>
 .user-info-form{
     padding: 0 10px;
 }
</style>