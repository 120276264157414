<template>
  <el-row>
    <el-row>
      <el-row class="app-info-table">
        <el-table
            border
            :data="userIconDetailList"
            :header-cell-style="{background:'#F3F4F7',color:'#555'}"
        >
          <el-table-column prop="iconType" label="类型" :formatter="this.formatterType"></el-table-column>
          <el-table-column prop="currentIcon" label="操作前金币"></el-table-column>
          <el-table-column prop="iconNumber" label="扣除/新增金币"></el-table-column>
          <el-table-column prop="toIcon" label="当前剩余金币"></el-table-column>
          <el-table-column prop="toDesc" label="说明"></el-table-column>
          <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
        </el-table>
      </el-row>
      <el-row class="app-table-page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[10,15,30,50,100]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.count">
        </el-pagination>
      </el-row>
    </el-row>
  </el-row>
</template>
<script >
import {queryUserIconPageList,queryMyUserIconPageList} from '@/api/wxHelper';
export default {
  name: "userIconDetail",
  props:{
    userId:{
      type:Number,
      default:0
    }
  },
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      userIconDetailList:[]
    }
  },
  mounted(){
    this.queryIconDetailPageList();
  },
  methods:{
    formatterType:function (row){
      if (row.iconType === 1) {
        return "新增"
      }
      return "扣减";
    },
    queryIconDetailPageList:function () {
      if(this.userId && this.userId>0){
        queryUserIconPageList(this.page,{"userId":this.userId},success=>{
          this.userIconDetailList = success.data;
          this.pageInfo.count = success.count;
          this.pageInfo.totalPage = success.totalPage;
        });
      }else {
        queryMyUserIconPageList(this.page,{},success=>{
          this.userIconDetailList = success.data;
          this.pageInfo.count = success.count;
          this.pageInfo.totalPage = success.totalPage;
        });
      }
    },
    queryReset:function () {
      this.appQueryParam={};
      this.appListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.appListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.appListPageQuery(true);
    },
  }
}
</script>
<style scoped>
.app-info-table{
  margin-top: 8px;
}
.app-table-page{
  margin-top: 8px;
}
</style>