<template>
    <el-container class="main-body">
        <div class="login-main">
            <div class="login-top">
                <span>{{loginTitle}}</span>
            </div>
            <el-form label-position="left"  :model="accountInfo"  ref="userLoginForm" label-width="0px" class="demo-ruleForm login-container">
                <el-form-item  v-if="loginByPassword == true">
                    <el-input type="text" v-model="accountInfo.userAccount" auto-complete="off" placeholder="请输入登录账号"></el-input>
                </el-form-item>
                <el-form-item  v-if="loginByPassword == true">
                    <el-input type="password" v-model="accountInfo.userPassword" auto-complete="off" placeholder="请输入登录密码"></el-input>
                </el-form-item>
                <el-form-item  v-if="loginByPassword == false">
                    <el-input type="text" v-model="phoneInfo.userPhone" auto-complete="off" placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item  v-if="loginByPassword == false">
                    <el-col :span="14">
                        <el-input type="text" v-model="phoneInfo.verificationCode" auto-complete="off"
                                  placeholder="短信验证码"></el-input>
                    </el-col>
                    <el-col :span="8" :offset="2">
                    <sendVerificationCode v-bind:get-code-url="'market/manager/userLogin/manager/sendLoginVerificationCode'" v-bind:user-phone="phoneInfo.userPhone"></sendVerificationCode>
                    </el-col>
                </el-form-item>
                <el-row v-if="codeimg != ''">
                    <el-col :span="16">
                        <el-form-item v-if="loginByPassword == true">
                            <el-input type="text" v-model="accountInfo.imgVerificationCode" auto-complete="off" placeholder="图形验证码" ></el-input>
                        </el-form-item>
                        <el-form-item v-if="loginByPassword == false">
                            <el-input type="text" v-model="phoneInfo.imgVerificationCode" auto-complete="off" placeholder="图形验证码" ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8" class="code-box" v-if="codeimg != ''">
                        <img :src="codeimg" alt="" class="codeimg" @click="refreshImgVerification()">
                    </el-col>
                </el-row>
                <el-link type="primary" class="remember" @click="switchLogin()" >
<!--                   <el-row v-if="loginByPassword == true">短信登录</el-row>-->
                    <el-row v-if="loginByPassword == false">账号密码登录</el-row>
                </el-link>
                <el-form-item style="width:100%;">
                    <el-button type="primary" style="width:100%;"  @click="userLogin()" :loading="logining">登录</el-button>
                </el-form-item>
            </el-form>
        </div>
    </el-container>
</template>

<script>
    import { loginByPassword,refreshImgVerificationCode,loginByVerificationCode } from '@/api/login';
    import {loginLocal} from '@/api/wxHelperLocal';
    import {findCacheLookupConfig} from '@/api/lookup';
    import sendVerificationCode from '@/components/send-verification-code'
    import {cache} from '@/store/store';
    import {check} from "@/util/check";
    import {config} from "@/config/data";

    export default {
        name: "login",
        data(){
          return {
              loginByPassword:true,
              logining:false,
              loginTitle:config.loginTitle,
              codeimg:'',
              accountInfo: {
                  userAccount: '',
                  userPassword: '',
                  imgVerificationCode: '',
              },
              phoneInfo:{
                  userPhone:'',
                  verificationCode:'',
                  imgVerificationCode: ''
              }
          }
        },
        mounted(){
           let codeImg =  cache.getCache("codeImg");
           if(codeImg){
               this.codeimg = codeImg;
           }
        },
        created:function () {
            let _this = this;
            document.onkeydown = function (event) {
                if(event.key === 'Enter'){
                    _this.userLogin();
                }
            }
        },
        methods:{
            userLogin(){
                if(this.loginByPassword){
                    if(!this.checkLoginParam()){
                        return;
                    }
                    this.logining = true;
                    loginByPassword(this.accountInfo,result=>{
                        this.logining=false;
                        if(result && result.requestSuccess
                            &&
                            result.obj.currentCompanyCode != "wxHelperUser"){
                            cache.removeCache("codeImg");
                            this.$message({
                                type: 'success',
                                message: '登录成功',
                                duration:1500
                            })
                          findCacheLookupConfig(success=>{
                            cache.addMemoryCache("lookupInfo",success);
                          });
                            let target = cache.getMemoryCache("target");
                            if(target){
                                this.$router.push({path:target.substring(1)});
                            }else{
                                this.$router.push({path:"/index"});
                            }
                        }else {
                          let logInfo =new Object();
                          logInfo.userName = this.accountInfo.userAccount;
                          logInfo.userPassword = this.accountInfo.userPassword;
                          loginLocal(logInfo,result=>{
                            this.logining=false;
                            cache.removeCache("codeImg");
                            this.$message({
                              type: 'success',
                              message: '登录成功',
                              duration:1500
                            })
                            findCacheLookupConfig(success=>{
                              cache.addMemoryCache("lookupInfo",success);
                            });
                            let target = cache.getMemoryCache("target");
                            if(target){
                              this.$router.push({path:target.substring(1)});
                            }else{
                              this.$router.push({path:"/index"});
                            }
                          },error=>{
                            this.logining=false;
                            this.$message({
                              type: 'error',
                              message: '请打开本地应用',
                              duration:1500
                            })
                          })
                        }
                    },error=>{
                        this.logining=false;
                        if(error && error.obj){
                            this.codeimg = error.obj;
                            if(error.obj != 'loginLock'){
                                cache.addCache("codeImg",this.codeimg);
                            }
                        }
                    });
                }else {
                    let success = check.checkPhone(this.phoneInfo.userPhone);
                    if(!this.phoneInfo.verificationCode){
                        return;
                    }
                    if(success){
                        this.logining = true;
                        loginByVerificationCode(this.phoneInfo,result=>{
                            this.logining=false;
                            if(result && result.requestSuccess){
                                this.$message({
                                    type: 'success',
                                    message: '登录成功',
                                    duration:1500
                                })
                              findCacheLookupConfig(success=>{
                                cache.addMemoryCache("lookupInfo",success);
                              });
                                let target = cache.getMemoryCache("target");
                                if(target){
                                    this.$router.push({path:decodeURIComponent(target.substring(1))});
                                }else{
                                    this.$router.push({path:"/index"});
                                }
                            }
                        },error=>{
                            this.logining=false;
                            if(error && error.obj){
                              this.codeimg = error.obj;
                              if(error.obj != 'loginLock'){
                                cache.addCache("codeImg",this.codeimg);
                              }
                            }
                        });
                    }
                }
            },
            checkLoginParam(){
                if(!this.accountInfo.userAccount){
                    return false;
                }
                if(!this.accountInfo.userPassword){
                    return false;
                }
                if(this.codeimg && !this.accountInfo.imgVerificationCode){
                    return false;
                }
                return true;
            },
            switchLogin(){
                this.loginByPassword = !this.loginByPassword;
            },
            refreshImgVerification(){
                let codeType = this.loginByPassword?'systemAccount':'systemPhone';
                if(!this.accountInfo.userAccount){
                    return;
                }
                refreshImgVerificationCode(codeType,{"userAccount":this.accountInfo.userAccount},success=>{
                    if(success.message){
                        this.codeimg = success.message;
                    }
                });
            }
        },
        components:{
            sendVerificationCode
        }

    }
</script>


<style scoped>
    .main-body{
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        padding-top: 10%;
        background:url(https://market-public-image.oss-cn-guangzhou.aliyuncs.com/loginbg.png);
        background-size: 100%;
    }
    .login-main {
        width: 428px;
        margin: 0 auto;
    }
    .login-top{
        height: 117px;
        background-color: #148be4;
        border-radius: 12px 12px 0 0;
        font-family: SourceHanSansCN-Regular;
        font-size: 30px;
        font-weight: 400;
        font-stretch: normal;
        letter-spacing: 0;
        color: #fff;
        line-height: 117px;
        text-align: center;
        overflow: hidden;
        -webkit-transform: rotate(0);
        -moz-transform: rotate(0);
        -ms-transform: rotate(0);
        -o-transform: rotate(0);
        transform: rotate(0);
    }
    .login-container {
        border-radius: 0 0 12px 12px;
        margin: 0px auto;
        padding: 30px 35px 15px 35px;
        background: #fff;
        border: 1px solid #eaeaea;
        text-align: left;
        box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    }
    .remember {
        margin: 0px 0px 35px 0px;
        font-size: 12px;
    }
    .code-box {
        text-align: right;
    }
    .codeimg {
        height: 40px;
    }
</style>
