import {_get,_post} from '../http/index';
//查询代办任务列表
export const findMyApproveTaskPageList = (page,param,success,error) => _get("/market/manager/flowPending/findMyApproveTaskPageList/"+page.pageSize+'/'+page.currentPage,param,success,error) ;

//查询我的已办任务列表
export const findMyApprovedTaskPageList = (page,param,success,error) => _get("/market/manager/flowPending/findMyApprovedTaskPageList/"+page.pageSize+'/'+page.currentPage,param,success,error) ;
//查询我的申请列表
export const findMySubmitTaskPageList = (page,param,success,error) => _get("/market/manager/flowPending/findMySubmitTaskPageList/"+page.pageSize+'/'+page.currentPage,param,success,error) ;
//提交审批
export const approveTask = (module,param,success,error) => _post("/market/"+module+"/flowTask/approveTask",param,success,error) ;
//查询审批历史
export const findApproveHistory = (param,success,error) => _get("/market/manager/flowPending/findApproveHistory",param,success,error) ;
