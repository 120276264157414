import Vue from 'vue';
import Router from 'vue-router';
import login from '@/views/login/login';
import index from '@/views/index/index';
import userInfo from '@/views/user/userInfo';
import updatePassword from '@/views/user/updatePassword';
import catalog from "@/views/catalog/catalog";
import nonentity from "@/views/error/404";
import shopCatalog from '@/views/catalog/shop-catalog';
import dictionary from '@/views/dictionary/dictionary';
import userListPage from '@/views/user/userListPage';
import appListPage from '@/views/app/appListPage';
import lookupListPage from "@/views/lookup/lookupListPage";
import process from '@/views/process/process';
import rangeListPage from '@/views/dataRange/rangeListPage';
import secretListPage from '@/views/security/secretListPage';
import roleListPage from '@/views/role/roleListPage';
import groupListPage from '@/views/roleGroup/groupListPage';
import pending from '@/views/pending/pending';
import shopOperationList from '@/views/operation/shopOperationList';
import shopListPage from '@/views/shop/shopListPage';
import baseMapListPage from "@/views/baseMap/baseMapListPage";
import photoManage from "@/views/photo/photoManage";
import goodsCategoryTree from "@/views/goods/goodsCategoryTree";
import brandListPage from "@/views/brand/brandListPage";
import goodsListPage from "@/views/goods/goodsListPage";
import wxHelperUser from '@/views/wxHelper/userList';
import shopIndex from '@/shopViews/index/index';
import config from '@/shopViews/config/config';
import rechargeListPage from '@/shopViews/recharge/rechargeListPage';
import rechargeHisListPage from '@/shopViews/recharge/rechargeHisListPage';
import rechargeCard from "@/shopViews/rechargeCard/rechargeCard";
import noticeListPage from "@/shopViews/notice/noticeListPage";
import memberListPage from "@/shopViews/member/memberListPage";
import shopUserListPage from "@/shopViews/shopUser/shopUserListPage";
import stockListPage from "@/shopViews/stock/stockListPage";
import couponListPage from "@/shopViews/coupon/couponListPage";
import excelModuleListPage from "@/views/excel/excelModuleListPage";
import firstOrderListPage from "@/shopViews/firstOrder/firstOrderListPage";
import specialListPage from "@/shopViews/special/specialListPage";
import slideshowListPage from "@/views/slideshow/slideshowListPage";
import stockClassifyListPage from "@/shopViews/stock/stockClassifyListPage"
import excelHistoryListPage from "@/views/excel/excelHistoryListPage";
import userStatistics from '@/views/wxHelper/statistics/index';
import wxHelperConfig from '@/views/wxHelper/config/index';
import wxCallBackConfig from "@/views/wxHelper/backConfig/index.vue";
import openVip from "@/views/wxHelper/vip/index.vue";
import shareStatistics from "@/views/wxHelper/share/index.vue"
import collectIndex from "@/views/wxHelper/collect/index.vue"
Vue.use(Router);
export default new Router({
    routes: [{
        path: '/',
        name: '速惠后台',
        component: index,
        hidden: true,
        meta: {
            requireAuth: false
        }
    },{
        path: '*',
        name: '404页面不存在',
        component: nonentity,
        hidden: true,
        meta: {
            requireAuth: false
        }
    }, {
        path: '/login',
        name: '登录',
        component: login,
        hidden: true,
        meta: {
            requireAuth: false
        }
    },{
        path: '/shareStatistics',
        name: '分享',
        component: shareStatistics,
        hidden: true,
        meta: {
            requireAuth: true
        }
    }, {
        path: '/index',
        name: '首页',
        component: index,
        hidden: true,
        meta: {
            requireAuth: true
        },
        children: [{
            path: '/userInfo',
            name: '用户信息',
            component: userInfo,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/updatePassword',
            name: '修改密码',
            component: updatePassword,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/catalog',
            name: '栏目管理',
            component: catalog,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/shopCatalog',
            name: '商家栏目管理',
            component: shopCatalog,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/dictionary',
            name: '数据字典',
            component: dictionary,
            hidden: true,
            meta: {
                requireAuth: true
            }
        }, {
            path: '/userListPage',
            name: '用户管理',
            component: userListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/appListPage',
            name: '应用管理',
            component: appListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/lookupListPage',
            name: 'lookup管理',
            component: lookupListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/process',
            name: '流程管理',
            component: process,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/rangeListPage',
            name: '数据范围管理',
            component: rangeListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/secretListPage',
            name: '密钥管理',
            component: secretListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/roleListPage',
            name: '角色管理',
            component: roleListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/groupListPage',
            name: '组别管理',
            component: groupListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/pending',
            name: '我的代办',
            component: pending,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/shopOperationList',
            name: '店铺运营',
            component: shopOperationList,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/shopListPage',
            name: '店铺管理',
            component: shopListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/baseMapListPage',
            name: '底图管理',
            component: baseMapListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/photoManage',
            name: '图片素材管理',
            component: photoManage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/goodsCategoryTree',
            name: '商品类目',
            component: goodsCategoryTree,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/brandListPage',
            name: '商品品牌',
            component: brandListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/goodsListPage',
            name: '产品列表',
            component: goodsListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/excelModuleListPage',
            name: '导入模板',
            component: excelModuleListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/slideshowListPage',
            name: '轮播图配置',
            component: slideshowListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/excelHistoryListPage',
            name: '导入导出',
            component: excelHistoryListPage,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },{
            path: '/wxHelperUser',
            name: '微信计数',
            component: wxHelperUser,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },
        {
            path: '/userStatistics',
            name: '首页统计',
            component: userStatistics,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },
            {
                path: '/wxHelperConfig',
                name: '账号配置',
                component: wxHelperConfig,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            }
            ,
            {
                path: '/wxCallBackConfig',
                name: '回复配置',
                component: wxCallBackConfig,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            } ,
            {
                path: '/openVip',
                name: '开通vip',
                component: openVip,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/collectIndex',
                name: '合作支持',
                component: collectIndex,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            }
        ]
    }, {
        path: '/shopIndex',
        name: '商家运营',
        component: shopIndex,
        hidden: true,
        meta: {
            requireAuth: false
        },
        children: [{
            path: '/config',
            name: '店铺配置',
            component: config,
            hidden: true,
            meta: {
                requireAuth: true
            }
        },
            {
                path: '/rechargeListPage',
                name: '充值券列表',
                component: rechargeListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/rechargeHisListPage',
                name: '充值历史记录',
                component: rechargeHisListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/rechargeCard',
                name: '充值卡列表',
                component: rechargeCard,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/noticeListPage',
                name: '轮播公告',
                component: noticeListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/memberListPage',
                name: '会员管理',
                component: memberListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/shopUserListPage',
                name: '商家用户管理',
                component: shopUserListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/stockListPage',
                name: '库存管理',
                component: stockListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/couponListPage',
                name: '优惠券管理',
                component: couponListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/firstOrderListPage',
                name: '首单配置',
                component: firstOrderListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/specialListPage',
                name: '特价商品',
                component: specialListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            },
            {
                path: '/stockClassifyListPage',
                name: '库存分类',
                component: stockClassifyListPage,
                hidden: true,
                meta: {
                    requireAuth: true
                }
            }
        ]
     }
    ]
});