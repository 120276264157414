<template>
  <el-row>
    <el-dialog
        :title="noticeInfo && noticeInfo.noticeId?'修改公告':'添加公告'"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="noticeDialog"
        @close="cancelAddNoticeInfo"
    >
      <el-row>
        <el-form ref="noticeInfoForm" :rules="noticeInfoRule" :model="noticeInfoDetail" label-width="80px">
          <el-form-item prop="noticeTitle" label="标题">
            <el-col :span="12">
              <el-input v-model="noticeInfoDetail.noticeTitle" placeholder="请输入标题"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item label="类型" prop="noticeType" >
            <el-select v-model="noticeInfoDetail.noticeType" clearable placeholder="请选择">
              <el-option
                  v-for="item in lookupList"
                  :key="item.itemCode"
                  :label="item.itemName"
                  :value="item.itemCode">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item prop="noticeText" label="内容">
            <el-input type="textarea" v-model="noticeInfoDetail.noticeText" placeholder="公告内容"></el-input>
          </el-form-item>
          <el-form-item prop="noticeOrder" label="顺序">
            <el-col :span="12">
              <el-input type="number" v-model="noticeInfoDetail.noticeOrder" placeholder="请输入顺序"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddNoticeInfo">取 消</el-button>
                    <el-button type="primary" @click="addNotice('noticeInfoForm')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {addNoticeInfo,updateNoticeInfo} from '@/api/notice';
export default {
  name: "addNoticeInfo",
  props:['addNoticeDialog','noticeInfo'],
  data(){
    return{
      noticeInfoDetail:{
        noticeEnable:'0'
      },
      noticeInfoRule:{
        noticeTitle:[
          { required: true, message: '标题不能为空', trigger: 'blur' },
        ],
        noticeType:[
          { required: true, message: '类型不能为空', trigger: 'blur' },
        ],
        noticeText:[
          { required: true, message: '内容不能为空', trigger: 'blur' },
        ],
        noticeOrder:[
          { required: true, message: '顺序不能为空', trigger: 'blur' },
        ]
      },
      lookupList:[],
      noticeDialog:false
    }
  },
  mounted(){
    this.lookupList =  this.$lookupInfo('NOTICE_TYPE')
    this.noticeDialog = this.addNoticeDialog;
    if(this.noticeInfo && this.noticeInfo.noticeId){
      this.noticeInfoDetail = this.noticeInfo;
    }
  },
  methods:{
    cancelAddNoticeInfo:function () {
      this.$emit("cancelAddNoticeInfo");
    },
    addNotice:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.noticeInfoDetail.noticeId){
            updateNoticeInfo(this.noticeInfoDetail,success=>{
              this.$message({type:"success",message:"修改公告信息成功",duration:1500});
              this.$emit("editNoticeSuccess");
            });
          }else{
            addNoticeInfo(this.noticeInfoDetail,success=>{
              this.$message({type:"success",message:"新增公告信息成功",duration:1500});
              this.$emit("addNoticeSuccess");
            });
          }
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>