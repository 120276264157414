<template>
    <el-container class="index-con">
        <el-aside  :class="showclass">
            <leftNav :logo-url="logoUrl" :title="title" v-bind:menu-info-list="menuInfoList"></leftNav>
        </el-aside>
        <el-container class="main-con">
            <el-header class="index-header">
                <headNav></headNav>
            </el-header>
            <el-row class="index-breadcrumb-item" v-if="homeInfo.catalogUrl">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/'+homeInfo.catalogUrl }">{{homeInfo.catalogName}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{this.$router.currentRoute.name}}</el-breadcrumb-item>
                </el-breadcrumb>
            </el-row>
            <el-main clss="index-main">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
    import leftNav from '@/components/left-nav';
    import headNav from '@/components/head-nav';
    import { getManageCatalogList} from '@/api/catalog';
    import {config} from "@/config/data";
    export default {
        name: "index",
        data() {
            return {
                showclass: 'asideshow',
                userInfo :{},
                showtype: false,
                menuInfoList:[],
                homeInfo:{},
                title:config.navTitle,
                logoUrl:config.logoUrl,
            }
        },
        async mounted(){
            this.initCatalog();
        },
        created() {
            // 监听
            this.$root.Bus.$on('toggle', value => {
                if (value) {
                    this.showclass = 'asideshow'
                } else {
                    setTimeout(() => {
                        this.showclass = 'aside'
                    }, 300)
                }
            })
        },
        methods: {
            initCatalog:function () {
                getManageCatalogList(success=>{
                    this.menuInfoList = success.menuInfo;
                    this.homeInfo = success.homeInfo;
                    if(this.$router.currentRoute.fullPath == '/index'){
                        if(this.homeInfo && this.homeInfo.catalogUrl){
                            this.$router.push({path:this.homeInfo.catalogUrl})
                        }
                    }
                });
            }
        },
        components: {
            leftNav,
            headNav
        }
    }
</script>

<style scoped>
    .index-con {
        height: 100%;
        width: 100%;
        box-sizing: border-box;
    }

    .aside {
        width: 60px !important;
        height: 100%;
        background-color: #334157;
        margin: 0px;
    }
    .asideshow {
        width: 220px !important;
        height: 100%;
        background-color: #334157;
        margin: 0px;
    }
    .index-header,
    .index-main {
        padding: 0px;
        border-left: 2px solid #333;
    }
    .index-breadcrumb-item{
        margin: 18px;
    }
</style>