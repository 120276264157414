<template>
    <div>
        <el-row :gutter="15">
            <el-form ref="elForm" :model="shopInfo" size="medium" label-width="100px" class>
                <el-form-item label="店铺名称:">
                        <el-row>{{shopInfo.shopName}}</el-row>
                </el-form-item>
                <el-form-item label="店家地址">
                    <el-row>{{shopInfo.shopProvince}}>{{shopInfo.shopCity}}>{{shopInfo.shopCounties}}</el-row>
                </el-form-item>
                <el-form-item label="明细地址" >
                    <el-row>{{shopInfo.shopAddressDetail}}</el-row>
                </el-form-item>
                <el-form-item label="服务热线">
                    <el-row>{{shopInfo.shopServiceHotLine}}</el-row>
                </el-form-item>
                <el-form-item label="店铺类型" >
                    <el-radio v-model="shopInfo.shopType" label="2" disabled>销售门店</el-radio>
                </el-form-item>
                <el-form-item label="营业时段">
                    <el-row>{{shopInfo.openBegin}}至{{shopInfo.openEnd}}</el-row>
                </el-form-item>
                <el-form-item label="店铺法人">
                    <el-row>{{shopInfo.shopShopkeeperName}}</el-row>
                </el-form-item>
                <el-form-item label="法人电话">
                    <el-row>{{shopInfo.shopShopkeeperPhone}}</el-row>
                </el-form-item>
                <el-form-item label="性别">
                    <template>
                        <el-radio v-model="shopInfo.shopShopkeeperSex" label="0" disabled>男</el-radio>
                        <el-radio v-model="shopInfo.shopShopkeeperSex" label="1" disabled>女</el-radio>
                    </template>
                </el-form-item>
                <el-form-item label="身份证号码">
                    <el-row>{{shopInfo.shopShopkeeperCard}}</el-row>
                </el-form-item>
                <el-form-item label="身份证">
                    <el-row>
                        <el-col style="width: 100px; height: 100px"  v-for="item in shopInfo.shopCardAttachmentList" :key="item.id">
                            <el-image :src="item.downloadUrl"></el-image>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="营业执照">
                    <el-row>
                        <el-col  style="width: 100px; height: 100px"  v-for="item in shopInfo.shopLicenseAttachmentList" :key="item.id">
                            <el-image :src="item.downloadUrl"></el-image>
                        </el-col>
                    </el-row>
                </el-form-item>
                <el-form-item label="佣金占比">
                    <el-row>{{shopInfo.commissionPercent}}</el-row>
                </el-form-item>
                <el-form-item label="退场原因" v-if="shopInfo.shopApproveStatus=='900'">
                    <el-row>{{shopInfo.quitReason}}</el-row>
                </el-form-item>
            </el-form>
        </el-row>
    </div>
</template>
<script>
    import {shopDetail} from '@/api/shop';
    export default {
        name: "shopDetail",
        components: {},
        props: ['shopQuery'],
        data() {
            return {
                shopInfo: {}
            }
        },
        mounted() {
               this.initShopInfo(this.shopQuery);
        },
        methods: {
            initShopInfo:function (shopQuery) {
                let shopId = shopQuery.taskBusinessKey?shopQuery.taskBusinessKey:shopQuery.shopId;
                shopDetail({shopId: shopId, dataToken: shopQuery.dataToken, operationCode:shopQuery.operationCode},success=>{
                            this.shopInfo = success.obj;
                })
            }
        }
    }

</script>
<style scoped>
</style>


