/**
* 头部菜单
*/
<template>
    <el-menu class="el-menu-demo" mode="horizontal" background-color="#303133" text-color="#fff" active-text-color="#fff">
        <el-button class="buttonimg" type="text">
            <img class="showimg" :src="collapsed?imgsq:imgshow" @click="toggle(collapsed)">
        </el-button>
        <el-row  class="shop-info"  v-if="shopInfo != null">
          当前店铺:{{shopInfo.shopName}}
        </el-row>

      <el-row  class="shop-info"  v-if="loading == true && isWxHelper">
         <notice></notice>
      </el-row>
        <el-submenu index="2" class="submenu" style="float: right;">
            <template slot="title">
                <span slot="title">{{user.userName}}</span>
            </template>
            <el-menu-item style="color: #FF6600" v-if="loading == true && shopInfo != null"  @click="toRouter('shopOperationList')" index="2-0">切换店铺</el-menu-item>
            <el-menu-item v-if="loading == true"  @click="toRouter('userInfo')" index="2-1">基本信息</el-menu-item>
            <el-menu-item v-if="loading == true && isWxHelper"  @click="toRouter('openVip')" index="2-1">vip延期</el-menu-item>
<!--            <el-menu-item v-if="loading == true && isWxHelper"  @click="toRouter('collectIndex')" index="2-1">合作咨询</el-menu-item>-->
           <!--<el-menu-item v-if="loading == true" @click="toRouter('updatePassword')" index="2-2">修改密码</el-menu-item>-->
            <el-menu-item @click="exit()" index="2-3">退出</el-menu-item>
        </el-submenu>
    </el-menu>
</template>
<script>
    import { getCurrentUser} from "@/api/user";
    import {userLoginOut} from "@/api/login";
    import {cache} from '@/store/store';
    import notice from "@/components/notice.vue";
    export default {
        name: 'headNav',
      props:{
          shopInfo:{
            type: Object,
            default:()=>{return null}
          }
      },
        data() {
            return {
                collapsed: true,
                imgshow: 'https://market-public-image.oss-cn-guangzhou.aliyuncs.com/nav-show.png',
                imgsq: 'https://market-public-image.oss-cn-guangzhou.aliyuncs.com/nav-hiden.png',
                user: {},
                loading: false,
                isWxHelper:false
            }
        },
        // 创建完毕状态(里面是操作)
        created() {
            getCurrentUser(success=>{
                this.loading=true;
                this.user =success.obj.currentUser;
                if(success.obj.currentUser && success.obj.currentUser.currentCompanyCode){
                  this.isWxHelper = success.obj.currentUser.currentCompanyCode === "wxHelperUser";
                }
                cache.addMemoryCache("currentUser",this.user);
            })
        },
        methods: {
            // 退出登录
            toRouter(path){
                this.$router.push({ path: path})
            },
            exit() {
                this.$confirm('退出登录, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        userLoginOut(success=>{
                            cache.clear();
                            this.$router.push({ path: '/login' })
                            this.$message({
                                type: 'success',
                                message: '已退出登录!'
                            })
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '取消退出'
                        })
                    })
            },
            // 切换显示
            toggle(showtype) {
                this.collapsed = !showtype
                this.$root.Bus.$emit('toggle', this.collapsed)
            }
        },
      components: {
        notice
      }
    }
</script>
<style scoped>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        border: none;
    }
    .submenu {
        float: right;
    }
    .buttonimg {
        height: 60px;
        background-color: transparent;
        border: none;
    }
    .showimg {
        width: 26px;
        height: 26px;
        position: absolute;
        top: 17px;
        left: 17px;
    }
    .showimg:active {
        border: none;
    }
    .shop-info{
      width: 30vw;
      position: fixed;
      text-align: center;
      align-items: center;
      left: 45%;
      top: 0;
      font-weight: 400;
      display: block;
      font-size: 1.5em;
      -webkit-margin-before: 0.59em;
      color: white;
    }
</style>