
import http from "../config/http";
import {Message} from "_element-ui@2.15.14@element-ui";
import {localUrl} from "@/config/env";

export const loginLocal = (param,success,error) => request("POST","/login",param,success,error) ;

export const currentIndex = (success,error) => request("GET","/index",{},success,error) ;

export const saveConfig = (param,success,error) => request("POST","/saveConfig",param,success,error) ;

export const saveControlConfig = (param,success,error) => request("POST","/saveControlConfig",param,success,error) ;

export const queryConfig = (param,success,error) => request("GET","/queryConfig/"+param,{},success,error) ;

export const injectWx = (success,error) => request("POST","/inject",{},success,error) ;


export const deleteControlConfig = (param,success,error) => request("POST","/deleteControlConfig",param,success,error) ;

export const queryControlConfig = (param,success,error) => request("GET","/queryControlConfig/"+param,{},success,error) ;

export const queryWxShare = (param,success,error) => request("GET","/queryWxShare/"+param,{},success,error) ;

export const clearTodayWxStatistic = (param,success,error) => request("GET","/clearTodayWxStatistic/"+param,{},success,error) ;

function request(type,url, params,success,error) {
    let config = {
        method:type,
        url:localUrl+url,
        data:params,
    };
    if(type=='GET'){
        config.params = params;
    }else{
        config.data = params;
    }
    return new Promise((resolve, reject) =>{
        http.request(config).then(res=>{
            resolve(res.data);
            if(res && res.data && (res.data.code === 500)){
                error && error(res.data);
                return;
            }
            if(res.data){
                success && success(res.data);
                return;
            }
            if(res.response.status == 403){
                error && error(res.data);
                return;
            }
            if(res.response.data && res.response.data.message){
                error && error(res.data);
                return Message.error({type:"error",message:res.response.data.message,duration:1500});
            }
            if(res.response.status != 403){
                Message.error({type:"error",message:"系统异常,程序员正在紧急修复",duration:1500});
                error && error(res.data);
            }
        }).catch(err =>{
            reject(err.data)
            Message.error({type:"error",message:"请打开本地应用",duration:1500});
            error && error(err.data);
        })
    });
}