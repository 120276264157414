import {_get,_post} from '../http/index';
//分页查询产品列表
export const findGoodsPageList = (page,param,success,error) =>  _get("/market/manager/goods/findGoodsPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除产品
export const deleteGoods = (param,success,error) =>  _get("/market/manager/goods/deleteGoods", param,success,error) ;
//保存产品
export const addGoods = (param,success,error) =>  _post("/market/manager/goods/addGoods", param,success,error) ;
//查询产品详情
export const findGoodsDetailByEdit = (param,success,error) =>  _get("/market/manager/goods/findGoodsDetailByEdit", param,success,error) ;
//修改产品信息
export const updateGoods = (param,success,error) =>  _post("/market/manager/goods/updateGoods", param,success,error) ;
//删除sku信息
export const deleteGoodsSku = (param,success,error) =>  _post("/market/manager/goodsSku/deleteGoodsSku", param,success,error) ;
//商品绑定店铺
export const allocationGoodsToShop = (param,success,error) =>  _post("/market/manager/goods/allocationGoodsToShop", param,success,error) ;
