<template>
  <el-row>
    <el-form ref="brandInfoForm" :rules="orderGoodsInfoRule" :model="orderGoodsInfo" label-width="120px">
      <el-form-item  label="商品图片">
        <el-col :span="12">
          <el-image class="goods-main-image" fit="scale-down"  :src="orderGoodsInfo.goodsPhotoPath"></el-image>
        </el-col>
      </el-form-item>
      <el-form-item  label="产品名称">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.goodsName" readonly></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="sku名称">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.skuName" readonly></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="商品价格">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.shopPrice" readonly></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="vip价格">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.memberPrice" readonly></el-input>
        </el-col>
      </el-form-item>
      <el-form-item v-if="goodsType == 0"  prop="firstGoodsPrice" label="首单价格">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.firstGoodsPrice" ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item v-if="goodsType == 1"  prop="specialPrice" label="商品特价">
        <el-col :span="12">
          <el-input v-model="orderGoodsInfo.specialPrice" ></el-input>
        </el-col>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
export default {
  name: "firstOrderGoodsInfo",
  props:{
    goodsType:{
      type:String,
      default:'0'
    },
    orderGoodsInfo:{
      type:Object,
      default:()=>{return null}
    }
  },
  data(){
    return{
      orderGoodsInfoRule:{
        firstGoodsPrice:[
          { required:true,message:"首单价格不能为空",trigger:'blur'}
        ],
        specialPrice:[
          { required:true,message:"特价不能为空",trigger:'blur'}
        ]
      }
    }
  }
}
</script>

<style scoped>
.goods-main-image{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>