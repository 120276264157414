<template>
  <el-row>
    <el-dialog
        :title="brandInfo && brandInfo.brandId?'修改品牌':'添加品牌'"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="brandDialog"
        @close="cancelAddBrandInfo"
    >
      <el-row>
        <el-form ref="brandInfoForm" :rules="brandInfoRule" :model="brandInfoDetail" label-width="120px">
          <el-form-item prop="brandName" label="品牌名称">
            <el-col :span="12">
              <el-input v-model="brandInfoDetail.brandName" placeholder="请输入品牌名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="brandName" label="品牌logo">
            <imageUpload v-bind:file="brandInfoDetail.attachmentUploadVo" v-bind:limit="1" v-bind:type="'image'"  @uploadSuccess="brandUploadSuccess"></imageUpload>
          </el-form-item>
          <el-form-item prop="brandRemark" label="备注">
            <el-col :span="12">
              <el-input type="textarea" v-model="brandInfoDetail.brandRemark" placeholder="请输入备注"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddBrandInfo">取 消</el-button>
                    <el-button type="primary" @click="addBrand('brandInfoForm')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import imageUpload from '@/components/image-upload';
import {addGoodsBrand,updateGoodsBrand} from '@/api/brand';
export default {
  name: "brandInfo",
  props:['addBrandDialog','brandInfo'],
  data(){
    return{
      brandInfoDetail:{},
      brandInfoRule:{
        brandName:[
          { required: true, message: '品牌名称不能为空', trigger: 'blur' },
        ],
        photoTransversePosition:[
          { required: true, message: '品牌logo不能为空', trigger: 'blur' },
        ]
      },
      brandDialog:false
    }
  },
  mounted(){
    this.brandDialog = this.addBrandDialog;
    if(this.brandInfo && this.brandInfo.brandId){
      this.brandInfoDetail = this.brandInfo;
    }
  },
  methods:{
    cancelAddBrandInfo:function () {
      this.$emit("cancelAddBrandInfo");
    },
    addBrand:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.brandInfoDetail.brandId){
            updateGoodsBrand(this.brandInfoDetail,success=>{
              this.$message({type:"success",message:"修改品牌信息成功",duration:1500});
              this.$emit("editBrandSuccess");
            });
          }else{
            addGoodsBrand(this.brandInfoDetail,success=>{
              this.$message({type:"success",message:"新增品牌信息成功",duration:1500});
              this.$emit("addBrandSuccess");
            });
          }
        } else {
          return false;
        }
      });
    },
    brandUploadSuccess:function(imageList){
      if(imageList && imageList.length>0){
        this.brandInfoDetail.brandPhotoPath
            =imageList[0].attachmentObjectName;
      }else{
        this.brandInfoDetail.brandPhotoPath = '';
      }
    },
  },
  components:{
    imageUpload
  }
}
</script>

<style scoped>

</style>