<template>
  <el-row>
    <el-row class="recharge-query-form">
      <el-form :inline="true" :model="rechargeQueryParam" >
        <el-form-item label="充值券名称" label-width="100px">
          <el-input v-model="rechargeQueryParam.rechargeName" placeholder="充值券名称"></el-input>
        </el-form-item>
        <el-form-item label="充值券状态" label-width="100px">
          <el-select v-model="rechargeQueryParam.rechargeStatus" placeholder="请选择">
            <el-option
                v-for="item in rechargeStatusList"
                :key="item.itemCode"
                :label="item.itemName"
                :value="item.itemCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="rechargeListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="recharge-info-add">
      <el-button type="primary" icon="el-icon-plus" @click="addRechargeInfoClick">创建充值券</el-button>
    </el-row>
    <el-row class="recharge-info-table">
      <el-table
          border
          :data="rechargeList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="rechargeName" label="充值券名称"></el-table-column>
        <el-table-column prop="rechargeMoney" label="充值金额"></el-table-column>
        <el-table-column prop="giveMoney" label="赠送金额"></el-table-column>
        <el-table-column prop="rechargeBeginDate" label="开始时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
        <el-table-column prop="rechargeEndDate" label="结束时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
        <el-table-column prop="rechargeUseTotal" label="充值次数" ></el-table-column>
        <el-table-column prop="rechargeNumLimit" label="每人限充次数" :formatter="formatRechargeNumLimit"></el-table-column>
        <el-table-column prop="rechargeStatus" label="状态" :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'RECHARGE_STATUS')"></el-table-column>
        <el-table-column prop="createdBy" label="创建者"></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
        <el-table-column  label="操作" width="240px">
          <template slot-scope="props">
            <el-button plain v-if="props.row.rechargeStatus != '2'"  type="primary" size="mini" @click="handOutRecharge(props.row)">发放</el-button>
            <el-button plain v-if="props.row.rechargeStatus == '2'"  type="danger" size="mini" @click="outOfRecharge(props.row)">下架</el-button>
            <el-button plain  type="warning" size="mini" @click="rechargeInfoUpdate(props.row)">编辑</el-button>
            <el-button plain v-if="canShowHistory(props.row)"  size="mini" @click="showRechargeHistory(props.row)">充值记录</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="recharge-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <add-recharge-info v-if="addRechargeDialog == true" :addRechargeDialog="addRechargeDialog" :rechargeInfo="currentRechargeInfo" @editRechargeSuccess="editRechargeSuccess" @cancelAddRechargeInfo="cancelAddRechargeInfo" @addRechargeSuccess="addRechargeSuccess"></add-recharge-info>
    <el-dialog
        title="充值券充值记录"
        width="50%"
        :close-on-click-modal="false"
        :visible.sync="rechargeHisDialog"
        @close="cancelAddRechargeInfo"
    >
      <rechargeHisListPage v-if="rechargeHisDialog" @handleSizeChange="hisHandleSizeChange" @handleCurrentChange="hisHandleCurrentChange"
                           :type="'dialog'" :recharge-his-list="rechargeHisList" :page="hisPage" :page-info="hisPageInfo"></rechargeHisListPage>
    </el-dialog>
  </el-row>
</template>

<script>
import {findRechargeInfoPageList,handOutRecharge,outOfRecharge,queryRechargeHistoryOne} from '@/api/recharge';
import addRechargeInfo from "@/shopViews/recharge/addRechargeInfo";
import rechargeHisListPage from "@/shopViews/recharge/rechargeHisListPage";
export default {
  name: "rechargeListPage",
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      hisPage:{
        pageSize:15,
        currentPage:1
      },
      hisPageInfo:{
        count:0,
        totalPage:0
      },
      rechargeStatusList:[],
      currentRechargeInfo:{},
      rechargeQueryParam:{},
      rechargeList:[],
      rechargeHisList:[],
      addRechargeDialog:false,
      rechargeHisDialog:false,
    }
  },
  mounted(){
    this.rechargeStatusList = this.$lookupInfo('RECHARGE_STATUS')
    this.rechargeListPageQuery();
  },
    methods:{
    formatRechargeNumLimit:function (row, column, cellValue, index){
        if(row.rechargeNumType == '1'){
          return '不限';
        }
        return cellValue;
    },
    canShowHistory:function (rechargeInfo){
      if(rechargeInfo.rechargeStatus == '1'){
        return false;
      }
      if(new Date(rechargeInfo.rechargeBeginDate).getTime()<=new Date().getTime()){
        return true;
      }
      return true;
    },
    editRechargeSuccess:function(){
      this.addRechargeDialog=false;
    },
    rechargeInfoUpdate:function(rechargeInfo){
      this.currentRechargeInfo = rechargeInfo;
      this.addRechargeDialog=true;
    },
    addRechargeInfoClick:function(){
      this.currentRechargeInfo = {};
      this.addRechargeDialog=true;
    },
    addRechargeSuccess:function(){
      this.addRechargeDialog=false;
      this.queryReset();
    },
    cancelAddRechargeInfo:function(){
      this.addRechargeDialog=false;
    },
    rechargeListPageQuery:function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      findRechargeInfoPageList(this.page,this.rechargeQueryParam,success=>{
        this.rechargeList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function () {
      this.rechargeQueryParam={};
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.rechargeListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.rechargeListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.rechargeListPageQuery(true);
    },
      hisHandleSizeChange:function (val){
        this.hisPage.pageSize = val;
        this.hisRechargeListPageQuery();
      },
      hisHandleCurrentChange:function(val){
        this.hisPage.currentPage = val;
        this.hisRechargeListPageQuery(true);
      },
       hisRechargeListPageQuery:function (notResetCurrentPage){
         if(!notResetCurrentPage){
           this.page.currentPage=1;
         }
         queryRechargeHistoryOne(this.hisPage,{rechargeId:this.currentRechargeInfo.rechargeId},success=>{
           this.rechargeHisList = success.data;
           this.hisPageInfo.count = success.count;
           this.hisPageInfo.totalPage = success.totalPage;
         });

       },
      showRechargeHistory:function (rechargeInfo){
        this.rechargeHisDialog=true;
        this.currentRechargeInfo = rechargeInfo;
        this.hisRechargeListPageQuery();
      },
     handOutRecharge:function (rechargeInfo) {
      this.$confirm('是否发放充值券 <span style="color: red">'+rechargeInfo.rechargeName+'</span>', '发放提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            handOutRecharge(rechargeInfo,success=>{
              rechargeInfo.rechargeStatus = '2';
              this.$message({type:"success",message:"发放成功",duration:1500});
            })
          })
          .catch(() => {

          })
    },
      outOfRecharge:function (rechargeInfo) {
        this.$confirm('是否下架充值券 <span style="color: red">'+rechargeInfo.rechargeName+'</span>', '下架提示', {
          dangerouslyUseHTMLString:true,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
            .then(() => {
              outOfRecharge(rechargeInfo,success=>{
                rechargeInfo.rechargeStatus = '3';
                this.$message({type:"success",message:"下架成功",duration:1500});
              })
            })
            .catch(() => {

            })
      }
  },
  components:{
    addRechargeInfo,
    rechargeHisListPage
  }
}
</script>

<style scoped>
.recharge-info-table{
  margin-top: 8px;
}
.recharge-table-page{
  margin-top: 8px;
}
</style>