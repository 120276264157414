<template>
  <el-row>
    <el-row v-if="type == 'catalog'" class="recharge-query-form">
      <el-form :inline="true" :model="rechargeHisQueryParam" >
        <el-form-item label="充值券名称" label-width="100px">
          <el-input v-model="rechargeHisQueryParam.rechargeName" placeholder="充值券名称"></el-input>
        </el-form-item>
        <el-form-item label="订单编号" label-width="80px">
          <el-input v-model="rechargeHisQueryParam.orderNo" placeholder="订单编号"></el-input>
        </el-form-item>
        <el-form-item label="充值时间" label-width="80px">
          <el-col :span="10">
            <el-form-item >
              <el-date-picker
                  v-model="rechargeHisQueryParam.startTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择开始日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="2">-</el-col>
          <el-col   :span="10">
            <el-form-item >
              <el-date-picker
                  v-model="rechargeHisQueryParam.endTime"
                  type="datetime"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  placeholder="选择结束日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="rechargeListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="recharge-his-info-table">
      <el-table
          border
          :data="rechargeHisList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="orderNo" label="订单编号"></el-table-column>
        <el-table-column prop="memberName" label="会员昵称"></el-table-column>
        <el-table-column prop="rechargeName" label="充值券名称"></el-table-column>
        <el-table-column prop="rechargeMoney" label="	充值金额" ></el-table-column>
        <el-table-column prop="giveMoney" label="	赠送金额" ></el-table-column>
        <el-table-column prop="rechargeTime" label="	充值时间"  :formatter="this.$formatTableCellDate" width="160px" ></el-table-column>
      </el-table>
    </el-row>
    <el-row class="recharge-his-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
  </el-row>
</template>

<script>
import {queryRechargeHistoryPage} from "@/api/recharge";

export default {
  name: "rechargeHisListPage",
  props:{
    rechargeHisList:{
      type:Array,
      default:()=>{return []}
    },
    page:{
      type:Object,
      default:()=>{
        return {
          pageSize:15,
          currentPage:1
        }
      }
    },
    pageInfo:{
      type:Object,
      default:()=>{
        return{
          count:0,
          totalPage:0
        }
      }
    },
    type:{
      type:String,
      default:'catalog'
    }
  },
  data(){
    return{
      rechargeHisQueryParam:{}
    }
  },
  mounted() {
    if(this.type == 'catalog'){
       this.rechargeListPageQuery();
    }
  },
  methods:{
    rechargeListPageQuery:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      queryRechargeHistoryPage(this.page,this.rechargeHisQueryParam,success=>{
        this.rechargeHisList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function (){
      this.rechargeHisQueryParam={};
      this.rechargeListPageQuery();
    },
    handleSizeChange:function (val){
      if(this.type == 'catalog'){
        this.page.pageSize = val;
        this.rechargeListPageQuery();
        return;
      }
      this.$emit("handleSizeChange",val);
    },
    handleCurrentChange:function (val){
      if(this.type == 'catalog'){
        this.page.currentPage = val;
        this.rechargeListPageQuery(true);
        return;
      }
      this.$emit("handleCurrentChange",val);
    }
  }
}
</script>

<style scoped>

.recharge-his-info-table,.recharge-his-table-page{
  margin-top: 8px;
}
</style>