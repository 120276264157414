//查询系统用户列表
import {_get,_post} from "@/http";
//查询商家用户分页列表
export const findShopUserPage =(page, param, success, error) =>  _get('/market/shop/shopUser/queryShopUserPage/'+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除商家用户
export const deleteShopUser =(param,success,error) =>  _get('/market/shop/shopUser/deleteShopUser', param,success,error) ;
//添加商家用户
export const addShopUser =(param,success,error) =>  _post('/market/shop/shopUser/saveShopUser', param,success,error) ;
//查询商家详情
export const shopUserInfo =(param,success,error) =>  _get('/market/shop/shopUser/shopUserInfo/'+param.shopUserId, {},success,error) ;
//查询商家用户角色
export const queryShopUserRoles =(param,success,error) =>  _get('/market/shop/shopUserRole/queryShopUserRoles',param,success,error) ;
//查询用户可绑定角色
export const queryRoleOptions =(param,success,error) =>  _get('/market/shop/shopUserRole/queryRoleOptions',param,success,error) ;
//批量修改用户角色权限
export const batchSaveShopUserRole =(param,success,error) =>  _post('/market/shop/shopUserRole/batchSaveShopUserRole',param,success,error) ;


