<template>
  <el-row :id="scrollId">
    <el-row style="position: absolute;z-index:100"  v-if="openWx">
      <el-button size="small" type="primary" @click="openWxClick()" >启动微信</el-button>
      <el-button size="small" type="primary" @click="shareWxClick()" >分享</el-button>
    </el-row>
    <el-row class="center-title">{{title}}</el-row>
    <dv-scroll-board :config="scrollConfig" />
  </el-row>
</template>

<script>
import {injectWx} from "@/api/wxHelperLocal"
import {getShareSign} from "@/api/wxHelper";
import {baseUrl} from "@/config/env";

import {write} from 'clipboardy';
export default {
  name: 'scrollBoard',
  props:{
    config:{

    },
    title:{
      type:String,
      default:""
    },
    scrollId:{
      type:String,
      default:"scroll-board"
    },
    openWx:{
      type:Boolean,
      default:false
    }
  },
  watch:{
    config(val){
      this.scrollConfig = val;
    }
  },
  data () {
    return {
      scrollConfig:this.config
    }
  },
  methods:{
    openWxClick :function (){
      injectWx(success=>{
        this.$successMessage("启动成功")
      })
    },
    shareWxClick:function (){
      getShareSign(success=>{
        if(success.obj){
          let text = baseUrl+"/#/shareStatistics?sign="+encodeURIComponent(String(success.obj))
          this.copyText(text);
        }
      })
    },
    async copyText(text) {
      // navigator clipboard 需要https等安全上下文
      if (navigator.clipboard && window.isSecureContext) {
        // navigator clipboard 向剪贴板写文本
        return navigator.clipboard.writeText(text).then(() => {
         this.$successMessage("复制成功")
        });
      } else {
        // 创建text area
        let textArea = document.createElement("textarea");
        textArea.value = text
        // 使 text area 不在viewport，同时设置不可见
        textArea.style.position = "absolute";
        textArea.style.opacity = '0';
        textArea.style.left = "-999999px";
        textArea.style.top = "-999999px";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        return new Promise((res, rej) => {
          // 执行复制命令并移除文本框
          document.execCommand('copy') ? res(null) : rej();
          textArea.remove();
          this.$successMessage("复制成功")
        });
      }
    }
  }
}
</script>

<style lang="less">
#scroll-board-all {
  box-sizing: border-box;
  margin: 10px;
  height: 100%;
  overflow: hidden;
}
#scroll-board {
  width: 50%;
  box-sizing: border-box;
  margin: 10px;
  height: 100%;
  overflow: hidden;
}
.center-title {
  font-size: 15px;
  text-align: center;
  padding: 8px;
}
</style>
