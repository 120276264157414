<template>
 <el-row>
   <el-row class="stock-query-form">
     <el-form :inline="true" :model="stockClassifyParam" >
       <el-form-item label="应用名" label-width="80px">
         <el-input v-model="stockClassifyParam.classifyName" placeholder="分类名称"></el-input>
       </el-form-item>
       <el-form-item  >
         <el-button icon="el-icon-search" type="primary" @click="initStockClassify">查询</el-button>
         <el-button @click="queryReset">重置</el-button>
       </el-form-item>
     </el-form>
   </el-row>
   <el-row class="classify-info-add">
     <el-button type="primary" icon="el-icon-plus" @click="addStockClassifyClick">添加</el-button>
   </el-row>
   <el-row class="classify-info-table">
     <el-table
         border
         :data="stockClassifyList"
         :header-cell-style="{background:'#F3F4F7',color:'#555'}"
     >
       <el-table-column prop="classifyName" label="分类名称"></el-table-column>
       <el-table-column prop="createdBy" label="创建者"></el-table-column>
       <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
       <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
       <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
       <el-table-column  label="操作" width="370px">
         <template slot-scope="props">
           <el-button plain  type="success" size="mini" @click="classifyAddStore(props.row)">库存引入</el-button>
           <el-button plain  type="success" size="mini" @click="classifyRemoveStore(props.row)">库存移除</el-button>
           <el-button plain  type="warning" size="mini" @click="classifyInfoUpdate(props.row)">编辑</el-button>
           <el-button plain  type="danger" size="mini" @click="classifyInfoDelete(props.row)">删除</el-button>
         </template>
       </el-table-column>
     </el-table>
   </el-row>
   <el-row class="classify-table-page">
     <el-pagination
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         :current-page="page.currentPage"
         :page-sizes="[10,15,30,50,100]"
         :page-size="page.pageSize"
         layout="total, sizes, prev, pager, next, jumper"
         :total="pageInfo.count">
     </el-pagination>
   </el-row>
   <stock-classify-info v-if="addStockClassify == true" :addClassifyDialog="addStockClassify" :classifyInfo="currentClassifyInfo" @editClassifySuccess="editAppSuccess" @cancelAddClassifyInfo="cancelAddAppInfo"></stock-classify-info>
   <el-dialog
       title="库存引入"
       width="70%"
       :close-on-click-modal="false"
       :visible.sync="classifyAddStoreDialog"
       @close="cancelAddStoreDialog"
   >
     <goods-stock-page v-if="classifyAddStoreDialog == true"  :query-param="currentClassifyInfo.classifyId"  :addGoodsStock="addGoodsStock"  :goods-category-tree="goodsCategoryTree" :brand-list="brandList" :stock-statistics="stockStatistics"  publish-status="1" use-type="4"></goods-stock-page>
   </el-dialog>
   <el-dialog
       title="库存移除"
       width="70%"
       :close-on-click-modal="false"
       :visible.sync="classifyRemoveStoreDialog"
       @close="cancelRemoveStoreDialog"
   >
     <goods-stock-page v-if="classifyRemoveStoreDialog == true" :query-param="currentClassifyInfo.classifyId" :removeGoodsStock="removeGoodsStock"  :goods-category-tree="goodsCategoryTree" :brand-list="brandList" :stock-statistics="stockStatistics"  publish-status="1" use-type="5"></goods-stock-page>
   </el-dialog>
 </el-row>
</template>

<script>
import stockClassifyInfo from "@/shopViews/stock/stockClassifyInfo";
import {
  findStockClassify,
  deleteClassifyInfo,
  addStockClassifyDetail,
  removeStockClassifyDetail
} from '@/api/stockClassify'
import goodsStockPage from "@/shopViews/stock/goodsStockPage";
import {findGoodsCategoryTreeList} from "@/api/goodsCategory";
import {findGoodsBrand} from "@/api/brand";
import {getStockStatistics} from "@/api/stock";
export default {
  name: "stockClassifyListPage",
  components:{
    stockClassifyInfo,
    goodsStockPage
  },
  data() {
    return {
      goodsCategoryTree:[],
      brandList:[],
      stockStatistics:{
        warehouseNum:0,
        sellNum:0,
        warnNum:0
      },
      classifyAddStoreDialog:false,
      classifyRemoveStoreDialog:false,
      stockClassifyList: [],
      stockClassifyParam:{},
      currentClassifyInfo:{},
      addStockClassify:false,
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  created() {
    this.initStockClassify();
    this.initGoodsCategoryTree();
    this.initGoodsBrand();
    this.initStockStatistics();
  },
  methods: {
    removeGoodsStock:function (stockList){
      if(stockList.length == 0){
        this.$errorMessage('请选择需要移除的库存');
        return;
      }
      let list = [];
      stockList.forEach(item=>{
        let stock = new Object();
        stock.classifyId = this.currentClassifyInfo.classifyId;
        stock.stockId = item.goodsStockId;
        list.push(stock);
      })
      removeStockClassifyDetail(list,success=>{
        this.$successMessage('移除库存成功');
        this.classifyRemoveStoreDialog=false;
        this.currentClassifyInfo={};
      });
    },
    addGoodsStock:function (stockList){
       if(stockList.length == 0){
         this.$errorMessage('请选择需要添加的库存');
         return;
       }
       let list = [];
      stockList.forEach(item=>{
         let stock = new Object();
         stock.classifyId = this.currentClassifyInfo.classifyId;
         stock.stockId = item.goodsStockId;
         list.push(stock);
      })
      addStockClassifyDetail(list,success=>{
         this.$successMessage('添加库存成功');
         this.classifyAddStoreDialog=false;
         this.currentClassifyInfo={};
      });
    },
    initGoodsCategoryTree:function (){
      findGoodsCategoryTreeList(success=>{
        this.goodsCategoryTree = success;
      })
    },
    initGoodsBrand:function (){
      findGoodsBrand(success=>{
        this.brandList=success;
      })
    },
    initStockStatistics:function (){
      getStockStatistics(success=>{
        this.stockStatistics = success.obj;
      })
    },
    cancelAddStoreDialog:function (){
       this.classifyAddStoreDialog=false;
    },
    cancelRemoveStoreDialog:function (){
      this.classifyRemoveStoreDialog = false;
    },
    classifyRemoveStore:function (data){
      this.classifyRemoveStoreDialog=true;
      this.currentClassifyInfo=data;
    },
    classifyAddStore:function (data){
      this.classifyAddStoreDialog=true;
      this.currentClassifyInfo=data;
    },
    classifyInfoUpdate:function (data){
       this.addStockClassify=true;
       this.currentClassifyInfo = data;
    },
    classifyInfoDelete:function (data){
      this.currentClassifyInfo = data;
      this.$confirm('是否删除分类 <span style="color: red">'+data.classifyName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteClassifyInfo(this.currentClassifyInfo,success=>{
              this.$successMessage('删除成功');
              this.stockClassifyList.forEach((item,index)=>{
                if(item.classifyId == data.classifyId){
                  this.stockClassifyList.splice(index,1);
                }
              });
            })
          })
          .catch(() => {

          })
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.initStockClassify();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.initStockClassify();
    },
    addStockClassifyClick:function (){
      this.currentClassifyInfo={}
       this.addStockClassify=true;
    },
    cancelAddAppInfo:function (){
      this.addStockClassify=false;
    },
    editAppSuccess:function (){
      this.addStockClassify=false;
      this.initStockClassify();
    },
    queryReset:function (){
      this.stockClassifyParam = {};
      this.initStockClassify();
    },
   initStockClassify:function (){
     findStockClassify(this.page,this.stockClassifyParam,success=>{
       this.stockClassifyList = success.data;
     });
   }
  }
}
</script>

<style scoped>
 .classify-info-table,.classify-table-page{
   margin-top: 10px;
 }
</style>