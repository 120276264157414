<template>
  <el-row>
     <el-form label-width="100px"  :model="basicConfigInfo">
       <el-form-item label="外卖开关">
         <template>
           <el-radio v-model="basicConfigInfo.takeawayEnable" label="1">开启</el-radio>
           <el-radio v-model="basicConfigInfo.takeawayEnable" label="0" >关闭</el-radio>
         </template>
       </el-form-item>
       <el-row v-if="basicConfigInfo.takeawayEnable == 1">
         <el-form-item  label="配送门槛">
           <el-col :span="6">
             <el-input type="number"  v-model="basicConfigInfo.sendFee" placeholder="配送门槛"></el-input>
           </el-col>
           <el-col  class="unit_info" :span="1">
             <span>元</span>
           </el-col>
         </el-form-item>
         <el-form-item  label="外卖范围">
           <el-col :span="6">
             <el-input  type="number" v-model="basicConfigInfo.takeawayRange" placeholder="外卖范围"></el-input>
           </el-col>
           <el-col  class="unit_info" :span="1">
             <span>km</span>
           </el-col>
         </el-form-item>
         <el-form-item  label="配送费">
           <el-col :span="6">
             <el-input type="number"  v-model="basicConfigInfo.takeawayPrice" placeholder="配送费"></el-input>
           </el-col>
           <el-col  class="unit_info" :span="1">
             <span>元</span>
           </el-col>
         </el-form-item>
         <el-form-item  label="配送时长">
           <el-col :span="6">
             <el-input type="number"  v-model="basicConfigInfo.takeawayTime" placeholder="配送时长"></el-input>
           </el-col>
           <el-col  class="unit_info" :span="1">
             <span>分钟</span>
           </el-col>
         </el-form-item>
       </el-row>
       <el-form-item v-model="basicConfigInfo.freightFreeStatus" v-if="basicConfigInfo.takeawayEnable == 1" label="免运费开关">
         <template>
           <el-radio v-model="basicConfigInfo.freightFreeStatus" label="1">开启</el-radio>
           <el-radio v-model="basicConfigInfo.freightFreeStatus" label="0" >关闭</el-radio>
         </template>
       </el-form-item>
       <el-form-item  v-if="basicConfigInfo.takeawayEnable == 1 && basicConfigInfo.freightFreeStatus == 1" label="免除运费门槛">
         <el-col :span="6">
         <el-input type="number" v-model="basicConfigInfo.freightFreePrice" placeholder="免除运费门槛"></el-input>
         </el-col>
         <el-col  class="unit_info" :span="1">
           <span>元</span>
         </el-col>
       </el-form-item>
       <el-form-item label="vip开关">
         <template>
           <el-radio v-model="basicConfigInfo.vipEnable" label="1">开启</el-radio>
           <el-radio v-model="basicConfigInfo.vipEnable" label="0" >关闭</el-radio>
         </template>
       </el-form-item>
       <el-row  v-if="basicConfigInfo.vipEnable == 1" >
       <el-form-item label="vip首冲">
         <el-col :span="6">
         <el-input type="number" v-model="basicConfigInfo.vipPrice" placeholder="首次重置"></el-input>
         </el-col>
         <el-col class="unit_info" :span="1">
           <span>元</span>
         </el-col>
       </el-form-item>
       <el-form-item  label="VIP推荐返现">
         <el-col :span="6">
         <el-input type="number" v-model="basicConfigInfo.vipReturnPrice" placeholder="VIP推荐人返现"></el-input>
         </el-col>
         <el-col  class="unit_info" :span="1">
           <span>元</span>
         </el-col>
       </el-form-item>
       <el-form-item label="VIP享受折扣">
         <el-col :span="6">
           <el-input-number v-model="basicConfigInfo.vipDiscount" :precision="1" :step="0.1" :min="0.1" :max="10"></el-input-number>
         </el-col>
         <el-col  class="unit_info" :span="1">
           <span>折</span>
         </el-col>
       </el-form-item>
       </el-row>
       <el-form-item  >
         <el-button  type="primary" @click="saveBasicConfig">保 存</el-button>
       </el-form-item>
     </el-form>
  </el-row>
</template>

<script>
import {queryBaseConfig,saveFreightFree} from  '@/api/config';
export default {
  name: "basicConfig",
  data(){
    return {
      basicConfigInfo: {
        takeawayEnable:'0',
        vipEnable:'0',
        freightFreeStatus:'0',
        vipDiscount:9.5
      }
    }
  },
  mounted() {
    this.queryBasicConfig();
  },
  methods:{
    queryBasicConfig:function (){
      queryBaseConfig(success=>{
        this.basicConfigInfo = success.obj;
        if(!this.basicConfigInfo.takeawayEnable){
          this.basicConfigInfo.takeawayEnable = '0';
        }
        if(!this.basicConfigInfo.vipEnable){
          this.basicConfigInfo.vipEnable = '0';
        }
        if(!this.basicConfigInfo.freightFreeStatus){
          this.basicConfigInfo.freightFreeStatus = '0';
        }
      })
    },
    saveBasicConfig:function (){
      saveFreightFree(this.basicConfigInfo,success=>{
        this.$successMessage('保存成功');
      })
    }
  }
}
</script>

<style scoped>
  .unit_info{
    padding-left: 8px;
  }
</style>