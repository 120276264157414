<template>
  <el-row>
    <el-row>
      <draggable tag="ul" class="el-upload-list el-upload-list--picture-card" @update="selectChange"  v-model="checkImageList" v-bind="dragOptions" >
        <li v-for="imageInfo in checkImageList" :key="imageInfo.selected" :style="imageInfo.selected==true?'border:1px solid red':''" class="el-upload-list__item is-ready">
            <el-image  :src="imageInfo.downloadUrl" class="image-info" fit="scale-down" @click="selectImage(imageInfo)" />
        </li>
      </draggable>
      <el-row    class="el-upload el-upload--picture-card"><i @click="imageSelect" class="el-upload el-upload--picture-card el-icon-plus"></i></el-row>
    </el-row>
    <el-dialog
        title="选择图片"
        width="70%"
        :close-on-click-modal="false"
        :visible.sync="imageShowDialog"
        :modal="false"
        @close="cancelImageShow"
    >
      <el-row class="photo-category-info">
        <el-row>
          <el-checkbox-group v-model="checkImageList">
            <el-col class="image-card" v-for="item in imageList" :key="item.id" >
              <el-checkbox @change="selectChange" :disabled="item.disabled" :label="item" style="position: absolute;z-index: 1"></el-checkbox>
              <el-image :src="item.downloadUrl" class="image-info" fit="scale-down" :preview-src-list="[item.downloadUrl]" />
            </el-col>
          </el-checkbox-group>
        </el-row>
      </el-row>
      <el-row class="image-table-page">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="page.currentPage"
            :page-sizes="[18]"
            :page-size="page.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="pageInfo.count">
        </el-pagination>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="imageShowDialog = false">取 消</el-button>
        <el-button type="primary" @click="imageShowDialog = false">确 定</el-button>
     </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {getPhotoMaterialPageList} from '@/api/photo';
import draggable from "vuedraggable";
export default {
  name: "image-select",
  props:['categoryId','limit','selectMain','list'],
  data(){
    return{
      checkImageList:[],
      imageList:[],
      imageShowDialog:false,
      pageInfo:{
        count:0,
        totalPage:0
      },
      page:{
        pageSize:18,
        currentPage:1
      }
    }
  },
  mounted() {
    if(this.list && this.list.length>0){
      this.checkImageList = this.list;
    }
    this.photoListPageQuery();
  },

  methods:{
    selectImage:function (image){
      if(!this.selectMain){
        return;
      }
      let newImageList = [];
      this.checkImageList.forEach(item=>{
        if(item.id == image.id){
          item.selected=!item.selected;
        }else {
          item.selected=false;
        }
        newImageList.push(item);
      });
      this.checkImageList=newImageList;
    },
    selectChange:function (){
      if(this.checkImageList.length>=this.limit){
        this.imageList.forEach(item=>{
          let disabled = true;
          this.checkImageList.forEach(selectItem=>{
               if(item.id == selectItem.id){
                 disabled=false;
               }
          })
          item.disabled = disabled;
        })
      }else{
        this.imageList.forEach(item=>{
          item.disabled = false;
        });
      }
      this.$emit("selectSuccess",this.checkImageList);
    },
    photoListPageQuery:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      if(this.categoryId || this.categoryName){
        getPhotoMaterialPageList(this.page,{categoryId:this.categoryId},success=>{
          this.imageList = success.data;
          this.pageInfo.count = success.count;
          this.pageInfo.totalPage = success.totalPage;
        });
      }
    },
    imageSelect:function (){
      if(!this.categoryId || this.categoryId < 0){
        this.$errorMessage("图片类目不能为空");
        return;
      }
      this.imageShowDialog=true;
    },
    cancelImageShow:function (){
      this.imageShowDialog=false;
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.photoListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.photoListPageQuery(true);
    },
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      };
    },
  },
  watch: {
    allList(newVal) {
      this.checkImageList = newVal;
    }
  },
  components:{
    draggable
  }
}
</script>
<style>
.el-checkbox>.el-checkbox__label{
  display: none;
}
</style>
<style scoped>
.image-card{
  width: 11vw;
  height: 11vw;
  padding: 10px;
}
.image-table-page{
  padding-left: 10px;
}
.image-info{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
</style>