<template>
  <el-row>
    <el-dialog
        :title="rechargeCardInfo && rechargeCardInfo.rechargeCardId?'修改充值卡':'添加充值卡'"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="rechargeCardDialog"
        @close="cancelAddRechargeCardInfo"
    >
      <el-row>
        <el-form ref="rechargeCardInfoForm" :rules="rechargeCardInfoRule" :model="rechargeCardInfoDetail" label-width="100px">
          <el-form-item v-if="rechargeCardInfo && rechargeCardInfo.rechargeCardBatchId" prop="rechargeCardNo" label="充值卡账号">
            <el-col :span="12">
              <el-input type="number" v-model="rechargeCardInfoDetail.rechargeCardNo" disabled></el-input>
            </el-col>
          </el-form-item>
          <el-form-item  v-if="rechargeCardInfo && rechargeCardInfo.rechargeCardBatchId" prop="batchId" label="充值卡批次">
            <el-col :span="12">
              <el-input type="number" v-model="rechargeCardInfoDetail.batchId" placeholder="请输入充值卡数量" disabled></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="rechargeCardNumber" label="充值卡数量">
            <el-col :span="12">
              <el-input type="number" v-model="rechargeCardInfoDetail.rechargeCardNumber" placeholder="请输入充值卡数量" :disabled="rechargeCardInfo &&
               rechargeCardInfo.rechargeCardBatchId != null"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="rechargeCardAmount" label="充值卡金额">
            <el-col :span="12">
              <el-input v-model="rechargeCardInfoDetail.rechargeCardAmount" placeholder="请输入充值卡金额"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddRechargeCardInfo">取 消</el-button>
                    <el-button type="primary" @click="addRechargeCard('rechargeCardInfoForm')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {addRechargeCardInfo, updateRechargeCardInfo} from '@/api/rechargeCard';
export default {
  name: "addRechargeCardInfo",
  props:['addRechargeCardDialog','rechargeCardInfo'],
  data(){
    return{
      rechargeCardInfoDetail:{},
      rechargeCardInfoRule:{
        rechargeCardNumber:[
          { required: true, message: '充值卡数量不能为空', trigger: 'blur' },
        ],
        rechargeCardAmount:[
          { required: true, message: '充值卡金额不能为空', trigger: 'blur' },
        ],
      },
      rechargeCardDialog:false
    }
  },
  mounted(){
    this.rechargeCardDialog = this.addRechargeCardDialog;
    if(this.rechargeCardInfo && this.rechargeCardInfo.rechargeCardBatchId){
      this.rechargeCardInfoDetail = this.rechargeCardInfo;
    }
  },
  methods:{
    cancelAddRechargeCardInfo:function () {
      this.$emit("cancelAddRechargeCardInfo");
    },
    saveRechargeCard:function (){
      if(this.rechargeCardInfoDetail.rechargeCardBatchId){
        updateRechargeCardInfo(this.rechargeCardInfoDetail,success=>{
          this.$message({type:"success",message:"修改充值卡成功",duration:1500});
          this.$emit("editRechargeCardSuccess");
        });
      }else{
        addRechargeCardInfo(this.rechargeCardInfoDetail,success=>{
          this.$message({type:"success",message:"创建充值优惠卡成功",duration:1500});
          this.$emit("addRechargeCardSuccess");
        });
      }
    },
    addRechargeCard:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.rechargeCardInfoDetail.rechargeCardNumber<=0){
            this.$errorMessage('充值卡数量必须大于0');
            return false;
          }
          if(this.rechargeCardInfoDetail.rechargeCardAmount<=0){
            this.$errorMessage('充值卡金额必须大于0');
            return false;
          }
          this.saveRechargeCard();
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>