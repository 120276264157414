<template>
    <el-row>
        <el-row class="security-query-form">
            <el-form :inline="true" :model="securityQueryParam"  >
                <el-form-item label="秘钥类型" label-width="100px">
                    <el-input v-model="securityQueryParam.secretKeyType" placeholder="秘钥类型"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="securityListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="security-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addSecurityInfo">添加</el-button>
        </el-row>
        <el-row class="security-info-table">
            <el-table
                    border
                    :data="securityList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="secretKeyType" label="秘钥类型"></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作" width="160px">
                    <template slot-scope="props">
                        <el-button plain  type="warning" size="mini" @click="securityInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain  type="danger" size="mini" @click="securityInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="security-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <el-row v-if="addSecurityDialog == true">
            <el-dialog
                    :title="currentSecurityInfo.secretKeyId?'修改密钥':'添加密钥'"
                    width="36%"
                    :close-on-click-modal="false"
                    :visible.sync="addSecurityDialog"
            >
                <el-row>
                    <el-form ref="securityInfoForm" :rules="securityInfoRule" :model="currentSecurityInfo" label-width="120px">
                        <el-form-item prop="secretKeyType" label="密钥类型" >
                            <el-col :span="12">
                                <el-input v-model="currentSecurityInfo.secretKeyType" placeholder="请输入密钥类型" :readonly="currentSecurityInfo.secretKeyId?true:false"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item prop="secretPrivateKeyValue" label="私钥值">
                            <el-col>
                                <el-input type="textarea" v-model="currentSecurityInfo.secretPrivateKeyValue" placeholder="请输入私钥值"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item prop="secretPublicKeyValue" label="公钥值">
                            <el-col>
                                <el-input type="textarea" v-model="currentSecurityInfo.secretPublicKeyValue" placeholder="请输入公钥值"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item prop="secretKeyValue" label="秘钥值">
                            <el-col>
                                <el-input type="textarea" v-model="currentSecurityInfo.secretKeyValue" placeholder="请输入秘钥值"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelSecurityInfo()">取 消</el-button>
                    <el-button type="primary" @click="submitSecurityInfo('securityInfoForm')">确 定</el-button>
                </span>
            </el-dialog>
        </el-row>
    </el-row>
</template>

<script>
    import {findSecretKeyPageList,deleteSecretKey,insertSecretKey,updateSecretKey} from '@/api/secretKey';
    export default {
        name: "secretListPage",
        data(){
            return {
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                currentSecurityInfo:{},
                securityQueryParam:{},
                securityList:[],
                addSecurityDialog:false,
                securityInfoRule:{
                    secretKeyType:[
                        {required:true,message: "秘钥类型不能为空",trigger: 'blur'}
                    ]
                }
            }
        },
        mounted(){
            this.securityListPageQuery();
        },
        methods:{
            cancelSecurityInfo:function(){
                this.currentSecurityInfo={};
                this.addSecurityDialog=false;
            },
            submitSecurityInfo:function(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.currentSecurityInfo.secretKeyId){
                            updateSecretKey(this.currentSecurityInfo,success=>{
                                this.$message({type:"success",message:"修改密钥成功",duration:1500});
                                this.addSecurityDialog=false;
                            });
                        }else{
                            insertSecretKey(this.currentSecurityInfo,success=>{
                                this.$message({type:"success",message:"保存密钥成功",duration:1500})
                                this.addSecurityDialog=false;
                                this.queryReset();
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
            securityInfoUpdate:function(securityInfo){
                this.currentSecurityInfo = securityInfo;
                this.addSecurityDialog=true;
            },
            addSecurityInfo:function(){
                this.currentSecurityInfo = {};
                this.addSecurityDialog=true;
            },
            securityListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findSecretKeyPageList(this.page,this.securityQueryParam,success=>{
                    this.securityList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            queryReset:function () {
                this.securityQueryParam={};
                this.page={
                    pageSize:10,
                    currentPage:1
                };
                this.securityListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.securityListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.securityListPageQuery(true);
            },
            securityInfoDelete:function (securityInfo) {
                this.$confirm('是否删除密钥 <span style="color: red">'+securityInfo.secretKeyType+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteSecretKey(securityInfo,success=>{
                            this.$message({type:"success",message:"删除成功",duration:1500});
                            this.securityList.forEach((item,index)=>{
                                if(item.secretKeyId == securityInfo.secretKeyId){
                                    this.securityList.splice(index,1);
                                }
                            });
                        });
                    })
                    .catch(() => {})
            }
        }
    }
</script>

<style scoped>
    .security-info-table{
        margin-top: 8px;
    }
    .security-table-page{
        margin-top: 8px;
    }
</style>