<template>
  <el-row>
    <el-row class="base-query-form">
      <el-form :inline="true" :model="brandQueryParam" >
        <el-form-item label="品牌名称" label-width="80px">
          <el-input v-model="brandQueryParam.brandName" placeholder="品牌名称"></el-input>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="brandListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="base-info-add">
      <el-button type="primary" icon="el-icon-plus" @click="addBrandInfoClick">添加</el-button>
    </el-row>
    <el-row class="base-info-table">
      <el-table
          border
          :data="brandList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="downloadUrl" label="品牌Logo" width="120px">
          <template slot-scope="props">
            <el-image class="brand-image" fit="scale-down"  :src="props.row.downloadUrl"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="brandName" label="品牌名称"></el-table-column>
        <el-table-column prop="brandRemark" label="备注"></el-table-column>
        <el-table-column prop="createdBy" label="创建者"></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
        <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column  label="操作"  width="160px">
          <template slot-scope="props">
            <el-button plain  type="warning" size="mini" @click="brandInfoUpdate(props.row)">编辑</el-button>
            <el-button plain  type="danger" size="mini" @click="deleteGoodsBrand(props.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="base-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <brand-info v-if="addBrandDialog == true" :addBrandDialog="addBrandDialog" :brandInfo="currentBrandInfo" @editBrandSuccess="editBrandSuccess" @cancelAddBrandInfo="cancelAddBrandInfo" @addBrandSuccess="addBrandSuccess"></brand-info>
  </el-row>
</template>

<script>
import {findGoodsBrandPageList,deleteGoodsBrand} from '@/api/brand';
import brandInfo from "@/views/brand/brandInfo";
export default {
  name: "brandListPage",
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      currentBrandInfo:{},
      brandQueryParam:{},
      brandList:[],
      addBrandDialog:false
    }
  },
  mounted(){
    this.brandListPageQuery();
  },
  methods:{
    editBrandSuccess:function(){
      this.addBrandDialog=false;
      this.queryReset();
    },
    brandInfoUpdate:function(brandInfo){
      this.currentBrandInfo = brandInfo;
      this.addBrandDialog=true;
    },
    addBrandInfoClick:function(){
      this.currentBrandInfo = {};
      this.addBrandDialog=true;
    },
    addBrandSuccess:function(){
      this.addBrandDialog=false;
      this.queryReset();
    },
    cancelAddBrandInfo:function(){
      this.addBrandDialog=false;
    },
    brandListPageQuery:function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      findGoodsBrandPageList(this.page,this.brandQueryParam,success=>{
        this.brandList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function () {
      this.brandQueryParam={};
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.brandListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.brandListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.brandListPageQuery(true);
    },
    deleteGoodsBrand:function (brandInfo) {
      this.$confirm('是否删除品牌 <span style="color: red">'+brandInfo.brandName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteGoodsBrand(brandInfo,success=>{
              this.$message({type:"success",message:"删除成功",duration:1500});
              this.brandList.forEach((item,index)=>{
                if(item.brandId == brandInfo.brandId){
                  this.brandList.splice(index,1);
                }
              });
            })
          })
          .catch(() => {

          })
    }
  },
  components:{
    brandInfo
  }
}
</script>

<style scoped>
.brand-image{
  width: 100px;
  height: 100px;
}
.base-info-table{
  margin-top: 8px;
}
.base-table-page{
  margin-top: 8px;
}
</style>