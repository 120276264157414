<template>
  <el-row>
    <el-row class="user-list-header">
      <template>
            <el-row>
              <el-form :inline="true" :model="userQueryParam" >
                <el-form-item label="姓名" label-width="80px">
                  <el-input v-model="userQueryParam.userRealName" placeholder="姓名"></el-input>
                </el-form-item>
                <el-form-item label="账号" label-width="80px">
                  <el-input v-model="userQueryParam.userAccount" placeholder="账号"></el-input>
                </el-form-item>
                <el-form-item label="手机号" label-width="80px">
                  <el-input v-model="userQueryParam.userPhone" placeholder="手机号"></el-input>
                </el-form-item>
                <el-form-item  >
                  <el-button icon="el-icon-search" type="primary" @click="systemUserQuery">查询</el-button>
                  <el-button @click="queryReset">重置</el-button>
                </el-form-item>
              </el-form>
            </el-row>
            <el-row>
              <addUser :user-type="'shop'" @addUserSuccess="addUserSuccess"></addUser>
            </el-row>
            <el-row class="user-table">
              <el-table :data="systemUserList" border  :header-cell-style="{background:'#F3F4F7',color:'#555'}">
                <el-table-column prop="userAccount" label="账号" ></el-table-column>
                <el-table-column prop="userName" label="昵称" ></el-table-column>
                <el-table-column prop="userRealName" label="姓名" ></el-table-column>
                <el-table-column prop="userSex" label="性别" :formatter="formatterSex"></el-table-column>
                <el-table-column prop="userCardCipher" label="身份证号" ></el-table-column>
                <el-table-column prop="userPhoneCipher" label="手机号" ></el-table-column>
                <el-table-column prop="createdBy" label="创建人" ></el-table-column>
                <el-table-column prop="createdDate" label="创建时间"  :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人"   ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                <el-table-column prop="userRemark" label="备注" min-width="126px"></el-table-column>
                <el-table-column  label="操作" min-width="220px">
                  <template slot-scope="props">
                    <el-button size="mini" type="warning" @click="viewCurrentUserIcon(props.row)" plain>充值金币</el-button>
                    <el-button size="mini" type="warning" @click="viewIconDetail(props.row)" plain>金币明细</el-button>
                    <el-button size="mini" type="primary" @click="viewCurrentUser(props.row)" plain>查看</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row class="user-table-page">
              <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="page.currentPage"
                  :page-sizes="[10,15,30,50,100]"
                  :page-size="page.pageSize"
                  layout="total, sizes, prev, pager, next, jumper"
                  :total="pageInfo.count">
              </el-pagination>
            </el-row>
      </template>
    </el-row>
    <el-row>
      <el-row>
        <el-dialog
            v-if="viewSystemUserDialog"
            title="用户详情"
            width="40%"
            :visible.sync="viewSystemUserDialog"
        >
          <user-info :userDetail="currentUserInfo" :select="true"></user-info>
        </el-dialog>
      </el-row>
      <el-row>
        <el-dialog
            v-if="viewUserIconDialog"
            title="用户权限"
            width="30%"
            :visible.sync="viewUserIconDialog"
            :close-on-click-modal="false"
        >
          <el-form ref="userIconForm" :model="userIconInfo" label-width="120px">
            <el-form-item label="充值总金币数"  prop="iconAllNumber">
              <el-input v-model="userIconInfo.iconAllNumber" readonly></el-input>
            </el-form-item>
            <el-form-item label="剩余金币数"  prop="iconNumber">
              <el-input v-model="userIconInfo.iconNumber" readonly></el-input>
            </el-form-item>
            <el-form-item label="已使用金币数"  prop="iconUseNumber">
              <el-input v-model="userIconInfo.iconUseNumber" readonly></el-input>
            </el-form-item>
            <el-form-item label="充值"  prop="addNumber">
              <el-input v-model="userIconInfo.addNumber" type="number"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelAddIconSubmit">取 消</el-button>
            <el-button type="primary" @click="submitAddIcon()">确 定</el-button>
          </span>
        </el-dialog>
      </el-row>
      <el-row>
        <el-dialog
            v-if="viewUserIconDetailDialog"
            title="金币明细"
            width="60%"
            :visible.sync="viewUserIconDetailDialog"
            :close-on-click-modal="false"
        >
           <user-icon-detail :user-id="currentUserId"></user-icon-detail>
        </el-dialog>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import {findWxHelperUserPage,deleteSystemUser} from '@/api/user';
import {queryUserIcon,addIcon} from "@/api/wxHelper";
import addUser from "@/views/wxHelper/addUser";
import userInfo from "@/views/user/userInfo";
import userIconDetail from "@/views/wxHelper/userIconDetail.vue";
export default {
  name: "userListPage",
  data(){
    return{
      userQueryParam:{},
      systemUserList:[],
      shopUserList:[],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{

      },
      currentUserInfo:{},
      viewSystemUserDialog:false,
      viewUserIconDialog:false,
      userIconInfo:{},
      currentUserId:0,
      viewUserIconDetailDialog:false
    }
  },
  mounted(){
    this.systemUserQuery();
  },
  methods:{
    viewIconDetail:function (data){
       this.currentUserId = data.userId;
       this.viewUserIconDetailDialog = true;
    },
    viewCurrentUserIcon:function(data){
      queryUserIcon(data,result=>{
        this.userIconInfo = result.obj;
        this.viewUserIconDialog=true;
      });
    },
    cancelAddIconSubmit:function (){
      this.userIconInfo = {};
      this.viewUserIconDialog=false;
    },
    viewCurrentUser:function(data){
      this.$set(this.currentUserInfo,'userId',data.userId);
      this.$set(this.currentUserInfo,'dataToken',data.dataToken);
      this.viewSystemUserDialog=true;
    },
    submitAddIcon:function(){
      addIcon(this.userIconInfo,result=>{
        this.$message({
          type:'success',
          message:'充值成功',
          duration:1500
        });
        this.viewUserIconDialog=false;
        this.systemUserQuery();
      })
    },
    systemUserQuery:function () {
      this.userQueryParam.userType = '001';
      findWxHelperUserPage(this.page,this.userQueryParam,result=>{
        this.systemUserList = result.data;
        this.pageInfo.totalPage= result.totalPage;
        this.pageInfo.count= result.count;
      });
    },
    formatterSex:function(row){
      if (row.userSex == 1) {
        return '男'
      } else {
        return '女'
      }
    },
    addUserSuccess:function(){
      this.queryReset();
    },
    queryReset:function () {
      this.page = {
        pageSize:15,
        currentPage:1
      };
      this.userQueryParam={}
      this.queryUserList();
    },
    queryUserList:function(notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
       this.systemUserQuery();
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryUserList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryUserList(true);
    },
    deleteSystemUser:function (row) {
      this.$confirm('是否删除 '+row.userRealName+' 该用户', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteSystemUser({userId: row.userId},success=>{
              this.$message({
                type:'success',
                message:'删除成功',
                duration:1500
              });
              this.systemUserList.forEach((item,index)=>{
                if(item.userId == row.userId){
                  this.systemUserList.splice(index,1)
                }
              });
              this.pageInfo.count = this.page.count-1;
            })
          })
          .catch(() => {

          })
    }
  },
  components:{
    addUser,
    userInfo,
    userIconDetail
  }
}
</script>

<style scoped>
.info-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.user-table{
  margin-top: 10px;
}
.user-table-page{
  margin-top: 10px;
}
</style>