import {_get, _post} from "@/http";

//分页查询角色列表
export const findRolePageList = (page,param,success,error) => _get("/market/manager/role/findRoleList/"+page.pageSize+'/'+page.currentPage,param,success,error) ;
//删除角色
export const deleteSingleRole = (param,success,error) => _post("/market/manager/role/deleteSingleRole",param,success,error) ;
//新增角色
export const addSingleRole = (param,success,error) => _post("/market/manager/role/addSingleRole",param,success,error) ;
//修改角色
export const updateSingleRole = (param,success,error) => _post("/market/manager/role/updateSingleRole",param,success,error) ;
//角色授权
export const roleAuthorization = (roleId,param,success,error) => _post("/market/manager/role/roleAuthorization?roleId="+roleId,param,success,error) ;

