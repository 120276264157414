<template>
  <el-row>
    <el-tabs v-model="configType" type="border-card">
      <el-tab-pane  label="会员首单产品" name="0">
           <first-order-page v-if="configType == 0"  :configType="configType" ></first-order-page>
      </el-tab-pane>
      <el-tab-pane    label="vip首单产品" name="1">
        <first-order-page v-if="configType == 1" :configType="configType"></first-order-page>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import firstOrderPage from "./firstOrderPage";
export default {
  name: "firstOrderListPage",
  data(){
    return {
      configType:'0'
    }
  },
  components:{
    firstOrderPage
  }
}
</script>

<style scoped>

</style>