import axios from 'axios';
import { Message } from "element-ui";
import router from '@/router/index';
import {cache} from '@/store/store';

const http =   axios.create({
    traditional: true,
    contentType : "application/json",
    timeout: 1000 * 1000, // 1 秒
});
//请求拦截器添加head
http.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        return config
    },
    err => {
        return Promise.reject(err)
    }
)
//返回拦截器
http.interceptors.response.use(
    response => {
        if (response.data.code === 500) {
            Message.error(response.data.message);
        }
        if (response.data.code === 401) {
            Message.error('没有操作该功能权限，请联系管理员添加权限');
        }
        return response
    },
    error => {
        if (error.response.status === 401) {
            Message.error('没有操作该功能权限，请联系管理员添加权限');
        }
        if (error.response.status === 403) {
            if('#/login'!=window.location.hash){
                Message.error('登录过期,请先登录');
                let target = window.location.hash;
                cache.addMemoryCache("target",target);
                router.push({path:"/login",params:{target:encodeURIComponent(target)}});
            }
        }
        return error
    }
)

export default http