<template>
    <el-container>
        <el-row class="error-image-container">
            <el-row class="error-image">
                <el-image :src="'https://market-public-image.oss-cn-guangzhou.aliyuncs.com/404_error.png'">

                </el-image>
                <el-row class="text-message-row">
                    <span class="text-message">404！抱歉，您查看的页面不存在～～</span>
                </el-row>
                <el-row>

                </el-row>
            </el-row>
        </el-row>
    </el-container>
</template>

<script>
    export default {
        name: "404"
    }
</script>

<style scoped>
    .error-image-container{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }
    .text-message-row{
        text-align: center;
        width: 100%;
    }
    .text-message{
        font-size: 16px;
        margin-top: 100px;
        color: #666;
        text-align: center;
    }
</style>