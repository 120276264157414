<template>
  <el-row>
    <el-tabs  v-model="rechargeStatus" type="border-card">
      <el-tab-pane label="未发布" name="0" >
    <rechargeCardListPage v-if="rechargeStatus == '0'" :status="rechargeStatus"></rechargeCardListPage>
      </el-tab-pane>
      <el-tab-pane label="已发布" name="1"  >
      <rechargeCardListPage v-if="rechargeStatus == '1'" :status="rechargeStatus"></rechargeCardListPage>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import rechargeCardListPage from "@/shopViews/rechargeCard/rechargeCardListPage";
export default {
  name: "rechargeCard",
  data(){
    return {
      rechargeStatus:'0'
    }
  },
  components:{
    rechargeCardListPage
  }
}
</script>

<style scoped>

</style>