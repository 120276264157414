<template>
    <el-row v-if="rolePermissionDialog == true">
        <el-dialog
                title="角色授权"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="rolePermissionDialog"
                @close="cancelRolePermission"
        >
            <el-row class="role-permission-add" >
                <el-select class="app-select" size="mini" v-model="syncAppInfo.appContext" clearable placeholder="请选择同步应用">
                    <el-option
                            v-for="item in appInfoList"
                            :key="item.appContext"
                            :label="item.appManagementName"
                            :value="item.appContext">
                    </el-option>
                </el-select>
                <el-button size="mini" type="primary" icon="el-icon-cloudy" @click="syncAllPermission()">同步权限</el-button>
                <el-button size="mini" type="success" icon="el-icon-refresh" @click="initRolePermission(true)">刷新权限</el-button>
            </el-row>
            <el-row class="role-permission-header" >
                <el-row>
                    <el-col :span="10">
                        角色名称:<span class="role-label-value">{{roleInfo.roleName}}</span>
                    </el-col>
                    <el-col :span="10">
                        角色编码: <span  class="role-label-value">{{roleInfo.roleCode}}</span>
                    </el-col>
                </el-row>
            </el-row>
            <el-row class="role-permission-tree">
                <el-tree
                        :data="rolePermissionList"
                        show-checkbox
                        node-key="id"
                        ref="permissionTree"
                        :default-checked-keys="checkPermissionIds"
                        :props="rolePermissionProps"
                >
                </el-tree>
            </el-row>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelRolePermission()">取 消</el-button>
                <el-button type="primary" @click="submitRolePermission()">确 定</el-button>
            </span>
        </el-dialog>
    </el-row>
</template>

<script>
    import {findRolePermissionTree,scanPermission} from '@/api/permission';
    import {roleAuthorization} from '@/api/role';
    export default {
        name: "rolePermission",
        props:['roleInfo','viewRolePermissionDialog','appInfoList'],
        data(){
            return{
                rolePermissionDialog:false,
                rolePermissionList:[],
                checkPermissionIds:[],
                rolePermissionProps:{
                    children: 'children',
                    label: 'title'
                },
                syncAppInfo:{}
            }
        },
        mounted(){
            this.initRolePermission();
            this.rolePermissionDialog = this.viewRolePermissionDialog;
        },
        methods:{
            syncAllPermission:function(){
                if(!this.syncAppInfo.appContext){
                    this.$errorMessage("请选择需要同步的应用");
                    return;
                }
                scanPermission(this.syncAppInfo.appContext,success=>{
                    this.$successMessage("同步完成,请刷新权限")
                })
            },
            cancelRolePermission:function () {
                this.$emit('cancelRolePermission');
            },
            submitRolePermission:function(){
                let roleCheckRoleNodes = this.initCheckPermissionNodes(this.$refs.permissionTree.getCheckedNodes());
                roleAuthorization(this.roleInfo.roleId,roleCheckRoleNodes,success=>{
                    this.$successMessage("授权成功")
                    this.$emit('submitRolePermission');
                })
            },
            initCheckPermissionNodes:function (rolePermissionList) {
                let checkPermissionNodes = [];
                if(rolePermissionList.length>0){
                    rolePermissionList.forEach(item=>{
                        if(item.children && item.children.length>0){
                            return;
                        }
                        checkPermissionNodes.push(item);
                    });
                }
                return checkPermissionNodes;
            },
            initRolePermission:function (refresh) {
                findRolePermissionTree({roleId:this.roleInfo.roleId},success=>{
                    this.initCheckPermissionIds(success);
                    this.rolePermissionList = success;
                    if(refresh){
                        this.$successMessage("刷新完成")
                    }
                });
            },
            initCheckPermissionIds:function (rolePermissionList) {
                if(rolePermissionList.length>0){
                    rolePermissionList.forEach(item=>{
                        if(item.checked){
                            this.checkPermissionIds.push(item.id);
                        }
                        if(item.children){
                            this.initCheckPermissionIds(item.children);
                        }
                    });
                }
            }
        }
    }
</script>

<style scoped>
    .app-select{
        padding-right: 8px;
    }
    .role-permission-header{
        margin-top:10px ;
    }
 .role-permission-tree{
     height: 46vh;
     overflow: auto;
     margin-top: 10px;
 }
  .role-label-value{
      padding-left: 10px;
      font-weight: 600;
      color: coral;
  }
</style>