<template>
  <el-row>
    <el-row class="rechargeCard-query-form">
      <el-form :inline="true" :model="rechargeCardQueryParam" >
        <el-form-item label="充值卡编号" label-width="100px">
          <el-input v-model="rechargeCardQueryParam.rechargeCardNo" placeholder="充值卡编号"></el-input>
        </el-form-item>
        <el-form-item label="充值卡批次" label-width="100px">
          <el-input v-model="rechargeCardQueryParam.batchId" placeholder="充值卡批次"></el-input>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="rechargeCardListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="rechargeCard-info-add">
      <el-button type="primary" icon="el-icon-plus" @click="addRechargeCardInfoClick">创建充值卡</el-button>
      <el-button v-if="status == '0'" plain type="primary"  @click="handOutRechargeCard">发放</el-button>
      <el-button v-if="status == '0'" plain type="danger" @click="deleteRechargeCard">删除</el-button>
    </el-row>
    <el-row class="rechargeCard-info-table">
      <el-table
          border
          :data="rechargeCardList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
          @selection-change="rechargeCardSelectionChange"
      >
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column prop="rechargeCardNo" label="充值卡编号"></el-table-column>
        <el-table-column prop="batchId" label="充值卡批次"></el-table-column>
        <el-table-column prop="rechargeCardNumber" label="充值卡数量"></el-table-column>
        <el-table-column prop="rechargeCardAmount" label="充值卡金额" ></el-table-column>
        <el-table-column prop="isEffect" label="是否生效" :formatter="formatRechargeCardEffect"></el-table-column>
        <el-table-column prop="createdBy" label="创建者"></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
        <el-table-column  label="操作" width="200px">
          <template slot-scope="props">
            <el-button plain v-if="status == '0'"  type="warning" size="mini"  @click="rechargeCardInfoUpdate(props.row)">编辑</el-button>
            <el-button plain   size="mini" @click="showRechargeCardDetail(props.row)">充值卡明细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="rechargeCard-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <add-rechargeCard-info v-if="addRechargeCardDialog == true" :addRechargeCardDialog="addRechargeCardDialog" :rechargeCardInfo="currentRechargeCardInfo" @editRechargeCardSuccess="editRechargeCardSuccess" @cancelAddRechargeCardInfo="cancelAddRechargeCardInfo" @addRechargeCardSuccess="addRechargeCardSuccess"></add-rechargeCard-info>
    <el-dialog
        title="充值卡明细"
        width="70%"
        :close-on-click-modal="false"
        :visible.sync="rechargeCardDetailDialog"
        @close="cancelAddRechargeCardInfo"
    >
      <rechargeCardDetailListPage v-if="rechargeCardDetailDialog"
                               :recharge-card-batch-id="currentRechargeCardInfo.rechargeCardBatchId" ></rechargeCardDetailListPage>
    </el-dialog>
  </el-row>
</template>

<script>
import {findRechargeCardInfoPageList,handOutRechargeCard,deleteRechargeCard} from '@/api/rechargeCard';
import addRechargeCardInfo from "@/shopViews/rechargeCard/addRechargeCardInfo";
import rechargeCardDetailListPage from "@/shopViews/rechargeCard/rechargeCardDetailListPage";
export default {
  name: "rechargeCardListPage",
  props:{
    status:{
      type:String,
      default:'0'
    }
  },
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      currentRechargeCardInfo:{},
      rechargeCardQueryParam:{},
      rechargeCardList:[],
      rechargeCardSelectionList:[],
      addRechargeCardDialog:false,
      rechargeCardDetailDialog:false,

    }
  },
  mounted(){
    this.rechargeCardListPageQuery();
  },
  methods:{
    rechargeCardSelectionChange:function (val){
        this.rechargeCardSelectionList = val;
    },
    formatRechargeCardEffect:function (row, column, cellValue, index){
      if(cellValue == '1'){
        return '已生效';
      }
      return '未生效';
    },
    editRechargeCardSuccess:function(){
      this.addRechargeCardDialog=false;
    },
    rechargeCardInfoUpdate:function(rechargeCardInfo){
      this.currentRechargeCardInfo = rechargeCardInfo;
      this.addRechargeCardDialog=true;
    },
    addRechargeCardInfoClick:function(){
      this.currentRechargeCardInfo = {};
      this.addRechargeCardDialog=true;
    },
    addRechargeCardSuccess:function(){
      this.addRechargeCardDialog=false;
      this.queryReset();
    },
    cancelAddRechargeCardInfo:function(){
      this.addRechargeCardDialog=false;
    },
    rechargeCardListPageQuery:function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      this.rechargeCardQueryParam.isEffect = this.status;
      findRechargeCardInfoPageList(this.page,this.rechargeCardQueryParam,success=>{
        this.rechargeCardList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function () {
      this.rechargeCardQueryParam={};
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.rechargeCardListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.rechargeCardListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.rechargeCardListPageQuery(true);
    },
    showRechargeCardDetail:function (rechargeCardInfo){
      this.rechargeCardDetailDialog=true;
      this.currentRechargeCardInfo = rechargeCardInfo;
    },
    handOutRechargeCard:function () {
      this.$confirm('是否发放选中充值卡', '发放提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            handOutRechargeCard(this.rechargeCardSelectionList,success=>{
              this.removeDateList();
              this.$message({type:"success",message:"发放成功",duration:1500});
            })
          })
          .catch(() => {

          })
    },
    deleteRechargeCard:function (rechargeCardInfo) {
      this.$confirm('是否删除选中充值卡', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteRechargeCard(this.rechargeCardSelectionList,success=>{
              this.removeDateList();
              this.$message({type:"success",message:"刪除成功",duration:1500});
            })
          })
          .catch(() => {

          })
    },
    removeDateList:function (){
      this.rechargeCardSelectionList.forEach(item=>{
        this.rechargeCardList.forEach((card,index)=>{
          if(card.rechargeCardBatchId == item.rechargeCardBatchId){
            this.rechargeCardList.splice(index,1);
          }
        })
      })
    }
  },
  components:{
    addRechargeCardInfo,
    rechargeCardDetailListPage
  }
}
</script>

<style scoped>
.rechargeCard-info-table{
  margin-top: 8px;
}
.rechargeCard-table-page{
  margin-top: 8px;
}
</style>