//查询商品所绑定的门店
import {_get, _post} from "@/http";

//查询商品分配门店分页列表
export const findGoodsAllocationShopPageList = (page, param, success, error) => _get("/market/shop/stock/findGoodsAllocationShopPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除门店商品库存列表
export const deleteStockProduct = (param,success,error) => _post("/market/shop/stock/deleteStockProduct", param,success,error);
//分页查询门店库存列表
export const queryGoodsStockPage = (page, param, success, error) => _get("/market/shop/stock/queryGoodsStockPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//查询门店库存数量
export const getStockStatistics = (success,error) => _get("/market/shop/stock/getStockStatistics", {},success,error);
//上架产品
export const publishGoodsStock = (param,success,error) => _post("/market/shop/stock/publishGoodsStock", param,success,error);
//下架产品
export const outOfGoodsStock = (param,success,error) => _post("/market/shop/stock/outOfGoodsStock", param,success,error);
//修改商品价格
export const  updateStockByCircle  = (param,success,error) => _post("/market/shop/stock/updateStockByCircle", param,success,error);
//查询库存明细
export const queryGoodsStockDetailPage = (page, param, success, error) => _get("/market/shop/stockDetail/queryGoodsStockDetailPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;



