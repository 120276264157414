import Vue from 'vue';
import App from './App.vue';
import router from './router';
import elementUi from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './static/icon/iconfont.css';
import format from "@/util/format";
import dataV from '@jiaminghi/data-view'
Vue.use(elementUi);
Vue.use(router)
Vue.use(format);
Vue.use(dataV)
Vue.config.productionTip = false
router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = `${to.name}`
  } else {
    document.title = '速惠系统'
  }
  next();
})

new Vue({
  el: '#app',
  router,
  render: h => h(App),
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  },
  methods:{

  }
}).$mount('#app')
