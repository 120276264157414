<template>
    <el-row>
        <el-dialog
            :title="lookupInfoDetail && lookupInfoDetail.classifyId?'修改lookup':'添加lookup'"
            width="36%"
            :close-on-click-modal="false"
            :visible.sync="lookupInfoDialog"
            @close="cancelLookup"
        >
            <el-row>
                <el-form ref="lookupForm" :rules="lookupInfoRules" :model="lookupInfoDetail" label-width="80px">
                    <el-form-item prop="classifyName" label="类别名称">
                        <el-col :span="12">
                            <el-input v-model="lookupInfoDetail.classifyName" placeholder="请输入类别名称"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="classifyCode" label="类别编码">
                        <el-col :span="12">
                            <el-input v-model="lookupInfoDetail.classifyCode" placeholder="请输入类别编码" :readonly="lookupInfoDetail.classifyId?true:false"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="classifyRemark" label="备注">
                        <el-input type="textarea" v-model="lookupInfoDetail.classifyRemark" placeholder="应用名备注信息"></el-input>
                    </el-form-item>
                </el-form>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelLookup">取 消</el-button>
                    <el-button type="primary" @click="addLookup('lookupForm')">确 定</el-button>
            </span>
        </el-dialog>
    </el-row>
</template>

<script>
    import {addLookUpClassify,updateLookUpClassify} from '@/api/lookup'
    export default {
        name: "addLookup",
        props:['lookupInfo','addLookupInfoDialog'],
        data(){
            return{
                lookupInfoDetail:{},
                lookupInfoRules:{
                    classifyName:[
                        { required: true, message: '类别名称不能为空', trigger: 'blur' },
                    ],
                    classifyCode:[
                        { required: true, message: '类别编码不能为空', trigger: 'blur' },
                    ]
                },
                lookupInfoDialog:false
            }
        },
        mounted(){
            this.lookupInfoDialog = this.addLookupInfoDialog;
            this.lookupInfoDetail = this.lookupInfo;
        },
        methods:{
            cancelLookup:function () {
                this.$emit("cancelLookup");
            },
            addLookup:function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.lookupInfoDetail.classifyId){
                            updateLookUpClassify(this.lookupInfoDetail,success=>{
                                this.$message({type:"success",message:"修改lookup信息成功",duration:1500});
                                this.$emit("updateLookUpClassify");
                            });
                        }else{
                            addLookUpClassify(this.lookupInfoDetail,success=>{
                                this.$message({type:"success",message:"新增lookup信息成功",duration:1500});
                                this.$emit("addLookUpClassify");
                            });
                        }
                    } else {
                        return false;
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>