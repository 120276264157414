var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('el-row',{attrs:{"gutter":15}},[_c('el-form',{ref:"elForm",attrs:{"model":_vm.shopInfo,"size":"medium","label-width":"100px"}},[_c('el-form-item',{attrs:{"label":"店铺名称:"}},[_c('el-row',[_vm._v(_vm._s(_vm.shopInfo.shopName))])],1),_c('el-form-item',{attrs:{"label":"店家地址"}},[_c('el-row',[_vm._v(_vm._s(_vm.shopInfo.shopProvince)+">"+_vm._s(_vm.shopInfo.shopCity)+">"+_vm._s(_vm.shopInfo.shopCounties))])],1),_c('el-form-item',{attrs:{"label":"明细地址"}},[_c('el-row',[_vm._v(_vm._s(_vm.shopInfo.shopAddressDetail))])],1),_c('el-form-item',{attrs:{"label":"店铺类型"}},[_c('el-radio',{attrs:{"label":"2","disabled":""},model:{value:(_vm.shopInfo.shopType),callback:function ($$v) {_vm.$set(_vm.shopInfo, "shopType", $$v)},expression:"shopInfo.shopType"}},[_vm._v("销售门店")])],1),_c('el-form-item',{attrs:{"label":"服务热线"}},[_c('el-col',{attrs:{"span":5}},[_c('el-input',{attrs:{"placeholder":"服务热线"},model:{value:(_vm.shopInfo.shopServiceHotLine),callback:function ($$v) {_vm.$set(_vm.shopInfo, "shopServiceHotLine", $$v)},expression:"shopInfo.shopServiceHotLine"}})],1)],1),_c('el-form-item',{attrs:{"label":"是否开业"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('el-switch',{attrs:{"inactive-value":"0","active-value":"1","active-color":"#13ce66","inactive-color":"#d2d2d2"},on:{"change":function($event){return _vm.openShop($event,props)}},model:{value:(_vm.shopInfo.openStatus),callback:function ($$v) {_vm.$set(_vm.shopInfo, "openStatus", $$v)},expression:"shopInfo.openStatus"}})]}}])}),_c('el-form-item',{attrs:{"label":"营业时段"}},[[_c('el-time-select',{attrs:{"placeholder":"起始时间","picker-options":{
                      start: '00:00',
                      step: '00:30',
                      end: '23:59:59'
                    }},model:{value:(_vm.shopInfo.openBegin),callback:function ($$v) {_vm.$set(_vm.shopInfo, "openBegin", $$v)},expression:"shopInfo.openBegin"}}),_vm._v(" 至 "),_c('el-time-select',{attrs:{"placeholder":"结束时间","picker-options":{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59:59',
                        minTime: _vm.shopInfo.openBegin
                      }},model:{value:(_vm.shopInfo.openEnd),callback:function ($$v) {_vm.$set(_vm.shopInfo, "openEnd", $$v)},expression:"shopInfo.openEnd"}})]],2),_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.saveBasicInfo}},[_vm._v("保 存")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }