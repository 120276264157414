<template>
    <el-row>
        <el-dialog
                :title="roleInfoDetail.roleId?'修改角色':'添加角色'"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="roleInfoDialog"
                @close="cancelRoleInfo"
        >
            <el-row>
               <el-form :rules="roleRules" ref="roleForm" label-width="100px" :model="roleInfoDetail">
                   <el-form-item label="角色名称" prop="roleName">
                       <el-col :span="10">
                        <el-input v-model="roleInfoDetail.roleName" placeholder="角色名称"></el-input>
                       </el-col>
                   </el-form-item>
                   <el-form-item label="角色编码" prop="roleCode">
                       <el-col :span="10">
                       <el-input v-model="roleInfoDetail.roleCode" placeholder="角色编码" :readonly="roleInfoDetail.roleId?true:false"></el-input>
                       </el-col>
                   </el-form-item>
                   <el-form-item label="角色类型" prop="roleType">
                       <el-col :span="10">
                           <el-select v-model="roleInfoDetail.roleType" placeholder="请选择角色类型" style="width: 100%" :disabled="roleInfoDetail.roleId?true:false">
                               <el-option
                                       v-for="item in roleTypeList"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.value">
                               </el-option>
                           </el-select>
                       </el-col>
                   </el-form-item>
                   <el-form-item label="备注信息">
                       <el-input type="textarea" v-model="roleInfoDetail.roleRemark" placeholder="备注信息"></el-input>
                   </el-form-item>
               </el-form>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelRoleInfo()">取 消</el-button>
                    <el-button type="primary" @click="submitRoleInfo('roleForm')">确 定</el-button>
                 </span>
        </el-dialog>
    </el-row>
</template>

<script>
    export default {
        name: "roleInfo",
        props:['roleTypeList','roleInfo','viewRoleInfoDialog'],
        data(){
            return {
                roleInfoDetail:{},
                roleInfoDialog:false,
                roleRules: {
                  roleName: [
                      { required:true,message:"角色名称不能为空",trigger:'blur'}
                  ],
                roleCode: [
                    { required:true,message:"角色编码不能为空",trigger:'blur'}
                ],
                roleType: [
                    { required:true,message:"角色类型不能为空",trigger:'blur'}
                ]
                 }
            }
        },
        mounted(){
            this.roleInfoDetail = this.roleInfo;
            this.roleInfoDialog = this.viewRoleInfoDialog;
        },
        methods:{
            cancelRoleInfo:function(){
                this.$emit('cancelRoleInfo');
            },
            submitRoleInfo:function(formName){
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.$emit('submitRoleInfo',this.roleInfoDetail);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>