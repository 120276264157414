<template>
  <el-row>
    <el-row class="shopTable-query-form">
      <el-form :inline="true" :model="shopTableQueryParam" >
        <el-form-item label="餐桌桌号" label-width="100px">
          <el-input v-model="shopTableQueryParam.tableName" placeholder="餐桌桌号"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="shopTableListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
          <el-button plain type="primary" @click="addShopTable">添加</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="shopTable-query-body">
      <el-col :span="4"  v-for="(shopTableInfo,index) in shopTableList" :key="shopTableInfo.id" :offset="1">
        <el-row class="shopTable-card-info">
          <el-card >
            <el-row  style="cursor: pointer" slot="header" class="clearfix">
              <span>{{shopTableInfo.tableName}}</span>
            </el-row>
            <el-row class="shopTable-image-info">
              <img v-if="shopTableInfo.qrCodeUrl"
                   :src="shopTableInfo.qrCodeUrl"  class="image-info" fit="scale-down">
              <img v-else src="@/assets/logo.png" class="image-info" fit="scale-down">
            </el-row>
            <el-row class="qr-code-activity">
              <el-button type="text" class="button danger" @click="deleteShopTableInfo(shopTableInfo,index)">删 除</el-button>
              <el-button type="text" class="qr-code-download" @click="downloadShopTableInfo(shopTableInfo)">生 成</el-button>
            </el-row>
          </el-card>
        </el-row>
      </el-col>
    </el-row>
  </el-row>
</template>

<script>
import {findShopTablePageList,deleteShopTable,addShopTable} from "@/api/shopTable";
export default {
  name: "shopTableOperationList",
  data(){
    return{
      shopTableQueryParam:{},
      shopTableList: [],
      page: {
        pageSize: 10,
        currentPage: 1
      },
      pageInfo: {
        count: 0,
        totalPage: 0
      }
    }
  },
  mounted() {
    this.shopTableListPageQuery();
  },
  methods:{
    shopTableListPageQuery:function (){
      findShopTablePageList(this.page,this.shopTableQueryParam,success=>{
        this.shopTableList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    downloadShopTableInfo:function (shopTableInfo){
      if(!shopTableInfo.qrCodeUrl){
        this.$message('二维码生成中,请稍后刷新重试');
        return;
      }
      window.open(shopTableInfo.qrCodeUrl,"blank");
    },
    queryReset:function (){
      this.shopTableQueryParam={};
      this.shopTableListPageQuery();
    },
    deleteShopTableInfo:function (shopTableInfo,index){
      this.$confirm('是否删除桌号 <span style="color: red">'+shopTableInfo.tableName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteShopTable(shopTableInfo,success=>{
              this.$successMessage("删除成功");
              this.shopTableList.splice(index,1);
            })
          })
          .catch(() => {

          })
    },
    addShopTable:function (){
      if(!this.shopTableQueryParam.tableName){
         this.$errorMessage("请输入餐桌号");
         return;
      }
      addShopTable(this.shopTableQueryParam,success=>{
        this.shopTableList.push(success.obj);
        this.$successMessage("添加成功");
      })
    }
  }
}
</script>

<style scoped>
.shopTable-query-body{
  margin-top: 10px;
}
.shopTable-image-info{
  display: flex;
  justify-content: center;
  align-content: center;
  cursor: pointer;
  width: 11vw;
  height: 11vw;
}
.shopTable-card-info{
  margin-top: 10px;
}
.image-info{
  width: 100%;
  height: 100%;
  border-radius: 8px;
}
.button {
  padding: 0;
  float: left;
}
.qr-code-activity{
  align-items: center;
  padding-top: 5px ;
}
.danger{
  color: red;
}
.qr-code-download{
  padding: 0;
  float: right;
}
</style>