<template>
  <el-row>
    <el-form ref="couponInfoForm" :rules="couponInfoRule" :model="couponInfo" label-width="100px" :disabled="!canEdit">
      <el-form-item prop="activityType" label="活动类型">
        <el-col :span="12">
          <el-radio v-model="couponInfo.activityType" label="1" :disabled="couponInfo.couponId>0">店家活动</el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="couponType" label="优惠券类型">
        <el-col :span="12">
          <el-radio v-model="couponInfo.couponType" label="1" :disabled="couponInfo.couponId>0">优惠券</el-radio>
          <el-radio v-model="couponInfo.couponType" label="2" :disabled="couponInfo.couponId>0">折扣券</el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="belongMemberType" label="会员类型">
        <el-col :span="12">
          <el-radio v-model="couponInfo.belongMemberType" label="0">所有会员</el-radio>
          <el-radio v-model="couponInfo.belongMemberType" label="1">超级vip</el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="couponName" label="优惠券名称">
        <el-col :span="8">
          <el-input v-model="couponInfo.couponName" placeholder="请输入优惠券名称"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  v-if="couponInfo.couponType == 1" prop="couponAmount" label="优惠券面额">
        <el-col :span="8" >
          <el-input v-model="couponInfo.couponAmount" placeholder="请输入优惠券面额"></el-input>
        </el-col>
        <el-col class="el-input-group__append" >
          <span >元</span>
        </el-col>
      </el-form-item>
      <el-form-item  v-if="couponInfo.couponType == 2" prop="couponAmount" label="打折力度">
        <el-col :span="8" >
          <el-input-number v-model="couponInfo.couponAmount" :precision="1" :step="0.1" :min="0.1" :max="10"></el-input-number>
          折
        </el-col>
      </el-form-item>
      <el-form-item prop="sendTotalType" label="发放总量">
        <el-col :span="12">
          <el-radio v-model="couponInfo.sendTotalType" label="1" @change="clearCouponSendTotal">不限制数量</el-radio>
          <el-radio v-model="couponInfo.sendTotalType" label="2" >
            发放
            <el-input-number v-model="couponInfo.couponSendTotal" :min="0" :max="9999999" label="" :disabled="couponInfo.sendTotalType == 1"></el-input-number>
            张
          </el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="conditionType" v-if="couponInfo.couponType == 1"  label="使用门槛">
        <el-col :span="12">
          <el-radio v-model="couponInfo.conditionType" label="0" @change="clearFullBuyPrice">无使用门槛</el-radio>
          <el-radio v-model="couponInfo.conditionType" label="1" >
            订单满
            <el-input-number v-model="couponInfo.fullBuyPrice" :min="0" :max="9999999" label="" :disabled="couponInfo.conditionType == 0"></el-input-number>
            元可用
          </el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="couponBeginDate" label="有效期">
        <el-col :span="9">
          <el-form-item prop="rechargeBeginDate">
            <el-date-picker
                v-model="couponInfo.couponBeginDate"
                type="date"
                placeholder="选择开始日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="1">-</el-col>
        <el-col :span="9">
          <el-form-item prop="rechargeEndDate">
            <el-date-picker
                v-model="couponInfo.couponEndDate"
                type="date"
                placeholder="选择结束日期">
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item prop="getNumType" label="领取次数">
        <el-col :span="12">
          <el-radio v-model="couponInfo.getNumType" label="1"  @change="clearGetNumLimit">不限制领取次数</el-radio>
          <el-radio v-model="couponInfo.getNumType" label="2" >
            限制领取
            <el-input-number v-model="couponInfo.getNumLimit" :min="0" :max="9999999" label="" :disabled="couponInfo.getNumType == 0"></el-input-number>
            次
          </el-radio>
        </el-col>
      </el-form-item>
      <el-form-item prop="couponUseType" label="适用商品">
        <el-radio v-model="couponInfo.couponUseType" label="0" >全场通用</el-radio>
        <el-radio v-model="couponInfo.couponUseType" label="1" >指定商品不可用</el-radio>
        <el-radio v-model="couponInfo.couponUseType" label="2" >指定商品可用</el-radio>
      </el-form-item>
      <el-form-item v-if="couponInfo.couponUseType != 0" prop="couponUseType" label="商品选择">
        <el-tabs v-model="goodsSelectType" type="border-card">
          <el-tab-pane label="已选择" name="0">
            <coupon-goods-list v-if="goodsSelectType == '0'" :use-type="goodsSelectType" @deleteCouponGoodsList="deleteCouponGoodsList" :coupon-goods-str="initCouponGoodsStr(couponInfo.couponGoodsVos)"></coupon-goods-list>
          </el-tab-pane>
          <el-tab-pane label="库存列表" name="1">
            <coupon-goods-list  v-if="goodsSelectType == '1'" :use-type="goodsSelectType" @addCouponGoodsList="addCouponGoodsList" :coupon-goods-str="initCouponGoodsStr(couponInfo.couponGoodsVos)"></coupon-goods-list>
          </el-tab-pane>
        </el-tabs>
      </el-form-item>
    </el-form>
  </el-row>
</template>
<script>
import couponGoodsList from "@/shopViews/coupon/couponGoodsList";
export default {
  name: "couponInfo",
  props:{
    canEdit:{
      type:Boolean,
      default:true
    },
    couponInfo:{
      type:Object,
      default:()=>{return {}}
    }
  },
  data(){
    return {
      goodsSelectType:'0',
      couponInfoRule:{
        couponName:[
          { required: true, message: '优惠券名称不能为空', trigger: 'blur' },
        ],
        couponAmount:[
          { required: true, message: '优惠券面额不能为空', trigger: 'blur' },
        ]
      }
    }
  },
  methods:{
    deleteCouponGoodsList:function (couponGoodsList){
      this.couponInfo.couponRelIds='';
      this.couponInfo.couponGoodsVos=[];
      this.addCouponGoodsList(couponGoodsList);
    },
    addCouponGoodsList:function (couponGoodsList){
       if(couponGoodsList && couponGoodsList.length>0){
           let relIds = '';
           couponGoodsList.forEach(item=>{
             if(this.isHasExists(item)){
                  return;
             }
             let appendStr = item.goodsStockId+',';
             relIds += appendStr;
             let obj = new Object();
             obj.relId = item.goodsStockId;
             if(!this.couponInfo.couponGoodsVos){
               this.couponInfo.couponGoodsVos = [];
             }
             this.couponInfo.couponGoodsVos.push(obj);
           });
           if(!this.couponInfo.couponRelIds){
             this.couponInfo.couponRelIds = '';
           }
          this.couponInfo.couponRelIds += relIds;
       }
    },
    isHasExists:function (item){
      let exists = false;
      if(this.couponInfo.couponGoodsVos && this.couponInfo.couponGoodsVos.length>0){
        this.couponInfo.couponGoodsVos.forEach(goods=>{
          if(item.goodsStockId == goods.relId){
            exists = true;
          }
        })
      }
      return exists;
    },
    initCouponGoodsStr:function (couponGoodsVos){
      let str = "";
      if(couponGoodsVos && couponGoodsVos.length>0){
        couponGoodsVos.forEach(item=>{
          let appendStr = item.relId+',';
          str += appendStr;
        })
      }
      return str;
    },
    clearCouponSendTotal:function (){
      this.couponInfo.couponSendTotal = '';
    },
    clearFullBuyPrice:function (){
      this.couponInfo.fullBuyPrice = '';
    },
    clearGetNumLimit:function (){
      this.couponInfo.getNumLimit = '';
    }
  },
  components:{
    couponGoodsList
  }
}
</script>

<style scoped>

</style>