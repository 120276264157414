<template>
    <el-row>
        <el-dialog
                title="组别成员列表"
                width="66%"
                :close-on-click-modal="false"
                :visible.sync="viewGroupRoleDialog"
                @close="cancelGroupRole"
        >
            <el-row class="group-role-query-form">
                <el-form :inline="true" :model="queryGroupRoleParam"  >
                    <el-form-item label="角色名称" label-width="100px">
                        <el-input v-model="queryGroupRoleParam.roleName" placeholder="角色名称"></el-input>
                    </el-form-item>
                    <el-form-item label="角色编码" label-width="100px">
                        <el-input v-model="queryGroupRoleParam.roleCode" placeholder="角色编码"></el-input>
                    </el-form-item>
                    <el-form-item  >
                        <el-button icon="el-icon-search" type="primary" @click="groupRoleListPageQuery">查询</el-button>
                        <el-button @click="queryReset">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-tabs v-model="defaultGroupRole" type="border-card" @tab-click="handleClick">
                <el-tab-pane label="组别角色" name="groupRole">
                    <el-row class="group-role-info-add">
                        <el-button  type="danger" icon="el-icon-delete"  size="mini" @click="deleteGroupRole">删除</el-button>
                    </el-row>
                    <el-row class="group-role-info-table">
                        <el-table
                                border
                                :data="groupRoleList"
                                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                                @selection-change="addGroupRoleChange"
                        >
                            <el-table-column
                                    :selectable="checkSelectable"
                                    type="selection"
                                    width="55"></el-table-column>
                            <el-table-column prop="roleName" label="角色名称"></el-table-column>
                            <el-table-column prop="roleCode" label="角色编码"></el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="group-role-table-page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="page.currentPage"
                                :page-sizes="[10,15,30,50,100]"
                                :page-size="page.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="pageInfo.count">
                        </el-pagination>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="待添加角色" name="addGroupRole">
                    <el-row class="group-role-info-add">
                        <el-button  type="primary" icon="el-icon-plus"  size="mini" @click="addGroupRole">添加</el-button>
                    </el-row>
                    <el-row class="group-role-info-table">
                        <el-table
                                border
                                :data="groupRoleList"
                                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                                @selection-change="addGroupRoleChange"
                        >
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column prop="roleName" label="角色名称"></el-table-column>
                            <el-table-column prop="roleCode" label="角色编码"></el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="group-role-table-page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="page.currentPage"
                                :page-sizes="[10,15,30,50,100]"
                                :page-size="page.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="pageInfo.count">
                        </el-pagination>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </el-row>
</template>

<script>
    import {findGroupRolePageList,findAddGroupRolePageList,addGroupRole,deleteGroupRole} from '@/api/group'
    export default {
        name: "groupRole",
        props:['groupRoleDialog','groupInfo'],
        data(){
            return{
                viewGroupRoleDialog:false,
                defaultGroupRole:'groupRole',
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                groupRoleList:[],
                queryGroupRoleParam:{
                    groupId:0
                },
                changeGroupRoleList:[],
                deleteGroupRoleList:[]
            }
        },
        mounted(){
            this.viewGroupRoleDialog = this.groupRoleDialog;
            this.queryGroupRoleParam.groupId = this.groupInfo.groupId;
            this.groupRoleListPageQuery();
        },
        methods:{
            checkSelectable(row,index){
                if(row.roleName == 'default'){
                    return false;
                }
                return true;
            },
            deleteGroupRole:function(){
                this.$confirm('是否删除所选择用户', '角色删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteGroupRole(this.changeGroupRoleList,success=>{
                            this.$successMessage("删除角色成功");
                            this.queryReset();
                        })
                    })
                    .catch(() => {})
            },
            addGroupRole:function(){
                if(this.changeGroupRoleList.length>0){
                    this.$confirm('是否添加所选角色', '添加角色', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            this.changeGroupRoleList.forEach(item=>{
                                item.groupId = this.groupInfo.groupId;
                            })
                            addGroupRole(this.changeGroupRoleList,success=>{
                                this.$successMessage("添加角色成功");
                                this.queryReset();
                            })
                        })
                        .catch(() => {})
                    return;
                }
                this.$errorMessage("请选择需要添加的角色");
            },
            addGroupRoleChange:function(val){
                this.changeGroupRoleList = val;
            },
            removeData(list,groupRole){
                list.forEach((item,index)=>{
                    if(item.groupRoleId == groupRole.groupRoleId){
                        list.splice(index,1);
                    }
                });
            },
            groupRoleListPageQuery(notResetCurrentPage){
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                if(this.defaultGroupRole=='groupRole'){
                    findGroupRolePageList(this.page,this.queryGroupRoleParam,success=>{
                        this.groupRoleList = success.data;
                        this.pageInfo.count = success.count;
                        this.pageInfo.totalPage =  success.totalPage;
                    });
                }
                if(this.defaultGroupRole=='addGroupRole'){
                    findAddGroupRolePageList(this.page,this.queryGroupRoleParam,success=>{
                        this.groupRoleList = success.data;
                        this.pageInfo.count = success.count;
                        this.pageInfo.totalPage =  success.totalPage;
                    });
                }
            },
            cancelGroupRole:function () {
                this.$emit('cancelGroupRole')
            },
            handleClick:function(){
                this.queryReset();
            },
            queryReset:function(){
                this.queryGroupRoleParam={groupId:this.groupInfo.groupId},
                    this.pageInfo={
                        count:0,
                        totalPage:0
                    }
                this.groupRoleListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.groupRoleListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.groupRoleListPageQuery(true);
            }
        }
    }
</script>
<style>
    tr.el-table__row.group-role-del-tag{
        background: #80808085;
    }
</style>
<style scoped>
    .group-role-info-table{
        margin-top: 8px;
        height: 42vh;
        overflow: auto;
    }
    .group-role-table-page{
        margin-top: 8px;
    }
</style>