<template>
    <el-row>
        <template>
            <el-table
                    :data="approveHistory"
                    style="width: 50%"
                    stripe>
                <el-table-column
                        prop="approvedUser"
                        label="审批人"
                        width="150">
                </el-table-column>
                <el-table-column
                        prop="opinion"
                        label="审批操作"
                        width="150"
                        :formatter="(row, column,cellValue) => cellValue === 'reject'?'驳回':'通过'">
                </el-table-column>
                <el-table-column
                        prop="approvedDate"
                        label="审批时间"
                        width="200"
                        :formatter="this.$formatTableCellDate">
                </el-table-column>
                <el-table-column
                        prop="remark"
                        label="审批备注">
                </el-table-column>
            </el-table>
        </template>
    </el-row>
</template>

<script>
    import {findApproveHistory} from '@/api/pending'
    export default {
        name: "approveHistory",
        props:['approveDetail'],
        data(){
            return{
                approveHistory:[]
            }
        },
        mounted(){
            this.initApproveHistory(this.approveDetail);
        },
        methods:{
            initApproveHistory:function (approveDetail) {
                findApproveHistory({taskBusinessKey: approveDetail.taskBusinessKey, modelKey: approveDetail.modelKey},success=>{
                   this.approveHistory = success;
                });
            }
        }
    }
</script>

<style scoped>

</style>