<template>
   <el-row>
     <el-table :data="couponSendDetailList" border
               :header-cell-style="{background:'#F3F4F7',color:'#555'}"
     >
       <el-table-column prop="couponName" label="优惠券名称" width="120px"></el-table-column>
       <el-table-column prop="headerImg" label="会员头像">
         <template slot-scope="props">
           <el-image class="goods-main-image" fit="scale-down"  :src="props.row.headerImg"></el-image>
         </template>
       </el-table-column>
       <el-table-column prop="mobile" label="手机号码"></el-table-column>
       <el-table-column prop="nikeName" label="昵称"></el-table-column>
       <el-table-column prop="createTime" label="注册时间"></el-table-column>
       <el-table-column prop="useStatus" label="使用状态"></el-table-column>
       <el-table-column prop="createdDate" label="领取时间"></el-table-column>
       <el-table-column prop="lastUpdatedDate" label="使用时间"></el-table-column>
     </el-table>
     <el-row class="coupon-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
   </el-row>
</template>

<script>
import {queryCouponSendDetail} from '@/api/coupon';
export default {
  name: "couponSendDetail",
  props:{
    couponId:{
      type:Number,
      default:0
    }
  },
  data(){
    return {
      couponSendDetailList:[],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
    this.queryCouponSendDetailPageList();
  },
  methods: {
    queryCouponSendDetailPageList:function (){
      queryCouponSendDetail(this.page,{couponId:this.couponId},success=>{
        this.couponSendDetailList =success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    }
  }
}
</script>

<style scoped>
 .coupon-table-page{
   padding-top: 8px;
 }
 .goods-main-image{
   width: 60px;
   height: 60px;
   border-radius: 6px;
 }
</style>