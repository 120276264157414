<template>
  <el-row id="data-view">
      <top-header />
     <digital-share-flop :ask-info="this.statistics.topStatisticsCount"/>
    <el-row class="block-top-bottom-content">
      <scroll-board :scroll-id="'scroll-board-all'" :title="'微信列表'" :config="bindWxConfig"/>
    </el-row>
  </el-row>
</template>

<script>
import topHeader from '@/views/wxHelper/statistics/topHeader';
import digitalShareFlop from '@/views/wxHelper/share/digitalShareFlop.vue'
import scrollBoard from '@/views/wxHelper/statistics/scrollBoard'
import {shareStatisticsCount} from "@/api/wxHelper";
export default {
  name: '计数器统计首页',
  components: {
    topHeader,
    digitalShareFlop,
    scrollBoard
  },
  data () {
    return {
      statistics:{
        topStatisticsCount:{

        },
        list:[]
      },
      onlineWxConfig: {
        header: ['微信id', '微信昵称', '账号', '备注'],
        data: [],
        columnWidth: [200,200,200,200],
        align: ['center'],
        headerBGC: '#1981f6',
        headerHeight: 45,
        rowNum: 5,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      },
      bindWxConfig: {
        header: ['微信id', '微信昵称', '申请数', '通过数', '今日申请','今日通过','在线状态'],
        data: [],
        align: ['center'],
        headerBGC: '#1981f6',
        headerHeight: 45,
        rowNum: 5,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      },
      askWxConfig: {
        header: ['申请WX_ID', '申请人昵称', '被申请人WX_ID', '被申请人昵称', '申请时间','通过状态','通过时间'],
        data: [],
        columnWidth:[200,200,200,200,200,200,200],
        align: ['center'],
        headerBGC: '#1981f6',
        headerHeight: 45,
        rowNum: 7,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  },
  mounted() {
    this.queryShare();
  },
  methods: {
    queryShare:function (){
      let sign = this.$route.query.sign?this.$route.query.sign:'';
      shareStatisticsCount(decodeURIComponent(sign),success=>{
        if(success.obj){
          this.statistics = success.obj;
          if(this.statistics.list  && this.statistics.list.length>0){
            let config = {
              header: ['微信id', '微信昵称', '申请数',' 通过数','今日总人数','昨日人数','在线状态'],
              data: [],
              align: ['center'],
              headerBGC: '#1981f6',
              headerHeight: 45,
              rowNum: 5,
              oddRowBGC: 'rgba(0, 44, 81, 0.8)',
              evenRowBGC: 'rgba(10, 29, 50, 0.8)'
            }
            this.statistics.list.forEach(item=>{
              let array = [];
              array.push(item.wxId);
              array.push(item.wxName);
              array.push(item.todayAskCount?item.todayAskCount:0);
              array.push(item.todayPassCount?item.todayPassCount:0);
              array.push(item.totalTodayCount?item.totalTodayCount:0);
              array.push(item.totalYesterdayCount?item.totalYesterdayCount:0);
              array.push(item.status ==0?"下线":"在线");
              config.data.push(array);
            })
            this.bindWxConfig=config;
          }
        }
      });
      setTimeout(()=>{
        this.queryShare()
      },5000)
    }
  }
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('../statistics/img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .block-top-content {
    height: 55%;
    display: flex;
    flex-grow: 0;
    box-sizing: border-box;
    padding-bottom: 20px;
  }
  .center-title {
    font-size: 15px;
    text-align: center;
    padding: 8px;
  }
}
</style>