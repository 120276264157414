<template>
 <el-row>
   <el-row>
     <el-tabs v-model="goodsActivity"   type="border-card" class="goods-tabs">
       <el-tab-pane label="基本信息" name="goodsBaseInfo">
         <el-row class="goods-base-info">
           <el-form  ref="goodsForm" :rules="goodsInfoRule" :model="goodsInfoDetail" label-width="100px" style="width: 80%">
             <el-form-item prop="goodsName" label="商品名称">
               <el-col :span="12">
                 <el-input v-model="goodsInfoDetail.goodsName" placeholder="商品名称"></el-input>
               </el-col>
             </el-form-item>
             <el-form-item prop="categoryId" label="商品类目">
               <el-col>
                 <select-tree  v-if="this.complete" :placeholder="'请选择商品类目'"
                              :tree-data="goodsCategoryTree" :show-checkbox="false" :expandAll="true" :nodeConfig="{value:'categoryId',label:'categoryName'}"
                               :init-show-label="goodsInfoDetail"
                               @selectTree="goodsCategoryChecked" @clear="goodsCategoryClear"></select-tree>
               </el-col>
             </el-form-item>
             <el-form-item label="图片类目">
               <el-col>
                 <select-tree :placeholder="'请选择图片类目'"
                              :tree-data="photoCategoryTree" :show-checkbox="false" :expandAll="false" :nodeConfig="{value:'categoryId',label:'categoryName'}"
                              @selectTree="photoCategoryChecked"></select-tree>
               </el-col>
             </el-form-item>
             <el-form-item prop="goodsMainPhotoPath" label="商品图片">
               <image-select  v-if="this.complete" @selectSuccess="mainImagesSelectSuccess" :list="goodsInfoDetail.goodsPhotoPathList" :select-main="true" :key="photoCategoryId" :limit="3" :category-id="photoCategoryId" ></image-select>
             </el-form-item>
             <el-form-item  v-if="this.complete" prop="goodsGraphicPhotoPath" label="详情图片">
               <image-select @selectSuccess="graphicImagesSelectSuccess" :list="goodsInfoDetail.goodsGraphicPhotoPathList" :key="photoCategoryId" :limit="5" :category-id="photoCategoryId" ></image-select>
             </el-form-item>
           </el-form>
         </el-row>
       </el-tab-pane>
       <el-tab-pane label="sku信息" name="goodsSkuList">
           <el-row>
             <el-row class="goods-sku-info" v-for="(item,index) in skuList" :key="item.skuId">
               <el-form class="goods-sku-form" :model="item" label-width="100px" >
                 <el-form-item  label="sku名称">
                   <el-col>
                     <el-input v-model="item.skuName" placeholder="sku名称"></el-input>
                   </el-col>
                 </el-form-item>
                 <el-form-item  label="sku条形码">
                   <el-col >
                     <el-input v-model="item.skuBarCode" placeholder="sku条形码"></el-input>
                   </el-col>
                 </el-form-item>
                 <el-form-item  label="产品类型">
                   <el-col >
                     <el-select v-model="item.goodsType" clearable placeholder="请选择">
                       <el-option
                           v-for="item in goodsTypeList"
                           :key="item.value"
                           :label="item.label"
                           :value="item.value">
                       </el-option>
                     </el-select>
                   </el-col>
                 </el-form-item>
                 <el-form-item label="sku排序">
                   <el-col>
                     <el-input v-model="item.goodsSkuIndex" placeholder="sku排序"></el-input>
                   </el-col>
                 </el-form-item>
                 <el-form-item label="备注">
                   <el-col >
                     <el-input type="textarea" v-model="item.goodsSkuRemark" placeholder="备注"></el-input>
                   </el-col>
                 </el-form-item>
               </el-form>
               <el-row>
                 <el-col v-if="index == 0" :offset="1">
                   <el-button plain type="primary" @click="addGoodsSku">添加</el-button>
                 </el-col>
                 <el-col v-else :offset="1">
                   <el-button plain  type="danger" @click="deleteGoodsSku(item,index)">删除</el-button>
                 </el-col>
               </el-row>
             </el-row>
           </el-row>
       </el-tab-pane>
       <el-tab-pane label="附加信息" name="goodsAttrInfo">
         <el-form  :model="goodsInfoDetail" label-width="100px" style="width: 80%">
           <el-form-item prop="brandId" label="品牌名称">
             <el-col>
               <el-select v-model="goodsInfoDetail.brandId" clearable placeholder="请选择">
                 <el-option
                     v-for="item in brandList"
                     :key="item.brandId"
                     :label="item.brandName"
                     :value="item.brandId">
                 </el-option>
               </el-select>
             </el-col>
           </el-form-item>
           <el-form-item label="商品附属标题">
             <el-col :span="12">
               <el-input type="textarea" v-model="goodsInfoDetail.goodsSubTitle" placeholder="商品附属标题"></el-input>
             </el-col>
           </el-form-item>
           <el-form-item  label="备注信息">
             <el-col :span="12">
               <el-input type="textarea" v-model="goodsInfoDetail.goodsRemark" placeholder="备注信息"></el-input>
             </el-col>
           </el-form-item>
            <el-row v-for="(item,index) in goodsPropertyList" :key="item.attrName">
              <el-form-item  :label="'附加属性'+index">
                <el-col :span="5" >
                  <el-input  v-model="item.attrName" placeholder="附加属性key"></el-input>
                </el-col>
                <el-col :span="5" :offset="1">
                  <el-input  v-model="item.attrValue" placeholder="附加属性value"></el-input>
                </el-col>
                <el-col :span="1" v-if="index == 0" :offset="1">
                  <el-button plain type="primary" @click="addGoodsAttr">添加</el-button>
                </el-col>
                <el-col :span="1" v-else :offset="1">
                  <el-button plain  type="danger" @click="deleteGoodsAttr(index)">删除</el-button>
                </el-col>
              </el-form-item>
            </el-row>
         </el-form>
       </el-tab-pane>
     </el-tabs>
     <div class="goods-activity">
       <el-button @click="closeGoodsInfo">取 消</el-button>
       <el-button type="primary" @click="saveGoodsInfo('goodsForm')">保 存</el-button>
     </div>
   </el-row>
 </el-row>
</template>

<script>
import SelectTree from "../../components/select-tree";
import imageSelect from "../../components/image-select";
import {addGoods,findGoodsDetailByEdit,updateGoods,deleteGoodsSku} from '@/api/goods';
export default {
  name: "goodsInfo",
  props:['goodsInfo','editGoods','goodsCategoryTree','brandList','photoCategoryTree'],
  data(){
    return{
      goodsActivity:'goodsBaseInfo',
      goodsRules:{},
      goodsInfoDetail:{},
      skuList:[{goodsType:'0'}],
      goodsPropertyList:[{attrName:'',attrValue:''}],
      photoCategoryId:null,
      goodsTypeList:[
        {value:"0",label:"定装"},
        {value:"1",label:"散装"}
      ],
      goodsInfoRule:{
        goodsName:[
          { required: true, message: '商品名称不能为空', trigger: 'blur' },
        ],
        categoryId:[
          { required: true, message: '商品类目不能为空', trigger: 'blur' },
        ],
        goodsMainPhotoPath:[
          { required: true, message: '商品图片不能为空', trigger: 'blur' },
        ],
        goodsGraphicPhotoPath:[
          { required: true, message: '详情图片不能为空', trigger: 'blur' },
        ]
      },
      complete:false
    }
  },
  mounted() {
    if(this.goodsInfo && this.goodsInfo.goodsId){
        this.initGoodsInfo();
      return;
    }
    this.complete=true;
  },
  methods:{
    addGoodsSku:function (){
      this.skuList.push({goodsType:'0'});
    },
    deleteGoodsSku:function (data,index){
      if(!data.skuId){
        this.skuList.splice(index,1);
        return;
      }
      this.$confirm('是否删除sku <span style="color: red">'+data.skuName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteGoodsSku(data,success=>{
              this.$successMessage('删除成功');
              this.skuList.splice(index,1)
            })
          })
          .catch(() => {

          })

    },
    initGoodsInfo:function (){
      findGoodsDetailByEdit({goodsId:this.goodsInfo.goodsId},success=>{
        this.goodsInfoDetail=success;
        if(this.goodsInfoDetail.goodsSkuVoList && this.goodsInfoDetail.goodsSkuVoList.length>0){
          this.skuList=this.goodsInfoDetail.goodsSkuVoList;
        }
        this.initMainPhoto();
        this.initGoodsAttrList();
        this.complete=true;
      })
    },
    initGoodsAttrList:function (){
      if(this.goodsInfoDetail.goodsProperty){
         this.goodsPropertyList = JSON.parse(this.goodsInfoDetail.goodsProperty);
         if(this.goodsPropertyList.length==0){
           this.goodsPropertyList=[{attrName:'',attrValue:''}];
         }
      }
    },
    deleteGoodsAttr:function (index){
      this.goodsPropertyList.splice(index,1)
    },
    addGoodsAttr:function (){
      this.goodsPropertyList.push({attrName:'',attrValue:''});
    },
    closeGoodsInfo:function (){
      this.$emit("closeGoodsInfo");
    },
    saveGoodsInfo:function (formName){
      this.$refs[formName].validate((valid) => {
            if (valid) {
              this.initGoodsAttr();
              this.goodsInfoDetail.goodsSkuVoList=this.initGoodsSku();
              if(this.goodsInfoDetail.goodsSkuVoList.length == 0){
                this.$errorMessage('sku信息不能为空');
                return ;
              }
              if(this.goodsInfoDetail.goodsId){
                updateGoods(this.goodsInfoDetail,success=>{
                  this.$successMessage("修改成功");
                  this.$emit("closeGoodsInfo",'save');
                })
                return;
              }
              addGoods(this.goodsInfoDetail,success=>{
                this.$successMessage("保存成功");
                this.$emit("closeGoodsInfo",'save');
              });
            }else {
              return false;
            }
      });
    },
    initGoodsSku(){
      let goodsSkuList = [];
      this.skuList.forEach(item=>{
        if(item.skuName && item.skuBarCode){
          goodsSkuList.push(item);
        }
      });
      return goodsSkuList;
    },
    initGoodsAttr(){
      let goodsAttrList = [];
      this.goodsPropertyList.forEach(item=>{
        if(item.attrName && item.attrValue){
          goodsAttrList.push(item);
        }
      });
      this.goodsInfoDetail.goodsProperty=JSON.stringify(goodsAttrList);
    },
    initMainPhoto:function (){
      if(this.goodsInfoDetail && this.goodsInfoDetail.goodsPhotoPathList && this.goodsInfoDetail.goodsMainPhotoPath){
        this.goodsInfoDetail.goodsPhotoPathList.forEach(item=>{
          if(this.goodsInfoDetail.goodsMainPhotoPath == item.attachmentObjectName){
            item.selected=true;
          }
        })
      }
    },
    graphicImagesSelectSuccess:function (imageList){
      if(imageList && imageList.length>0){
        this.goodsInfoDetail.goodsGraphicPhotoPath=this.getPhotoPath(imageList);
        return;
      }
      this.goodsInfoDetail.goodsGraphicPhotoPath='';
    },
    mainImagesSelectSuccess:function (imageList){
      if(imageList && imageList.length>0){
        this.goodsInfoDetail.goodsMainPhotoPath=this.getMainPhoto(imageList).attachmentObjectName;
        this.goodsInfoDetail.goodsPhotoPath=this.getPhotoPath(imageList);
        return;
      }
      this.goodsInfoDetail.goodsPhotoPath='';
      this.goodsInfoDetail.goodsMainPhotoPath='';
    },
    getPhotoPath:function (imageList){
      let photoPath = '';
      imageList.forEach(item=>{
          photoPath=photoPath+item.attachmentObjectName+',';
      });
      return photoPath;
    },
    getMainPhoto:function (imageList){
       let mainPhoto = imageList[0];
        imageList.forEach(item=>{
          if(item.selected){
            mainPhoto=item;
          }
        });
        return mainPhoto;
    },
    goodsCategoryChecked:function (data){
      this.$set(this.goodsInfoDetail, 'categoryId', data);
    },
    goodsCategoryClear:function (){
      this.$set(this.goodsInfoDetail, 'categoryId', null);
    },
    photoCategoryChecked:function (data){
      this.photoCategoryId=data;
    }
  },
  components:{
    SelectTree,
    imageSelect
  }
}
</script>

<style scoped>
.goods-sku-form{
  width: 40vw;
  padding: 10px 16px 0px 0px;
  margin: 2px;
  border: 1px dashed #F60;
  border-radius: 6px;
}
.goods-sku-info{
  display: flex;
  align-items: center;
}
.goods-activity{
  text-align: center;
  margin-top: 10px;
}
</style>