<template>
    <el-row>
        <el-row class="group-query-form">
            <el-form :inline="true" :model="queryGroupParam"  >
                <el-form-item label="组别名称" label-width="100px">
                    <el-input v-model="queryGroupParam.groupName" placeholder="组别名称"></el-input>
                </el-form-item>
                <el-form-item label="组别编码" label-width="100px">
                    <el-input v-model="queryGroupParam.groupCode" placeholder="组别编码"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="groupListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="group-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addGroupInfo">添加</el-button>
        </el-row>
        <el-row class="group-info-table">
            <el-table
                    border
                    :data="groupList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="groupName" label="组别名称"></el-table-column>
                <el-table-column prop="groupCode" label="组别编码"></el-table-column>
                <el-table-column prop="groupRemark" label="备注" ></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作" width="280px">
                    <template slot-scope="props">
                        <el-button plain  type="primary" size="mini" @click="groupRoleUpdate(props.row)">角色</el-button>
                        <el-button plain  type="success" size="mini" @click="groupUserUpdate(props.row)">用户</el-button>
                        <el-button plain  type="warning" size="mini" @click="groupInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain  type="danger" size="mini" @click="groupInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="group-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <group-info  v-if="viewGroupDetailDialog == true" :viewGroupInfoDialog="viewGroupDetailDialog"
                    :group-info="currentGroupInfo"
                    @cancelGroupInfo="cancelGroupInfo" @submitGroupInfo="submitGroupInfo"></group-info>
        <group-user v-if="viewGroupUserDialog == true" :groupUserDialog="viewGroupUserDialog"
                    :group-info="currentGroupInfo" @cancelGroupUser="cancelGroupUser"></group-user>
        <group-role v-if="viewGroupRoleDialog == true" :groupRoleDialog="viewGroupRoleDialog"
                    :group-info="currentGroupInfo" @cancelGroupRole="cancelGroupRole"></group-role>
    </el-row>
</template>

<script>
    import {findGroupPageList,deleteGroup,updateGroup,addGroup} from '@/api/group'
    import groupInfo from "@/views/roleGroup/groupInfo";
    import groupRole from "@/views/roleGroup/groupRole";
    import groupUser from "@/views/roleGroup/groupUser";
    export default {
        name: "groupListPage",
        data(){
            return{
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                groupList:[],
                queryGroupParam:{},
                currentGroupInfo:{},
                viewGroupDetailDialog:false,
                viewGroupRoleDialog:false,
                viewGroupUserDialog:false
            }
        },
        mounted(){
            this.groupListPageQuery();
        },
        methods:{
            groupRoleUpdate:function(groupInfo){
                this.currentGroupInfo = groupInfo;
                this.viewGroupRoleDialog = true;
            },
            groupUserUpdate:function(groupInfo){
                this.currentGroupInfo = groupInfo;
                this.viewGroupUserDialog = true;
            },
            cancelGroupUser:function(){
                this.viewGroupUserDialog = false;
            },
            cancelGroupRole:function(){
                this.viewGroupRoleDialog = false;
            },
            groupListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findGroupPageList(this.page,this.queryGroupParam,success=>{
                    this.groupList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage =  success.totalPage;
                })
            },
            addGroupInfo:function () {
                this.currentGroupInfo = {};
                this.viewGroupDetailDialog=true;
            },
            groupInfoUpdate:function(groupInfo){
                this.currentGroupInfo = groupInfo;
                this.viewGroupDetailDialog=true;
            },
            queryReset:function(){
                this.queryGroupParam={};
                this.page ={
                    pageSize:10,
                    currentPage:1
                }
                this.groupListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.groupListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.groupListPageQuery(true);
            },
            cancelGroupInfo:function(){
                this.viewGroupDetailDialog=false;
            },
            submitGroupInfo:function(groupInfo){
                if(groupInfo.groupId){
                    updateGroup(groupInfo,success=>{
                        this.$successMessage("修改成功");
                        this.viewGroupDetailDialog=false;
                    })
                }else{
                    addGroup(groupInfo,success=>{
                        this.$successMessage("新增成功");
                        this.viewGroupDetailDialog=false;
                        this.queryReset();
                    })
                }
            },
            groupInfoDelete:function (groupInfo) {
                this.$confirm('是否删除群组 <span style="color: red">'+groupInfo.groupName+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteGroup(groupInfo,success=>{
                            this.$successMessage("删除成功");
                            this.groupList.forEach((item,index)=>{
                                if(item.groupId == groupInfo.groupId){
                                    this.groupList.splice(index,1);
                                }
                            });
                        });
                    })
                    .catch(() => {})
            }
        },
        components:{
            groupInfo,
            groupRole,
            groupUser
        }
    }
</script>

<style scoped>
    .group-info-table{
        margin-top: 8px;
    }
    .group-table-page{
        margin-top: 8px;
    }
</style>