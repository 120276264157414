<template>
    <el-menu :default-active="defaultActive" :collapse="collapsed" router  unique-opened 
             class="el-menu-vertical-demo" background-color="#334157" text-color="#fff" active-text-color="#ffd04b">
        <div class="logo-box">
            <img class="logo-img" :src="logoUrl" alt="">
            <h1 class="logo-title">{{title}}</h1>
        </div>
        <el-row  v-for="menu in menuInfoList" :key="menu.catalogId" :index="menu.catalogName">
            <el-submenu v-if="menu.child != null && menu.child.length>0" :index="menu.catalogName">
                <template slot="title" >
                    <i class="iconfont" :class="menu.icon"></i>
                    <span  slot="title" class="catalog-name">{{menu.catalogName}}</span>
                </template>
                <navNode v-bind:menu-info-list="menu.child"></navNode>
            </el-submenu>
            <el-menu-item v-else :index="'/'+menu.catalogUrl" :key="menu.catalogId" >
                <i class="iconfont" :class="menu.icon"></i>
                <span slot="title" class="catalog-name">{{menu.catalogName}}</span>
            </el-menu-item>
        </el-row>
    </el-menu>
</template>
<script>
    import navNode from "@/components/nav-node"
    export default {
        name: 'leftNav',
        props: {
          menuInfoList: {
            type: Array,
            default: () => { return [] }
          },
          title: {
            type: String,
            default: '速惠后台'
          },
          logoUrl: {
            type: String,
            default: 'https://market-public-image.oss-cn-guangzhou.aliyuncs.com/logo.jpg'
          }
        },
        data() {
            return {
                defaultActive:this.$router.currentRoute.fullPath,
                collapsed: false,
            }
        },
        methods:{
        },
        // 创建完毕状态(里面是操作)
        created() {
            this.$root.Bus.$on('toggle', value => {
                this.collapsed = !value
            })
        },
        components:{
            navNode
        }
    }
</script>
<style>
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        width: 200px;
        min-height: 400px;
    }
    .el-menu-vertical-demo:not(.el-menu--collapse) {
        border: none;
        text-align: left;
    }
    .el-menu-item-group__title {
        padding: 0px;
    }
    .el-menu-bg {
        background-color: #1f2d3d !important;
    }
    .el-menu {
        border: none;
    }
    .logo-box {
        height: 40px;
        line-height: 40px;
        color: #9d9d9d;
        font-size: 20px;
        text-align: center;
        padding: 20px 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .logo-img {
        height: 40px;
        border-radius: 99px;
    }
    .logo-title{
        padding-left: 10px;
        overflow: hidden;
        height: 40px;
    }
    .el-menu--collapse>.logo-box>.logo-title{
        display: none;
    }
    .catalog-name{
        padding-left: 8px;
    }
    .el-menu--collapse>.el-row>.el-submenu>.el-submenu__title span {
        height: 0;
        width: 0;
        overflow: hidden;
        visibility: hidden;
        display: inline-block;
    }
    .el-menu--collapse>.el-row>.el-submenu>.el-submenu__title>.el-submenu__icon-arrow {
        display: none;
    }
</style>
