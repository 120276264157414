<template>
    <el-row>
        <el-row class="range-query-form">
            <el-form :inline="true" :model="rangeQueryParam"  >
                <el-form-item label="数据范围key" label-width="100px">
                    <el-input v-model="rangeQueryParam.dataRangeKey" placeholder="数据范围key"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="rangeListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="range-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addRangeInfo">添加</el-button>
        </el-row>
        <el-row class="range-info-table">
            <el-table
                    border
                    :data="rangeList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="dataRangeKey" label="数据范围key"></el-table-column>
                <el-table-column prop="dataRangeValue" label="数据范围value"></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作" width="160px">
                    <template slot-scope="props">
                        <el-button plain  type="warning" size="mini" @click="rangeInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain  type="danger" size="mini" @click="rangeInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="range-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <el-row v-if="addRangeDialog == true">
            <el-dialog
                title="添加数据范围"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="addRangeDialog"
            >
                <el-row>
                    <el-form ref="rangeInfoForm" :rules="rangeInfoRule" :model="currentRangeInfo" label-width="120px">
                        <el-form-item prop="dataRangeKey" label="数据范围key" >
                            <el-col :span="12">
                                <el-input v-model="currentRangeInfo.dataRangeKey" placeholder="请输入数据范围key" :readonly="currentRangeInfo.dataRangeId?true:false"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item prop="dataRangeValue" label="数据范围value">
                            <el-col :span="12">
                                <el-input v-model="currentRangeInfo.dataRangeValue" placeholder="请输入数据范围value"></el-input>
                            </el-col>
                        </el-form-item>
                    </el-form>
                </el-row>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelRangeInfo('rangeInfoForm')">取 消</el-button>
                    <el-button type="primary" @click="submitRangeInfo('rangeInfoForm')">确 定</el-button>
                </span>
            </el-dialog>
        </el-row>
    </el-row>
</template>

<script>
    import {findDataRangePageList,deleteDataRange,insertDataRange,updateDataRange} from '@/api/dataRange';
    export default {
        name: "rangeListPage",
        data(){
            return {
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                currentRangeInfo:{},
                rangeQueryParam:{},
                rangeList:[],
                addRangeDialog:false,
                rangeInfoRule:{
                    dataRangeKey:[
                        {required:true,message: "数据范围key不能为空",trigger: 'blur'}
                    ],
                    dataRangeValue:[
                        {required:true,message: "数据范围value不能为空",trigger: 'blur'}
                    ]
                }
            }
        },
        mounted(){
            this.rangeListPageQuery();
        },
        methods:{
            cancelRangeInfo:function(){
                this.currentRangeInfo={};
                this.addRangeDialog=false;
            },
            submitRangeInfo:function(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.currentRangeInfo.dataRangeId){
                            updateDataRange(this.currentRangeInfo,success=>{
                                this.$message({type:"success",message:"修改数据范围成功",duration:1500});
                                this.addRangeDialog=false;
                            });
                        }else{
                            insertDataRange(this.currentRangeInfo,success=>{
                                this.$message({type:"success",message:"保存数据范围成功",duration:1500})
                                this.addRangeDialog=false;
                                this.queryReset();
                            })
                        }
                    } else {
                        return false;
                    }
                });
            },
            rangeInfoUpdate:function(rangeInfo){
                this.currentRangeInfo = rangeInfo;
                this.addRangeDialog=true;
            },
            addRangeInfo:function(){
                this.currentRangeInfo = {};
                this.addRangeDialog=true;
            },
            rangeListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findDataRangePageList(this.page,this.rangeQueryParam,success=>{
                    this.rangeList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            queryReset:function () {
                this.rangeQueryParam={};
                this.page={
                    pageSize:10,
                    currentPage:1
                };
                this.rangeListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.rangeListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.rangeListPageQuery(true);
            },
            rangeInfoDelete:function (rangeInfo) {
                this.$confirm('是否删除数据范围 <span style="color: red">'+rangeInfo.dataRangeKey+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteDataRange(rangeInfo,success=>{
                            this.$message({type:"success",message:"删除成功",duration:1500});
                            this.rangeList.forEach((item,index)=>{
                                if(item.dataRangeId == rangeInfo.dataRangeId){
                                    this.rangeList.splice(index,1);
                                }
                            });
                        })
                    })
                    .catch(() => {

                    })
            }
        }
    }
</script>

<style scoped>
    .range-info-table{
        margin-top: 8px;
    }
    .range-table-page{
        margin-top: 8px;
    }
</style>