<template>
 <el-row>
   <el-row class="slideshow-query-form">
     <el-form :inline="true" :model="slideshowQueryParam" >
       <el-form-item label="类型" label-width="80px">
         <el-select v-model="slideshowQueryParam.slideshowType" clearable placeholder="请选择">
           <el-option
               v-for="item in slideshowTypeList"
               :key="item.itemCode"
               :label="item.itemName"
               :value="item.itemCode">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item  >
         <el-button icon="el-icon-search" type="primary" @click="slideshowPageQuery">查询</el-button>
         <el-button @click="queryReset">重置</el-button>
       </el-form-item>
     </el-form>
   </el-row>
   <el-row class="slideshow-info-add">
     <el-button type="primary" icon="el-icon-plus" @click="addSlideshowInfoClick">添加</el-button>
   </el-row>
   <el-row class="slideshow-info-table">
     <el-table
         border
         :data="slideshowList"
         :header-cell-style="{background:'#F3F4F7',color:'#555'}"
     >
       <el-table-column prop="attachmentUploadVo" label="轮播图">
         <template slot-scope="props">
           <el-image v-if="props.row.downloadUrl" :src="props.row.downloadUrl"></el-image>
         </template>
       </el-table-column>
       <el-table-column prop="slideshowType" label="轮播类型"  :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'SLIDESHOW_TYPE')"></el-table-column>
       <el-table-column prop="slideshowOrder" label="轮播图顺序" ></el-table-column>
       <el-table-column prop="noticeEnable" label="是否启用">
         <template slot-scope="props">
           <el-switch
               v-model="props.row.slideshowEnable"
               inactive-value="0"
               active-value="1"
               active-color="#13ce66"
               @change="slideshowEnableUpdate($event,props)"
               inactive-color="#d2d2d2">
           </el-switch>
         </template>
       </el-table-column>
       <el-table-column prop="createdBy" label="创建者"></el-table-column>
       <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
       <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
       <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
       <el-table-column  label="操作">
         <template slot-scope="props">
           <el-button plain  type="warning" size="mini" @click="slideshowInfoUpdate(props.row)">编辑</el-button>
           <el-button plain v-if="props.row.slideshowEnable == '0'"  type="danger" size="mini" @click="slideshowInfoDelete(props.row)">删除</el-button>
         </template>
       </el-table-column>
     </el-table>
   </el-row>
   <el-row class="slideshow-table-page">
     <el-pagination
         @size-change="handleSizeChange"
         @current-change="handleCurrentChange"
         :current-page="page.currentPage"
         :page-sizes="[10,15,30,50,100]"
         :page-size="page.pageSize"
         layout="total, sizes, prev, pager, next, jumper"
         :total="pageInfo.count">
     </el-pagination>
   </el-row>
   <el-row v-if="addSlideshowDialog == true">
     <slideshow-detail :slideshow="currentSlideshow" :view-slideshow="addSlideshowDialog" @cancelAddSlideshow="cancelAddSlideshow" @editSlideshowSuccess="editSlideshowSuccess" @addSlideshowSuccess="addSlideshowSuccess"></slideshow-detail>
   </el-row>

 </el-row>
</template>

<script>
import slideshowDetail from "@/views/slideshow/slideshowDetail";
import {findSlideshowPageList,enableSlideshow,deleteSlideshow} from "@/api/slideshow";
import {deleteBaseMapInfo} from "@/api/baseMap";
export default {
  name: "slideshowListPage",
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      slideshowList:[],
      slideshowQueryParam:{},
      slideshowTypeList:[],
      addSlideshowDialog:false,
      currentSlideshow:{}
    }
  },
  created() {
    this.slideshowTypeList =  this.$lookupInfo('SLIDESHOW_TYPE')
    this.slideshowPageQuery();
  },
  methods:{
    addSlideshowSuccess:function (){
      this.currentSlideshow={};
      this.addSlideshowDialog=false;
      this.slideshowPageQuery();
    },
    editSlideshowSuccess:function (){
      this.addSlideshowDialog=false;
    },
    cancelAddSlideshow:function (){
      this.addSlideshowDialog=false;
    },
    addSlideshowInfoClick:function (){
      this.addSlideshowDialog=true;
    },
    slideshowInfoUpdate:function (data){
      this.currentSlideshow = data;
      this.addSlideshowDialog=true;
    },
    slideshowInfoDelete:function (data){
      this.$confirm('是否删除轮播图', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteSlideshow(data,success=>{
              this.$successMessage('删除成功');
              this.slideshowList.forEach((item,index)=>{
                if(item.slideshowId == data.slideshowId){
                  this.slideshowList.splice(index,1);
                }
              });
            })
          })
          .catch(() => {

          })
    },
    queryReset:function () {
      this.slideshowQueryParam={};
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.slideshowPageQuery();
    },
    slideshowPageQuery:function (){
      findSlideshowPageList(this.page,this.slideshowQueryParam,success=>{
        this.slideshowList = success.data;
      })
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.slideshowPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.slideshowPageQuery();
    },
    slideshowEnableUpdate:function ($event,props){
      let data = props.row;
      enableSlideshow(data,success=>{
        this.$successMessage('修改成功');
      })
    }
  },
  components:{
    slideshowDetail
  }
}
</script>

<style scoped>
.slideshow-info-table{
  margin-top: 8px;
}
.slideshow-table-page{
  margin-top: 8px;
}
</style>