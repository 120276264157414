//查询应用列表
import {_get} from "@/http";
import {_delete, _post} from "../http";

export const findShopPageList = (page, param, success, error) => _get("/market/shop/shop/findAllShopPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;

//保存运营商入驻草稿信息
export const addShopBaseInfo = (param,success,error) => _post("/market/shop/shop/addShopBaseInfo", param,success,error);
//修改运营商入驻草稿信息
export const updateShopBaseInfo = (param,success,error) => _post("/market/shop/shop/updateShopBaseInfo", param,success,error);
//删除店铺信息
export const deleteShopInfo = (param,success,error) => _delete("/market/shop/shop/delete", param,success,error);
//保存店铺附加信息
export const updateShopExp = (param,success,error) => _post("/market/shop/shop/updateShopExp", param,success,error);
//查询门店详细信息
export const shopDetail = (param,success) => _get("/market/shop/shop/detail", param,success);
//提交门店
export const submitShopExpInfo = (param,success) => _post("/market/shop/shop/submit", param,success);
//修改门店主图
export const updateShopPhoto = (param,success) => _post("/market/shop/shop/updateShopPhoto", param,success);
//查询店铺基本信息
export const baseInfoDetail = (success) => _get("/market/shop/shop/baseInfoDetail", {},success);
//修改店铺基本信息
export const updateShopBaseInfoByManager = (param,success) => _post("/market/shop/shop/updateShopBaseInfoByManager", param,success);
//修改店铺状态
export const openShop = (status,success) => _get("/market/shop/shop/status/"+status, {},success);
//店铺预售信息
export const findPreSellInfo = (param,success) => _get("/market/shop/shop/findPreSellInfo", param,success);
//保存店铺预售信息
export const saveShopPreSell = (param,success) => _post("/market/shop/shop/saveShopPreSell", param,success);
//预售开关
export const openShopPreSell = (status,param,success) => _get("/market/shop/shop/circle/preSell/"+status, param,success);
//商家服务
export const queryShopServer = (success) => _get("/market/shop/shopServer/query", {},success);
//修改商家服务
export const addShopServer = (param,success) => _post("/market/shop/shopServer/add", param,success);




