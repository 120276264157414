import {_get,_post,_put,_delete} from '../http/index';
//查询轮播列表
export const  findSlideshowPageList = (page,param,success,error) => _get("/market/manager/slideshow/findSlideshowPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加轮播图
export const  addSlideshow  = (param,success,error) => _post("/market/manager/slideshow/add", param,success,error) ;
//修改轮播图
export const  updateSlideshow  = (param,success,error) => _put("/market/manager/slideshow/update", param,success,error) ;
//启用轮播图
export const  enableSlideshow  = (param,success,error) => _put("/market/manager/slideshow/enable", param,success,error) ;
//删除轮播图
export const  deleteSlideshow  = (param,success,error) => _delete("/market/manager/slideshow/delete", param,success,error) ;