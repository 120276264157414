<template>
    <el-container class="update-user-pwd">
        <el-row class="update-pwd-lab">
            <el-steps :active="updateStep" finish-status="success">
                <el-step title="手机验证码"></el-step>
                <el-step title="密码修改"></el-step>
            </el-steps>
            <el-row class="update-pwd-form">
                <el-form v-if="updateStep==0" label-width="100px" class="send-verification-code">
                    <el-form-item label="手机号码">
                        <el-input v-model="userPhone" readonly></el-input>
                    </el-form-item>
                    <el-form-item  label="手机验证码" v-if="userPhone != null">
                        <el-row>
                            <el-col :span="14">
                                <el-input type="text" v-model="sendVerificationCode" auto-complete="off"
                                          placeholder="短信验证码"></el-input>
                            </el-col>
                            <el-col :span="8" :offset="2">
                                <sendVerificationCode v-bind:get-code-url="'market/manager//user/setPassword/sendVerificationCode'" v-bind:user-phone="userPhone"></sendVerificationCode>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="checkVerificationCode()">下一步</el-button>
                    </el-form-item>
                </el-form>
                <el-form v-if="updateStep==1" label-width="100px" class="send-verification-code">
                    <el-form-item label="新密码">
                        <el-input v-model="newPwd" show-password></el-input>
                    </el-form-item>
                    <el-form-item label="再次确认">
                        <el-input v-model="newPwdVerify" show-password></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="preStep()">上一步</el-button>
                        <el-button type="primary" @click="updateUserPassword()">确认修改</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>
    </el-container>
</template>
<script>
    import {getCurrentUser,checkVerificationCode,updateUserPassword} from '@/api/user';
    import sendVerificationCode from '@/components/send-verification-code';
    export default {
        name: "updatePassword",
        data(){
            return {
                userPhone:'',
                sendVerificationCode:'',
                updateStep:0,
                userInfo:{},
                newPwd:'',
                newPwdVerify:''
            }
        },
        mounted(){
            getCurrentUser(success=> {
                this.userInfo = success.obj.currentUser;
                this.userPhone = success.obj.currentUser.userPhone;
            })
        },
        methods:{
            checkVerificationCode:function(){
                if(this.sendVerificationCode.length != 6){
                    this.$message({
                        type:'error',
                        message:'验证码不正确',
                        duration:1500
                    })
                    return;
                }
                let param = new Object();
                param.userPhone = this.userPhone;
                param.sendVerificationCode = this.sendVerificationCode;
                checkVerificationCode(param,success=>{
                        this.updateStep =1;
                })
            },
            preStep:function () {
                this.updateStep =0;
            },
            updateUserPassword:function () {
                if(this.newPwd != this.newPwdVerify){
                    this.$message({
                        type:'error',
                        message:'两次密码不一致,请重新输入',
                        duration:1500
                    })
                    return;
                }
                let regex = new RegExp('(?=.*[0-9])(?=.*[a-zA-Z]){8,30}');
                if(!regex.test(this.newPwd)){
                    this.$message({
                        type:'error',
                        message:'密码过于简单,需要8位并包括字母和数字',
                        duration:1500
                    })
                    return false;
                }
                let param = new Object();
                param.userPhone = this.userPhone;
                param.verificationCode = this.sendVerificationCode;
                param.userId = this.userInfo.userId;
                param.newPwd = this.newPwd;
                param.newPwdVerify = this.newPwdVerify;
                updateUserPassword(param,success=>{
                    this.$message({
                        type:'success',
                        message:'修改成功',
                        duration:1500
                    });
                    this.updateStep =0;
                    this.sendVerificationCode = '';
                })
            }
        },
        components:{
            sendVerificationCode
        }
    }
</script>

<style scoped>
.send-verification-code{
    padding-top: 10px;
    width: 420px;
}
    .update-user-pwd{
        justify-content: center;
    }
    .update-pwd-lab{
        width: 50vw;
    }
    .update-pwd-form{
        display: flex;
        justify-content: center;
        margin-top: 10px;
    }
</style>