<template>
 <el-row>
   <el-dialog
       :title="slideshow && slideshow.slideshowId?'修改轮播图':'添加轮播图'"
       width="36%"
       :close-on-click-modal="false"
       :visible.sync="viewSlideshow"
       @close="cancelAddBaseMapInfo"
   >
   <el-form ref="slideshowForm" :rules="slideshowInfoRule" :model="slideshowDetail" label-width="120px">
     <el-form-item label="类型" prop="slideshowType">
       <el-select v-model="slideshowDetail.slideshowType" clearable placeholder="请选择">
         <el-option
             v-for="item in slideshowTypeList"
             :key="item.itemCode"
             :label="item.itemName"
             :value="item.itemCode">
         </el-option>
       </el-select>
     </el-form-item>
     <el-form-item prop="baseMapName" label="轮播图">
       <imageUpload v-bind:file="slideshowDetail.attachmentUploadVo" v-bind:limit="1" v-bind:type="'image'"  @uploadSuccess="slideshowUploadSuccess"></imageUpload>
     </el-form-item>
     <el-form-item prop="slideshowOrder" label="顺序">
       <el-col :span="12">
         <el-input v-model="slideshowDetail.slideshowOrder" placeholder="请输入轮播图顺序"></el-input>
       </el-col>
     </el-form-item>
   </el-form>
     <span slot="footer" class="dialog-footer">
       <el-button @click="cancelAddSlideshow">取 消</el-button>
         <el-button type="primary" @click="addSlideshow('slideshowForm')">确 定</el-button>
     </span>
   </el-dialog>
 </el-row>
</template>

<script>
import {addSlideshow, updateSlideshow} from "@/api/slideshow";
import imageUpload from '@/components/image-upload';
export default {
  name: "slideshowDetail",
  props:{
    viewSlideshow:{
      type:Boolean,
      default:false
    },
    slideshow:{
      type:Object,
      default:()=>{return {}}
    }
  },
  components:{
    imageUpload
  },
  created() {
    this.slideshowTypeList =  this.$lookupInfo('SLIDESHOW_TYPE');
  },
  mounted() {
    if(this.slideshow && this.slideshow.slideshowId) {
      this.slideshowDetail = this.slideshow;
    }
  },
  data(){
    return {
      slideshowDetail:{
        slideshowEnable:0
      },
      slideshowTypeList:[],
      slideshowInfoRule:{
        slideshowType:[
          { required: true, message: '轮播类型不能为空', trigger: 'blur' },
        ],
        slideshowOrder:[
          { required: true, message: '轮播顺序不能为空', trigger: 'blur' },
        ]
      }
    }
  },
  methods:{
    cancelAddBaseMapInfo:function (){
      this.$emit('cancelAddSlideshow')
    },
    cancelAddSlideshow:function (){
      this.$emit('cancelAddSlideshow')
    },
    addSlideshow:function (formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.slideshowDetail.slideshowId){
            updateSlideshow(this.slideshowDetail,success=>{
              this.$successMessage('修改轮播图成功');
              this.$emit("editSlideshowSuccess");
            });
          }else{
            addSlideshow(this.slideshowDetail,success=>{
              this.$successMessage('新增轮播图成功');
              this.$emit("addSlideshowSuccess");
            });
          }
        } else {
          return false;
        }
      });
    },
    slideshowUploadSuccess:function(imageList){
      if(imageList && imageList.length>0){
        this.slideshowDetail.attachmentName
            =imageList[0].attachmentObjectName;
      }else{
        this.slideshowDetail.attachmentName = '';
      }
    },
  }
}
</script>

<style scoped>

</style>