import http from '../config/http';
import {baseUrl} from "@/config/env";
import { Message } from "element-ui";
import {cache} from "@/store/store";

// 通用公用方法
const _get =  (url, params,success,error) => {
    return   request('GET',url, params,success,error);
};
const _post =  (url, params,success,error) => {
    return request('POST',url, params,success,error);
};
const _put =  (url, params,success,error) => {
    return  request('PUT',url, params,success,error);
};
const _delete =  (url, params,success,error) => {
    return  request('DELETE',url, params,success,error);
};


function request(type,url, params,success,error) {
    let config = {
        method:type,
        url:baseUrl+url,
        data:params,
    };
    let shopId = cache.getCache('currentShopId');
    if(shopId){
        config.headers = {'current-shop-id':shopId};
    }
    if(type=='GET'){
        config.params = params;
    }else{
        config.data = params;
    }
    return new Promise((resolve, reject) =>{
        http.request(config).then(res=>{
            resolve(res.data);
            if(res && res.data && (res.data.code === 500)){
                error && error(res.data);
                return;
            }
            if(res.data){
                success && success(res.data);
                return;
            }
            if(res.response.status == 403){
                error && error(res.data);
                return;
            }
            if(res.response.data && res.response.data.message){
                error && error(res.data);
                return Message.error({type:"error",message:res.response.data.message,duration:1500});
            }
            if(res.response.status != 403){
                Message.error({type:"error",message:"系统异常,程序员正在紧急修复",duration:1500});
                error && error(res.data);
            }
        }).catch(err =>{
            reject(err.data)
            Message.error({type:"error",message:"系统异常,程序员正在紧急修复",duration:1500});
            error && error(err.data);
        })
    });
}


export {
    _get,
    _post,
    _put,
    _delete
}