<template>
  <el-row>
    <el-form label-width="100px" :model="perSellInfo" :rules="perSellInfoRule">
      <el-form-item label="预售开关" >
        <template slot-scope="props">
          <el-switch
              v-model="shopInfo.preSell"
              inactive-value="N"
              active-value="Y"
              active-color="#13ce66"
              @change="openShopPerSell($event,props)"
              inactive-color="#d2d2d2">
          </el-switch>
        </template>
      </el-form-item>
      <el-form-item  prop="leadTime" label="提前时间">
        <el-col :span="5">
          <el-input v-model="perSellInfo.leadTime" type="number" placeholder="提前时间"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item   label="提前时间">
        <el-col :span="3" >
          <el-time-select
              placeholder="预计送达时间1"
              v-model="perSellInfo.dispatchingTime1"
              :picker-options="{
                        start: '00:00',
                        step: '00:10',
                        end: '23:59:59'
                      }">
          </el-time-select>
        </el-col>
        <el-col :span="3" :offset="1">
        <el-time-select
            placeholder="预计送达时间2"
            v-model="perSellInfo.dispatchingTime2"
            :picker-options="{
                          start: '00:00',
                          step: '00:10',
                          end: '23:59:59'
                        }">
        </el-time-select>
        </el-col>
        <el-col :span="3" :offset="1">
        <el-time-select
            placeholder="预计送达时间3"
            v-model="perSellInfo.dispatchingTime3"
            :picker-options="{
                          start: '00:00',
                          step: '00:10',
                          end: '23:59:59'
                        }">
        </el-time-select>
        </el-col>
      </el-form-item>
      <el-form-item   label="预售说明">
        <el-col :span="5">
        <el-input type="textarea" v-model="perSellInfo.remark" placeholder="预售说明"></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  >
        <el-button  type="primary" @click="savePreSell">保 存</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
import {findPreSellInfo, openShopPreSell, saveShopPreSell} from "@/api/shop";
import {cache} from "@/store/store";
export default {
  name: "perSell",
  created() {
    findPreSellInfo({shopId:this.shopInfo.shopId,dataToken:this.shopInfo.dataToken,operationCode:this.shopInfo.operationCode},
    success=>{
      this.perSellInfo=success.obj;
    })
  },
  data(){
    return{
      shopInfo: cache.getMemoryCache("currentShopInfo"),
      perSellInfo:{},
      perSellInfoRule:{
        leadTime:[
          {required: true, message: '提前时间不能为空', trigger: 'blur'},
        ]
      }
    }
  },
  methods:{
    savePreSell:function (){
      this.perSellInfo.dataToken = this.shopInfo.dataToken;
      this.perSellInfo.operationCode = this.shopInfo.operationCode;
      saveShopPreSell(this.perSellInfo,success=>{
        this.$successMessage('修改成功');
      })
    },
    openShopPerSell:function (){
      let status = this.shopInfo.preSell=='N'?'close':'open';
      openShopPreSell(status,{shopId:this.shopInfo.shopId},success=>{
        this.$successMessage('修改成功');
      })
    }
  }
}
</script>

<style scoped>

</style>