<template>
   <el-row>
     <el-row class="app-query-form">
       <el-form :inline="true" :model="historyQueryParam" >
         <el-form-item label="应用名" label-width="80px">
           <el-input v-model="historyQueryParam.attachmentName" placeholder="文件名称"></el-input>
         </el-form-item>
         <el-form-item  >
           <el-button icon="el-icon-search" type="primary" @click="queryExcelHistoryPageList">查询</el-button>
           <el-button @click="queryReset">重置</el-button>
         </el-form-item>
       </el-form>
     </el-row>
     <el-row class="history-table">
       <el-table
           border
           :data="excelHistoryList"
           :header-cell-style="{background:'#F3F4F7',color:'#555'}"
       >
         <el-table-column prop="activityType" label="操作类型" :formatter="activityTypeFormatter"></el-table-column>
         <el-table-column prop="attachmentName" label="文件名称"></el-table-column>
         <el-table-column prop="attachmentPath" label="文件路径" width="460px"></el-table-column>
         <el-table-column prop="attachmentUploadStatus" label="导入状态" >
           <template slot-scope="props">
             <el-row class="excel-module-activity">
               <el-link @click="downLoadExcel(props.row.id)" v-if="props.row.attachmentUploadStatus == '2'" type="primary">成功(<span style="text-decoration:underline">下载</span>)</el-link>
               <el-link @click="downLoadErrorExcel(props.row.id)" v-if="props.row.attachmentUploadStatus == '3'" type="danger">失败(<span style="text-decoration:underline">下载</span>)</el-link>
               <el-link @click="downLoadErrorExcel(props.row.id)" v-if="props.row.attachmentUploadStatus == '4'" type="warning">部分成功(<span style="text-decoration:underline">下载</span>)</el-link>
               <el-row v-if="props.row.attachmentUploadStatus == '0'">上传成功</el-row>
               <el-row v-if="props.row.attachmentUploadStatus == '1'">解析中</el-row>
             </el-row>
           </template>
         </el-table-column>
         <el-table-column prop="createdBy" label="创建者"></el-table-column>
         <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
       </el-table>
     </el-row>
     <el-row class="history-table-page">
       <el-pagination
           :key="pageInfo.count"
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
   </el-row>
</template>

<script>
import {excelHisPageList} from '@/api/excelHistory';
import {baseUrl} from "@/config/env";
export default {
  name: "excelHistoryListPage",
  data(){
    return{
      historyQueryParam:{},
      excelHistoryList:[],
      page:{
        pageSize:10,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
   this.queryExcelHistoryPageList();
  },
  methods:{
    downLoadExcel:function (id){
      let downloadUrl = baseUrl
          +"/market/manager/excel/history/downLoadExcel/success?id="+id;
      window.open(downloadUrl,"blank");
    },
    downLoadErrorExcel:function (id){
      let downloadUrl = baseUrl
          +"/market/manager/excel/history/downLoadExcel/error?id="+id;
      window.open(downloadUrl,"blank");
    },
    activityTypeFormatter:function (row, column,cellValue){
      if(cellValue == 'import'){
        return '导入'
      }
      if(cellValue == 'export'){
        return '导出'
      }
      return '导入'
    },
    queryReset:function (){
      this.historyQueryParam={};
      this.queryExcelHistoryPageList();
    },
    queryExcelHistoryPageList:function (notResetCurrentPage){
      if(!notResetCurrentPage){
         this.page.currentPage=1;
      }
      excelHisPageList(this.page,this.historyQueryParam,success=>{
        this.excelHistoryList=success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryExcelHistoryPageList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryExcelHistoryPageList(true);
    }
  }
}
</script>

<style scoped>
.history-table,.history-table-page{
  padding-top: 8px;
}
</style>