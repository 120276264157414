<template>
  <el-row>
    <el-form ref="goodsStockForm" :rules="goodsStockRule" :model="stockInfo" label-width="80px">
      <el-form-item  label="主图">
        <el-col :span="12">

          <el-image class="goods-main-image" fit="scale-down"  :src="stockInfo.goodsPhotoPath"></el-image>
        </el-col>
      </el-form-item>
      <el-form-item  label="产品名称">
        <el-col :span="12">
          <el-input v-model="stockInfo.goodsName" disabled></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="sku名称">
        <el-col :span="12">
          <el-input v-model="stockInfo.skuName" disabled></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="库存" prop="salesStock">
        <el-col :span="12">
          <el-input type="number" v-model="stockInfo.salesStock" ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="商品价" prop="shopPrice">
        <el-col :span="12">
          <el-input type="number" v-model="stockInfo.shopPrice" ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="会员价" prop="memberPrice">
        <el-col :span="12">
          <el-input type="number" v-model="stockInfo.memberPrice" placeholder="不填按照配置比例计算" ></el-input>
        </el-col>
      </el-form-item>
      <el-form-item  label="包装费" prop="packagingFee">
        <el-col :span="12">
          <el-input type="number" v-model="stockInfo.packagingFee" ></el-input>
        </el-col>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
export default {
  name: "goodsStockInfo",
  props: {
    stockInfo:{
      type:Object,
      default:()=>{return {}}
    }
  },
  data(){
    return{
      goodsStockRule:{
        salesStock:[
          {required:true, message: '商品库存不能为空', trigger: 'blur'}
        ],
        shopPrice:[
          {required:true, message: '商品价格不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.goods-main-image{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>