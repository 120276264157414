<template>
   <el-row>
     <el-row class="module-info-add">
       <el-col :span="3" >
         <el-select v-model="moduleType" clearable placeholder="请选择">
           <el-option
               v-for="item in moduleTypeList"
               :key="item.itemCode"
               :label="item.itemName"
               :value="item.itemCode">
           </el-option>
         </el-select>
       </el-col>
       <el-col :span="3">
       <el-upload
           class="excel-module-upload"
           :action="excelModuleUploadUrl"
           name="file"
           ref="excelModuleUpload"
           :data="{moduleType:this.moduleType}"
           :multiple="false"
           :limit="1"
           :show-file-list="false"
           :on-success="uploadSuccess"
           :before-upload="fileTypeCheck">
         <el-button size="small"  icon="el-icon-upload" type="primary">模板上传</el-button>
       </el-upload>
       </el-col>
     </el-row>
     <el-row class="module-table">
       <el-table
           border
           :data="excelModuleList"
           :header-cell-style="{background:'#F3F4F7',color:'#555'}"
       >
         <el-table-column prop="attachmentName" label="模板名称">
         </el-table-column>
         <el-table-column prop="moduleType" label="模板类型" :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'EXCEL_MODULE')" ></el-table-column>
         <el-table-column prop="attachmentPath" label="模板存储路径" width="460px"></el-table-column>
         <el-table-column prop="createdBy" label="创建者"></el-table-column>
         <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
         <el-table-column  label="操作">
           <template slot-scope="props">
             <el-row class="excel-module-activity">
             <el-button plain  size="mini" @click="downloadExcelModule(props.row)">下 载</el-button>
             <el-upload
                 class="excel-module-upload"
                 :action="excelModuleUploadUrl"
                 name="file"
                 ref="excelModuleUpload"
                 :data="{moduleType:props.row.moduleType,id:props.row.id}"
                 :multiple="false"
                 :limit="1"
                 :show-file-list="false"
                 :on-success="uploadSuccess"
                 :before-upload="updateFileTypeCheck">
               <el-button plain  type="warning" size="mini"  >修 改</el-button>
             </el-upload>
             <el-button plain  type="danger" size="mini" @click="deleteExcelModule(props.row)">删 除</el-button>
             </el-row>
           </template>
         </el-table-column>
       </el-table>
     </el-row>
     <el-row class="module-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
   </el-row>
</template>

<script>
import {excelModulePageList,deleteExcelModuleInfo} from '@/api/excelModule';
import {baseUrl} from "@/config/env";
export default {
  name: "excelModuleListPage",
  data(){
    return {
      excelModuleUploadUrl:baseUrl+'/market/manager/excel/excelSingleUpload',
      moduleType:'',
      moduleTypeList:this.$lookupInfo('EXCEL_MODULE'),
      excelModuleList:[],
      page:{
        pageSize:10,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
    this.queryExcelModulePageList()
  },
  methods:{
    deleteExcelModule:function (data){
      this.$confirm('是否模板 <span style="color:red;">' + data.attachmentName + '</span> 吗?其下的条目也会一并删除','删除提示',{
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(()=>{
        deleteExcelModuleInfo(data,success=>{
          this.$message({type: "success",message:"删除成功",duration:1500});
          this.excelModuleList.forEach((item,index)=>{
            if(item.id == data.id){
              this.excelModuleList.splice(index,1);
            }
          })
        });
      }).catch(()=>{})
    },
    downloadExcelModule:function(data){
      let downloadUrl = baseUrl
          +"/market/manager/excel/downLoadExcel?id="+data.id;
      window.open(downloadUrl,"blank");
    },
    uploadSuccess:function(){
      this.$refs.excelModuleUpload.clearFiles();
      this.$message({type:"success",message:"模板上传成功",duration:1500})
      this.queryExcelModulePageList();
    },
    queryExcelModulePageList:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      excelModulePageList(this.page,{},success=>{
        this.excelModuleList=success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryExcelModulePageList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryExcelModulePageList(true);
    },
    fileTypeCheck:function (file) {
      if(!this.moduleType){
        this.$errorMessage('文件类型不能为空');
        return false;
      }
      return this.updateFileTypeCheck(file);
    },
    updateFileTypeCheck:function (file){
      let fileName =  file.name;
      if(fileName.endsWith('.xlsx')||fileName.endsWith('.xls')){
        return true;
      }
      this.$errorMessage('模板只能是xlsx,或者xls');
      return  false;
    }
  }
}
</script>

<style scoped>
.module-table,.module-table-page{
  padding-top: 8px;
}
.module-info-add{
  display: flex;
  align-items: center;
}
.excel-module-upload{
  padding-left: 8px;
}
.excel-module-activity{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>