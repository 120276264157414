import {_get,_post} from '../http/index';

//查询充值券列表
export const findRechargeInfoPageList = (page,param,success,error) => _get("/market/shop/recharge/queryRechargePageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//发放充值券
export const handOutRecharge = (param,success,error) => _post("/market/shop/recharge/handOutRecharge", param,success,error) ;
//下架充值券
export const outOfRecharge = (param,success,error) => _post("/market/shop/recharge/outOfRecharge", param,success,error) ;
//保存充值优惠券
export const addRechargeInfo = (param,success,error) => _post("/market/shop/recharge/saveRecharge", param,success,error) ;
//修改充值优惠券
export const updateRechargeInfo = (param,success,error) => _post("/market/shop/recharge/updateRecharge", param,success,error) ;
//重新充值券充值历史
export const queryRechargeHistoryOne = (page,param,success,error) => _get("/market/shop/recharge/queryRechargeHistoryOne/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//查询充值历史记录
export const queryRechargeHistoryPage = (page,param,success,error) => _get("/market/shop/recharge/queryRechargeHistoryPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;

