<template>
  <div class="wrap">
    <div class="icon-wrap"></div>
    <div class="text-wrap" ref="textWrapDom">
      <div class="text-content" ref="textContentDom">
        {{text}}
      </div>
    </div>
    <!-- 空的dom，不展示，仅用于计算文字的宽度 -->
    <div class="virtual-dom" ref="virtualDom">
      {{text}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'notice',
  props: {
    layout: {},
    data: {},
  },
  computed: {
    // 跑马灯效果的文字
    text() {
      return ' 声明: 本平台只用于计数器使用，禁止用于违法操作。产生任何违法活动.交易纠纷!跟平台无关，谢谢合作！'
    }
  },
  data() {
    return {
      timer: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.move()
    }, 1000)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer = null
  },
  methods: {
    move() {
      // 可视区域的宽度
      let curWidth = this.$refs.textWrapDom.offsetWidth
      // 所有文字的宽度
      let allWidth = this.$refs.virtualDom.offsetWidth

      // 位移距离
      let distance = 0
      if (allWidth > curWidth) {
        this.timer = setInterval(() => {
          distance = distance - 1
          // 如果位移超过文字宽度，则回到起点
          if (-distance >= (allWidth - 150)) {
            distance = 40
          }
          if (this.$refs.textContentDom) {
            this.$refs.textContentDom.style.transform = 'translateX(' + distance + 'px)'
          }
        }, 20)
      }
    },
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wrap {
  margin: 5px 14px;
  height: 34px;
  display: flex;
}

.icon-wrap {
  display: flex;
  height: 34px;
  align-items: center;
  margin-right: 10px;
  flex: 0 0 20px;
  font-size: 20px;
}

.text-wrap {
  flex: 1;
  overflow: hidden;
}

.text-content {
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 14px;
  font-weight: 300;
  white-space: nowrap;
  color: white;
}

.more {
  position: relative;
  padding-left: 14px;
  padding-right: 8px;
  display: flex;
  align-items: center;
  height: 34px;
  font-size: 12px;
  font-weight: 400;
  color: #4FA3FF;
  box-shadow: -7px 0px 7px -7px rgba(59, 61, 68, 0.1);
}

.virtual-dom {
  position: absolute;
  visibility: hidden;
  white-space: nowrap;
  z-index: -100;
}
</style>