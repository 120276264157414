import {_get,_post} from '../http/index';
//首页展示栏目信息
export const getManageCatalogList = (success,error) => _get("/market/manager/catalog/manage/getManageCatalogList", {},success,error) ;
//查询栏目管理栏目信息
export const findManageCatalogList = (success,error) => _get("/market/manager/catalog/manage/findManageCatalogList", {},success,error) ;
//设置主页
export const updateManageHomePage = (param,success,error) => _post("/market/manager/catalog/manage/updateManageHomePage", param,success,error) ;
//栏目开关
export const  updateManageCatalogState = (param,success,error) => _post("/market/manager/catalog/manage/updateManageCatalogState", param,success,error) ;
//跳转方式
export const updateManageTarget = (param,success,error) => _post("/market/manager/catalog/manage/updateManageTarget", param,success,error) ;
//批量保存
export const saveManageCatalog = (param,success,error) => _post("/market/manager/catalog/manage/saveManageCatalog", param,success,error) ;
//保存权限码
export const updateCatalogPermissionCode = (param,success,error) => _post("/market/manager/catalog/manage/updateManagePermissionCode", param,success,error) ;
//查询商家栏目
export const findShopManageCatalogList = (success,error) => _get("/market/manager/catalog/shop/findShopCatalogList", {},success,error) ;
//保存商家栏目权限码
export const updateShopCatalogPermissionCode = (param,success,error) => _post("/market/manager/catalog/shop/updateShopPermissionCode", param,success,error) ;
//商家栏目开关
export const updateShopCatalogState = (param,success,error) => _post("/market/manager/catalog/shop/updateShopCatalogState", param,success,error) ;
//商家栏目跳转方式
export const updateShopTarget = (param,success,error) => _post("/market/manager/catalog/shop/updateShopTarget", param,success,error) ;
//设置商家栏目主页
export const updateShopHomePage = (param,success,error) => _post("/market/manager/catalog/shop/updateShopHomePage", param,success,error) ;
//批量保存商家栏目信息
export const saveShopCatalog = (param,success,error) => _post("/market/manager/catalog/shop/saveShopCatalog", param,success,error) ;
//设置商家标识
export const updateCatalogShopSign = (param,success,error) => _post("/market/manager/catalog/shop/updateCatalogShopSign", param,success,error) ;
//设置供应商标识
export const updateCatalogSupplierSign = (param,success,error) => _post("/market/manager/catalog/shop/updateCatalogSupplierSign", param,success,error) ;
//查询商家栏目
export const getShopCatalogList = (success,error) => _get("/market/manager/catalog/shop/getShopCatalogList", {},success,error) ;
