
let memoryCache=new Map();

const cache = {
    addCache(key, value) {
        sessionStorage.setItem(key, value);
    },
    removeCache(key){
        sessionStorage.removeItem(key);
    },
    // 退出
    getCache(key) {
        return sessionStorage.getItem(key);
    },
    clear(){
        sessionStorage.clear()
    },
    addMemoryCache(key, value){
        memoryCache.set(key,value);
    },
    removeMemoryCache(key){
        memoryCache.set(key,null);
    },
    getMemoryCache(key){
        return memoryCache.get(key);
    },
    clearMemoryCache(){
        memoryCache=new Map();
    }
}

export {
    cache
}
