import { Message } from "element-ui";
const check = {
    checkPhone: function(phone){
        let status = true;
        if (phone == '') {
            Message.error({message:'请输入手机号',duration:1500});
            return false;
        }
        let param = /^1(3[0-9]|5[0-3,5-9]|7[1-3,5-8]|8[0-9])\d{8}$/;
        if (!param.test(phone)) {
            Message.error({message:'手机号不合法，请重新输入',duration:1500});
            return false;
        }
        return status;
    }
}
export {
    check
}