<template>
  <el-row>
    <el-form :inline="true" :model="queryStockParam" >
      <el-form-item label="商品名称" label-width="80px">
        <el-input v-model="queryStockParam.goodsName" placeholder="商品名称"></el-input>
      </el-form-item>
      <el-form-item v-if="useType == '0'" label="品牌名称" label-width="80px">
        <el-select v-model="queryStockParam.brandId" clearable placeholder="请选择">
          <el-option
              v-for="item in brandList"
              :key="item.brandId"
              :label="item.brandName"
              :value="item.brandId">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="类目名称" label-width="80px">
        <select-tree  ref="selectCategoryTree" placeholder="请选择类目"
                      :tree-data="goodsCategoryTree" :expandAll="true" :nodeConfig="{value:'categoryId',label:'categoryName'}"
                      @changeChecked="goodsCategoryTreeChecked"></select-tree>
      </el-form-item>
      <el-form-item  >
        <el-button icon="el-icon-search" type="primary" @click="queryStockPageList">查询</el-button>
        <el-button @click="queryReset">重置</el-button>
      </el-form-item>
    </el-form>
    <el-row>
      <el-button  v-if="publishStatus == '1' && useType == '5'"  type="primary"  @click="removeGoodsStock(stockSelectList)">移 除</el-button>
      <el-button  v-if="publishStatus == '1' && useType == '4'"  type="primary"  @click="addGoodsStock(stockSelectList)">引 入</el-button>
      <el-button  v-if="publishStatus == '0' && useType == '0'"  type="primary"  @click="publishGoodsStock()">上 架</el-button>
      <el-button  v-if="publishStatus == '1' && useType == '0'"  type="primary"  @click="outOfGoodsStock()">下 架</el-button>
    </el-row>
    <el-row class="stock-table">
      <el-table :data="stockList" border
                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                 @selection-change="stockSelectChange"
               >
        <el-table-column
            type="selection"
            width="55"
            :selectable="goodsCanSelect"
            >
        </el-table-column>
        <el-table-column prop="goodsPhotoPath" label="商品图片" width="120px">
          <template slot-scope="props">
            <el-image class="goods-main-image" fit="scale-down"  :src="props.row.goodsPhotoPath"></el-image>
          </template>
        </el-table-column>
        <el-table-column prop="categoryId" label="分类" :formatter="formatterCategoryId"></el-table-column>
        <el-table-column prop="brandId" v-if="useType == '0'" label="品牌" :formatter="formatterBrandId"></el-table-column>
        <el-table-column prop="goodsName" label="产品名称"></el-table-column>
        <el-table-column prop="skuName" label="商品sku名称"></el-table-column>
        <el-table-column prop="skuBarCode" v-if="useType == '0'" label="sku条形码"></el-table-column>
        <el-table-column prop="shopPrice" label="商品价格"></el-table-column>
        <el-table-column prop="memberPrice" label="vip价格"></el-table-column>
        <el-table-column prop="packagingFee" label="包装费"></el-table-column>
        <el-table-column prop="salesStock" label="库存"></el-table-column>
        <el-table-column prop="soldStock"  label="销量"></el-table-column>
        <el-table-column prop="操作" label="操作" width="160px" v-if="useType == '0'">
          <template slot-scope="props">
            <el-button plain type="primary" size="mini"   @click="stockUpdate(props.row)">调 整</el-button>
            <el-button plain v-if="publishStatus == '0'"  type="danger" size="mini"  @click="stockDelete(props.row)">删 除</el-button>
            <el-button plain v-if="publishStatus != '0'"  size="mini"  @click="stockDetail(props.row)">明 细</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="stock-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <el-row>
      <el-dialog
          :title="'库存价格调整'"
          width="36%"
          :close-on-click-modal="false"
          :visible.sync="stockUpdateDialog"
      >
        <goods-stock-info :stock-info="currentStockInfo"></goods-stock-info>
        <span slot="footer" class="dialog-footer">
            <el-button @click="cancelStockUpdate">取 消</el-button>
             <el-button type="primary" @click="saveStockInfo()">确 定</el-button>
        </span>
      </el-dialog>
    </el-row>
    <el-row>
      <el-dialog
          :title="'库存价格调整'"
          width="70%"
          :close-on-click-modal="false"
          :visible.sync="stockDetailDialog"
      >
        <goods-stock-detail-list-page v-if="stockDetailDialog == true" :goods-stock-id="currentStockInfo.goodsStockId"></goods-stock-detail-list-page>
      </el-dialog>
    </el-row>
  </el-row>
</template>

<script>
import SelectTree from "../../components/select-tree";
import {queryGoodsStockPage,deleteStockProduct,publishGoodsStock,outOfGoodsStock,updateStockByCircle} from "@/api/stock";
import goodsStockInfo from "./goodsStockInfo";
import goodsStockDetailListPage from "./goodsStockDetailListPage";
export default {
  name: "goodsStockPage",
  props:{
    queryParam:{
      type:Number,
      default: ()=>{return {}}
    },
    useType:{
      type:String,
      default:'0'
    },
    publishStatus:{
      type:String,
      default:'0'
    },
    goodsCategoryTree:{
      type:Array,
      default: ()=>{return []}
    },
    brandList:{
      type:Array,
      default: ()=>{return []}
    },
    stockStatistics:{
      type:Object,
      default: ()=>{return {}}
    },
    addGoodsStock:Function,
    removeGoodsStock:Function
  },
  data(){
    return {
      queryStockParam:{},
      stockList:[],
      stockSelectList:[],
      currentStockInfo:{},
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      stockUpdateDialog:false,
      stockDetailDialog:false
    }
  },
  mounted() {
    this.queryStockPageList();
  },
  methods:{
    clearSelect:function (){
      this.stockSelectList=[];
    },
    saveStockInfo:function (){
      if(this.currentStockInfo.shopPrice<=0){
         this.$errorMessage('商品价格不能小于0');
        return;
      }
      updateStockByCircle(this.currentStockInfo,success=>{
        this.$successMessage('调整成功')
        this.stockUpdateDialog=false;
        this.queryStockPageList();
      })
    },
    cancelStockUpdate:function (){
      this.stockUpdateDialog=false;
    },
    stockSelectChange:function (val){
      this.stockSelectList=val;
      if(this.useType != 0){
        this.$emit("stockSelectChange",val);
      }
    },
    publishGoodsStock:function (){
      if(this.stockSelectList.length == 0){
        this.$errorMessage("没有发布的数据");
        return
      }
      this.$confirm('是否上架选择产品', '上架提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
        publishGoodsStock(this.stockSelectList,success=>{
          this.$successMessage('上架成功');
          this.$emit("publishGoodsStock");
          this.queryStockPageList();
        })
          })
          .catch(() => {

          })
    },
    outOfGoodsStock:function (){
      if(this.stockSelectList.length == 0){
        this.$errorMessage("没有下架的数据");
        return
      }
      this.$confirm('是否下架选择产品', '下架提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
      outOfGoodsStock(this.stockSelectList,success=>{
        this.$successMessage('下架成功');
        this.queryStockPageList();
        this.$emit("outOfGoodsStock");
      })
          })
          .catch(() => {

          })
    },
    stockDelete:function (row){
      this.$confirm('是否删除 <span style="color: red">'+row.goodsName+'</span> 该产品', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            let deleteList = [];
            deleteList.push(row)
            deleteStockProduct(deleteList,success=>{
              this.removeData(row);
              this.$message({type:"success",message:"刪除成功",duration:1500});
              this.$emit("publishGoodsStock");
            })
          })
          .catch(() => {

          })
    },
    removeData:function (data){
      this.stockList.forEach((item,index)=>{
        if(item.goodsStockId == data.goodsStockId){
          this.stockList.splice(index,1);
        }
      })
    },
    stockDetail:function (row){
      this.stockDetailDialog=true;
      this.currentStockInfo=row;
    },
    stockUpdate:function (row){
      this.stockUpdateDialog=true;
      this.currentStockInfo=row;
    },
    goodsCanSelect:function (row){
       if(this.publishStatus != '0'){
         return true;
       }
       if(row.shopPrice && row.shopPrice>0){
         return true;
       }
       return false;
    },
    formatterBrandId:function (row, column, cellValue){
      if(this.brandList.length>0){
        for(let index=0;index<this.brandList.length;index++){
          let brandItem = this.brandList[index];
          if(brandItem.brandId == cellValue){
            return  brandItem.brandName;
          }
        }
      }
       return '';
    },
    formatterCategoryId:function (row, column, cellValue){
      return this.getCategoryNameById(this.goodsCategoryTree,cellValue);
    },
    getCategoryNameById:function (goodsCategoryList,cellValue){
      if(goodsCategoryList.length>0){
        for(let index=0;index<goodsCategoryList.length;index++){
          let item = goodsCategoryList[index];
          if(item.categoryId == cellValue){
            return  item.categoryName;
          }
          if(item.children){
            let categoryName = this.getCategoryNameById(item.children,cellValue);
            if(categoryName){
              return categoryName;
            }
          }
        }
      }
      return '';
    },
    queryStockPageList:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      if(this.useType == '1'){
        this.queryStockParam.firstOrderConfigType = '0';
      }
      if(this.useType == '2'){
        this.queryStockParam.firstOrderConfigType = '1';
      }
      if(this.useType == '3'){
        this.queryStockParam.specialType = '0';
      }
      if(this.useType == '4'){
        this.queryStockParam.useType = '0';
        this.queryStockParam.queryParam = this.queryParam;
      }
      if(this.useType == '5'){
        this.queryStockParam.useType = '1';
        this.queryStockParam.queryParam = this.queryParam;
      }
      this.queryStockParam.publishStatus = this.publishStatus;
      queryGoodsStockPage(this.page,this.queryStockParam,success=>{
        this.stockList=success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function (){
      this.queryStockParam={};
      this.queryStockPageList();
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryStockPageList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryStockPageList(true);
    },
    goodsCategoryTreeChecked:function (checkList){
      if(checkList.length==0){
        this.queryStockParam.categoryIds=null;
        return;
      }
      this.queryStockParam.categoryIds=this.getIdStrings(checkList);
    },
    getIdStrings:function(checkList){
      let Ids = '';
      if(checkList && checkList.length>0){
        checkList.forEach(item=>{
          Ids=Ids+item+',';
        });
      }
      return Ids;
    },
  },
  components:{
    SelectTree,
    goodsStockInfo,
    goodsStockDetailListPage
  }
}
</script>

<style scoped>
.stock-table,stock-table-page{
  padding-top: 8px;
}
.goods-main-image{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>