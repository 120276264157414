<template>
    <el-row>
        <el-row class="process-query">
            <el-form :inline="true" :model="processQuery" >
                <el-form-item label="流程名" label-width="80px">
                    <el-input v-model="processQuery.name" placeholder="流程名"></el-input>
                </el-form-item>
                <el-form-item label="流程定义" label-width="80px">
                    <el-input v-model="processQuery.modelKey" placeholder="流程定义"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="processListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="process-info-upload">
            <el-upload
                    class="process-upload"
                    :action="processUploadUrl"
                    :multiple="false"
                    :limit="1"
                    :show-file-list="false"
                    :on-success="uploadSuccess"
                    :before-upload="fileTypeCheck">
                <el-button size="small"  icon="el-icon-upload" type="primary">流程部署</el-button>
            </el-upload>
        </el-row>
        <el-row class="process-body">
            <el-table
                    border
                    :data="processList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="name" label="流程名称"></el-table-column>
                <el-table-column prop="modelKey" label="流程定义" width="260px"></el-table-column>
                <el-table-column prop="version" label="流程最新版本"></el-table-column>
                <el-table-column prop="category" label="流程分类"></el-table-column>
                <el-table-column prop="processDefinitionName" label="文件名"></el-table-column>
                <el-table-column  label="操作" width="220px">
                    <template slot-scope="props">
                        <el-button plain type="primary"  size="mini" @click="viewProcess(props.row)">预览流程图</el-button>
                        <el-button plain  size="mini" type="info" @click="downloadProcess(props.row)">下载流程图</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="process-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <el-row>
            <el-dialog
                v-if="viewProcessDialog == true"
                :title="viewProcessName"
                width="50%"
                :visible.sync="viewProcessDialog"
            >
                <el-image :src="viewProcessImageUrl"></el-image>
            </el-dialog>
        </el-row>
    </el-row>
</template>

<script>
    import {findProcessDefinitionPageList} from '@/api/process';
    import {baseUrl} from "@/config/env";
    export default {
        name: "process",
        data(){
            return{
                processUploadUrl:baseUrl+'/market/manager/flow/importProcessModel',
                processQuery:{},
                processList:[],
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0,
                },
                viewProcessImageUrl:'',
                viewProcessDialog:false,
                viewProcessName:'',
            }
        },
        mounted(){
            this.processListPageQuery();
        },
        methods:{
            viewProcess:function(data){
                let imageUrl = baseUrl
                    +"/market/manager/flow/showProcessDefinitionImage?deploymentId="+data.deploymentId
                    +'&dgrmResourceName='+data.dgrmResourceName;
                this.viewProcessImageUrl = imageUrl;
                this.viewProcessName = data.name;
                this.viewProcessDialog=true;
            },
            downloadProcess:function(data){
                let downloadUrl = baseUrl
                    +"/market/manager/flow/downloadProcessDefinition?id="+data.id;
                window.open(downloadUrl,"blank");
            },
            processListPageQuery:function(notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findProcessDefinitionPageList(this.page,this.processQuery,success=>{
                    this.processList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            queryReset:function () {
                this.processQuery={};
                 this.page={
                    pageSize:10,
                    currentPage:1
                };
                this.processListPageQuery();
            },
            handleSizeChange:function (val) {
                this.page.pageSize = val;
                this.processListPageQuery();
            },
            uploadSuccess:function(){
                this.$message({type:"success",message:"流程图部署成功",duration:1500})
                this.queryReset();
            },
            handleCurrentChange:function (val) {
                this.page.currentPage = val;
                this.processListPageQuery(true);
            },
            fileTypeCheck:function (file) {
                let fileName =  file.name;
                if(fileName.endsWith('.bpmn')){
                    return true;
                }
                this.$message({type:"error",message:'流程图格式错误',duration:1500});
                return  false;
            }
        }
    }
</script>

<style scoped>
    .process-body{
        margin-top: 8px;
    }
    .process-table-page{
        margin-top: 8px;
    }
</style>