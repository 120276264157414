<template>
    <el-row>
        <el-row class="baidu-map" id="map-container"></el-row>
    </el-row>
</template>

<script>
    import loadBMap from "@/util/bMap";
    export default {
        name: "baidu-map",
        props:['lan','lat','myValue'],
        data(){
            return{
                form: {
                    address: '', //详细地址
                    addrPoint: { //详细地址经纬度
                        lng: 0,
                        lat: 0
                    }
                },
                map:'',
                mk: '',
                local:{}
            }
        },
        async mounted(){
            await loadBMap();
            this.initMap();
        },
        methods:{
            initMap:function() {
                let BMap = window.BMap;
                if(!this.map){
                    this.map = new BMap.Map("map-container")  //新建地图实例，enableMapClick:false ：禁用地图默认点击弹框
                }
                if(this.lan){
                    let point = new BMap.Point(this.lan,this.lat);
                    this.map.centerAndZoom(point,8);
                    let marker = new BMap.Marker(point);  // 创建标注
                    this.map.addOverlay(marker);               // 将标注添加到地图中
                    marker.setAnimation(window.BMAP_ANIMATION_BOUNCE); //跳动的动画
                }else if(this.myValue){
                    this.map.clearOverlays();
                    let that = this;
                    this.local = new BMap.LocalSearch(this.map, { //智能搜索
                        onSearchComplete: that.searchResult
                    });
                    this.local.search(this.myValue);
                    return;
                }else{
                    this.map.centerAndZoom("北京",12);
                }
                this.map.enableScrollWheelZoom(true);
                this.map.disableDragging();     //禁止拖拽
                let that = this;
                setTimeout(function(){
                    that.map.enableDragging();   //两秒后开启拖拽
                    //map.enableInertialDragging();   //两秒后开启惯性拖拽
                }, 2000);
            },
            searchResult:function () {
                //this.map.clearOverlays();
                //let BMap = window.BMap;
                //let point = this.local.getResults().getPoi(0).point;    //获取第一个智能搜索的结果
                this.$emit('searchResult',this.local.getResults().Cr);
               // this.map.centerAndZoom(point, 8);
               // let marker = new BMap.Marker(point);  // 创建标注
               // this.map.addOverlay(marker);
                //marker.setAnimation(window.BMAP_ANIMATION_BOUNCE); //跳动的动画
                //this.$emit('searchResult',point);
            }
        }
    }
</script>

<style scoped>
    .baidu-map {
        width: 80%;
        height: 200px;
    }
</style>