<template>
    <el-row>
        <el-row class="role-query">
            <el-form :inline="true" :model="userRoleParam" >
                <el-form-item>
                    <el-input v-model="userRoleParam.roleName" placeholder="角色名"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="userRoleQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="role-query">
            <el-button type="success" icon="el-icon-plus" size="mini" @click="addUserRole">添加角色</el-button>
            <el-button  type="primary" icon="el-icon-receiving"  size="mini" @click="submitUserRole">保存</el-button>
        </el-row>
        <el-row class="role-table">

            <el-table
              :data="userRoleList"
              border
              :header-cell-style="{background:'#F3F4F7',color:'#555'}"
              :row-class-name="roleDeleteTag"
              @cell-click="roleEdit"
            >
                <el-table-column prop="roleName" label="角色名">
                    <template slot-scope="props">
                        <el-select v-if="props.row.addId<0 && props.row.roleNameEdit" v-focus @change="roleEditComplete(props)" v-model="props.row.roleId" placeholder="请选择">
                            <el-option
                                    v-for="item in unboundRoleList"
                                    :key="item.roleCode"
                                    :label="item.roleName"
                                    :value="item.roleId"
                                    :disabled="canSelectRole(item)">
                            </el-option>
                        </el-select>
                        <span v-else>{{initRoleName(props.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userRoleBeginDate" :formatter="this.$formatTableCellDate" width="200px" label="生效时间">
                    <template slot-scope="props">
                        <el-date-picker
                                v-focus
                                v-if="props.row.roleName != 'default' && props.row.userRoleBeginDateEdit"
                                v-model="props.row.userRoleBeginDate"
                                @blur="roleEditComplete(props)"
                                type="datetime"
                                placeholder="生效时间">
                        </el-date-picker>
                        <span v-else>{{$formatterDateToStr(props.row.userRoleBeginDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="userRoleEndDate" :formatter="this.$formatTableCellDate" width="200px" label="失效时间">
                    <template slot-scope="props">
                        <el-date-picker
                                v-focus
                                v-if="props.row.roleName != 'default' && props.row.userRoleEndDateEdit"
                                v-model="props.row.userRoleEndDate"
                                @blur="roleEditComplete(props)"
                                type="datetime"
                                placeholder="失效时间">
                        </el-date-picker>
                        <span v-else>{{$formatterDateToStr(props.row.userRoleEndDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="dataRangeVo.dataRangeValue" label="数据维度">
                    <template slot-scope="props">
                        <el-select  v-if="props.row.roleName != 'default' && props.row.dataRangeValueEdit" v-focus @change="roleEditComplete(props)"
                                    v-model="props.row.dataRangeId" placeholder="请选择">
                            <el-option
                                    v-for="item in dataRangeList"
                                    :key="item.dataRangeId"
                                    :label="item.dataRangeValue"
                                    :value="item.dataRangeId">
                            </el-option>
                            <el-option label="清空选项" value=""><span style="color: #8492a6;">清空选项</span></el-option>
                        </el-select>
                        <span v-else>{{initDataRange(props.row)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="110px">
                    <template slot-scope="props">
                        <el-button plain v-if="props.row.roleName != 'default' && props.row.isDeleted != true" type="danger" size="mini" @click="userRoleDelete(props.row)">删除</el-button>
                        <el-button v-if="props.row.isDeleted == true" type="warning" size="mini" @click="cancelRoleDelete(props.row)">取消删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
    </el-row>
</template>

<script>
    import {queryUserRoles,queryUnboundRoleList,updateUserRoles} from '@/api/user';
    import {queryDataRangeList} from '@/api/dataRange';
    import {queryShopUserRoles,queryRoleOptions,batchSaveShopUserRole} from '@/api/shopUser';
    export default {
        name: "userRoleList",
        props:{
          userId:{
            type:Number,
            default:0
          },
          userType:{
            type:String,
            default: 'system'
          }
        },
        directives: {
            focus: {
                inserted: function(el) {
                    el.querySelector("input").focus();
                }
            }
        },
        data(){
            return{
                userRoleParam:{
                    roleName:''
                },
                addId:0,
                userRoleList:[],
                addRoleList:[],
                updateRoleList:[],
                deleteRoleList:[],
                roleEditTag:new Map(),
                unboundRoleList:[],
                dataRangeList:[]
            }
        },
        mounted(){
            this.userRoleParam.userId = this.userId;
            this.userRoleQuery();
            this.initRoleAndRange();
            this.roleEditTag.set('生效时间','userRoleBeginDateEdit');
            this.roleEditTag.set('失效时间','userRoleEndDateEdit');
            this.roleEditTag.set('数据维度','dataRangeValueEdit');
            this.roleEditTag.set('角色名','roleNameEdit');
        },
        methods:{
            initRoleAndRange(){
                let param = new Object();
                param.userId = this.userId;
                if(this.userType == 'system'){
                  param.roleType = '001';
                  queryUnboundRoleList(param,success=>{
                    this.unboundRoleList = success.obj;
                  });
                }else{
                  queryRoleOptions(param,success=>{
                    this.unboundRoleList = success.obj;
                  });
                }
                queryDataRangeList(success=>{
                    this.dataRangeList = success.obj;
                })
            },
            canSelectRole:function(role){
                let canSelect = false;
                this.userRoleList.forEach(item=>{
                    if(role.roleId == item.roleId){
                        canSelect = true;
                    }
                });
                return canSelect;
            },
            roleEdit:function(row, column, cell, event){
                if(row.isDeleted == true){
                    return;
                }
                if(this.roleEditTag.get(column.label)){
                    this.$set(row, this.roleEditTag.get(column.label), true)
                }
            },
            roleEditComplete:function({row,column}){
                if(this.roleEditTag.get(column.label)){
                    this.$set(row, this.roleEditTag.get(column.label), false);
                    if(row.userRoleId){
                        this.removeData(this.updateRoleList,row,'userRoleId')
                        this.updateRoleList.push(row);
                    }
                }
            },
            addUserRole:function(){
                this.addId--;
                let defaultAdd = new Object();
                defaultAdd.addId = this.addId;
                defaultAdd.userId = this.userId;
                defaultAdd.userRoleBeginDate = new Date();
                let date=new Date();
                date.setFullYear(date.getFullYear()+1);
                date.setDate(date.getDate()-1);
                defaultAdd.userRoleEndDate = date;
                this.userRoleList.unshift(defaultAdd);
                this.addRoleList.push(defaultAdd);
            },
            clearUpdate:function(){
                this.updateRoleList=[];
                this.addRoleList=[];
                this.deleteRoleList=[];
            },
            submitUserRole:function(){
                for(let index =0;index< this.addRoleList.length;index++){
                    let addRole = this.addRoleList[index];
                    if(!addRole.roleId){
                        this.$message({type:'error',message: '角色不能为空',duration:1500})
                        return;
                    }
                }
                if(this.updateRoleList.length>0 || this.addRoleList.length>0 || this.deleteRoleList.length>0){
                    let param = new Object();
                    param.updateUserRoleVos= this.updateRoleList;
                    param.addUserRoleVos= this.addRoleList;
                    param.deleteUserRoleVos= this.deleteRoleList;
                    if(this.userType == 'system'){
                      updateUserRoles(param,success=>{
                        this.$message({type:'success',message:'保存成功',duration:1500});
                        this.queryReset();
                      })
                    }else {
                      this.initRoleCode(param.updateUserRoleVos);
                      this.initRoleCode(param.addUserRoleVos);
                      this.initRoleCode(param.deleteUserRoleVos);
                      batchSaveShopUserRole(param,success=>{
                        this.$message({type:'success',message:'保存成功',duration:1500});
                        this.queryReset();
                      })
                    }

                }else {
                    this.$message({type:'warning',message:'没有修改无需保存',duration:1500});
                }

            },
            initRoleCode:function (roleList){
               if(roleList && roleList.length>0){
                  roleList.forEach(role=>{
                    this.unboundRoleList.forEach(item=>{
                      if(role.roleId == item.roleId){
                        role.roleCode = item.roleCode;
                      }
                    })
                  })
               }
            },
            userRoleDelete:function(role){
                let roleName = this.initRoleName(role);
                this.$confirm('是否删除选中 <span style="color: red;"> '+roleName+' </span> 角色', '角色删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString:true,
                    type: 'warning'
                })
                    .then(() => {
                        if(!role.userRoleId){
                            this.removeData(this.addRoleList,role,'addId')
                            this.removeData(this.userRoleList,role,'addId')
                            return;
                        }
                        this.$set(role,'isDeleted',true);
                        this.removeData(this.updateRoleList,role,'userRoleId')
                        this.deleteRoleList.push(role);
                    })
                    .catch(() => {})
            },
            removeData(list,role,type){
                if(type == 'addId'){
                    list.forEach((item,index)=>{
                        if(item.addId && item.addId == role.addId){
                            list.splice(index,1);
                        }
                    });
                }
                if(type == 'userRoleId'){
                    list.forEach((item,index)=>{
                        if(item.userRoleId == role.userRoleId){
                            list.splice(index,1);
                        }
                    });
                }
            },
            cancelRoleDelete:function(role){
                this.$set(role,'isDeleted',false);
                this.deleteRoleList.forEach((item,index)=>{
                    if(item.userRoleId == role.userRoleId){
                        this.deleteRoleList.splice(index,1);
                    }
                })
            },
            userRoleQuery:function () {
                this.clearUpdate();
                if(this.userType == 'system'){
                  queryUserRoles(this.userRoleParam,success=>{
                    this.userRoleList = success.obj;
                  });
                  return;
                }
               queryShopUserRoles(this.userRoleParam,success=>{
                 this.userRoleList=[];
                 if(success.obj && success.obj.length>0){
                   success.obj.forEach(item=>{
                     let userRoleInfo = item.roleVo;
                     userRoleInfo.userRoleId = item.userRoleId;
                     userRoleInfo.userRoleBeginDate = item.userRoleBeginDate;
                     userRoleInfo.userRoleEndDate= item.userRoleEndDate;
                     userRoleInfo.dataRangeVo = item.dataRangeVo;
                     userRoleInfo.roleCode = item.roleCode;
                     userRoleInfo.dataRangeId = item.dataRangeId;
                     this.userRoleList.push(userRoleInfo);
                   })
                 }
               });
            },
            queryReset:function () {
                this.userRoleParam.roleName = '';
                this.userRoleQuery();
            },
            initRoleName:function(role){
                if(role.roleName){
                    return role.roleName;
                }
                if(role.roleId){
                    let current;
                    this.unboundRoleList.forEach(item=>{
                        if(item.roleId == role.roleId){
                            current = item;
                        }
                    });
                    if(current){
                        return current.roleName;
                    }
                    return '';
                }
                return '';
            },
            initDataRange:function(role){
                if(role.dataRangeId || role.dataRange){
                    let dataRangeId = role.dataRangeId?role.dataRangeId:role.dataRange.dataRangeId;
                    let current;
                    this.dataRangeList.forEach(item=>{
                        if(item.dataRangeId == dataRangeId){
                            current = item;
                        }
                    });
                    if(current){
                        return current.dataRangeValue;
                    }
                    return '';
                }

            },
            roleDeleteTag:function(row, rowIndex){
                if(row.row.isDeleted == true){
                    return 'role-del-tag'
                }
                return ''
            }
        }
    }
</script>

<style>
    tr.el-table__row.role-del-tag{
        background: #80808085;
    }
</style>

<style scoped>
 .role-query{
     padding-left: 15px;
 }
    .role-table{
        padding-left: 15px;
        padding-top: 8px;
        height: 46vh;
        overflow: auto;
    }

</style>