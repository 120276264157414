<template>
    <el-row>
        <el-dialog
            title="组别成员列表"
            width="66%"
            :close-on-click-modal="false"
            :visible.sync="viewGroupUserDialog"
            @close="cancelGroupUser"
        >
            <el-row class="group-user-query-form">
                <el-form :inline="true" :model="queryGroupUserParam"  >
                    <el-form-item label="账号" label-width="100px">
                        <el-input v-model="queryGroupUserParam.userRealName" placeholder="账号"></el-input>
                    </el-form-item>
                    <el-form-item label="姓名" label-width="100px">
                        <el-input v-model="queryGroupUserParam.userAccount" placeholder="姓名"></el-input>
                    </el-form-item>
                    <el-form-item  >
                        <el-button icon="el-icon-search" type="primary" @click="groupUserListPageQuery">查询</el-button>
                        <el-button @click="queryReset">重置</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
            <el-tabs v-model="defaultGroupUser" type="border-card" @tab-click="handleClick">
                <el-tab-pane label="组别用户" name="groupUser">
                    <el-row class="group-user-info-add">
                    <el-button  type="primary" icon="el-icon-receiving"  size="mini" @click="submitGroupUser">保存</el-button>
                </el-row>
                    <el-row class="group-user-info-table">
                        <el-table
                                border
                                :data="groupUserList"
                                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                                :row-class-name="groupUserDeleteTag"
                                @cell-click="groupUserEdit"
                        >
                            <el-table-column prop="userAccount" label="账号"></el-table-column>
                            <el-table-column prop="userRealName" label="姓名"></el-table-column>
                            <el-table-column prop="groupUserBegin" label="生效开始时间">
                                <template slot-scope="props">
                                    <el-date-picker
                                            v-focus
                                            v-if="props.row.groupUserBeginEdit"
                                            v-model="props.row.groupUserBegin"
                                            @blur="columnEditComplete(props)"
                                            type="datetime"
                                            placeholder="生效开始时间">
                                    </el-date-picker>
                                    <span v-else>{{$formatterDateToStr(props.row.groupUserBegin)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="groupUserEnd" label="生效结束时间">
                                <template slot-scope="props">
                                    <el-date-picker
                                            v-focus
                                            v-if="props.row.groupUserEndEdit"
                                            v-model="props.row.groupUserEnd"
                                            @blur="columnEditComplete(props)"
                                            type="datetime"
                                            placeholder="生效结束时间">
                                    </el-date-picker>
                                    <span v-else>{{$formatterDateToStr(props.row.groupUserEnd)}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column  label="操作" width="120px">
                                <template slot-scope="props">
                                    <el-button v-if="props.row.isDeleted == true" plain  type="warning" size="mini" @click="cancelGroupUserDelete(props.row)">取消删除</el-button>
                                    <el-button v-if="props.row.isDeleted != true" plain  type="danger" size="mini" @click="groupUserDelete(props.row)">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="group-user-table-page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="page.currentPage"
                                :page-sizes="[10,15,30,50,100]"
                                :page-size="page.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="pageInfo.count">
                        </el-pagination>
                    </el-row>
                </el-tab-pane>
                <el-tab-pane label="待添加用户" name="addGroupUser">
                    <el-row class="group-user-info-add">
                        <el-button  type="primary" icon="el-icon-plus"  size="mini" @click="addGroupUser">添加</el-button>
                    </el-row>
                    <el-row class="group-user-info-table">
                        <el-table
                                border
                                :data="groupUserList"
                                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                                @selection-change="addGroupUserChange"
                        >
                            <el-table-column
                                    type="selection"
                                    width="55">
                            </el-table-column>
                            <el-table-column prop="userAccount" label="账号"></el-table-column>
                            <el-table-column prop="userRealName" label="姓名"></el-table-column>
                        </el-table>
                    </el-row>
                    <el-row class="group-user-table-page">
                        <el-pagination
                                @size-change="handleSizeChange"
                                @current-change="handleCurrentChange"
                                :current-page="page.currentPage"
                                :page-sizes="[10,15,30,50,100]"
                                :page-size="page.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="pageInfo.count">
                        </el-pagination>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-dialog>
    </el-row>
</template>

<script>
    import {findGroupUserPageList,findAddGroupUserPageList,addGroupUser,submitGroupUser} from '@/api/group'
    export default {
        name: "groupUser",
        props:['groupUserDialog','groupInfo'],
        directives: {
            focus: {
                inserted: function(el) {
                    el.querySelector("input").focus();
                }
            }
        },
        data(){
            return{
                viewGroupUserDialog:false,
                defaultGroupUser:'groupUser',
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                groupUserList:[],
                queryGroupUserParam:{
                    groupId:0
                },
                groupUserEditTag:new Map(),
                addGroupUserList:[],
                updateGroupUserList:[],
                deleteGroupUserList:[]
            }
        },
        mounted(){
            this.viewGroupUserDialog = this.groupUserDialog;
            this.queryGroupUserParam.groupId = this.groupInfo.groupId;
            this.groupUserListPageQuery();
            this.groupUserEditTag.set('生效结束时间','groupUserEndEdit');
            this.groupUserEditTag.set('生效开始时间','groupUserBeginEdit');
        },
        methods:{
            submitGroupUser:function(){
                let submitParam = new Object();
                this.deleteGroupUserList.forEach(groupUser=>{
                    this.removeData(this.updateGroupUserList,groupUser)
                })
                submitParam.updateGroupUserList = this.updateGroupUserList;
                submitParam.deleteGroupUserList = this.deleteGroupUserList;
                submitGroupUser(submitParam,success=>{
                    this.$successMessage("保存成功");
                    this.deleteGroupUserList.forEach(groupUser=>{
                        this.removeData(this.groupUserList,groupUser)
                    })
                })
            },
            addGroupUser:function(){
                if(this.addGroupUserList.length>0){
                    this.addGroupUserList.forEach(item=>{
                        item.groupId = this.groupInfo.groupId;
                    })
                    this.$confirm('是否添加所选用户', '添加用户', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                            addGroupUser(this.addGroupUserList,success=>{
                                this.$successMessage("添加用户成功");
                                this.queryReset();
                            })
                        })
                        .catch(() => {})
                    return;
                }
                this.$errorMessage("请选择需要添加的用户");
            },
            addGroupUserChange:function(val){
                this.addGroupUserList = val;
            },
            groupUserEdit:function(row, column, cell, event){
                if(row.isDeleted == true){
                    return;
                }
                if(this.groupUserEditTag.get(column.label)){
                    this.$set(row, this.groupUserEditTag.get(column.label), true)
                }
            },
            columnEditComplete:function({row,column}){
                if(this.groupUserEditTag.get(column.label)){
                    this.$set(row, this.groupUserEditTag.get(column.label), false);
                    if(row.groupUserId){
                        this.removeData(this.updateGroupUserList,row,'groupUserId')
                        this.updateGroupUserList.push(row);
                    }
                }
            },
            cancelGroupUserDelete:function(groupUser){
                this.$set(groupUser,'isDeleted',false);
                this.removeData(this.deleteGroupUserList,groupUser,'groupUserId');
            },
            groupUserDelete:function(groupUser){
                let userRealName = groupUser.userRealName?groupUser.userRealName:'';
                this.$confirm('是否删除用户 <span style="color: red;"> '+userRealName+' </span>', '角色删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    dangerouslyUseHTMLString:true,
                    type: 'warning'
                })
                    .then(() => {
                        this.$set(groupUser,'isDeleted',true);
                        this.deleteGroupUserList.push(groupUser);
                    })
                    .catch(() => {})
            },
            removeData(list,groupUser){
                list.forEach((item,index)=>{
                    if(item.groupUserId == groupUser.groupUserId){
                        list.splice(index,1);
                    }
                });
            },
            groupUserDeleteTag:function(row, rowIndex){
                if(row.row.isDeleted == true){
                    return 'group-user-del-tag'
                }
                return ''
            },
            groupUserListPageQuery(notResetCurrentPage){
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                if(this.defaultGroupUser=='groupUser'){
                    findGroupUserPageList(this.page,this.queryGroupUserParam,success=>{
                        this.groupUserList = success.data;
                        this.pageInfo.count = success.count;
                        this.pageInfo.totalPage =  success.totalPage;
                    });
                }
                if(this.defaultGroupUser=='addGroupUser'){
                    findAddGroupUserPageList(this.page,this.queryGroupUserParam,success=>{
                        this.groupUserList = success.data;
                        this.pageInfo.count = success.count;
                        this.pageInfo.totalPage =  success.totalPage;
                    });
                }
            },
            cancelGroupUser:function () {
                this.$emit('cancelGroupUser')
            },
            handleClick:function(){
                this.queryReset();
            },
            queryReset:function(){
                this.queryGroupUserParam={groupId:this.groupInfo.groupId},
                this.pageInfo={
                    count:0,
                    totalPage:0
                }

                this.groupUserListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.groupUserListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.groupUserListPageQuery(true);
            }
        }
    }
</script>
<style>
    tr.el-table__row.group-user-del-tag{
        background: #80808085;
    }
</style>
<style scoped>
    .group-user-info-table{
        margin-top: 8px;
        height: 42vh;
        overflow: auto;
    }
    .group-user-table-page{
        margin-top: 8px;
    }
</style>