<template>
    <el-row>
        <el-tabs v-model="toDoType" type="border-card">
            <el-tab-pane label="代办任务" name="toDo">
                <pending-list-page v-if="toDoType == 'toDo'" :todoType="toDoType"></pending-list-page>
            </el-tab-pane>
            <el-tab-pane label="已办任务" name="alreadyDo">
                <pending-list-page v-if="toDoType == 'alreadyDo'" :todoType="toDoType"></pending-list-page>
            </el-tab-pane>
            <el-tab-pane label="我的申请" name="myApply">
                <pending-list-page v-if="toDoType == 'myApply'" :todoType="toDoType"></pending-list-page>
            </el-tab-pane>
        </el-tabs>
    </el-row>
</template>

<script>
    import pendingListPage from "@/views/pending/pendingListPage";
    export default {
        name: "pending",
        data(){
            return{
                toDoType:'toDo'
            }
        },
        components:{
            pendingListPage
        }
    }
</script>

<style scoped>

</style>