<template>
  <el-row>
    <el-dialog
        :title="shopInfoDetail && shopInfoDetail.shopId?'修改商家':'添加商家'"
        width="56%"
        :close-on-click-modal="false"
        :visible.sync="shopInfoDialog"
        @close="cancelShop"
    >
      <el-row class="shop-step">
        <el-steps :active="step" finish-status="success">
          <el-step title="住基本信息"></el-step>
          <el-step title="补充信息"></el-step>
        </el-steps>
      </el-row>
      <el-row class="shop-body">
        <el-form v-if="step == 0" ref="shopForm" :rules="shopRules" :model="shopInfoDetail" label-width="100px" style="width: 80%">
          <el-form-item prop="shopName" label="店铺名称">
            <el-col :span="12">
              <el-input v-model="shopInfoDetail.shopName" placeholder="请输入店铺名称"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="shopType" label="店铺类型">
            <template>
              <el-radio v-model="shopInfoDetail.shopType" label="2" >销售门店</el-radio>
            </template>
          </el-form-item>
          <el-form-item prop="shopServiceHotLine" label="服务热线">
            <el-col :span="12">
              <el-input type="number" v-model="shopInfoDetail.shopServiceHotLine" placeholder="请输入店铺服务热线"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="openBegin" label="营业时间">
              <template>
                <el-time-select
                        placeholder="起始时间"
                        v-model="shopInfoDetail.openBegin"
                        :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59:59'
                      }">
                </el-time-select>
                至
                <el-time-select
                        placeholder="结束时间"
                        v-model="shopInfoDetail.openEnd"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '23:59:59',
                          minTime: shopInfoDetail.openBegin
                        }">
                </el-time-select>
              </template>
          </el-form-item>
          <el-form-item prop="shopProvince" label="店铺地址">
            <el-col :span="12">
              <el-input type="text" v-model="shopInfoDetail.shopProvince" style="display: none"></el-input>
              <city-selector @selectedOptions="selectedOptions" :initOptions="shopInfoAddress"></city-selector>
            </el-col>
          </el-form-item>
          <el-form-item prop="shopAddressDetail" label="明细地址">
            <el-col :span="12" style="display: flex">
              <el-select
                  @change="selectCurrentAddress"
                  v-model="shopInfoDetail.shopAddressDetail"
                  filterable
                  remote
                  reserve-keyword
                  :remote-method="searchLocation"
                  placeholder="请输入店铺明细地址">
                <el-option
                    v-for="item in searchPoints"
                    :key="item.uid"
                    :label="item.address"
                    :value="item.title">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <baidu-map :key="searchAddressIndex" v-if="searchAddressInfo==true" @searchResult="searchResult" :lan="shopInfoDetail.longitude" :lat="shopInfoDetail.latitude" :my-value="searchAddress"></baidu-map>
        </el-form>
        <el-form v-if="step == 1" ref="shopForm" :rules="shopExpRules" :model="shopInfoDetail" label-width="100px" style="width: 80%">
          <el-form-item prop="shopShopKeeperName" label="法人名字">
            <el-col :span="12">
              <el-input v-model="shopInfoDetail.shopShopkeeperName" placeholder="请输入法人名字"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="shopShopKeeperSex" label="法人性别">
            <template>
              <el-radio v-model="shopInfoDetail.shopShopkeeperSex" label="0">男</el-radio>
              <el-radio v-model="shopInfoDetail.shopShopkeeperSex" label="1" >女</el-radio>
            </template>
          </el-form-item>
          <el-form-item prop="shopShopKeeperCard" label="身份证号码">
            <el-col :span="12">
              <el-input type="number" v-model="shopInfoDetail.shopShopkeeperCard" placeholder="请输入身份证号码"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="shopShopKeeperPhone" label="法人电话">
            <el-col :span="12">
              <el-input v-model="shopInfoDetail.shopShopkeeperPhone" placeholder="请输入法人电话"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="shopCardAttachment" label="身份证附件">
            <imageUpload v-bind:file-list="shopInfoDetail.shopCardAttachmentList"  @uploadSuccess="shopCardUploadSuccess"></imageUpload>
          </el-form-item>
          <el-form-item prop="shopLicenseAttachment" label="营业执照附件" >
            <imageUpload v-bind:file-list="shopInfoDetail.shopLicenseAttachmentList" @uploadSuccess="shopLicenseUploadSuccess"></imageUpload>
          </el-form-item>
          <el-form-item label="佣金比例" prop="commissionPercent">
            <el-col :span="12">
              <el-input v-model="shopInfoDetail.commissionPercent" placeholder="请输入佣金比例"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer"  :model="shopInfoDetail">
                    <el-button v-if="step != 0" type="info" @click="perAddShop('shopForm')">上一步</el-button>
                    <el-button v-if="step != 1" type="success" @click="addShop('shopForm')">下一步</el-button>
                    <el-button v-if="step == 1" type="success" @click="submitShop('shopForm')">提 交</el-button>
                    <el-button v-if="step == 1" type="primary" @click="saveShop('shopForm')">保存草稿</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import citySelector from '@/components/city-selector';
import {addShopBaseInfo,updateShopBaseInfo,updateShopExp,shopDetail,submitShopExpInfo} from '@/api/shop';
import baiduMap from '@/components/baidu-map';
import imageUpload from '@/components/image-upload';
export default {
  name: "addShop",
  props:['shopInfo','addShopInfoDialog'],
  data(){
    return{
      step:0,
      shopInfoDetail:{
        shopType:'2',
        shopShopkeeperSex:'0',
        commissionPercent:1
      },
      shopInfoAddress:[],
      shopInfoDialog:false,
      searchAddress:'',
      searchPoints:[],
      searchAddressIndex:0,
      searchAddressInfo:true,
      shopRules:{
        shopName:[
          { required: true, message: '店铺名称不能为空', trigger: 'blur' },
        ],
        shopType:[
          { required: true, message: '店铺类型不能为空', trigger: 'blur' },
        ],
        shopServiceHotLine:[
          { required: true, message: '店铺热线不能为空', trigger: 'blur' },
        ],
        shopAddressDetail:[
          { required: true, message: '店铺地址不能为空', trigger: 'blur' },
        ],
        shopProvince:[
          { required: true, message: '地址不能为空', trigger: 'blur' },
        ],
        openBegin:[
          { required: true, message: '营业开始时间不能为空', trigger: 'blur' },
        ],
        openEnd:[
          { required: true, message: '营业结束时间不能为空', trigger: 'blur' },
        ]
      },
      shopExpRules:{
        shopKeeperType:[
          { required: true, message: '经营类型不能为空', trigger: 'blur' },
        ],shopShopkeeperName:[
          { required: true, message: '法人名称不能为空', trigger: 'blur' },
        ],shopShopkeeperSex:[
          { required: true, message: '法人性别不能为空', trigger: 'blur' },
        ],shopShopkeeperCard:[
          { required: true, message: '法人身份证不能为空', trigger: 'blur' },
        ],shopShopkeeperPhone:[
          { required: true, message: '法人电话不能为空', trigger: 'blur' },
        ],shopCardAttachment:[
          { required: true, message: '身份证附件不能为空', trigger: 'blur' },
        ],shopLicenseAttachment:[
          { required: true, message: '营业执照附件不能为空', trigger: 'blur' },
        ],commissionPercent:[
          { required: true, message: '抽佣比列不能为空', trigger: 'blur' },
        ]
      }
    }
  },
  mounted(){
    if(this.shopInfo && this.shopInfo.shopId){
      shopDetail({shopId: this.shopInfo.shopId, dataToken: this.shopInfo.dataToken, operationCode: this.shopInfo.operationCode},success=>{
        this.shopInfoDetail = success.obj;
        this.shopInfoDetail.dataToken = this.shopInfo.dataToken;
        this.shopInfoDetail.operationCode = this.shopInfo.operationCode;
        if(this.shopInfoDetail.shopProvince){
          this.shopInfoAddress.push(this.shopInfoDetail.shopProvince);
          this.shopInfoAddress.push(this.shopInfoDetail.shopCity);
          this.shopInfoAddress.push(this.shopInfoDetail.shopCounties);
        }
      })
    }
    this.shopInfoDialog = this.addShopInfoDialog;
  },
  methods:{
    selectCurrentAddress:function(address){
      this.searchPoints.forEach(item=>{
        if(item.title == address){
          this.shopInfoDetail.longitude = item.point.lng;
          this.shopInfoDetail.latitude = item.point.lat;
          this.searchAddressIndex++;
        }
      })
    },
    shopCardUploadSuccess:function(imageList){
      this.shopInfoDetail.shopCardAttachment =this.getIdStrings(imageList);
    },
    shopLicenseUploadSuccess:function(imageList){
        this.shopInfoDetail.shopLicenseAttachment
                =this.getIdStrings(imageList);
    },
    getIdStrings:function(imageList){
      let Ids = '';
      if(imageList && imageList.length>0){
          imageList.forEach(item=>{
            Ids=Ids+item.id+',';
          });
      }
      return Ids;
    },
    searchLocation:function(query){
      if(!this.shopInfoDetail.shopProvince){
        this.$errorMessage('填写地址')
        return;
      }
      if(!this.shopInfoDetail.shopAddressDetail && !query){
        this.$errorMessage('填写明细地址')
        return;
      }
      let shopAddressDetail = query?query:this.shopInfoDetail.shopAddressDetail;
      this.shopInfoDetail.longitude='';
      this.shopInfoDetail.latitude='';
      this.searchAddressIndex++;
      this.searchAddress = this.shopInfoDetail.shopProvince+this.shopInfoDetail.shopCity
          +this.shopInfoDetail.shopCounties+shopAddressDetail;
    },
    searchResult:function(points){
      this.searchPoints = points;
    },
    selectedOptions:function(options){
      this.shopInfoDetail.shopProvince = options[0];
      this.shopInfoDetail.shopCity = options[1];
      this.shopInfoDetail.shopCounties = options[2];
    },
    cancelShop:function () {
      if(this.step == 0){
        this.$emit("cancelShop");
        return;
      }
      this.$emit("updateShopSuccess");
    },
    perAddShop:function (){
      this.step = 0;
    },
    addShopBaseInfo:function(){
      if(this.shopInfoDetail.shopId){
        updateShopBaseInfo(this.shopInfoDetail,success=>{
          this.step = 1;
        })
      }else{
       addShopBaseInfo(this.shopInfoDetail,success=>{
          this.shopInfoDetail = success.obj;
          this.step = 1;
        })
      }
    },
    saveShopExpInfo:function(){
        updateShopExp(this.shopInfoDetail,success=>{
           this.$successMessage("保存成功")
          this.$emit('addShopSuccess',this.shopInfoDetail);
        })
    },
    addShop:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.step == 0){
            this.addShopBaseInfo();
          }
        } else {
          return false;
        }
      });
    },
    saveShop:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.step == 1){
            this.saveShopExpInfo();
          }
        } else {
          return false;
        }
      });
    },
    submitShop:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.step == 1){
            submitShopExpInfo(this.shopInfoDetail,success=>{
              this.$successMessage("提交成功")
              this.$emit('addShopSuccess',this.shopInfoDetail);
            })
          }
        } else {
          return false;
        }
      });
    }
  },
  components:{
    citySelector,
    baiduMap,
    imageUpload
  }
}
</script>
<style>
/* 谷歌 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}
/* 火狐 */
input{
  -moz-appearance:textfield;
}
</style>
<style scoped>
.shop-step{
  padding: 0 10%;
}
.shop-body{
  margin-top: 20px;
  display: flex;
  justify-content: center;
}
</style>