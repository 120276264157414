<template>
    <el-row>
        <el-row class="lookup-query">
            <el-form :inline="true" :model="lookupQueryParam" >
                <el-form-item label="类别名称" label-width="80px">
                    <el-input v-model="lookupQueryParam.classifyName" placeholder="类别名称"></el-input>
                </el-form-item>
                <el-form-item label="类别编码" label-width="80px">
                    <el-input v-model="lookupQueryParam.classifyCode" placeholder="类别编码"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="lookupListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="lookup-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addLookupInfo">添加</el-button>
        </el-row>
        <el-row class="lookup-body">
            <el-table
                border
                :data="lookupList"
                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="classifyName" label="类别名称"></el-table-column>
                <el-table-column prop="classifyCode" label="类别编码" width="260px"></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人"></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                <el-table-column prop="classifyRemark" label="备注"></el-table-column>
                <el-table-column  label="操作" width="220px">
                    <template slot-scope="props">
                        <el-button plain  size="mini" @click="lookupItemList(props.row)">条目</el-button>
                        <el-button plain  type="warning" size="mini" @click="lookupUpdate(props.row)">编辑</el-button>
                        <el-button plain type="danger" size="mini" @click="lookupDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="lookup-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <add-lookup v-if="addLookupInfoDialog == true"  :addLookupInfoDialog="addLookupInfoDialog" :lookupInfo="currentLookup"
                    @cancelLookup="cancelLookup" @updateLookUpClassify="updateLookUpClassifySuccess" @addLookUpClassify="addLookUpClassifySuccess"></add-lookup>
        <el-dialog v-if="lookupItemDialog == true"
                   title="lookup条目"
                   width="76%"
                   :close-on-click-modal="false"
                   :visible.sync="lookupItemDialog"
        >
            <lookup-item-list-page  :lookupInfo="currentLookup" ></lookup-item-list-page>
        </el-dialog>

    </el-row>
</template>

<script>
    import {findLookUpClassifyPageList,deleteLookUpClassify} from '@/api/lookup'
    import addLookup from "@/views/lookup/addLookup";
    import lookupItemListPage from "@/views/lookup/lookupItemListPage";
    export default {
        name: "lookupListPage",
        data(){
            return {
                lookupList:[],
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0,
                },
                lookupQueryParam:{},
                currentLookup:{},
                addLookupInfoDialog:false,
                lookupItemDialog:false,
            }
        },
        mounted(){
            this.lookupListPageQuery();
        },
        methods:{
            cancelLookup:function(){
                this.addLookupInfoDialog = false;
            },
            updateLookUpClassifySuccess:function(){
                this.addLookupInfoDialog = false;
            },
            addLookUpClassifySuccess:function(){
                this.addLookupInfoDialog = false;
                this.queryReset();
            },
            lookupListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findLookUpClassifyPageList(this.page,this.lookupQueryParam,success=>{
                    this.lookupList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                })
            },
            queryReset:function () {
                this.lookupQueryParam={};
                this.page={
                    pageSize:10,
                    currentPage:1
                };
                this.lookupListPageQuery();
            },
            addLookupInfo:function () {
                this.currentLookup={};
                this.addLookupInfoDialog=true;
            },
            handleSizeChange:function (val) {
                this.page.pageSize = val;
                this.lookupListPageQuery();
            },
            handleCurrentChange:function (val) {
                this.page.currentPage = val;
                this.lookupListPageQuery(true);
            },
            lookupItemList:function(lookup){
                this.currentLookup = lookup;
                this.lookupItemDialog=true;
            },
            lookupUpdate:function(lookup){
                this.currentLookup = lookup;
                this.addLookupInfoDialog=true;
            },
            lookupDelete:function (lookup) {
                this.$confirm('真的删除类别 <span style="color:red;">' + lookup.classifyName + '</span> 吗?其下的条目也会一并删除','删除提示',{
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    deleteLookUpClassify(lookup,success=>{
                        this.$message({type: "success",message:"删除成功",duration:1500});
                        this.lookupList.forEach((item,index)=>{
                            if(item.classifyId == lookup.classifyId){
                                this.lookupList.splice(index,1);
                            }
                        })
                    });
                }).catch(()=>{})
            }
        },
        components:{
            addLookup,
            lookupItemListPage
        }
    }
</script>

<style scoped>
.lookup-body{
    margin-top: 8px;
}
.lookup-table-page{
    margin-top: 8px;
}
</style>