import {_delete, _get, _post} from "@/http";
//查询用户金币信息
export const queryUserIcon = (param,success,error) => _get("/market/manager/wxManager/queryUserIcon/"+param.userId,{},success,error) ;
//用户添加金币
export const addIcon = (param,success,error) => _post("/market/manager/wxManager/addIcon",param,success,error) ;
//查询用户首页统计数据
export const userStatistics = (success,error) => _get("/market/manager/wxUser/getUserStatisticsCount",{},success,error) ;
//查询用户首页统计数据
export const shareStatisticsCount = (param,success,error) => _get("/market/manager/wxUser/getUserShareStatisticsCount/"+param,{},success,error) ;

//查询用户首页统计数据
export const bindWxList = (success,error) => _get("/market/manager/wxUser/bindWxList",{},success,error) ;

//查询开卡类型
export const queryWxHelperPackage = (success,error) => _get("/market/manager/wxUser/queryWxHelperPackage",{},success,error) ;

export const openWxHelperPackage = (param,success,error) => _post("/market/manager/wxUser/openWxHelperPackage",param,success,error) ;

//查询开卡类型
export const getShareSign = (success,error) => _get("/market/manager/wxUser/getShareSign",{},success,error) ;
//解除绑定
export const deleteBindWxList = (param,success,error) => _delete("/market/manager/wxUser/deleteBindWxList",param,success,error) ;
//修改备注
export const updateBindWxRemark = (param,success,error) => _post("/market/manager/wxUser/updateBindWxRemark",param,success,error) ;
//查询我的金币数量
export const queryMyIcon = (success,error) => _get("/market/manager/wxUser/queryMyIcon",{},success,error) ;
//清理当日统计
export const clearWxStatistic = (param,success,error) => _post("/market/manager/wxUser/clearWxStatistic",param,success,error) ;
//清理当日统计
export const queryUserIconPageList = (page,param,success,error) => _get("/market/manager/wxManager/queryUserIconPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//清理当日统计
export const queryMyUserIconPageList = (page,param,success,error) => _get("/market/manager/wxUser/queryMyUserIconPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
