<template>
  <el-row>
    <el-dialog
        :title="rechargeInfo && rechargeInfo.rechargeId?'修改充值券':'添加充值券'"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="rechargeDialog"
        @close="cancelAddRechargeInfo"
    >
      <el-row>
        <el-form ref="rechargeInfoForm" :rules="rechargeInfoRule" :model="rechargeInfoDetail" label-width="80px">
          <el-form-item prop="rechargeMoney" label="充值金额">
            <el-col :span="12">
              <el-input type="number" v-model="rechargeInfoDetail.rechargeMoney" placeholder="请输入充值金额"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item prop="giveMoney" label="赠送金额">
            <el-col :span="12">
              <el-input type="number" v-model="rechargeInfoDetail.giveMoney" placeholder="请输入赠送金额"></el-input>
            </el-col>
          </el-form-item>
          <el-form-item  prop="rechargeBeginDate" label="有效期">
            <el-col :span="10">
              <el-form-item prop="rechargeBeginDate">
                <el-date-picker
                    v-model="rechargeInfoDetail.rechargeBeginDate"
                    type="date"
                    placeholder="选择开始日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="1">-</el-col>
            <el-col :span="10">
              <el-form-item prop="rechargeEndDate">
                <el-date-picker
                    v-model="rechargeInfoDetail.rechargeEndDate"
                    type="date"
                    placeholder="选择结束日期">
              </el-date-picker>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item prop="rechargeNumType" label="限充次数">
            <el-radio v-model="rechargeInfoDetail.rechargeNumType" label="1" @change="clearRechargeNumLimit" >不限制充值次数</el-radio>
            <el-radio v-model="rechargeInfoDetail.rechargeNumType" label="2" >
                限制充
              <el-input-number v-model="rechargeInfoDetail.rechargeNumLimit" :min="0" :max="99" label="限充次数" :disabled="rechargeInfoDetail.rechargeNumType == 2"></el-input-number>
                次
            </el-radio>

          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddRechargeInfo">取 消</el-button>
                    <el-button type="primary" @click="addRecharge('rechargeInfoForm')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {addRechargeInfo, updateRechargeInfo} from '@/api/recharge';
export default {
  name: "addRechargeInfo",
  props:['addRechargeDialog','rechargeInfo'],
  data(){
    return{
      rechargeInfoDetail:{
        rechargeNumType:'1',
        rechargeNumLimit:1
      },
      rechargeInfoRule:{
        rechargeMoney:[
          { required: true, message: '充值金额不能为空', trigger: 'blur' },
        ],
        giveMoney:[
          { required: true, message: '赠送金额不能为空', trigger: 'blur' },
        ],
        rechargeBeginDate:[
          { required: true, message: '开始时间不能为空', trigger: 'blur' },
        ],
        rechargeEndDate:[
          { required: true, message: '结束时间不能为空', trigger: 'blur' },
        ],
        rechargeNumType:[
          { required: true, message: '有效期不能为空', trigger: 'blur' },
        ]
      },
      rechargeDialog:false
    }
  },
  mounted(){
    this.rechargeDialog = this.addRechargeDialog;
    if(this.rechargeInfo && this.rechargeInfo.rechargeId){
      this.rechargeInfoDetail = this.rechargeInfo;
    }
  },
  methods:{
    clearRechargeNumLimit:function (){
      this.rechargeInfoDetail.rechargeNumLimit='';
    },
    cancelAddRechargeInfo:function () {
      this.$emit("cancelAddRechargeInfo");
    },
    saveRecharge:function (){
      if(this.rechargeInfoDetail.rechargeId){
        updateRechargeInfo(this.rechargeInfoDetail,success=>{
          this.$message({type:"success",message:"修改充值优惠券成功",duration:1500});
          this.$emit("editRechargeSuccess");
        });
      }else{
        addRechargeInfo(this.rechargeInfoDetail,success=>{
          this.$message({type:"success",message:"创建充值优惠券成功",duration:1500});
          this.$emit("addRechargeSuccess");
        });
      }
    },
    addRecharge:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if(this.rechargeInfoDetail.rechargeMoney<=0){
               this.$errorMessage('充值金额必须大于0');
               return false;
          }
          if(new Date(this.rechargeInfoDetail.rechargeBeginDate).getTime()<=new Date().getTime()){
            this.$errorMessage('开始时间必须大于当前时间');
            return false;
          }
          if(new Date(this.rechargeInfoDetail.rechargeEndDate).getTime()<=new Date(this.rechargeInfoDetail.rechargeBeginDate).getTime()){
            this.$errorMessage('开始时间不能大于结束时间');
            return false;
          }
          if(this.rechargeInfoDetail.giveMoney>=(this.rechargeInfoDetail.rechargeMoney/2)){
            this.$confirm('赠送金额: <span style="color: red">'+this.rechargeInfoDetail.giveMoney+'元,已超过充值金额的50%</span>', '超额提示', {
              dangerouslyUseHTMLString:true,
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            })
                .then(() => {
                  this.saveRecharge();
                })
                .catch(() => {

                })
            return ;
          }
          this.saveRecharge();
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>