<template>
    <el-row>
      <el-row class="shop-query-form">
        <el-form :inline="true" :model="shopQueryParam" >
          <el-form-item label="店铺名称" label-width="100px">
            <el-input v-model="shopQueryParam.shopName" placeholder="店铺名称"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button icon="el-icon-search" type="primary" @click="shopListPageQuery">查询</el-button>
            <el-button @click="queryReset">重置</el-button>
          </el-form-item>
        </el-form>
      </el-row>
      <el-row class="shop-query-body">
        <el-col :span="4"  v-for="shopInfo in shopList" :key="shopInfo.shopId" :offset="1">
          <el-row class="shop-card-info">
            <el-card >
              <el-row @click.native="toShopOperation(shopInfo)" style="cursor: pointer" slot="header" class="clearfix">
                <span>{{shopInfo.shopName}}</span>
              </el-row>
              <el-row @click.native="toShopOperation(shopInfo)" class="shop-image-info">
                <img v-if="shopInfo.shopPhotoAttachmentList && shopInfo.shopPhotoAttachmentList.length>0"
                     :src="shopInfo.shopPhotoAttachmentList[0].downloadUrl"  class="image-info" fit="scale-down">
                <img v-else src="@/assets/logo.png" class="image-info" fit="scale-down">
              </el-row>
              <el-row class="shop-card-desc">地址:{{shopInfo.shopAddressDetail}}</el-row>
              <el-row class="shop-card-desc shop-card-activity">服务热线:{{shopInfo.shopServiceHotLine}}
                <el-upload
                    style="float: right"
                    :action="imageUploadUrl"
                    name="photo"
                    :multiple="false"
                    :limit="1"
                    :show-file-list="false"
                    :on-success="function (res, file) { return uploadSuccess(res, shopInfo)}"
                    :before-upload="imageTypeCheck">
                   <el-button type="text" class="button">上传封页</el-button>
                </el-upload>
              </el-row>
            </el-card>
          </el-row>
        </el-col>
      </el-row>
    </el-row>
</template>

<script>
import {findShopPageList,updateShopPhoto} from "@/api/shop";
import {baseUrl} from "@/config/env";
import {cache} from "@/store/store";

export default {
  name: "shopOperationList",
  data(){
    return{
      shopQueryParam:{
        shopType:'2',
        shopApproveStatus:'800'
      },
      shopList: [],
      page: {
        pageSize: 10,
        currentPage: 1
      },
      pageInfo: {
        count: 0,
        totalPage: 0
      },
      imageUploadUrl:baseUrl+'/market/manager/attachment/photoSingleUpload?saveDb=N',
    }
  },
  mounted() {
    this.shopListPageQuery();
  },
  methods:{
    toShopOperation:function (shop){
      cache.addCache("currentShopId",shop.shopId)
      this.$router.push({path:'/shopIndex'})
    },
    imageTypeCheck:function (file) {
      let fileName =  file.name;
      if(fileName.endsWith('.png')|fileName.endsWith('.jpg')){
        return true;
      }
      this.$message({type:"error",message:'图片只支持png和jpg',duration:1500});
      return  false;
    },
    uploadSuccess:function(data,shopInfo){
      shopInfo.shopPhoto =  data.obj.attachmentObjectName;
     updateShopPhoto(shopInfo,success=>{
       this.$message({type:"success",message:"图片上传成功",duration:1500});
       let attachmentList = [];
       attachmentList.push(data.obj);
       shopInfo.shopPhotoAttachmentList = attachmentList;
     });
    },
    shopListPageQuery:function (){
      findShopPageList(this.page,this.shopQueryParam,success=>{
        this.shopList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    queryReset:function (){

    }
  }
}
</script>

<style scoped>
 .shop-query-body{
   margin-top: 10px;
 }
 .shop-image-info{
   display: flex;
   justify-content: center;
   align-content: center;
   cursor: pointer;
 }
 .shop-card-info{
   margin-top: 10px;
 }
 .image-info{
   width: 100%;
   height: 100%;
 }
 .button {
   padding: 0;
   float: right;
 }
 .shop-card-desc{
   font-size: 12px;
   color: #9d9d9d;
   padding: 6px;
 }
 .shop-card-activity{
   align-items: center;
 }
</style>