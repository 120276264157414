<template>
    <el-container>
        <el-row class="dictionary-tree">
            <el-tree
                :data="dictionaryTree"
                @node-click="selectDictionary"
                node-key="dictionaryId"
                :expand-on-click-node="false"
                highlight-current
                 >
                <span class="dictionary-tree-node" slot-scope="{node, data }">
                    <span @click="expandDictionaryNode(node)">{{ data.dictionaryName }}</span>
                    <span>
                  <el-button
                          type="text"
                          size="mini"
                          icon="el-icon-document-add"
                          style="font-size: 16px"
                          v-if="data.dictionaryId"
                          @click.stop="() => addChildren(node,data)">
                  </el-button>
                  <el-button
                          v-if="!data.children || data.children.length==0"
                          type="text"
                          size="mini"
                          icon="el-icon-delete"
                          style="font-size: 16px"
                          @click.stop="() => removeNode(node, data)">
                  </el-button>
                    </span>
                </span>
            </el-tree>
        </el-row>
        <el-row class="dictionary-info">
            <el-form :model="dictionaryInfo"  ref="dictionaryInfoForm" :rules="dictionaryRules"  class="dictionary-info-form">
                <el-form-item label="字典名称" label-width="100px" prop="dictionaryName">
                    <el-input v-model="dictionaryInfo.dictionaryName"></el-input>
                </el-form-item>
                <el-form-item label="字典code" label-width="100px" prop="dictionaryCode">
                    <el-input @input.native="dictionaryCodeChange" v-model="dictionaryInfo.dictionaryCode"  :readonly="dictionaryInfo.dictionaryId>0"></el-input>
                </el-form-item>
                <el-form-item label="字典父路径" label-width="100px">
                    <el-input v-model="dictionaryInfo.dictionaryParentPath" readonly></el-input>
                </el-form-item>
                <el-form-item label="字典绝对路径" label-width="100px">
                    <el-input v-model="dictionaryInfo.dictionaryAbsolutePath" readonly></el-input>
                </el-form-item>
                <el-form-item label="字典值" label-width="100px">
                    <el-input v-model="dictionaryInfo.dictionaryValue" ></el-input>
                </el-form-item>
                <el-form-item label="备注信息" label-width="100px">
                    <el-input type="textarea" v-model="dictionaryInfo.dictionaryRemark" ></el-input>
                </el-form-item>
                <el-form-item label="字典是否启用" label-width="100px">
                    <template>
                        <el-radio v-model="dictionaryInfo.dictionaryEnable" label="1" >启用</el-radio>
                        <el-radio v-model="dictionaryInfo.dictionaryEnable" label="2" >禁用</el-radio>
                    </template>
                </el-form-item>
                <el-form-item >
                    <el-row class="dictionary-info-submit">
                        <el-button type="primary" @click="saveDictionary('dictionaryInfoForm')" round>保存</el-button>
                        <el-button type="warning" @click="resetDictionary()" round>清空</el-button>
                    </el-row>
                </el-form-item>
            </el-form>
        </el-row>
    </el-container>
</template>

<script>
    import {findDictionaryTreeList,deleteDataDictionary,saveDataDictionary} from '@/api/dictionary'

    export default {
        name: "dictionary",
        data(){
            return{
                dictionaryTree:[],
                dictionaryInfo:{dictionaryParentId:-1,dictionaryEnable:'1'},
                dictionaryRules:{
                    dictionaryName:[
                        { required: true, message: '请输入字典名称', trigger: 'blur' },
                    ],
                    dictionaryCode:[
                        { required: true, message: '请输入字典编码', trigger: 'blur' },
                    ]
                }
            }
        },
        mounted(){
            findDictionaryTreeList(success=>{
                this.dictionaryTree = success;
            })
        },
        methods:{
            resetDictionary:function(){
                this.dictionaryInfo = {dictionaryParentId:-1,dictionaryEnable:'1'};
            },
            saveDictionary:function(formName){
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        saveDataDictionary(this.dictionaryInfo,success=>{
                            if(success.obj){
                                success.obj.children=[];
                                this.removeDictionaryTreeData(this.dictionaryTree,success.obj);
                                if(success.obj.dictionaryParentId == -1){
                                    this.dictionaryTree.push(success.obj);
                                }else{
                                    this.addDictionaryChild(this.dictionaryTree,success.obj);
                                }
                            }
                            this.$message({
                                type:'success',
                                message:'保存成功',
                                duration:1500
                            });
                        })
                    } else {
                        return false;
                    }
                });
            },
            addDictionaryChild(tree,data){
                for(let index=0;index<tree.length;index++){
                    let item = tree[index];
                    if(item.dictionaryId == data.dictionaryParentId){
                         if(!item.children){
                            item.children = [];
                        }
                        item.children.unshift(data);
                    }
                    if(item.children && item.children.length>0){
                        this.addDictionaryChild(item.children,data);
                    }
                }
            },
            removeDictionaryTreeData(tree,data){
                tree.forEach((item,index)=>{
                    if(item.dictionaryId == data.dictionaryId){
                        tree.splice(index, 1);
                    }
                    if(item.children && item.children.length>0){
                        this.removeDictionaryTreeData(item.children,data)
                    }
                });
            },
            dictionaryCodeChange:function(){
                if(this.dictionaryInfo.dictionaryParentPath){
                    this.$set(this.dictionaryInfo,'dictionaryAbsolutePath',this.dictionaryInfo.dictionaryParentPath +'.'+this.dictionaryInfo.dictionaryCode);
                }else {
                    this.$set(this.dictionaryInfo,'dictionaryAbsolutePath',this.dictionaryInfo.dictionaryCode);
                }
            },
            selectDictionary:function (data) {
                this.dictionaryInfo = data;
            },
            expandDictionaryNode:function(node){
               if(node.expanded){
                   node.expanded=false;
               }else {
                   node.expanded=true;
               }
            },
            getDefaultDictionary:function(data){
                const defaultDictionary= { dictionaryParentId:-1,dictionaryEnable:'1',children: []};
                if(data){
                    defaultDictionary.dictionaryName = "新增子节点";
                }
                if(data && data.dictionaryId){
                    defaultDictionary.dictionaryParentId=data.dictionaryId;
                }
                if(data && data.dictionaryAbsolutePath){
                    defaultDictionary.dictionaryParentPath = data.dictionaryAbsolutePath;
                }
                return defaultDictionary;
            },
            addChildren:function (node,data) {
                this.dictionaryInfo =this.getDefaultDictionary(data);
            },
            removeNode:function (node,data) {
                this.$confirm('是否删除该数据?删除后不可恢复', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                .then(() => {
                    deleteDataDictionary(data,success=>{
                        this.$message({
                            type:'success',
                            message:'删除成功',
                            duration:1500
                        })
                        const parent = node.parent;
                        const children = parent.data.children && parent.data.children.length>0?
                            parent.data.children: parent.data ;
                        const index = children.findIndex(d => d.id === data.id);
                        children.splice(index, 1);
                        this.dictionaryInfo = {dictionaryParentId:-1,dictionaryEnable:'1'};
                    })
                 })
                  .catch(() => {

                  })
            }
        }
    }
</script>

<style scoped>
     .dictionary-tree{
         width: 25vw;
         height: 68vh;
         overflow: auto;
         border: 3px solid whitesmoke;
         padding: 10px;
     }

    .dictionary-info{
        display: flex;
        justify-content: center;
        width: 40vw;
        height: 68vh;
        overflow: auto;
        border: 3px solid whitesmoke;
        padding: 10px;
    }
    .dictionary-info-form{
        width: 38vw;
        height: 50vh;
    }
     .dictionary-tree-node {
         flex: 1;
         display: flex;
         align-items: center;
         justify-content: space-between;
         font-size: 14px;
         padding-right: 8px;
     }
    .dictionary-info-submit{
        text-align: center;
    }
</style>