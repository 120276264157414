<template>
  <el-row>
    <el-row>
      <el-button plain  v-if="useType == 0"  type="danger"  @click="deleteCouponGoodsList()">删 除</el-button>
      <el-button plain v-if="useType == 1"  type="primary"  @click="addCouponGoodsList()">添 加</el-button>
    </el-row>
    <el-row class="coupon-goods-table">
      <el-table :data="couponGoodsList" border
                @selection-change="couponGoodsListChange"
                row-key="goodsStockId"
                ref="couponGoodsListTab"
                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column
            type="selection"
            width="55"
            prop="goodsSelect"
        ></el-table-column>
        <el-table-column prop="goodsName" label="产品名称" width="140px" ></el-table-column>
        <el-table-column prop="skuName" label="sku名称" ></el-table-column>
        <el-table-column prop="shopPrice" label="价格" ></el-table-column>
        <el-table-column prop="memberPrice" label="vip价格" ></el-table-column>
        <el-table-column prop="salesStock" label="库存" ></el-table-column>
      </el-table>
    </el-row>
    <el-row v-if="useType == 1" class="coupon-goods-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
  </el-row>
</template>

<script>
import {delCoupon, queryGoodsList} from "@/api/coupon";
import {queryGoodsStockPage} from "@/api/stock";
export default {
  name: "couponGoodsList",
  props:{
    useType:{
      type:String,
      default:'0'
    },
    couponGoodsStr:{
      type:String,
      default:''
    }
  },
  data(){
    return{
      couponGoodsList:[],
      couponSelectGoodsList:[],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      goodsStockParam:{
        publishStatus:1
      }
    }
  },
  mounted() {
    if(this.useType == '0'){
       this.queryCouponGoodsList();
    }
    if(this.useType == '1'){
      this.queryGoodsStockListPage();
    }
  },
  methods:{
    deleteCouponGoodsList:function (){
      this.$confirm('是否删除选中商品,需确认保存后生效', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            this.couponSelectGoodsList.forEach(item=>{
              this.couponGoodsList.forEach((select,index)=>{
                if(item.goodsStockId ==select.goodsStockId ){
                  this.couponGoodsList.splice(index,1);
                }
              })
            });
            this.$emit("deleteCouponGoodsList",this.couponGoodsList);
            this.$successMessage('删除成功,保存后生效');
          })
          .catch(() => {

          })
    },
    addCouponGoodsList:function (){
      this.$emit("addCouponGoodsList",this.couponSelectGoodsList);
      this.$successMessage('添加成功,保存后生效');
    },
    couponGoodsListChange:function (val){
      this.couponSelectGoodsList=val;
    },
    queryGoodsStockListPage:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      queryGoodsStockPage(this.page,this.goodsStockParam,success=>{
         this.couponGoodsList=success.data;
         this.pageInfo.count = success.count;
         this.pageInfo.totalPage = success.totalPage;
         this.initRowSelect();
      })
    },
    initRowSelect:function (){
      if(this.couponGoodsStr){
        this.couponGoodsStr.split(",").forEach(item=>{
          this.couponGoodsList.forEach(goods=>{
             if(goods.goodsStockId == item){
               this.$nextTick(() => {
                 this.$refs.couponGoodsListTab.toggleRowSelection(goods,true);
               })
             }
          })
        })
      }

    },
    queryCouponGoodsList:function (){
      queryGoodsList({goodsStockIds:this.couponGoodsStr},success=>{
         this.couponGoodsList = success;
      });
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryGoodsStockListPage();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryGoodsStockListPage(true);
    },
  }
}
</script>

<style scoped>
.coupon-goods-table,.coupon-goods-table-page{
  padding-top: 8px;
}
</style>