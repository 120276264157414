
//查询充值券列表
import {_get, _post} from "@/http";
//查询充值卡列表
export const findRechargeCardInfoPageList = (page, param, success, error) => _get("/market/shop/rechargeCard/queryRechargeCardBatchPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//新增充值卡
export const addRechargeCardInfo = (param,success,error) => _post("/market/shop/rechargeCard/saveRechargeCard", param,success,error) ;
//修改充值卡
export const updateRechargeCardInfo = (param,success,error) => _post("/market/shop/rechargeCard/updateRechargeCard", param,success,error) ;
//查询充值卡明细
export const queryRechargeCardPage = (page, param, success, error) => _get("/market/shop/rechargeCard/queryRechargeCardPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//生效充值卡
export const handOutRechargeCard = (param,success,error) => _post("/market/shop/rechargeCard/handOutRechargeCard", param,success,error) ;
//删除充值卡
export const deleteRechargeCard = (param,success,error) => _post("/market/shop/rechargeCard/deleteRechargeCard", param,success,error) ;
//查询充值卡信息
export const queryRechargeCardById = (param,success,error) => _get("/market/shop/rechargeCard/queryRechargeCardById", param,success,error) ;
//失效充值卡
export const failureRechargeCardDetail = (param,success,error) => _post("/market/shop/rechargeCard/failureRechargeCardDetail", param,success,error) ;



