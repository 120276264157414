/*let config = {
    loginTitle:"速惠商家后台登录",
     navTitle:"速惠后台",
     logoUrl:"https://market-public-image.oss-cn-guangzhou.aliyuncs.com/logo.jpg",
};*/
let config = {
    loginTitle:"零号微信计数器",
    navTitle:"零号计数器",
    logoUrl:"https://market-public-image.oss-cn-guangzhou.aliyuncs.com/title.jpg",
};
export {
    config
}