<template>
   <el-row>
     <el-form :inline="true" :model="specialParam" >
     <el-form-item label="产品名称" label-width="100px">
       <el-input v-model="specialParam.goodsName" placeholder="产品名称"></el-input>
     </el-form-item>
     <el-form-item label="sku名称" label-width="100px">
       <el-input v-model="specialParam.skuName" placeholder="sku名称"></el-input>
     </el-form-item>
     <el-form-item>
       <el-button icon="el-icon-search" type="primary" @click="querySpecialGoodsListPage">查询</el-button>
       <el-button @click="queryReset">重置</el-button>
     </el-form-item>
     </el-form>
     <el-row>
       <el-button  type="primary"  @click="addSpecialGoods()">添 加</el-button>
       <el-button  type="danger"  @click="deleteSpecialGoods()">删 除</el-button>
     </el-row>
     <el-row class="special-table">
       <el-table :data="specialGoodsList" border
                 :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                 @selection-change="specialGoodsSelect"
       >
         <el-table-column
             type="selection"
             width="55"
         >
         </el-table-column>
         <el-table-column prop="goodsPhotoPath" label="商品图片">
           <template slot-scope="props">
             <el-image class="goods-main-image" fit="scale-down"  :src="props.row.goodsPhotoPath"></el-image>
           </template>
         </el-table-column>
         <el-table-column prop="goodsName" label="产品名称"></el-table-column>
         <el-table-column prop="skuName" label="sku名称"></el-table-column>
         <el-table-column prop="shopPrice" label="商品价格"></el-table-column>
         <el-table-column prop="memberPrice" label="vip价格"></el-table-column>
         <el-table-column prop="specialPrice" label="商品特价"></el-table-column>
         <el-table-column prop="packagingFee" label="包装费"></el-table-column>
         <el-table-column prop="salesStock" label="库存"></el-table-column>
         <el-table-column label="操作" min-width="100px">
           <template slot-scope="props">
             <el-button plain size="mini" type="warning"  @click="editSpecialGoods(props.row)">编 辑</el-button>
             <el-button plain size="mini" type="danger" @click="deleteSpecialGoodsOne(props.row)">删 除</el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-row>
     <el-row class="special-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
     <el-dialog
         :title="'添加首单产品'"
         width="60%"
         :close-on-click-modal="false"
         :visible.sync="addSpecialGoodsDialog"
     >
       <goods-stock-page v-if="addSpecialGoodsDialog" :use-type="'3'" :publish-status="'1'" :goods-category-tree="goodsCategoryTree" @stockSelectChange="stockSelectChange" ></goods-stock-page>
       <span slot="footer" class="dialog-footer">
            <el-button @click="cancelAddSpecialGoods">取 消</el-button>
            <el-button type="primary" @click="saveSpecialGoods()">确 定</el-button>
       </span>
     </el-dialog>
     <el-dialog
         :title="'编辑首单产品'"
         width="46%"
         :close-on-click-modal="false"
         :visible.sync="editSpecialGoodsDialog"
     >
       <first-order-goods-info :goods-type="'1'" :order-goods-info="currentSpecialGoods"></first-order-goods-info>
       <span slot="footer" class="dialog-footer">
            <el-button @click="cancelEditSpecialGoods">取 消</el-button>
             <el-button type="primary" @click="saveFirstOrderGoods()">确 定</el-button>
       </span>
     </el-dialog>
   </el-row>
</template>

<script>
import goodsStockPage from "@/shopViews/stock/goodsStockPage";
import firstOrderGoodsInfo from "@/shopViews/firstOrder/firstOrderGoodsInfo";
import {querySpecialGoodsSkuPage,insertSpecialGoodsSku,deleteSpecialGoodsSku,updateSpecialGoodsSku} from '@/api/special';
import {findGoodsCategoryTreeList} from "@/api/goodsCategory";
export default {
  name: "specialListPage",
  data(){
    return{
      specialParam:{},
      specialGoodsList:[],
      specialGoodsSelectList:[],
      stockSelectList:[],
      currentSpecialGoods:{},
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      editSpecialGoodsDialog:false,
      goodsCategoryTree:[],
      addSpecialGoodsDialog:false
    }
  },
  mounted() {
    this.querySpecialGoodsListPage();
    this.initGoodsCategoryTree();
  },
  methods:{
    saveFirstOrderGoods:function (){
      if(!this.currentSpecialGoods.specialPrice){
        this.$errorMessage('特价价不能为空');
        return;
      }
      if(parseFloat(this.currentSpecialGoods.specialPrice)>parseFloat(this.currentSpecialGoods.shopPrice)){
        this.currentSpecialGoods.specialPrice='';
        this.$errorMessage('特价不能大于商品价格');
        return;
      }
      updateSpecialGoodsSku(this.currentSpecialGoods,success=>{
        this.$successMessage('修改成功');
        this.editSpecialGoodsDialog=false;
        this.querySpecialGoodsListPage();
      });
    },
    cancelEditSpecialGoods:function (){
      this.editSpecialGoodsDialog=false;
    },
    editSpecialGoods:function (specialGoods){
      this.currentSpecialGoods = specialGoods;
      this.editSpecialGoodsDialog=true;
    },
    deleteSpecialGoods:function (){
      this.$confirm('是否删除选择产品', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteSpecialGoodsSku(this.specialGoodsSelectList,success=>{
              this.$successMessage('删除成功');
              this.querySpecialGoodsListPage();
            });
          })
          .catch(() => {

          })
    },
    deleteSpecialGoodsOne:function (specialGoods){
      this.$confirm('是否删除产品 <span style="color: red">'+specialGoods.goodsName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            let deleteList = [];
            deleteList.push(specialGoods);
            deleteSpecialGoodsSku(deleteList,success=>{
              this.$successMessage('删除成功');
              this.querySpecialGoodsListPage();
            });
          })
          .catch(() => {

          })
    },
    saveSpecialGoods:function (){
      insertSpecialGoodsSku(this.stockSelectList,success=>{
        this.$successMessage('添加特价商品成功');
        this.addSpecialGoodsDialog=false;
        this.querySpecialGoodsListPage();
      });
    },
    cancelAddSpecialGoods:function (){
      this.addSpecialGoodsDialog=false;
    },
    stockSelectChange:function (val){
      this.stockSelectList=val;
    },
    addSpecialGoods:function (){
      this.addSpecialGoodsDialog=true;
    },
    specialGoodsSelect:function (val){
      this.specialGoodsSelectList=val;
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.querySpecialGoodsListPage();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.querySpecialGoodsListPage(true);
    },
    querySpecialGoodsListPage:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      querySpecialGoodsSkuPage(this.page,this.specialParam,success=>{
        this.specialGoodsList =success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    queryReset:function (){
      this.specialParam={};
      this.querySpecialGoodsListPage();
    },
    initGoodsCategoryTree:function (){
      findGoodsCategoryTreeList(success=>{
        this.goodsCategoryTree = success;
      })
    },
  },
  components:{
    goodsStockPage,
    firstOrderGoodsInfo
  }
}
</script>

<style scoped>
.special-table,.special-table-page{
  padding-top: 8px;
}
.goods-main-image{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>