import {_get,_post,_put,_delete} from '../http/index';
//查询公告列表
export const findNoticeInfoPageList = (page,param,success,error) => _get("/market/shop/notice/shopNoticePageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除公告信息
export const deleteNoticeInfo = (param,success,error) => _delete("/market/shop/notice/delete", param,success,error) ;
//保存公告信息
export const addNoticeInfo = (param,success,error) => _post("/market/shop/notice/add", param,success,error) ;
//修改公告信息
export const updateNoticeInfo = (param,success,error) => _put("/market/shop/notice/update", param,success,error) ;
//修改公告状态
export const updateNoticeState = (param,success,error) => _put("/market/shop/notice/enable", param,success,error) ;
