import {_get, _post} from "@/http";

//分页查询门店优惠券列表
export const queryCouponPageList = (page, param, success, error) => _get("/market/shop/coupon/queryCouponPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//保存优惠券
export const saveCoupon = (param,success,error) => _post("/market/shop/coupon/saveCoupon", param,success,error) ;
//修改优惠券
export const updateCoupon = (param,success,error) => _post("/market/shop/coupon/updateCoupon", param,success,error) ;
//发放优惠券
export const handOutCoupon = (param,success,error) => _get("/market/shop/coupon/handOutCoupon", param,success,error) ;
//查询优惠券领取明细
export const queryCouponSendDetail = (page,param,success,error) => _get("/market/shop/memberCouponDetail/queryCouponSendDetail/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除优惠券
export const delCoupon = (param,success,error) => _post("/market/shop/coupon/delCoupon", param,success,error) ;
//查询商品列表
export const queryGoodsList = (param,success,error) => _get("/market/shop/coupon/queryGoodsList", param,success,error) ;






