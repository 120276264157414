<template>
  <el-row>
    <el-tabs v-model="couponStatus" type="border-card">
      <el-tab-pane label="全部" name="0">
        <coupon-page v-if="couponStatus == '0'" :coupon-status="couponStatus"></coupon-page>
      </el-tab-pane>
      <el-tab-pane label="已创建" name="1">
        <coupon-page v-if="couponStatus == '1'" :coupon-status="couponStatus"></coupon-page>
      </el-tab-pane>
      <el-tab-pane label="生效中" name="2">
        <coupon-page v-if="couponStatus == '2'" :coupon-status="couponStatus"></coupon-page>
      </el-tab-pane>
      <el-tab-pane label="已失效" name="5">
        <coupon-page v-if="couponStatus == '5'" :coupon-status="couponStatus"></coupon-page>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import couponPage from "./couponPage";
export default {
  name: "couponListPage",
  data(){
    return{
      couponStatus:'0'
    }
  },
  components:{
    couponPage
  }
}
</script>

<style scoped>

</style>