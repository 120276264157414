import {_get,_post} from '../http/index';
//查询应用列表
export const findAppInfoPageList = (page,param,success,error) => _get("/market/manager/appManagement/findAppInfoPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除应用信息
export const deleteAppInfo = (param,success,error) => _post("/market/manager/appManagement/deleteAppInfo", param,success,error) ;
//保存应用信息
export const addAppInfo = (param,success,error) => _post("/market/manager/appManagement/addAppInfo", param,success,error) ;
//修改应用信息
export const updateAppInfo = (param,success,error) => _post("/market/manager/appManagement/updateAppInfo", param,success,error) ;
//修改应用状态
export const updateAppState = (param,success,error) => _post("/market/manager/appManagement/updateAppState", param,success,error) ;
//查询所有应用信息
export const findAppInfoList = (success,error) => _get("/market/manager/appManagement/findAppInfoList", {},success,error) ;
