<template>
   <el-row>
     <el-dialog
         :title="type=='1'?'分配门店':'已分配门店'"
         width="50%"
         :close-on-click-modal="false"
         :visible.sync="goodsShopListDialog"
         @close="cancelGoodsShopList"
     >
     <el-row class="shop-query-form">
       <el-form :inline="true" :model="shopQueryParam" >
         <el-form-item label="店铺名称" label-width="100px">
           <el-input v-model="shopQueryParam.shopName" placeholder="店铺名称"></el-input>
         </el-form-item>
         <el-form-item>
           <el-button icon="el-icon-search" type="primary" @click="shopListPageQuery">查询</el-button>
           <el-button @click="queryReset">重置</el-button>
         </el-form-item>
       </el-form>
     </el-row>
     <el-row>
       <el-table
           border
           :data="shopList"
           :header-cell-style="{background:'#F3F4F7',color:'#555'}"
           @selection-change="goodsShopSelectionChange"
       >
         <el-table-column
             type="selection"
             width="55">
         </el-table-column>
         <el-table-column prop="shopName" label="店铺名称" width="180px"></el-table-column>
         <el-table-column prop="shopProvince" label="地址" width="246px">
           <template slot-scope="props">
             {{ props.row.shopProvince }}>{{ props.row.shopCity }}>{{ props.row.shopCounties }}
           </template>
         </el-table-column>
         <el-table-column prop="shopAddressDetail" label="明细地址" width="280px"></el-table-column>
         <el-table-column prop="shopServiceHotLine" label="服务热线"></el-table-column>
       </el-table>
     </el-row>
     <el-row class="shop-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
     <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelGoodsShopList">取 消</el-button>
                    <el-button type="primary" @click="checkGoodsShopList('userInfoForm')">确 定</el-button>
     </span>
     </el-dialog>
   </el-row>
</template>

<script>
import {findShopPageList} from '@/api/shop';
import {findGoodsAllocationShopPageList} from '@/api/stock';
export default {
  name: "goodsShopListPage",
  props:['type','shopQuery','goodsShopDialog'],
  data(){
    return {
      shopQueryParam: {
        shopName: ''
      },
      shopList: [],
      selectShopList: [],
      goodsShopListDialog:false,
      page: {
        pageSize: 10,
        currentPage: 1
      },
      pageInfo: {
        count: 0,
        totalPage: 0
      }
    }
  },
  mounted() {
    this.goodsShopListDialog = this.goodsShopDialog;
    this.shopQueryParam = this.shopQuery;
    this.shopListPageQuery();
  },
  methods:{
    queryReset: function () {
      this.shopQueryParam.shopName = null;
      this.page = {
        pageSize: 10,
        currentPage: 1
      };
      this.shopListPageQuery();
    },
    shopListPageQuery:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      if(this.type == '2'){
        findGoodsAllocationShopPageList(this.page,this.shopQueryParam,success=>{
          this.shopList = success.data;
          this.pageInfo.count = success.count;
          this.pageInfo.totalPage = success.totalPage;
        })
        return;
      }
      findShopPageList(this.page,this.shopQueryParam,success=>{
        this.shopList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      })
    },
    goodsShopSelectionChange:function (val){
      this.selectShopList=val;
    },
    cancelGoodsShopList:function (){
      this.$emit("cancelGoodsShopList");
    },
    checkGoodsShopList:function (){
      this.$emit("checkGoodsShopList",this.selectShopList);
    },
    handleSizeChange: function (val) {
      this.page.pageSize = val;
      this.shopListPageQuery();
    },
    handleCurrentChange: function (val) {
      this.page.currentPage = val;
      this.shopListPageQuery(true);
    }
  }
}
</script>

<style scoped>

</style>