import {_get,_post} from '../http/index';
//分页查询lookup列表
export const findLookUpClassifyPageList = (page,param,success,error) => _get("/market/manager/lookup/findLookUpClassifyPageList/"+page.pageSize+'/'+page.currentPage, param,success,error);
//删除lookup
export const deleteLookUpClassify = (param,success,error) => _post("/market/manager/lookup/deleteLookUpClassify", param,success,error);
//添加lookup
export const addLookUpClassify = (param,success,error) => _post("/market/manager/lookup/addLookUpClassify", param,success,error);
//修改lookup
export const updateLookUpClassify = (param,success,error) => _post("/market/manager/lookup/updateLookUpClassify", param,success,error);
//查询lookupItem列表
export const findLookUpItemPageList = (page,param,success,error) => _get("/market/manager/lookup/findLookUpItemPageList/"+page.pageSize+'/'+page.currentPage, param,success,error);
//删除lookupItem
export const deleteItem = (param,success,error) => _post("/market/manager/lookup/deleteItem", param,success,error);
//保存lookupItem
export const updateItemForm = (param,success,error) => _post("/market/manager/lookup/updateItemForm", param,success,error);
//查询lookup列表
export const findCacheLookupConfig = (success,error) => _get("/market/manager/lookup/findCacheLookupConfig", {},success,error);