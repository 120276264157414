<template>
   <el-row>
     <el-row class="member-query-form">
       <el-form :inline="true" :model="memberQueryParam" >
         <el-form-item label="会员昵称" label-width="80px">
           <el-input v-model="memberQueryParam.realName" placeholder="会员昵称"></el-input>
         </el-form-item>
         <el-form-item label="电话号码" label-width="80px">
           <el-input v-model="memberQueryParam.mobile" placeholder="电话号码"></el-input>
         </el-form-item>
         <el-form-item label="会员等级" label-width="80px">
           <el-select v-model="memberQueryParam.levelId" clearable placeholder="请选择">
             <el-option
                 v-for="item in levelList"
                 :key="item.value"
                 :label="item.label"
                 :value="item.value">
             </el-option>
           </el-select>
         </el-form-item>
         <el-form-item label="注册时间" label-width="80px">
           <el-date-picker
               v-model="memberCreateTime"
               type="daterange"
               align="right"
               unlink-panels
               range-separator="至"
               start-placeholder="开始日期"
               end-placeholder="结束日期"
               value-format="yyyy-MM-dd 00:00:00"
               :picker-options="pickerOptions">
           </el-date-picker>
         </el-form-item>
         <el-form-item  >
           <el-button icon="el-icon-search" type="primary" @click="memberListPageQuery">查询</el-button>
           <el-button @click="queryReset">重置</el-button>
         </el-form-item>
       </el-form>
     </el-row>
     <el-row class="member-info-add">
       <el-button type="primary" >发放优惠券</el-button>
       <el-button type="primary" >升级vip</el-button>
     </el-row>
     <el-row class="member-info-table">
       <el-table
           border
           :data="shopMemberList"
           :header-cell-style="{background:'#F3F4F7',color:'#555'}"
       >
         <el-table-column
             type="selection"
             width="55"
         ></el-table-column>
         <el-table-column prop="headerImg" label="头像">
           <template slot-scope="props">
             <el-image class="member-header-image" fit="scale-down"  :src="props.row.headerImg"></el-image>
           </template>
         </el-table-column>
         <el-table-column prop="realName" label="真实姓名" ></el-table-column>
         <el-table-column prop="nikeName" label="昵称" ></el-table-column>
         <el-table-column prop="gender" label="性别" :formatter="sexFormatter"></el-table-column>
         <el-table-column prop="mobile" label="电话" ></el-table-column>
         <el-table-column prop="sourceType" label="来源" :formatter="sourceFormatter"></el-table-column>
         <el-table-column prop="createTime" label="注册时间" :formatter="this.$formatTableCellDate"></el-table-column>
         <el-table-column prop="levelId" label="会员等级" :formatter="levelFormatter"></el-table-column>
       </el-table>
     </el-row>
     <el-row class="member-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
   </el-row>
</template>

<script>
import {queryShopMemberPage} from "@/api/shopMember";

export default {
  name: "memberListPage",
  data(){
    return{
      levelList:[
         {label:'超级vip',value:'2'}
        ,{label:'普通会员',value:'1'}],
      memberCreateTime:'',
      pickerOptions: {
        shortcuts: [{
          text: '今天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
            picker.$emit('pick', [start, end]);
          }
        },{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      shopMemberList:[],
      memberQueryParam:{},
      page:{
        pageSize:10,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      dayInfo:''
    }
  },
  mounted() {
    this.memberListPageQuery();
  },
  methods:{
    levelFormatter:function (row){
      if(row.levelId == '2'){
        return '超级VIP'
      }
      return '普通会员'
    },
    sourceFormatter:function (row){
      if(row.sourceType == '1'){
        return '微信'
      }
      if(row.sourceType == '2'){
        return '支付宝'
      }
      return '未知'
    },
    sexFormatter:function (row){
      if(row.gender == '1'){
        return '男'
      }
      if(row.gender == '2'){
        return '女'
      }
      return '未知'
    },
    initTime:function (){
      if(this.memberCreateTime && this.memberCreateTime.length == 2){
        this.memberQueryParam.startTime = this.memberCreateTime[0];
        this.memberQueryParam.endTime = this.memberCreateTime[1];
      }
    },
    memberListPageQuery:function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      this.initTime();
      queryShopMemberPage(this.page,this.memberQueryParam,success=>{
        this.shopMemberList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function () {
      this.memberQueryParam={};
      this.memberCreateTime='';
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.memberListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.memberListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.memberListPageQuery(true);
    },
  }
}
</script>

<style scoped>
.member-info-table{
  margin-top: 8px;
}
.member-table-page{
  margin-top: 8px;
}
.member-header-image{
  width: 100px;
  height: 100px;
}
</style>