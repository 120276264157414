
<template>
 <el-row>
   <el-table
       border
       :data="bindWxList"
       :header-cell-style="{background:'#F3F4F7',color:'#555'}"
   >
     <el-table-column prop="wxId" label="微信id"></el-table-column>
     <el-table-column prop="wxName" label="微信昵称"></el-table-column>
     <el-table-column prop="passCount" label="总申请数"></el-table-column>
     <el-table-column prop="askCount" label="总通过数"></el-table-column>
     <el-table-column prop="wxBindRemark" label="备注"></el-table-column>
     <el-table-column prop="createdDate" label="绑定时间" :formatter="this.$formatTableCellDate"></el-table-column>
     <el-table-column  label="操作" width="400">
       <template slot-scope="props">
         <el-button plain  type="primary" size="mini" @click="addWxBindRemark(props.row)">备注</el-button>
         <el-button plain  type="warning" size="mini" @click="updateBaseConfig(props.row)">基础配置</el-button>
         <el-button plain  type="danger" size="mini" @click="updateCallBackConfig(props.row)">回复配置</el-button>
         <el-button plain  type="danger" size="mini" @click="deleteBind(props.row)">解绑</el-button>
         <el-button plain  type="danger" size="mini" @click="clearToday(props.row)">清零</el-button>
       </template>
     </el-table-column>
   </el-table>
   <add-base-config v-if="viewBaseConfig" :add-base-config-dialog="viewBaseConfig"
                    :base-config="configData" @cancelAddConfigInfo="cancelAddConfigInfo"
                    @submitConfig="submitConfig"></add-base-config>
   <el-dialog
       title="修改备注"
       :visible.sync="updateRemark"
       width="30%">
     <el-form  :model="currentWxInfo" label-width="120px">
       <el-form-item prop="openPass" label="微信备注">
         <el-col :span="12">
           <el-input type="text" v-model="currentWxInfo.wxBindRemark" placeholder="微信备注"></el-input>
         </el-col>
       </el-form-item>
     </el-form>
     <span slot="footer" class="dialog-footer">
       <el-button @click="updateRemark = false">取 消</el-button>
        <el-button type="primary" @click="submitWxBindRemark">确 定</el-button>
     </span>
   </el-dialog>
 </el-row>
</template>

<script>
import {queryConfig,clearTodayWxStatistic} from "@/api/wxHelperLocal"
import {bindWxList,deleteBindWxList,updateBindWxRemark,clearWxStatistic} from "@/api/wxHelper";
import AddBaseConfig from "./AddBaseConfig.vue";
export default  {
  components: {AddBaseConfig},
   data(){
     return {
       bindWxList:[],
       viewBaseConfig:false,
       viewCallBackConfig:false,
       updateRemark:false,
       configData:{

       },
       currentWxInfo:{}
     }
   },
   mounted() {
     bindWxList(success=>{
       this.bindWxList=success.obj;
     })
   },
   methods:{
     updateCallBackConfig:function (data){
        this.$router.push({path:'/wxCallBackConfig',query:{wxId:data.wxId}});
     },
     updateBaseConfig:function (data){
       queryConfig(data.wxId,success=>{
         this.configData = success.obj;
         this.configData.wxId = data.wxId;
         this.viewBaseConfig=true;
       })
     },
     cancelAddConfigInfo:function (){
         this.viewBaseConfig=false;
     },
     submitConfig:function (){
       this.viewBaseConfig=false;
     },
     submitWxBindRemark:function (){
       updateBindWxRemark(this.currentWxInfo,success=>{
         this.$successMessage("修改成功");
         this.updateRemark=false;
         bindWxList(success=>{
           this.bindWxList=success.obj;
         })
       })
     },
     deleteBind:function (data){
       this.$confirm('是否解除绑定', '删除提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning'
       })
           .then(() => {
             deleteBindWxList(data,success=>{
               this.$successMessage("解绑成功");
               bindWxList(success=>{
                 this.bindWxList=success.obj;
               })
             })
           })
           .catch(() => {

           })
     },
     addWxBindRemark:function (data){
        this.currentWxInfo = data;
        this.updateRemark=true;
     },
     clearToday:function (data){
       let param = {};
       param.wxId = data.wxId;
       clearWxStatistic(param,success=>{
         clearTodayWxStatistic(data.wxId,success=>{
           this.$successMessage("清零成功");
         })
       })
     }
   }
}
</script>

<style scoped>

</style>