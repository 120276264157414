<template>
  <el-row>
    <el-row class="app-info-add" >
      <el-button size="medium" type="primary" icon="el-icon-plus" @click="addBackConfigInfo">添加</el-button>
    </el-row>
    <el-row class="app-info-table">
    <el-table
        border
        :data="wxConfigList"
        :header-cell-style="{background:'#F3F4F7',color:'#555'}"
    >
      <el-table-column prop="wxId" label="微信id"></el-table-column>
      <el-table-column prop="typeStr" label="回复类型"></el-table-column>
      <el-table-column prop="order" label="回复顺序"></el-table-column>
      <el-table-column prop="value" label="回复内容"></el-table-column>
      <el-table-column  label="操作">
        <template slot-scope="props">
          <el-button plain  type="warning" size="mini" @click="deleteCallBackConfig(props.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    </el-row>
    <add-back-base-config v-if="viewBackDialog" :add-back-config-dialog="viewBackDialog"
                          :back-config="currentConfigData"
                          @cancelAddBackConfigInfo="cancelAddBackConfigInfo"
                          @submitBackConfig="submitBackConfig"
    ></add-back-base-config>
  </el-row>
</template>

<script >
import {deleteControlConfig,queryControlConfig} from "@/api/wxHelperLocal"
import AddBackBaseConfig from "@/views/wxHelper/backConfig/AddBackBaseConfig.vue";
 export default {
   components: {AddBackBaseConfig},
    data(){
      return {
        wxConfigList:[],
        viewBackDialog:false,
        currentConfigData:{},
        currentWxId:""
      }
    },
   methods:{
     addBackConfigInfo:function (){
       this.currentConfigData={};
       this.currentConfigData.wxId = this.currentWxId;
       this.viewBackDialog = true;
     },
     submitBackConfig:function(){
       this.viewBackDialog = false;
       this.queryWxConfigList(this.currentConfigData.wxId);
     },
     cancelAddBackConfigInfo:function (){
       this.viewBackDialog = false;
     },
     deleteCallBackConfig:function(data){
       deleteControlConfig(data,success=>{
         this.queryWxConfigList(this.currentConfigData.wxId);
         this.$message("删除成功");
       })
     },
     queryWxConfigList:function(wxId){
       queryControlConfig(wxId,success=>{
         this.wxConfigList = success.obj;
       })
     }
   },
    mounted() {
      let wxId =  this.$route.query.wxId;
      if(wxId){
         this.currentWxId = wxId;
         this.currentConfigData.wxId = wxId;
         this.queryWxConfigList(wxId);
       }else{
        this.$router.push({path:'/wxHelperConfig'})
      }
    }
 }
</script>


<style scoped>
.app-info-table{
  margin-top: 8px;
}
</style>