<template>
  <div>
    <el-row :gutter="15">
      <el-form ref="elForm" :model="shopInfo" size="medium" label-width="100px" class>
        <el-form-item label="店铺名称:">
          <el-row>{{shopInfo.shopName}}</el-row>
        </el-form-item>
        <el-form-item label="店家地址">
          <el-row>{{shopInfo.shopProvince}}>{{shopInfo.shopCity}}>{{shopInfo.shopCounties}}</el-row>
        </el-form-item>
        <el-form-item label="明细地址" >
          <el-row>{{shopInfo.shopAddressDetail}}</el-row>
        </el-form-item>
        <el-form-item label="店铺类型" >
          <el-radio v-model="shopInfo.shopType" label="2" disabled>销售门店</el-radio>
        </el-form-item>
        <el-form-item label="服务热线" >
          <el-col :span="5">
          <el-input v-model="shopInfo.shopServiceHotLine" placeholder="服务热线" ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="是否开业" >
          <template slot-scope="props">
            <el-switch
                v-model="shopInfo.openStatus"
                inactive-value="0"
                active-value="1"
                active-color="#13ce66"
                @change="openShop($event,props)"
                inactive-color="#d2d2d2">
            </el-switch>
          </template>
        </el-form-item>
        <el-form-item label="营业时段">
          <template>
            <el-time-select
                placeholder="起始时间"
                v-model="shopInfo.openBegin"
                :picker-options="{
                        start: '00:00',
                        step: '00:30',
                        end: '23:59:59'
                      }">
            </el-time-select>
            至
            <el-time-select
                placeholder="结束时间"
                v-model="shopInfo.openEnd"
                :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '23:59:59',
                          minTime: shopInfo.openBegin
                        }">
            </el-time-select>
          </template>
        </el-form-item>
        <el-form-item  >
          <el-button  type="primary" @click="saveBasicInfo">保 存</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </div>
</template>
<script>
import {cache} from "../../store/store";
import {updateShopBaseInfoByManager,openShop} from "@/api/shop"
export default {
  name: "basicInfo",
  components: {},
  data() {
    return {
      shopInfo: cache.getMemoryCache("currentShopInfo")
    }
  },
  created() {

  },
  methods: {
    saveBasicInfo:function (){
      updateShopBaseInfoByManager(this.shopInfo,success=>{
        this.$successMessage('修改成功');
      })
    },
    openShop:function (){
      let status = this.shopInfo.openStatus=='1'?'open':'close';
      openShop(status,success=>{
        this.$successMessage('修改成功');
      })
    }
  }
}

</script>
<style scoped>
</style>


