import {_get,_post} from '../http/index';

//分页查询首单记录
export const queryFirstOrderGoodsPage = (page,param,success,error) =>  _get("/market/shop/firstOrderGoodsConfig/queryFirstOrderGoodsPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//批量删除首单产品
export const deleteFirstOrderGoods = (param,success,error)  =>  _post("/market/shop/firstOrderGoodsConfig/deleteFirstOrderGoods", param,success,error);
//编辑首单产品
export const updateFirstOrderGoods = (param,success,error)  =>  _post("/market/shop/firstOrderGoodsConfig/updateFirstOrderGoods", param,success,error);
//添加首单产品
export const addFirstOrderGoods = (param,success,error)  =>  _post("/market/shop/firstOrderGoodsConfig/addFirstOrderGoods", param,success,error);


