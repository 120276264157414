<template>
    <el-container direction="horizontal">
        <el-row>
        <el-form   ref="userInfoForm" label-width="80px">
            <el-row>
                <el-col :span="12">
                    <el-form-item label="账号">
                        <el-input v-model="userInfo.userAccount" disabled></el-input>
                    </el-form-item>
                    <el-form-item label="姓名">
                        <el-input v-model="userInfo.userRealName" :disabled="select"></el-input>
                    </el-form-item>
                    <el-form-item label="身份证号">
                        <el-input v-model="userInfo.userCardCipher" disabled></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="昵称">
                        <el-input v-model="userInfo.userName" :disabled="select"></el-input>
                    </el-form-item>
                    <el-form-item label="性别">
                        <el-radio-group v-model="userInfo.userSex">
                            <el-radio label="1" :disabled="select">男</el-radio>
                            <el-radio label="2" :disabled="select">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="手机号">
                        <el-input v-model="userInfo.userPhoneCipher" disabled></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="12">
                    <el-form-item label="用户类型">
                        <el-select v-model="userInfo.userType" disabled placeholder="请选择">
                            <el-option
                                    v-for="item in userTypes"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-form-item label="备注信息">
                <el-input  :disabled="select" type="textarea" v-model="userInfo.userRemark"></el-input>
            </el-form-item>
            <el-form-item v-if="!select">
                <el-button type="primary" @click="submitUserUpdate()">提交</el-button>
            </el-form-item>
        </el-form>
        <el-row>
                <el-table :data="userRole"
                          :row-class-name="roleExpiredRemind">
                    <el-table-column prop="roleName"
                                     label="角色"
                                     width="180"></el-table-column>
                    <el-table-column prop="userRoleBeginDate"
                                     label="生效时间"
                                     :formatter="this.$formatTableCellDate"
                                     width="180"></el-table-column>
                    <el-table-column prop="userRoleEndDate"
                                     label="失效时间"
                                     :formatter="this.$formatTableCellDate"
                                     width="180"></el-table-column>
                    <el-table-column prop="dataRangeVo.dataRangeValue"
                                     label="数据维度"
                                     width="180">

                    </el-table-column>
                </el-table>
        </el-row>
        </el-row>
    </el-container>
</template>
<style>
    tr.el-table__row.user-lose_role {
        background: #80808085;
    }
    tr.el-table__row.user-warn_role {
        background: #ffe000;
    }
    tr.el-table__row.user-danger_role{
        background: #ff0000b8;
    }
</style>
<script>
    import {getCurrentUser,findUserInfo,findUserRoleList,updateUserInfo} from '@/api/user';
    import {shopUserInfo,queryShopUserRoles} from '@/api/shopUser';
    export default {
        name: "userInfo",
        props:['userDetail','select','checkRole'],
        data(){
            return{
                userInfo:{},
                userRole:[],
                userTypes:[
                    {
                        value: '001',
                        label: '系统用户'
                    },
                    {
                        value: '002',
                        label: '商家用户'
                    }
                ]
            }
        },
        mounted(){
            if(this.userDetail && this.userDetail.userId && !this.userDetail.shopUserId){
                findUserInfo({"userId":this.userDetail.userId,"dataToken":this.userDetail.dataToken},success=>{
                    this.userInfo = success.obj;
                });
                findUserRoleList({"userId":this.userDetail.userId},success=>{
                    this.userRole = success.obj;
                });
            }else if(this.userDetail && this.userDetail.shopUserId){
                shopUserInfo({"shopUserId":this.userDetail.shopUserId},success=>{
                this.userInfo = success.obj;
                });
                queryShopUserRoles({"userId":this.userDetail.userId},success=>{
                  if(success.obj && success.obj.length>0){
                    success.obj.forEach(item=>{
                       let userRoleInfo = item.roleVo;
                       userRoleInfo.userRoleId = item.userRoleId;
                       userRoleInfo.userRoleBeginDate = item.userRoleBeginDate;
                       userRoleInfo.userRoleEndDate= item.userRoleBeginDate;
                       userRoleInfo.dataRangeVo = item.dataRangeVo;
                       this.userRole.push(userRoleInfo);
                    })
                  }
                });
            }else {
                getCurrentUser(success=>{
                    findUserInfo({"userId":success.obj.currentUser.userId},success=>{
                        this.userInfo = success.obj;
                    });
                    findUserRoleList({"userId":success.obj.currentUser.userId},success=>{
                        this.userRole = success.obj;
                    });
                })
            }
        },
        methods:{
            roleExpiredRemind:function({row, rowIndex}){
                if(row.userRoleEndDate){
                    let endDate = new Date(row.userRoleEndDate).getTime();
                    let currentDate = new Date().getTime();
                    if(endDate<currentDate){
                        return 'user-lose_role'
                    }
                    if(endDate<(currentDate+3*24*60*60*1000)){
                        return 'user-danger_role'
                    }
                    if(endDate<(currentDate+15*24*60*60*1000)){
                        return 'user-warn_role'
                    }
                }
                return '';
            },
            submitUserUpdate:function () {
                updateUserInfo(this.userInfo,success=>{
                    this.$message({
                        type: 'success',
                        message: '修改成功',
                        duration:1500
                    })
                });
            }
        }

    }
</script>