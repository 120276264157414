<template>
        <el-row>
            <el-row>
                <el-button  type="primary" icon="el-icon-folder-add" @click="catalogAdd()"  size="medium">新增父栏目</el-button>
                <el-button  type="primary" icon="el-icon-receiving"  size="medium" @click="submitCatalog()">保存</el-button>
            </el-row>
            <el-table
                    :data="catalogTree"
                    style="margin-bottom: 8px; margin-top: 10px"
                    row-key="catalogId"
                    border
                    :expand-row-keys="expandRow"
                    :row-class-name="catalogDeleteTag"
                    @cell-click="catalogEdit"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                    :tree-props="{children: 'child', hasChildren: 'hasChildren'}">
                <el-table-column
                        prop="catalogName"
                        label="栏目名称"
                        width="180">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.catalogNameEdit" v-model="props.row.catalogName"
                                  @keyup.native.enter="catalogEditComplete(props)" @blur="catalogEditComplete(props)" style="width:80%;hight:100%"></el-input>
                        <span v-else>{{props.row.catalogName}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="indexNo"
                        label="排序序号">
                    <template slot-scope="props">
                        <el-input type="number" v-focus ref="gain" size="mini" v-if="props.row.indexNoEdit" v-model="props.row.indexNo"
                                  @keyup.native.enter="catalogEditComplete(props)" @blur="catalogEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.indexNo}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogUrl"
                        label="栏目路径"
                        width="160">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.catalogUrlEdit" v-model="props.row.catalogUrl"
                                  @keyup.native.enter="catalogEditComplete(props)" @blur="catalogEditComplete(props)"></el-input>
                        <span v-else>{{props.row.catalogUrl}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="icon"
                        label="栏目图标"
                        width="180">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.iconEdit" v-model="props.row.icon"
                                  @keyup.native.enter="catalogEditComplete(props)" @blur="catalogEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.icon}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogPermissionCode"
                        label="栏目权限码"
                        width="180">
                    <template slot-scope="props">
                        <el-popover
                                ref="popover"
                                placement="bottom-start"
                                trigger="click"
                                @hide="catalogEditComplete(props)"
                        >
                            <el-link @click="showAllPermission(props.row)"  slot="reference" type="primary">{{props.row.catalogPermissionCode ==null?'设置栏目权限码':props.row.catalogPermissionCode}}</el-link>
                            <el-row v-if="currentSetRow.catalogId == props.row.catalogId">
                                <el-tree :data="permissionTree"
                                         :props="permissionProps"
                                         @node-click="selectPermission($event,props)"
                                         style="height: 280px;overflow:auto;"
                                >
                                </el-tree>
                            </el-row>
                        </el-popover>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogShopSign"
                        label="商家栏目">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.catalogShopSign"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                :disabled="props.row.operationCode == '-1'"
                                @change="catalogShopSignUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogSupplierSign"
                        label="运营栏目">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.catalogSupplierSign"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                :disabled="props.row.operationCode == '-1'"
                                @change="catalogSupplierSignUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="target"
                        label="是否跳转">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.target"
                                inactive-value="_self"
                                active-value="_blank"
                                active-color="#13ce66"
                                :disabled="props.row.operationCode == '-1'"
                                @change="catalogTargetUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogEnable"
                        label="是否启用">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.catalogEnable"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                :disabled="props.row.operationCode == '-1'"
                                @change="catalogEnableUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="isHomePage"
                        label="是否主页">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.isHomePage"
                                :disabled="props.row.operationCode == '-1' || props.row.catalogId<0"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                @change="catalogIsHomeUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="catalogRemark"
                        label="备注"
                        min-width="180">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.catalogRemarkEdit" v-model="props.row.catalogRemark"
                                  @keyup.native.enter="catalogEditComplete(props)" @blur="catalogEditComplete(props)"></el-input>
                        <span v-else>{{props.row.catalogRemark}}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        fixed="right"
                        label="操作"
                        min-width="180">
                    <template slot-scope="props" >
                        <el-button plain v-if="props.row.catalogId>0 && props.row.operationCode!= '-1'" size="small" @click="catalogAdd(props)">新增子栏目</el-button>
                        <el-button plain v-if="props.row.operationCode!= '-1'" type="danger" size="small" @click="catalogDelete(props)" >删除</el-button>
                        <el-button v-if="props.row.operationCode == '-1'" type="warning" size="small" @click="cancelCatalogDelete(props)" >取消删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
</template>
<style >
    tr.el-table__row.catalog-del-tag{
        background: #80808085;
    }
</style>
<script>
    import {findShopManageCatalogList,updateShopHomePage,updateShopCatalogState,updateShopTarget,
        saveShopCatalog,updateShopCatalogPermissionCode,updateCatalogShopSign,updateCatalogSupplierSign} from '@/api/catalog';
    import {findRolePermissionTree} from '@/api/permission'
    export default {
        name: "shop-catalog",
        directives: {
            focus: {
                inserted: function(el) {
                    el.querySelector("input").focus();
                }
            }
        },
        data(){
            return{
                catalogTree:[],
                updateCatalog:[],
                deleteCatalog:[],
                insertCatalog:[],
                currentSetRow:{},
                expandRow:[],
                virtualCatalogId:0,
                roleEditTag:new Map(),
                permissionTree:[],
                permissionProps: {
                    children: 'children',
                    label: 'title'
                }
            }
        },
        mounted(){
            findShopManageCatalogList(success=>{
                this.catalogTree = success.menuInfo;
            });
            this.roleEditTag.set('栏目名称','catalogNameEdit');
            this.roleEditTag.set('排序序号','indexNoEdit');
            this.roleEditTag.set('栏目路径','catalogUrlEdit');
            this.roleEditTag.set('栏目图标','iconEdit');
            this.roleEditTag.set('备注','catalogRemarkEdit');
        },
        methods:{
            submitCatalog:function(){
                let checkSubmit = this.checkCatalogTree(this.catalogTree);
                if(!checkSubmit){
                    this.$message({
                        type:'error',
                        message:'栏目名称为空,或者重复',
                        duration:1500
                    });
                    return;
                }
                if(this.deleteCatalog.length>0 || this.insertCatalog.length>0 || this.updateCatalog.length>0){
                    let param = new Object();
                    param.deleteCatalog = this.deleteCatalog;
                    param.insertCatalog = this.insertCatalog;
                    param.updateCatalog = this.updateCatalog;
                    saveShopCatalog(param,success=>{
                        this.catalogTree = success.obj.menuInfo;
                        this.successMessage();
                        this.deleteCatalog=[];
                        this.insertCatalog=[];
                        this.updateCatalog=[];
                    });
                }else {
                    this.$message({
                        type:'warn',
                        message:'没有修改,无需保存',
                        duration:1500
                    });
                }
            },
            showAllPermission:function(data){
                this.currentSetRow = data;
            },
            selectPermission:function(node,props){
                if(node.children && node.children.length>0){
                    return;
                }
                this.$confirm('确认设置该栏目的权限为: <span style="color: red">'+node.title+'</span> 吗?', '修改提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        props.row.catalogPermissionCode = node.permissionOperationCode;
                        document.body.click();
                        updateShopCatalogPermissionCode(props.row,success=>{
                            this.successMessage();
                        })
                    }).catch((error)=>{

                })
            },
            checkCatalogTree:function(catalogList){
                if(!catalogList){
                    return;
                }
                for(let index=0;index<catalogList.length;index++){
                    let item = catalogList[index];
                    if(item.child){
                        let checked = this.checkCatalogTree(item.child);
                        if(!checked){
                            return false;
                        }
                    }
                    if(!item.catalogName){
                        return false;
                    }
                    for(let i=0;i<catalogList.length;i++){
                        let compare = catalogList[i];
                        if(index == i){
                            continue;
                        }
                        if(item.catalogName == compare.catalogName){
                            return false;
                        }
                    }
                }
                return true;
            },
            successMessage:function(){
                this.$message({
                    type:'success',
                    message: '修改成功',
                    duration:1500
                })
            },
            catalogDeleteTag:function(row, rowIndex){
                if(row.row.operationCode == '-1'){
                    return 'catalog-del-tag'
                }
                return ''
            },
            catalogIsHomeUpdate:function ($event,props) {
                let data = props.row;
                if(data.isHomePage == '1'){
                    this.initHomePage(data,this.catalogTree);
                    this.initHomePage(data,this.updateCatalog);
                    this.initHomePage(data,this.insertCatalog);
                }
                updateShopHomePage(data,success=>{
                    this.successMessage();
                })
            },
            catalogEnableUpdate:function($event,props){
                let data = props.row;
                updateShopCatalogState(data,success=>{
                    this.successMessage();
                })
            },
            catalogShopSignUpdate:function($event,props){
                let data = props.row;
                updateCatalogShopSign(data,success=>{
                    this.successMessage();
                })
            },
            catalogSupplierSignUpdate:function($event,props){
                let data = props.row;
                updateCatalogSupplierSign(data,success=>{
                    this.successMessage();
                })
            },
            catalogTargetUpdate:function($event,props){
                let data = props.row;
                updateShopTarget(data,success=>{
                    this.successMessage();
                })
            },
            catalogAdd:function(props){
                if(props){
                    let data = props.row;
                    if(data.catalogId<0){
                        return;
                    }
                    let object = this.initNewCatalog(data);
                    this.addExpandRow(String(data.catalogId));
                    if(data.child){
                        data.child.unshift(object);
                    }else{
                        let child = [];
                        child.unshift(object);
                        data.child=child;
                    }
                    return;
                }
                let object = this.initNewCatalog();
                this.catalogTree.unshift(object);
            },
            catalogEdit:function(row, column, cell, event){
                if(row.operationCode == '-1'){
                    return;
                }
                if(this.roleEditTag.get(column.label)){
                    this.$set(row, this.roleEditTag.get(column.label), true)
                }
                if(column.label == '栏目权限码'){
                    findRolePermissionTree({},success=>{
                        this.permissionTree = success;
                    });
                }
            },
            catalogEditComplete:function({row,column}){
                if(this.roleEditTag.get(column.label)){
                    this.$set(row, this.roleEditTag.get(column.label), false)
                    this.initUpdateData(row);
                }
            },
            addExpandRow:function(rowKey){
                let isExists = false;
                this.expandRow.forEach(item=>{
                    if(item == rowKey){
                        isExists = true;
                    }
                });
                if(!isExists){
                    this.expandRow.push(rowKey);
                }
            },
            initNewCatalog:function(data){
                let object = new Object();
                this.virtualCatalogId--;
                object.catalogId = this.virtualCatalogId;
                object.target = '_self';
                object.isHomePage = '0';
                object.catalogEnable = '1';
                if(data){
                    object.catalogParentId = data.catalogId;
                }else {
                    object.catalogParentId = '-1';
                }
                return object;
            },
            initUpdateData:function(data,action){
                if(data.catalogId>0){
                    this.updateCatalog.forEach((item,i)=>{
                        if(item.catalogId == data.catalogId){
                            this.updateCatalog.splice(i, 1);
                        }
                    });
                    if(action){
                        return;
                    }
                    this.updateCatalog.push(data);
                }
                this.insertCatalog.forEach((item,i)=>{
                    if(item.catalogId == data.catalogId){
                        this.insertCatalog.splice(i, 1);
                    }
                });
                if(action){
                    return;
                }
                this.insertCatalog.push(data);
            },
            initHomePage:function (data,catalogTree) {
                catalogTree.forEach(item=>{
                    if(item.catalogId != data.catalogId){
                        item.isHomePage = '0';
                    }
                    if(item.child){
                        this.initHomePage(data,item.child);
                    }
                })
            },
            catalogDelete:function (props) {
                this.$confirm('你确定删除此栏目\'后台栏目管理\'吗？如果其存在子栏目则无法删除，请先删除所有子栏目', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        let data = props.row;
                        if(data.catalogId<0){
                            this.removeCatalog(data,this.catalogTree,'del');
                            return;
                        }
                        if(data.child){
                            this.$message({
                                type:'error',
                                message:'请先删除子栏目',
                                duration:1500
                            })
                            return;
                        }
                        this.removeCatalog(data,this.catalogTree,'delTag');
                    }).catch((error)=>{

                })
            },
            cancelCatalogDelete:function(props){
                let data = props.row;
                this.removeCatalog(data,this.catalogTree,'cancelDelTag');
                this.initUpdateData(data,'cancel');
                this.deleteCatalog.forEach((item,i)=>{
                    if(item.catalogId == data.catalogId){
                        this.deleteCatalog.splice(i, 1);
                    }
                })
            },
            removeCatalog:function(data,catalogTree,del){
                catalogTree.forEach((item,i)=>{
                    if(item.catalogId == data.catalogId){
                        if(del == 'del'){
                            catalogTree.splice(i, 1);

                        }
                        if(del=='saveDelTag' && data.operationCode == '-1'){
                            catalogTree.splice(i, 1);
                        }
                        if(del == 'delTag'){
                            item.operationCode = '-1';
                            this.deleteCatalog.push(item);
                        }
                        if(del == 'cancelDelTag'){
                            item.operationCode = '7';
                        }
                    }
                    if(item.child){
                        this.removeCatalog(data,item.child,del);
                    }
                })
            }
        }
    }
</script>
