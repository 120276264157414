<template>
    <el-row>
        <el-row class="lookup-item-query">
            <el-form :inline="true" :model="lookupItemQuery" >
                <el-form-item label="条目名称" label-width="80px">
                    <el-input v-model="lookupItemQuery.itemName" placeholder="条目名称"></el-input>
                </el-form-item>
                <el-form-item label="条目值" label-width="80px">
                    <el-input v-model="lookupItemQuery.itemValue" placeholder="条目值"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="lookupItemListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="lookup-item-add">
            <el-button type="primary" size="mini" @click="addLookupItem">添加</el-button>
            <el-button  type="primary" icon="el-icon-receiving"  size="mini" @click="submitLookupItem">保存</el-button>
        </el-row>
        <el-row class="lookup-item-body">
            <el-table
                    border
                    :data="lookupItemList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                    @cell-click="lookupItemEdit"
            >
                <el-table-column prop="itemName" label="条目名称">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemNameEdit" v-model="props.row.itemName"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemName}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemCode" label="条目编码">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemCodeEdit" v-model="props.row.itemCode"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemCode}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemValue" label="条目值">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemValueEdit" v-model="props.row.itemValue"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemValue}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr1" label="属性1">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr1Edit" v-model="props.row.itemAttr1"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr1}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr2" label="属性2">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr2Edit" v-model="props.row.itemAttr2"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr2}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr3" label="属性3">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr3Edit" v-model="props.row.itemAttr3"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr3}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr4" label="属性4">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr4Edit" v-model="props.row.itemAttr4"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr4}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr5" label="属性5">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr5Edit" v-model="props.row.itemAttr5"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr5}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr6" label="属性6">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr6Edit" v-model="props.row.itemAttr6"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr6}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="itemAttr7" label="属性7">
                    <template slot-scope="props">
                        <el-input v-focus ref="gain" size="mini" v-if="props.row.itemAttr7Edit" v-model="props.row.itemAttr7"
                                  @keyup.native.enter="lookupItemEditComplete(props)" @blur="lookupItemEditComplete(props)" ></el-input>
                        <span v-else>{{props.row.itemAttr7}}</span>
                    </template>
                </el-table-column>
                <el-table-column  label="操作" width="80px">
                    <template slot-scope="props">
                        <el-button plain  type="danger" size="mini" @click="lookupItemDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="lookup-item-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
    </el-row>
</template>

<script>
    import {findLookUpItemPageList,deleteItem,updateItemForm} from '@/api/lookup';
    export default {
        name: "lookupItemListPage",
        props:['lookupInfo'],
        directives: {
            focus: {
                inserted: function(el) {
                    el.querySelector("input").focus();
                }
            }
        },
        data(){
          return{
              lookupItemList:[],
              lookupItemQuery:{
                  classifyId:0
              },
              page:{
                  currentPage:1,
                  pageSize:10
              },
              pageInfo:{
                  count:0,
                  totalCount:0
              },
              lookupItemEditTag:new Map(),
              addItemId:0
          }
        },
        mounted(){
            this.lookupItemEditTag.set('条目名称','itemNameEdit');
            this.lookupItemEditTag.set('条目编码','itemCodeEdit');
            this.lookupItemEditTag.set('条目值','itemValueEdit');
            this.lookupItemEditTag.set('属性1','itemAttr1Edit');
            this.lookupItemEditTag.set('属性2','itemAttr2Edit');
            this.lookupItemEditTag.set('属性3','itemAttr3Edit');
            this.lookupItemEditTag.set('属性4','itemAttr4Edit');
            this.lookupItemEditTag.set('属性5','itemAttr5Edit');
            this.lookupItemEditTag.set('属性6','itemAttr6Edit');
            this.lookupItemEditTag.set('属性7','itemAttr7Edit');
            this.lookupItemQuery.classifyId = this.lookupInfo.classifyId;
            this.lookupItemListPageQuery();
        },
        methods:{
            lookupItemEdit:function(row, column, cell, event){
                if(this.lookupItemEditTag.get(column.label)){
                    this.$set(row, this.lookupItemEditTag.get(column.label), true)
                }
            },
            submitLookupItem:function () {
                updateItemForm(this.lookupItemList,success=>{
                    this.$message({type:"success",message:"保存成功",duration:1500});
                    this.queryReset();
                })
            },
            lookupItemEditComplete:function({row,column}){
                if(this.lookupItemEditTag.get(column.label)){
                    this.$set(row, this.lookupItemEditTag.get(column.label), false)
                }
            },
            addLookupItem:function () {
                let lookupItem = new Object();
                this.addItemId--;
                lookupItem.addItemId = this.addItemId;
                lookupItem.classifyId = this.lookupInfo.classifyId;
                this.lookupItemList.unshift(lookupItem);
            },
            lookupItemDelete:function(data){
                let itemName = data.itemName?data.itemName:''
                this.$confirm('是否删除条目 <span style="color:red;">'+ itemName+'</span>','删除提示',{
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(()=>{
                    if(data.addItemId){
                        this.lookupItemList.forEach((item,index)=>{
                            if(item.addItemId == data.addItemId){
                                this.lookupItemList.splice(index,1);
                            }
                        })
                        return;
                    }
                    let param = new Object();
                    param.itemId = data.itemId;
                    param.classifyId = this.lookupInfo.classifyId;
                    deleteItem(param,success=>{
                        this.$message({type:'success',message:'删除成功',duration:1500})
                        this.lookupItemList.forEach((item,index)=>{
                            if(item.itemId == data.itemId){
                                this.lookupItemList.splice(index,1);
                            }
                        });
                    })
                }).catch(()=>{

                })
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.lookupItemListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.lookupItemListPageQuery(true);
            },
            lookupItemListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findLookUpItemPageList(this.page,this.lookupItemQuery,success=>{
                    this.lookupItemList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalCount = success.totalCount;
                });
            },
            queryReset:function () {
                this.lookupItemQuery={};
                this.lookupItemQuery.classifyId = this.lookupInfo.classifyId;
                this.page={
                    pageSize:10,
                    currentPage:1
                };
                this.lookupItemListPageQuery();
            }
        }
    }
</script>

<style scoped>
.lookup-item-table-page{
    margin-top: 8px;
}
.lookup-item-body{
    margin-top: 8px;
    height: 46vh;
    overflow: auto;
}
</style>