//保存运营商入驻草稿信息
import {_get,_post,_delete,_put} from "../http";

export const getPrinterList = (success, error) => _get("/market/shop/printer/printerList", {},success,error);

export const addPrinter = (param,success, error) => _post("/market/shop/printer/add", param,success,error);

export const updatePrinter = (param,success, error) => _put("/market/shop/printer/update", param,success,error);

export const deletePrinter = (param,success, error) => _delete("/market/shop/printer/delete", param,success,error);

export const enablePrinter = (param,success, error) => _put("/market/shop/printer/enable", param,success,error);