<template>
  <el-row>
    <el-tabs v-model="rechargeStatus" type="border-card">
      <el-tab-pane :label="'待上架('+stockStatistics.warehouseNum+')'" name="0">
        <goods-stock-page v-if="rechargeStatus == '0'" :goods-category-tree="goodsCategoryTree" :brand-list="brandList" :stock-statistics="stockStatistics" :publish-status="rechargeStatus"
        @publishGoodsStock="publishGoodsStock" ></goods-stock-page>
      </el-tab-pane>
      <el-tab-pane :label="'已上架('+stockStatistics.sellNum+')'" name="1">
        <goods-stock-page  v-if="rechargeStatus == '1'" :goods-category-tree="goodsCategoryTree" :brand-list="brandList" :stock-statistics="stockStatistics"  :publish-status="rechargeStatus"
        @outOfGoodsStock="outOfGoodsStock" ></goods-stock-page>
      </el-tab-pane>
      <el-tab-pane :label="'库存预警('+stockStatistics.warnNum+')'" name="2">
        <goods-stock-page  v-if="rechargeStatus == '2'" :goods-category-tree="goodsCategoryTree" :brand-list="brandList" :stock-statistics="stockStatistics"  :publish-status="rechargeStatus" ></goods-stock-page>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import goodsStockPage from "@/shopViews/stock/goodsStockPage";
import {findGoodsCategoryTreeList} from "@/api/goodsCategory";
import {findGoodsBrand} from "@/api/brand";
import {getStockStatistics} from "@/api/stock";
export default {
  name: "stockListPage",
  data(){
    return {
      rechargeStatus:'0',
      goodsCategoryTree:[],
      brandList:[],
      stockStatistics:{
        warehouseNum:0,
        sellNum:0,
        warnNum:0
      }
    }
  },
  mounted() {
    this.initGoodsCategoryTree();
    this.initGoodsBrand();
    this.initStockStatistics();
  },
  methods:{
    publishGoodsStock:function(){
      this.initStockStatistics();
    },
    outOfGoodsStock:function (){
      this.initStockStatistics();
    },
    initGoodsCategoryTree:function (){
      findGoodsCategoryTreeList(success=>{
        this.goodsCategoryTree = success;
      })
    },
    initGoodsBrand:function (){
      findGoodsBrand(success=>{
        this.brandList=success;
      })
    },
    initStockStatistics:function (){
      getStockStatistics(success=>{
        this.stockStatistics = success.obj;
      })
    },
  },
  components:{
    goodsStockPage
  }
}
</script>

<style scoped>

</style>