<template>
    <el-row>
        <el-row class="pending-info-table">
            <el-table
                    border
                    :data="pendingList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column  label="操作" width="146px">
                    <template slot-scope="props">
                        <el-button plain v-if="props.row.taskType === 'myApprove'" type="success" size="mini" @click="approve(props.row)">审批</el-button>
                      <el-button plain v-if="props.row.taskType === 'mySubmitTask'" type="success" size="mini" @click="submitApproveDetail(props.row)">明细</el-button>
                        <el-button plain  type="primary" size="mini" @click="viewProcess(props.row)">跟踪</el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="taskName" label="任务名称"></el-table-column>
                <el-table-column prop="taskNode" label="审批环节"></el-table-column>
                <el-table-column prop="taskCreatedTime" label="代办发起时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="createdBy" label="提交人" ></el-table-column>
                <el-table-column v-if="todoType=='todo'" prop="processCreatedTime" label="提交时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  v-if="todoType=='alreadyDo'" prop="approvedTime" label="审批结束时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  v-if="todoType=='myApply'" prop="approveName" label="当前审批人"></el-table-column>
            </el-table>
        </el-row>
        <el-row class="pending-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <el-row>
            <el-dialog
                    v-if="viewProcessDialog == true"
                    :title="viewProcessName"
                    width="50%"
                    :visible.sync="viewProcessDialog"
            >
                <el-image :src="viewProcessImageUrl"></el-image>
            </el-dialog>
        </el-row>
        <el-drawer
                style="margin: auto;width: 70%;height:80%;"
                :title="approveInfo.taskName"
                :visible.sync="showApproveOperation"
                direction="ttb"
                size="100%">
            <el-row style="margin-left: 20px;overflow-x: hidden">
            <shop-detail v-if="showApproveOperation" v-bind:shopQuery="approveInfo"></shop-detail>
            <approve v-if="showApproveOperation" v-bind:approveDetail="approveInfo" @approveSuccess="approveSuccess"></approve>
            </el-row>
        </el-drawer>
        <el-drawer
                style="margin: auto;width: 70%;height:80%;"
                :title="approveInfo.taskName+'审批明细'"
                :visible.sync="showApproveDetail"
                direction="ttb"
                size="100%">
            <el-row style="margin-left: 20px;overflow-x: hidden">
                <shop-detail v-if="showApproveDetail" v-bind:shopQuery="approveInfo"></shop-detail>
                <approveHistory v-if="showApproveDetail" v-bind:approveDetail="approveInfo"></approveHistory>
            </el-row>
        </el-drawer>
    </el-row>
</template>

<script>
    import {baseUrl} from "@/config/env";
    import {findMyApproveTaskPageList,findMyApprovedTaskPageList,findMySubmitTaskPageList} from '@/api/pending';
    import shopDetail from "@/views/shop/shopDetail";
    import approve from "@/views/pending/approve";
    import approveHistory from "@/views/pending/approveHistory";
    export default {
        name: "pendingListPage",
        props:['todoType'],
        data(){
          return{
              approveInfo:{},
              showApproveOperation:false,
              showApproveDetail:false,
              viewProcessDialog:false,
              viewProcessImageUrl:'',
              viewProcessName:'',
              page:{
                  pageSize:10,
                  currentPage:1
              },
              pageInfo:{
                  count:0,
                  totalPage:0
              },
              pendingList:[]
          }
        },
        mounted(){
            this.pendingListPageQuery();
        },
        methods:{
            approveSuccess:function(){
                this.showApproveOperation=false;
                this.pendingListPageQuery();
            },
            submitApproveDetail:function(data){
                this.showApproveDetail=true;
                this.approveInfo = data;
            },
            approve:function(data){
                this.showApproveOperation=true;
                this.approveInfo = data;
            },
            viewProcess:function(data){
                let imageUrl = baseUrl
                    +"/market/manager/flowPending/showProcessImage?processInstanceId="+data.processInstanceId;
                this.viewProcessImageUrl = imageUrl;
                this.viewProcessName = data.taskName;
                this.viewProcessDialog=true;
            },
            pendingListPageQuery:function(notResetCurrentPage){
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                if(this.todoType=='toDo'){
                    findMyApproveTaskPageList(this.page,{"pengdingApproveStatus":200},success=>{
                        this.initPageInfo(success);
                    });
                }
                if(this.todoType=='alreadyDo'){
                    findMyApprovedTaskPageList(this.page,{"pengdingApproveStatus":800},success=>{
                        this.initPageInfo(success);
                    });
                }
                if(this.todoType=='myApply'){
                    findMySubmitTaskPageList(this.page,{"pengdingApproveStatus":500},success=>{
                        this.initPageInfo(success);
                    });
                }
            },
            initPageInfo:function(success){
                this.pendingList = success.data;
                this.pageInfo.count = success.count;
                this.pageInfo.totalPage = success.totalPage;
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.pendingListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.pendingListPageQuery(true);
            }
        },
        components:{
            shopDetail,
            approve,
            approveHistory
        }
    }
</script>

<style scoped>

</style>