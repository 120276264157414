import {_get,_post} from '../http/index';
//查询品牌列表
export const  findGoodsBrandPageList = (page,param,success,error) => _get("/market/manager/goodsBrand/findGoodsBrandPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加品牌
export const  addGoodsBrand  = (param,success,error) => _post("/market/manager/goodsBrand/addGoodsBrand", param,success,error) ;
//删除品牌
export const  deleteGoodsBrand  = (param,success,error) => _post("/market/manager/goodsBrand/deleteGoodsBrand", param,success,error) ;
//修改品牌
export const  updateGoodsBrand  = (param,success,error) => _post("/market/manager/goodsBrand/updateGoodsBrand", param,success,error) ;
//查询商品品牌
export const  findGoodsBrand  = (success,error) => _get("/market/manager/goodsBrand/findGoodsBrand", {},success,error) ;


