<template>
   <el-row>
     <el-form :inline="true" :model="firstOrderParam" >
       <el-form-item label="产品名称" label-width="100px">
         <el-input v-model="firstOrderParam.goodsName" placeholder="产品名称"></el-input>
       </el-form-item>
       <el-form-item label="sku名称" label-width="100px">
         <el-input v-model="firstOrderParam.skuName" placeholder="sku名称"></el-input>
       </el-form-item>
       <el-form-item label="状态" label-width="100px">
         <el-select v-model="firstOrderParam.publishStatus"  placeholder="请选择">
           <el-option
               v-for="item in publishStatusList"
               :key="item.value"
               :label="item.label"
               :value="item.value">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item>
         <el-button icon="el-icon-search" type="primary" @click="queryFirstOrderGoodsListPage">查询</el-button>
         <el-button @click="queryReset">重置</el-button>
       </el-form-item>
     </el-form>
     <el-row>
       <el-button  type="primary"  @click="addFirstOrderGoods()">添 加</el-button>
       <el-button  type="danger"  @click="deleteFirstOrderGoods()">删 除</el-button>
     </el-row>
     <el-row class="first-order-table">
       <el-table :data="firstOrderGoodsList" border
                 :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                 @selection-change="firstOrderGoodsSelect"
       >
         <el-table-column
             type="selection"
             width="55"
         >
         </el-table-column>
         <el-table-column prop="goodsPhotoPath" label="商品图片">
           <template slot-scope="props">
             <el-image class="goods-main-image" fit="scale-down"  :src="props.row.goodsPhotoPath"></el-image>
           </template>
         </el-table-column>
         <el-table-column prop="goodsName" label="产品名称"></el-table-column>
         <el-table-column prop="skuName" label="sku名称"></el-table-column>
         <el-table-column prop="shopPrice" label="商品价格"></el-table-column>
         <el-table-column prop="memberPrice" label="vip价格"></el-table-column>
         <el-table-column prop="firstGoodsPrice" label="首单价格"></el-table-column>
         <el-table-column prop="packagingFee" label="包装费"></el-table-column>
         <el-table-column prop="salesStock" label="库存"></el-table-column>
         <el-table-column prop="publishStatus" label="状态">
           <template slot-scope="props">
             <el-switch
                 v-model="props.row.publishStatus"
                 inactive-value="0"
                 active-value="1"
                 active-color="#13ce66"
                 @change="firstOrderEnableUpdate($event,props.row)"
                 inactive-color="#d2d2d2">
             </el-switch>
           </template>
         </el-table-column>
         <el-table-column label="操作">
           <template slot-scope="props">
             <el-button plain size="mini" type="warning"  @click="editFirstOrderGoods(props.row)">编 辑</el-button>
             <el-button plain size="mini" type="danger" @click="deleteFirstOrderGoodsOne(props.row)">删 除</el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-row>
     <el-row class="first-order-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
     <el-dialog
         :title="'编辑首单产品'"
         width="46%"
         :close-on-click-modal="false"
         :visible.sync="editFirstOrderGoodsDialog"
     >
     <first-order-goods-info :order-goods-info="currentFirstOrderGoods"></first-order-goods-info>
       <span slot="footer" class="dialog-footer">
            <el-button @click="cancelFirstOrderGoods">取 消</el-button>
             <el-button type="primary" @click="saveFirstOrderGoods()">确 定</el-button>
       </span>
     </el-dialog>
     <el-dialog
         :title="'添加首单产品'"
         width="60%"
         :close-on-click-modal="false"
         :visible.sync="addFirstOrderGoodsDialog"
     >
      <goods-stock-page v-if="addFirstOrderGoodsDialog" :use-type="configType=='0'?'1':'2'" :publish-status="'1'" :goods-category-tree="goodsCategoryTree" @stockSelectChange="stockSelectChange" ></goods-stock-page>
       <span slot="footer" class="dialog-footer">
            <el-button @click="cancelAddFirstOrderGoods">取 消</el-button>
            <el-button type="primary" @click="addFirstOrderGoodsList()">确 定</el-button>
       </span>
     </el-dialog>
   </el-row>
</template>

<script>
import {queryFirstOrderGoodsPage,deleteFirstOrderGoods,updateFirstOrderGoods,addFirstOrderGoods} from '@/api/firstOrder';
import {findGoodsCategoryTreeList} from "@/api/goodsCategory";
import {findGoodsBrand} from "@/api/brand";
import firstOrderGoodsInfo from "./firstOrderGoodsInfo";
import goodsStockPage from "../stock/goodsStockPage";
import {updateAppState} from "@/api/app";
export default {
  name: "firstOrderPage",
  props:{
    configType:{
      type:String,
      default:'0'
    }
  },
  data(){
    return {
      publishStatusList:[{label:'待发布',value:'0'},{label:'已发布',value:'1'}],
      editFirstOrderGoodsDialog:false,
      addFirstOrderGoodsDialog:false,
      firstOrderParam:{
        configType:this.configType
      },
      currentFirstOrderGoods:{},
      firstOrderGoodsList:[],
      firstAddOrderGoodsList:[],
      firstOrderGoodsSelectList:[],
      goodsCategoryTree:[],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
   this.queryFirstOrderGoodsListPage();
   this.initGoodsCategoryTree();
  },
  methods:{
    firstOrderEnableUpdate:function($event,data){
      this.currentFirstOrderGoods = data;
      if(!this.currentFirstOrderGoods.firstGoodsPrice){
        this.$errorMessage('首单价不能为空');
        data.publishStatus = 0;
        return;
      }
      this.saveFirstOrderGoods();
    },
    stockSelectChange:function (val){
      this.firstAddOrderGoodsList = val;
    },
    addFirstOrderGoodsList:function (){
      if(!this.firstAddOrderGoodsList || this.firstAddOrderGoodsList.length==0){
         this.$errorMessage('没有需要添加的产品');
         return;
      }
      let param = new Object();
      param.configType = this.configType;
      param.firstOrderGoodsConfigVos = this.firstAddOrderGoodsList;
      addFirstOrderGoods(param,success=>{
        this.$successMessage('添加成功');
        this.addFirstOrderGoodsDialog=false;
        this.queryFirstOrderGoodsListPage();
      })
    },
    cancelAddFirstOrderGoods:function (){
      this.addFirstOrderGoodsDialog=false;
    },
    initGoodsCategoryTree:function (){
      findGoodsCategoryTreeList(success=>{
        this.goodsCategoryTree = success;
      })
    },
    saveFirstOrderGoods:function (){
      if(!this.currentFirstOrderGoods.firstGoodsPrice){
        this.$errorMessage('首单价不能为空');
        return;
      }
      if(parseFloat(this.currentFirstOrderGoods.firstGoodsPrice)>parseFloat(this.currentFirstOrderGoods.goodsPrice)){
        this.currentFirstOrderGoods.firstGoodsPrice='';
        this.$errorMessage('首单价不能大于商品价格');
        return;
      }
      updateFirstOrderGoods(this.currentFirstOrderGoods,success=>{
        this.$successMessage('修改成功');
        this.editFirstOrderGoodsDialog=false;
      },error=>{
        this.currentFirstOrderGoods.firstGoodsPrice='';
      });
    },
    cancelFirstOrderGoods:function (){
      this.editFirstOrderGoodsDialog=false;
    },
    editFirstOrderGoods:function (orderGoods){
      this.currentFirstOrderGoods = orderGoods;
      this.editFirstOrderGoodsDialog=true;
    },
    firstOrderGoodsSelect:function (val){
      this.firstOrderGoodsSelectList=val;
    },
    deleteFirstOrderGoodsOne:function (orderGoods){
      this.$confirm('是否删除产品 <span style="color: red">'+orderGoods.goodsName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            let deleteList = [];
            deleteList.push(orderGoods);
            deleteFirstOrderGoods(deleteList,success=>{
              this.$successMessage('删除成功');
              this.queryFirstOrderGoodsListPage();
            });
          })
          .catch(() => {

          })
    },
    deleteFirstOrderGoods:function (){
      this.$confirm('是否删除选中首单产品', '删除提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteFirstOrderGoods(this.firstOrderGoodsSelectList,success=>{
              this.$successMessage('删除成功');
              this.queryFirstOrderGoodsListPage();
            });
          })
          .catch(() => {

          })
    },
    addFirstOrderGoods:function (){
      this.addFirstOrderGoodsDialog=true;
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryFirstOrderGoodsListPage();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryFirstOrderGoodsListPage(true);
    },
    queryFirstOrderGoodsListPage:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      this.firstOrderParam.configType = this.configType;
      queryFirstOrderGoodsPage(this.page,this.firstOrderParam,success=>{
        this.firstOrderGoodsList =success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function (){
      this.firstOrderParam={};
      this.queryFirstOrderGoodsListPage();
    }
  },
  components:{
    firstOrderGoodsInfo,
    goodsStockPage
  }
}
</script>

<style scoped>
.first-order-table,.first-order-table-page{
  padding-top: 8px;
}
.goods-main-image{
  width: 100px;
  height: 100px;
  border-radius: 6px;
}
</style>