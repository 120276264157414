import {_get, _post,_delete} from "@/http";


export const findStockClassify = (page, param, success, error) => _get("/market/shop/stockClassify/findStockClassify/"+page.pageSize+'/'+page.currentPage, param,success,error) ;

export const addClassifyInfo = (param, success, error) => _post("/market/shop/stockClassify/add", param,success,error) ;

export const deleteClassifyInfo = (param, success, error) => _delete("/market/shop/stockClassify/delete", param,success,error) ;

export const addStockClassifyDetail = (param, success, error) => _post("/market/shop/stockClassify/addStockClassifyDetail", param,success,error) ;

export const removeStockClassifyDetail = (param, success, error) => _post("/market/shop/stockClassify/removeStockClassifyDetail", param,success,error) ;

