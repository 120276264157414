<template>
  <el-row id="data-view">
      <top-header />
     <digital-flop :ask-info="askInfo" :user-info="this.userInfo"/>
    <el-row class="block-top-bottom-content">
      <el-row class="">
        <scroll-board :scroll-id="'scroll-board-all'"  :open-wx="true" :title="'在线微信'" :config="onlineWxConfig"/>
      </el-row>
      <el-row class="">
        <scroll-board :scroll-id="'scroll-board-all'" :title="'今日申请明细'" :config="this.askWxConfig"/>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import topHeader from './topHeader';
import digitalFlop from './digitalFlop'
import scrollBoard from './scrollBoard'
import {userStatistics} from "@/api/wxHelper";
import {currentIndex} from "@/api/wxHelperLocal"
export default {
  name: 'index',
  components: {
    topHeader,
    digitalFlop,
    scrollBoard
  },
  data () {
    return {
      userInfo:{},
      askInfo:{},
      statistics:{
        list:[]
      },
      onlineWxConfig: {
        header: ['微信id', '微信昵称', '账号', '备注','申请数',' 通过数','今日总人数','昨日人数'],
        data: [],
        columnWidth: [200,200,200,200],
        align: ['center'],
        headerBGC: '#1981f6',
        headerHeight: 45,
        rowNum: 5,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      },
      askWxConfig: {
        header: ['申请WX_ID', '申请人昵称', '被申请人WX_ID', '被申请人昵称', '申请时间','通过状态','通过时间'],
        data: [],
        columnWidth:[200,200,200,200,200,200,200],
        align: ['center'],
        headerBGC: '#1981f6',
        headerHeight: 45,
        rowNum: 7,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  },
  mounted() {
    this.initStatistic();
    this.initLocalStatistic();
  },
  methods: {
    initLocalStatistic:function (){
      currentIndex(success=>{
        if(success.obj && success.obj.loginList && success.obj.loginList.length>0){
          let config ={
            header: ['微信id', '微信昵称', '账号', '备注','申请数',' 通过数','今日总人数','昨日人数'],
            data: [],
            columnWidth: [],
            align: ['center'],
            headerBGC: '#1981f6',
            headerHeight: 45,
            rowNum: 5,
            oddRowBGC: 'rgba(0, 44, 81, 0.8)',
            evenRowBGC: 'rgba(10, 29, 50, 0.8)'
          };

          success.obj.loginList.forEach(item=>{
            let wxStatic = this.getWxStatic(item.wx_id);
            let array = [];
            array.push(item.wx_id);
            array.push(item.nick_name);
            array.push(item.wx_account);
            array.push(wxStatic.wxBindRemark?wxStatic.wxBindRemark:"");
            array.push(item.askToday);
            array.push(item.passToday);
            array.push(item.todayCount);
            array.push(item.yesterdayCount);
            console.log(array)
            config.data.push(array);
          })
          this.onlineWxConfig = config;
        }
        if(success.obj && success.obj.askList && success.obj.askList.length>0){
          let config = {
            header: ['申请WX_ID', '申请人昵称', '被申请人WX_ID', '被申请人昵称', '申请时间','通过状态','通过时间'],
            data: [],
            columnWidth:[200,200,200,200,200,200,200],
            align: ['center'],
            headerBGC: '#1981f6',
            headerHeight: 45,
            rowNum: 7,
            oddRowBGC: 'rgba(0, 44, 81, 0.8)',
            evenRowBGC: 'rgba(10, 29, 50, 0.8)'
          }
          success.obj.askList.forEach(item=>{
            let array = [];
            array.push(item.wxId);
            array.push(item.wxName);
            array.push(item.myWxId);
            array.push(item.myWxName);
            array.push(item.askDate);
            array.push(item.status == 1?"通过":"待通过");
            array.push(item.passDate);
            config.data.push(array);
          })
          this.askWxConfig = config;
        }
        this.askInfo = success.obj.statisticVo;
      })
      setTimeout(()=>{
        this.initLocalStatistic()
      },2000);
    },
    initStatistic:function (){
      userStatistics(success=>{
        if(success.obj){
          this.statistics = success.obj;
          this.userInfo = success.obj.topStatisticsCount;
        }
      });
      setTimeout(()=>{
        this.initStatistic()
      },300000);
    },
    getWxStatic:function (wxId){
      let wxStatic = {};
      if(this.statistics.list  && this.statistics.list.length>0){
        this.statistics.list.forEach(item=>{
          if(item.wxId === wxId){
            wxStatic =  item;
          }
        })
      }
      return wxStatic;
    }
  }
}
</script>

<style lang="less">
#data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  #dv-full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .block-left-right-content {
    flex: 1;
    display: flex;
    margin-top: 20px;
  }

  .block-top-bottom-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-left: 20px;
  }

  .center-title {
    font-size: 15px;
    text-align: center;
    padding: 8px;
  }
}
</style>