<template>
    <el-row>
        <el-row  v-for="menu in menuInfoList" :key="menu.catalogId" :index="menu.catalogName">
            <el-submenu v-if="menu.child != null && menu.child.length>0">
                <template slot="title" >
                    <i class="iconfont" :class="menu.icon"></i>
                    <span  slot="title" class="catalog-name">{{menu.catalogName}}</span>
                </template>
                <navNode v-bind:menu-info-list="menu.child"></navNode>
            </el-submenu>
            <el-menu-item v-else :index="'/'+menu.catalogUrl" :key="menu.catalogId" >
                <i class="iconfont" :class="menu.icon"></i>
                <span slot="title" class="catalog-name">{{menu.catalogName}}</span>
            </el-menu-item>
        </el-row>
    </el-row>
</template>

<script>
    export default {
        name: "navNode",
        props: ['menuInfoList']
    }
</script>

<style scoped>
    .catalog-name{
        padding-left: 8px;
    }

</style>