<template>
  <el-row>
    <el-row class="stock-table">
      <el-table :data="goodsStockDetailList" border
                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="stockType" label="操作" :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'STOCK_TYPE_STATUS')"></el-table-column>
        <el-table-column prop="changeBefore" label="修改前数据">
          <template slot-scope="props">
            <p v-if="props.row.changeBefore &&  JSON.parse(props.row.changeBefore).salesStock">
              库存:{{JSON.parse(props.row.changeBefore).salesStock}}
            </p>
            <p v-if="props.row.changeBefore &&  JSON.parse(props.row.changeBefore).goodsPrice">
              商品价格:{{JSON.parse(props.row.changeBefore).goodsPrice}}
            </p>
            <p v-if="props.row.changeBefore &&  JSON.parse(props.row.changeBefore).memberPrice">
              会员价:{{JSON.parse(props.row.changeBefore).memberPrice}}
            </p>
            <p v-if="props.row.changeBefore &&  JSON.parse(props.row.changeBefore).shopPrice">
              店家价格:{{JSON.parse(props.row.changeBefore).shopPrice}}
            </p>
            <p v-if="props.row.changeBefore &&  JSON.parse(props.row.changeBefore).packagingFee">
              包装费:{{JSON.parse(props.row.changeBefore).packagingFee}}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="changeBeAfter" label="修后后数据">
          <template slot-scope="props">
            <p v-if="props.row.changeAfter &&  JSON.parse(props.row.changeAfter).salesStock">
              库存:{{JSON.parse(props.row.changeAfter).salesStock}}
            </p>
            <p v-if="props.row.changeAfter &&  JSON.parse(props.row.changeAfter).goodsPrice">
              商品价格:{{JSON.parse(props.row.changeAfter).goodsPrice}}
            </p>
            <p v-if="props.row.changeAfter &&  JSON.parse(props.row.changeAfter).memberPrice">
              会员价:{{JSON.parse(props.row.changeAfter).memberPrice}}
            </p>
            <p v-if="props.row.changeAfter &&  JSON.parse(props.row.changeAfter).shopPrice">
              店家价格:{{JSON.parse(props.row.changeAfter).shopPrice}}
            </p>
            <p v-if="props.row.changeAfter &&  JSON.parse(props.row.changeAfter).packagingFee">
              包装费:{{JSON.parse(props.row.changeAfter).packagingFee}}
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="stockNum" label="库存变更量" :formatter="stockNumFormatter">
        </el-table-column>
        <el-table-column prop="stockStatus" label="状态"  :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'STOCK_DETAIL_STATUS')"></el-table-column>
        <el-table-column prop="lastUpdatedBy" label="操作人" ></el-table-column>
        <el-table-column prop="lastUpdatedDate" label="操作时间"  :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
      </el-table>
    </el-row>
    <el-row class="stock-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
  </el-row>
</template>

<script>
import {queryGoodsStockDetailPage} from "@/api/stock";
export default {
  name: "goodsStockDetailListPage",
  props:{
    goodsStockId:{
      type:Number,
      default:0
    }
  },
  data(){
    return {
      goodsStockDetailList:[],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
    this.queryGoodsStockDetail();
  },
  methods:{
    stockNumFormatter:function (row){
      if(row.stockNum){
        return row.stockNum;
      }
      return  '---';
    },
    queryGoodsStockDetail:function (){
      queryGoodsStockDetailPage(this.page,{goodsStockId:this.goodsStockId},success=>{
        this.goodsStockDetailList=success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryStockPageList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryStockPageList(true);
    }
  }
}
</script>

<style scoped>

</style>