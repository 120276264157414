<template>
    <el-row>
        <el-row class="role-query-form">
            <el-form :inline="true" :model="roleQueryParam"  >
                <el-form-item label="角色名称" label-width="100px">
                    <el-input v-model="roleQueryParam.roleName" placeholder="角色名称"></el-input>
                </el-form-item>
                <el-form-item label="角色编码" label-width="100px">
                <el-input v-model="roleQueryParam.roleCode" placeholder="角色编码"></el-input>
                </el-form-item>
                <el-form-item label="角色类型" label-width="100px">
                    <el-select v-model="roleQueryParam.roleType" placeholder="请选择角色类型">
                        <el-option
                                v-for="item in roleTypeList"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="roleListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="role-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addRoleInfo">添加</el-button>
        </el-row>
        <el-row class="role-info-table">
            <el-table
                    border
                    :data="roleList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="roleName" label="角色名称"></el-table-column>
                <el-table-column prop="roleCode" label="角色编码"></el-table-column>
                <el-table-column prop="roleType" label="角色类型"  :formatter="formatterRoleType"></el-table-column>
                <el-table-column prop="roleRemark" label="备注" ></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作" width="220px">
                    <template slot-scope="props">
                        <el-button plain  type="primary" size="mini" @click="rolePermissionUpdate(props.row)">授权</el-button>
                        <el-button plain v-if="props.row.roleCode != 'default'"  type="warning" size="mini" @click="roleInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain v-if="props.row.roleCode != 'default'"  type="danger" size="mini" @click="roleInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="role-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
         <role-info  v-if="viewRoleInfoDialog == true" :viewRoleInfoDialog="viewRoleInfoDialog"
                     :role-type-list="roleTypeList" :role-info="currentRoleInfo"
                     @cancelRoleInfo="cancelRoleInfo" @submitRoleInfo="submitRoleInfo"></role-info>
        <role-permission v-if="viewRolePermissionDialog == true" :role-info="currentRoleInfo" :viewRolePermissionDialog="viewRolePermissionDialog" :appInfoList="appInfoList"
         @cancelRolePermission="cancelRolePermission" @submitRolePermission="cancelRolePermission"></role-permission>
    </el-row>
</template>

<script>
    import {findRolePageList,deleteSingleRole,addSingleRole,updateSingleRole} from '@/api/role';
    import roleInfo from "@/views/role/roleInfo";
    import rolePermission from "@/views/role/rolePermission";
    import {findAppInfoList} from '@/api/app'
    export default {
        name: "roleListPage",
        data(){
            return{
                page:{
                    pageSize:10,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                roleList:[],
                currentRoleInfo:{},
                roleQueryParam:{},
                viewRoleInfoDialog:false,
                viewRolePermissionDialog:false,
                roleTypeList:[
                    {value:"001",label:"系统角色"},
                    {value:"002",label:"商家角色"},
                    {value:"003",label:"商圈角色"}
                ],
                appInfoList:[]
            }
        },
        mounted(){
            this.initAppInfoList()
            this.roleListPageQuery();
        },
        methods:{
            initAppInfoList:function(){
                findAppInfoList(success=>{
                    this.appInfoList = success;
                })
            },
            addRoleInfo:function(){
                this.currentRoleInfo={};
                this.viewRoleInfoDialog=true;
            },
            cancelRolePermission:function(){
                this.viewRolePermissionDialog=false;
            },
            cancelRoleInfo:function(){
                this.viewRoleInfoDialog=false;
            },
            submitRoleInfo:function(roleInfo){
                if(roleInfo.roleId){
                    updateSingleRole(roleInfo,success=>{
                        this.$successMessage("修改成功");
                        this.viewRoleInfoDialog=false;
                    })
                }else{
                    addSingleRole(roleInfo,success=>{
                        this.$successMessage("新增成功");
                        this.viewRoleInfoDialog=false;
                        this.queryReset();
                    })
                }
            },
            roleInfoUpdate:function(roleInfo){
                this.currentRoleInfo=roleInfo;
                this.viewRoleInfoDialog=true;
            },
            rolePermissionUpdate:function(roleInfo){
                this.currentRoleInfo=roleInfo;
                this.viewRolePermissionDialog=true;
            },
            roleInfoDelete:function(roleInfo){
                this.$confirm('是否删除角色 <span style="color: red">'+roleInfo.roleName+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteSingleRole(roleInfo,success=>{
                            this.$successMessage("删除成功");
                            this.roleList.forEach((item,index)=>{
                                if(item.roleId == roleInfo.roleId){
                                    this.roleList.splice(index,1);
                                }
                            });
                        });
                    })
                    .catch(() => {})
            },
            roleListPageQuery:function(notResetCurrentPage){
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findRolePageList(this.page,this.roleQueryParam,success=>{
                    this.roleList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                })
            },
            formatterRoleType:function(row,column,cellValue,index){
                let roleType='';
                this.roleTypeList.forEach(item=>{
                    if(item.value == cellValue){
                        roleType =  item.label;
                    }
                });
                return roleType;
            },
            queryReset:function () {
                this.roleQueryParam={};
                this.page ={
                    pageSize:10,
                    currentPage:1
                }
                this.roleListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.roleListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.roleListPageQuery(true);
            }
        },
        components:{
            roleInfo,
            rolePermission
        }
    }
</script>

<style scoped>
    .role-info-table{
        margin-top: 8px;
    }
    .role-table-page{
        margin-top: 8px;
    }
</style>