<template>
   <el-row>
     <el-form :inline="true" :model="queryCouponParam" >
       <el-form-item label="优惠券名称" label-width="100px">
         <el-input v-model="queryCouponParam.couponName" placeholder="优惠券名称"></el-input>
       </el-form-item>
       <el-form-item label="优惠券类型" label-width="100px">
         <el-select v-model="queryCouponParam.couponType" clearable placeholder="请选择">
           <el-option
               v-for="item in couponTypeList"
               :key="item.itemCode"
               :label="item.itemName"
               :value="item.itemCode">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item label="会员类型" label-width="80px">
         <el-select v-model="queryCouponParam.belongMemberType" clearable placeholder="请选择">
           <el-option
               v-for="item in belongMemberTypeList"
               :key="item.value"
               :label="item.label"
               :value="item.value">
           </el-option>
         </el-select>
       </el-form-item>
       <el-form-item>
         <el-button icon="el-icon-search" type="primary" @click="queryCouponPageList">查询</el-button>
         <el-button @click="queryReset">重置</el-button>
       </el-form-item>
     </el-form>
     <el-row>
       <el-button  type="primary"  @click="createCoupon()">创建优惠券</el-button>
     </el-row>
     <el-row class="coupon-table">
       <el-table :data="couponList" border
                 :header-cell-style="{background:'#F3F4F7',color:'#555'}"
       >
         <el-table-column prop="couponName" label="优惠券名称" width="120px"></el-table-column>
         <el-table-column prop="couponType" label="优惠券类型"  :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'COUPON_TYPE')" ></el-table-column>
         <el-table-column prop="belongMemberType" label="会员类型" :formatter="formatterMemberType"></el-table-column>
         <el-table-column prop="couponAmount" label="面额/折扣" :formatter="formatterCouponAmount"></el-table-column>
         <el-table-column prop="expiryDate" label="有效期" :formatter="formatterExpiryDate" width="180px">
         </el-table-column>
         <el-table-column v-if="couponStatus == '0'" prop="couponStatus" label="状态"  :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'COUPON_STATUS')"></el-table-column>
         <el-table-column prop="couponSendTotal" label="发放量"  :formatter="formatterCouponSendTotal" ></el-table-column>
         <el-table-column prop="getNumLimit" label="限领次数"  :formatter="formatterNumLimit"></el-table-column>
         <el-table-column prop="couponReceiveTotal" label="领取量" :formatter="(row, column,cellValue)=> {return  cellValue ? cellValue : '0';} "></el-table-column>
         <el-table-column prop="couponUseTotal" label="使用量" :formatter="(row, column,cellValue)=> {return  cellValue ? cellValue : '0';} "></el-table-column>
         <el-table-column prop="createdBy" label="创建者"></el-table-column>
         <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
         <el-table-column prop="操作" label="操作" :width="couponStatus == 1?'300px':'180px'">
           <template slot-scope="props">
             <el-button plain size="mini"  @click="couponInfoDetail(props.row)">详 情</el-button>
             <el-button plain size="mini" v-if="couponStatus == '1'" type="primary"  @click="handOutCouponInfo(props.row)">发 放</el-button>
             <el-button plain size="mini" v-if="couponStatus == '1'"  type="warning" @click="editCouponInfo(props.row)">编 辑</el-button>
             <el-button plain size="mini" v-if="couponStatus == '1'"  type="danger"  @click="deleteCouponInfo(props.row)">删 除</el-button>
             <el-button plain size="mini"  v-if="couponStatus == '2' || couponStatus == '5'" type="primary"  @click="couponInfoSendDetail(props.row)">领取记录</el-button>
           </template>
         </el-table-column>
       </el-table>
     </el-row>
     <el-row class="coupon-table-page">
       <el-pagination
           @size-change="handleSizeChange"
           @current-change="handleCurrentChange"
           :current-page="page.currentPage"
           :page-sizes="[10,15,30,50,100]"
           :page-size="page.pageSize"
           layout="total, sizes, prev, pager, next, jumper"
           :total="pageInfo.count">
       </el-pagination>
     </el-row>
     <el-dialog
         :title="currentCouponInfo.couponId?'修改优惠券':'创建优惠券'"
         width="40%"
         :close-on-click-modal="false"
         :visible.sync="couponShowDialog"
     >
        <coupon-info :can-edit="canEdit" :coupon-info="currentCouponInfo"></coupon-info>
       <span v-if="canEdit" slot="footer" class="dialog-footer">
            <el-button @click="cancelCouponInfo">取 消</el-button>
             <el-button type="primary" @click="saveCouponInfo()">确 定</el-button>
        </span>
     </el-dialog>
     <el-dialog
         :title="'优惠券领取明显'"
         width="60%"
         :close-on-click-modal="false"
         :visible.sync="couponDetailShowDialog"
     >
       <coupon-send-detail v-if="couponDetailShowDialog == true" :coupon-id="currentCouponInfo.couponId"></coupon-send-detail>
     </el-dialog>
   </el-row>
</template>

<script>
import {queryCouponPageList,saveCoupon,updateCoupon,handOutCoupon,delCoupon} from '@/api/coupon'
import couponInfo from "@/shopViews/coupon/couponInfo";
import couponSendDetail from "./couponSendDetail";
export default {
  name: "couponPage",
  components:{
    couponInfo,
    couponSendDetail
  },
  props:{
    couponStatus:{
      type:String,
      default:'0'
    }
  },
  data(){
    return{
      canEdit:false,
      couponShowDialog:false,
      couponDetailShowDialog:false,
      queryCouponParam:{},
      belongMemberTypeList:[{label:'所有会员',value:'0'},{label:'超级VIP',value:'1'}],
      couponTypeList:this.$lookupInfo('COUPON_TYPE'),
      couponList:[],
      defaultCouponInfo:{
        activityType:'1',
        couponType:'1',
        belongMemberType:'0',
        sendTotalType:'1',
        conditionType:'0',
        getNumType:'1',
        couponUseType:'0',
        couponBeginDate:'',
        couponEndDate:''
      },
      currentCouponInfo:{},
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
    }
  },
  mounted() {
    this.queryCouponPageList();
  },
  methods:{
    couponInfoSendDetail:function (couponInfo){
      this.currentCouponInfo=couponInfo;
      this.couponDetailShowDialog=true;
    },
    deleteCouponInfo:function (couponInfo){
      this.$confirm('是否删除优惠券 <span style="color: red">'+couponInfo.couponName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            delCoupon({couponId:couponInfo.couponId},success=>{
              this.$successMessage('删除成功');
              this.queryCouponPageList();
            });
          })
          .catch(() => {

          })
    },
    handOutCouponInfo:function (couponInfo){
      this.$confirm('是否发布优惠券 <span style="color: red">'+couponInfo.couponName+'</span>', '发布提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            handOutCoupon({couponId:couponInfo.couponId},success=>{
              this.$successMessage('发布成功');
              this.queryCouponPageList();
            });
          })
          .catch(() => {

          })
    },
    editCouponInfo:function (couponInfo){
      this.canEdit=true,
      this.currentCouponInfo=couponInfo;
      this.couponShowDialog=true;
    },
    couponInfoDetail:function (couponInfo){
      this.canEdit=false,
      this.currentCouponInfo=couponInfo;
      this.couponShowDialog=true;
    },
    formatterNumLimit:function (row){
      if (row.getNumType === '1') {
        return "不限量";
      }
      return row.getNumLimit;
    },
    formatterCouponSendTotal:function (row){
      if (row.sendTotalType === '1') {
        return "不限量";
      }
      return row.couponSendTotal;
    },
    formatterExpiryDate:function (row){
      return row.couponBeginDate + "~" + row.couponEndDate;
    },
    formatterCouponAmount:function (row){
      if (row.couponType === '1') {
        if (row.conditionType === '0') {
          return "满0减" + row.couponAmount;
        }
        return "满" + row.fullBuyPrice + "减" + row.couponAmount;
      }
      return row.couponAmount + "折";
    },
    formatterMemberType:function (row){
      if (row.belongMemberType === '0') {
        return '所有会员';
      }
      return '超级VIP';
    },
    cancelCouponInfo:function (){
      this.couponShowDialog=false;
    },
    saveCouponInfo:function (){

      if(this.currentCouponInfo.couponUseType != 0 && !this.currentCouponInfo.couponRelIds){
        this.$errorMessage('请选择商品');
        return ;
      }
      if(this.currentCouponInfo.couponId){
        updateCoupon(this.currentCouponInfo,success=>{
          this.$successMessage('修改成功');
          this.couponShowDialog=false;
        })
        return;
      }
      saveCoupon(this.currentCouponInfo,success=>{
        this.$successMessage('创建成功');
        this.couponShowDialog=false;
        this.queryCouponPageList();
      })
    },
    createCoupon:function (){
      this.canEdit=true,
      this.currentCouponInfo=this.defaultCouponInfo;
      this.couponShowDialog=true;
    },
    queryCouponPageList:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      if(this.couponStatus != '0'){
        this.queryCouponParam.couponStatus = this.couponStatus;
      }
      queryCouponPageList(this.page,this.queryCouponParam,success=>{
        this.couponList =success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function (){
      this.queryCouponParam={};
      this.queryCouponPageList();
    },
    handleSizeChange:function (val) {
      this.page.pageSize = val;
      this.queryCouponPageList();
    },
    handleCurrentChange:function (val) {
      this.page.currentPage = val;
      this.queryCouponPageList(true);
    },
  }
}
</script>

<style scoped>
 .coupon-table,.coupon-table-page{
   padding-top: 8px;
 }
</style>