<template>
  <el-row id="digital-flop">
    <el-row
      class="digital-flop-item"
      v-for="item in askInfoList"
      :key="item.title"
    >
      <el-row class="digital-flop-title">{{ item.title }}</el-row>
      <el-row class="digital-flop">
        <dv-digital-flop
          :config="item.number"
          style="width:100px;height:50px;"
        />
          <el-row class="unit">{{ item.unit }}</el-row>
      </el-row>
    </el-row>
    <dv-decoration-10 />
  </el-row>
</template>

<script>
export default {
  name: 'digitalShareFlop',
  props:{
    askInfo:{
      askTodayCount:0,
      passTodayCount:0,
      todayCount:0,
      askYesterdayCount:0
    }
  },
  data () {
    return {
      askInfoList:[]
    }
  },
  methods: {

  },
  watch: {
    // 设置回显
    askInfo: {
      handler(val) {
        this.askInfoList=[
          {
            title: '申请总数',
            number: {
              number: [val.askTodayCount],
              content: '{nt}',
              textAlign: 'right',
              style: {
                fill: '#4d99fc',
                fontWeight: 'bold'
              }
            },
            unit: '个'
          },
          {
            title: '通过总数',
            number: {
              number: [val.passTodayCount],
              content: '{nt}',
              textAlign: 'right',
              style: {
                fill: '#27f431',
                fontWeight: 'bold'
              }
            },
            unit: '个'
          },
          {
            title: '今日总数',
            number: {
              number: [val.askTodayCount],
              content: '{nt}',
              textAlign: 'right',
              style: {
                fill: '#4d99fc',
                fontWeight: 'bold'
              }
            },
            unit: '个'
          },
          {
            title: '昨日总数',
            number: {
              number: [val.askYesterdayCount],
              content: '{nt}',
              textAlign: 'right',
              style: {
                fill: '#27f431',
                fontWeight: 'bold'
              }
            },
            unit: '个'
          }
        ]
      }
    },
  },
  mounted () {

    }
}
</script>

<style lang="less">
#digital-flop {
  position: relative;
  height: 15%;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(6, 30, 93, 0.5);

  .dv-decoration-10 {
    position: absolute;
    width: 95%;
    left: 2.5%;
    height: 5px;
    bottom: 0px;
  }

  .digital-flop-item {
    width: 11%;
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-left: 3px solid rgb(6, 30, 93);
    border-right: 3px solid rgb(6, 30, 93);
  }

  .digital-flop-title {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .digital-flop {
    display: flex;
  }

  .unit {
    margin-left: 10px;
    display: flex;
    align-items: flex-end;
    box-sizing: border-box;
    padding-bottom: 13px;
  }
}
</style>
