<template>
    <el-row>
        <el-dialog
                :title="groupInfoDetail.groupId?'修改组别':'添加组别'"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="groupInfoDialog"
                @close="cancelGroupInfo"
        >
            <el-row>
                <el-form :rules="groupRules" ref="groupForm" label-width="100px" :model="groupInfoDetail">
                    <el-form-item label="角色名称" prop="groupName">
                        <el-col :span="10">
                            <el-input v-model="groupInfoDetail.groupName" placeholder="角色名称"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="角色编码" prop="groupCode">
                        <el-col :span="10">
                            <el-input v-model="groupInfoDetail.groupCode" placeholder="角色编码" :readonly="groupInfoDetail.groupId?true:false"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item label="备注信息">
                        <el-input type="textarea" v-model="groupInfoDetail.groupRemark" placeholder="备注信息"></el-input>
                    </el-form-item>
                </el-form>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelGroupInfo()">取 消</el-button>
                    <el-button type="primary" @click="submitGroupInfo('groupForm')">确 定</el-button>
                 </span>
        </el-dialog>
    </el-row>
</template>

<script>
    export default {
        name: "groupInfo",
        props:['groupInfo','viewGroupInfoDialog'],
        data(){
            return {
                groupInfoDetail:{},
                groupInfoDialog:false,
                groupRules: {
                    groupName: [
                        { required:true,message:"组别名称不能为空",trigger:'blur'}
                    ],
                    groupCode: [
                        { required:true,message:"组别编码不能为空",trigger:'blur'}
                    ]
                }
            }
        },
        mounted(){
            this.groupInfoDetail = this.groupInfo;
            this.groupInfoDialog = this.viewGroupInfoDialog;
        },
        methods:{
            cancelGroupInfo:function(){
                this.$emit('cancelGroupInfo');
            },
            submitGroupInfo:function(formName){
                this.$refs[formName].validate((valid) => {
                    if(valid){
                        this.$emit('submitGroupInfo',this.groupInfoDetail);
                    }
                });
            }
        }
    }
</script>

<style scoped>

</style>