<template>
  <el-row>
    <el-form label-width="100px" :model="shopServer" >
      <el-form-item label="提供服务" >
        <el-checkbox-group   v-model="checkList" :min="1">
          <el-checkbox :label="item.itemName" :name="item.itemCode"  v-for="(item,index) in serverTypeList" :key="index" ></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item label="是否支持预定" >
        <template slot-scope="props">
          <el-switch
              v-model="shopServer.canReserve"
              inactive-value="N"
              active-value="Y"
              active-color="#13ce66"
              @change="openShopCanReserve($event,props)"
              inactive-color="#d2d2d2">
          </el-switch>
        </template>
      </el-form-item>
      <el-form-item label="预定提前" >
        <el-col :span="3">
          <el-input v-model="shopServer.leadTime" type="number" placeholder="预定提前多少分钟"></el-input>
        </el-col>
        <el-col :span="1" style="padding-left: 5px">
          分钟
        </el-col>
      </el-form-item>
      <el-form-item label="支付设置" >
        <template>
          <el-radio v-model="shopServer.shopPayServer" label="1" >线上支付</el-radio>
          <el-radio v-model="shopServer.shopPayServer" label="2">线下支付</el-radio>
        </template>
      </el-form-item>
      <el-form-item label="购物车设置" >
        <template>
          <el-radio v-model="shopServer.shopCartServer" label="1">个人</el-radio>
          <el-radio v-model="shopServer.shopCartServer" label="2">桌号</el-radio>
        </template>
      </el-form-item>
      <el-form-item  >
        <el-button  type="primary" @click="savePreSell">保 存</el-button>
      </el-form-item>
    </el-form>
  </el-row>
</template>

<script>
import {queryShopServer,addShopServer} from '@/api/shop';
export default {
  name: "shopServer",
  data(){
    return{
      checkList:[
          '堂食'
      ],
      shopServer:{
        canReserve:'N'
      },
      serverTypeList:this.$lookupInfo('SHOP_SERVER')
    }
  },
  created() {
    queryShopServer(success=>{
      if(success.obj){
        this.shopServer = success.obj;
        if(!this.shopServer.shopPayServer){
          this.shopServer.shopPayServer='1';
        }
        if(!this.shopServer.shopCartServer){
          this.shopServer.shopCartServer='1';
        }
        if(this.shopServer.shopServer){
           this.checkList = this.shopServer.shopServer.split(',');
        }
      }
    })
  },
  mounted() {

  },
  methods:{
    openShopCanReserve:function (){
      this.initShopServer();
      addShopServer(this.shopServer,success=>{
        this.$successMessage('保存成功')
      })
    },
    savePreSell:function (){
      this.initShopServer();
      addShopServer(this.shopServer,success=>{
        this.$successMessage('保存成功')
      })
    },
    initShopServer:function (){
      this.shopServer.shopServer='';
         this.checkList.forEach((item,index)=>{
           if(index != (this.checkList.length-1)){
             this.shopServer.shopServer+=(item+',')
           }else{
             this.shopServer.shopServer+=item
           }
         })
    }
  }
}
</script>
<style>
.el-checkbox>.el-checkbox__label{
  display: inline;
}
</style>
<style scoped>

</style>