<template>
    <el-row>
        <el-dialog
                :title="baseMapInfo && baseMapInfo.baseMapId?'修改底图':'添加底图'"
                width="36%"
                :close-on-click-modal="false"
                :visible.sync="baseMapDialog"
                @close="cancelAddBaseMapInfo"
        >
            <el-row>
                <el-form ref="baseMapInfoForm" :rules="baseMapInfoRule" :model="baseMapInfoDetail" label-width="120px">
                    <el-form-item prop="baseMapName" label="底图名称">
                        <el-col :span="12">
                            <el-input v-model="baseMapInfoDetail.baseMapName" placeholder="请输入底图名称"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="baseMapName" label="底图名称">
                        <imageUpload v-bind:file="baseMapInfoDetail.attachmentUploadVo" v-bind:limit="1" v-bind:type="'image'"  @uploadSuccess="baseMapUploadSuccess"></imageUpload>
                    </el-form-item>
                    <el-form-item prop="photoTransversePosition" label="底图x坐标">
                        <el-col :span="12">
                            <el-input v-model="baseMapInfoDetail.photoTransversePosition" placeholder="请输入底图x坐标"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="photoVerticalPosition" label="底图y坐标">
                        <el-col :span="12">
                            <el-input v-model="baseMapInfoDetail.photoVerticalPosition" placeholder="请输入底图y坐标"></el-input>
                        </el-col>
                    </el-form-item>
                    <el-form-item prop="photoScaling" label="底图缩放比例">
                        <el-input  v-model="baseMapInfoDetail.photoScaling" placeholder="底图缩放比例"></el-input>
                    </el-form-item>
                </el-form>
            </el-row>
            <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddBaseMapInfo">取 消</el-button>
                    <el-button type="primary" @click="addBaseMap('baseMapInfoForm')">确 定</el-button>
            </span>
        </el-dialog>
    </el-row>
</template>

<script>
    import imageUpload from '@/components/image-upload';
    import {addBaseMap,updateBaseMap} from '@/api/baseMap';
    export default {
        name: "baseBaseMapInfo",
        props:['addBaseMapDialog','baseMapInfo'],
        data(){
            return{
                baseMapInfoDetail:{},
                baseMapInfoRule:{
                    baseMapName:[
                        { required: true, message: '底图名称不能为空', trigger: 'blur' },
                    ],
                    photoTransversePosition:[
                        { required: true, message: '底图x坐标不能为空', trigger: 'blur' },
                    ],
                    photoVerticalPosition:[
                        { required: true, message: '底图y坐标不能为空', trigger: 'blur' },
                    ],
                    photoScaling:[
                        { required: true, message: '底图缩放比例不能为空', trigger: 'blur' },
                    ]
                },
                baseMapDialog:false
            }
        },
        mounted(){
            this.baseMapDialog = this.addBaseMapDialog;
            if(this.baseMapInfo && this.baseMapInfo.baseMapId){
                this.baseMapInfoDetail = this.baseMapInfo;
            }
        },
        methods:{
            cancelAddBaseMapInfo:function () {
                this.$emit("cancelAddBaseMapInfo");
            },
            addBaseMap:function (formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if(this.baseMapInfoDetail.baseMapId){
                            updateBaseMap(this.baseMapInfoDetail,success=>{
                                this.$message({type:"success",message:"修改底图信息成功",duration:1500});
                                this.$emit("editBaseMapSuccess");
                            });
                        }else{
                            addBaseMap(this.baseMapInfoDetail,success=>{
                                this.$message({type:"success",message:"新增底图信息成功",duration:1500});
                                this.$emit("addBaseMapSuccess");
                            });
                        }
                    } else {
                        return false;
                    }
                });
            },
            baseMapUploadSuccess:function(imageList){
                if(imageList && imageList.length>0){
                    this.baseMapInfoDetail.photoPath
                        =imageList[0].attachmentObjectName;
                }else{
                    this.baseMapInfoDetail.photoPath = '';
                }
            },
        },
        components:{
            imageUpload
        }
    }
</script>

<style scoped>

</style>