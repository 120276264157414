<template>
  <el-row>
    <el-tabs v-model="shopConfig"   type="border-card" class="shop-config-tabs">
      <el-tab-pane label="基础信息" name="basicInfo">
         <basic-info v-if="shopConfig=='basicInfo'"></basic-info>
      </el-tab-pane>
      <el-tab-pane label="基础配置" name="basicConfig">
        <basic-config v-if="shopConfig=='basicConfig'"></basic-config>
      </el-tab-pane>
      <el-tab-pane label="预售信息" name="perSell">
        <per-sell v-if="shopConfig=='perSell'"></per-sell>
      </el-tab-pane>
      <el-tab-pane label="桌台配置" name="shopAddress">
        <address-list-page v-if="shopConfig=='shopAddress'"></address-list-page>
      </el-tab-pane>
      <el-tab-pane label="打印配置" name="printer">
        <printer v-if="shopConfig=='printer'"></printer>
      </el-tab-pane>
      <el-tab-pane label="服务配置" name="server">
        <shop-server v-if="shopConfig=='server'"></shop-server>
      </el-tab-pane>
    </el-tabs>
  </el-row>
</template>

<script>
import printer from "./printer";
import basicConfig from "./basicConfig";
import addressListPage from "./addressListPage";
import basicInfo from "@/shopViews/config/basicInfo";
import perSell from "@/shopViews/config/perSell";
import shopServer from "@/shopViews/config/shopServer";
export default {
  name: "config",
  data(){
    return{
      shopConfig:'basicInfo'
    }
  },
  components:{
    printer,
    basicConfig,
    addressListPage,
    basicInfo,
    perSell,
    shopServer
  }
}
</script>

<style scoped>

</style>