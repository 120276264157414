<template>
    <el-row>
        <el-cascader
                :options="regionData"
                v-model="selectedOptions"
                @change="handleChange"
                :clearable="true"
                separator=">"
                :placeholder="'请选择省>市>区县'"
        >
        </el-cascader>
    </el-row>
</template>

<script>
    import regionData from "@/util/regionData";
    export default {
        name: "city-selector",
        props:['initOptions'],
        data(){
            return {
                regionData,
                selectedOptions: [],
            }
        },
        mounted(){
          this.selectedOptions = this.initOptions;
        },
        methods: {
            handleChange() {
                this.$emit("selectedOptions",this.selectedOptions);
            },
        }
    }
</script>

<style scoped>

</style>