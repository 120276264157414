<template>
  <el-row>
    <el-row class="recharge-query-form">
      <el-form :inline="true" :model="rechargeCardDetailQueryParam" >
        <el-form-item label="是否已使用" label-width="100px">
          <el-select v-model="rechargeCardDetailQueryParam.isUse" placeholder="全部状态">
            <el-option
                v-for="item in isUseStatus"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="detailRechargeCardListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="recharge-detail-info-delete">
      <el-button plain type="danger" @click="failureRechargeCardDetail">失 效</el-button>
    </el-row>
    <el-row class="recharge-detail-info-table">
      <el-table
          ref="rechargeDetailTable"
          border
          :data="rechargeCardDetailList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
          @expand-change="showCurrentRechargeCard"
          @selection-change="rechargeCardSelectionChange"
      >
        <el-table-column
            type="selection"
            width="55"
            :selectable="checkSelectable"
        >
        </el-table-column>
        <el-table-column type="expand"  label="查看" width="60px">
          <template slot-scope="props">
            <el-form label-position="left" class="recharge-card-info">
              <el-form-item label="充值卡账号">
                <span>{{props.row.rechargeCardNo }}</span>
              </el-form-item>
              <el-form-item label="充值卡密码">
                <span>{{props.row.rechargeCardPwd }}</span>
              </el-form-item>
              <el-form-item label="充值卡金额">
                <span>{{props.row.rechargeCardAmount }}</span>
              </el-form-item>
            </el-form>
          </template>
        </el-table-column>
        <el-table-column prop="rechargeCardNo" label="充值卡编号"></el-table-column>
        <el-table-column prop="rechargeCardPwd" label="充值卡密码">
          <template>
            ********
          </template>
        </el-table-column>
        <el-table-column prop="rechargeCardAmount" label="充值卡金额"></el-table-column>
        <el-table-column prop="isUse" label="是否使用" :formatter="this.isUseFormat" ></el-table-column>
        <el-table-column prop="userName" label="使用人" ></el-table-column>
        <el-table-column prop="userPhone" label="使用人电话" ></el-table-column>
        <el-table-column prop="createdBy" label="创建者" ></el-table-column>
        <el-table-column prop="createdDate" label="创建时间"  :formatter="this.$formatTableCellDate" width="160px" ></el-table-column>
      </el-table>
    </el-row>
    <el-row class="recharge-detail-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
  </el-row>
</template>

<script>

import {queryRechargeCardPage, queryRechargeCardById, failureRechargeCardDetail} from "@/api/rechargeCard";

export default {
  name: "rechargeCardDetailListPage",
  props:{
    rechargeCardBatchId:{
      type:Number,
      default:0
    }
  },
  data(){
    return{
      rechargeCardDetailQueryParam:{},
      rechargeCardDetailList:[],
      rechargeSelectCardDetailList:[],
      isUseStatus:[
        {label:'未使用',value:'0'},
        {label:'已使用',value:'1'},
        {label:'已失效',value:'2'}
      ],
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      }
    }
  },
  mounted() {
      this.detailRechargeCardListPageQuery();
  },
  methods:{
    failureRechargeCardDetail:function (){
      this.$confirm('是否失效选中充值卡', '失效提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            failureRechargeCardDetail(this.rechargeSelectCardDetailList,success=>{
              this.rechargeCardDetailList.forEach(detail=>{
                this.rechargeSelectCardDetailList.forEach(item=>{
                    if(detail.rechargeCardId == item.rechargeCardId){
                      detail.isUse = '2';
                    }
                })
              });
              this.$refs.rechargeDetailTable.clearSelection();
              this.$message({type:"success",message:"失效成功",duration:1500});
            })
          })
          .catch(() => {

          })
    },
    rechargeCardSelectionChange:function (val){
      this.rechargeSelectCardDetailList = val;
    },
    checkSelectable:function (row,index){
        if(row.isUse == '0'){
          return true;
        }
        return false;
    },
    isUseFormat:function (row, column, cellValue, index){
      let label = '';
      this.isUseStatus.forEach(item=>{
           if(item.value == cellValue){
             label = item.label;
           }
      })
      return label;
    },
    showCurrentRechargeCard:function (rows,expandedRows){
      if(rows.rechargeCardPwd){
        return;
      }
      if(this.isExpanded(rows,expandedRows)){
          queryRechargeCardById({rechargeCardId:rows.rechargeCardId },success=>{
            this.rechargeCardDetailList.forEach(item=>{
               if(item.rechargeCardId == rows.rechargeCardId){
                 item.rechargeCardPwd = success.obj.rechargeCardPwd;
               }
            })
          });
      }
    },
    isExpanded:function (rows,expandedRows){
      let isExpanded = false;
      expandedRows.forEach(item=>{
         if(item.rechargeCardId == rows.rechargeCardId ){
           isExpanded = true;
         }
      })
      return isExpanded;
    },
    detailRechargeCardListPageQuery:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      this.rechargeCardDetailQueryParam.rechargeCardBatchId = this.rechargeCardBatchId;
      queryRechargeCardPage(this.page,this.rechargeCardDetailQueryParam,success=>{
        this.rechargeCardDetailList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function (){
      this.rechargeCardDetailQueryParam={};
      this.detailRechargeCardListPageQuery();
    },
    handleSizeChange:function (val){
        this.page.pageSize = val;
        this.detailRechargeCardListPageQuery();
    },
    handleCurrentChange:function (val){
        this.page.currentPage = val;
        this.detailRechargeCardListPageQuery(true);
    }
  }
}
</script>
<style>
.recharge-card-info label {
  width: 90px;
  color: #99a9bf;
}
.recharge-card-info .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
<style scoped>

.recharge-card-info{
  font-size: 0;
  padding: 8px;
}
.recharge-detail-info-table,.recharge-detail-table-page{
  margin-top: 8px;
}
</style>