import {_get,_post} from '../http/index';

//分页店铺特价商品列表
export const querySpecialGoodsSkuPage = (page,param,success,error) =>  _get("/market/shop/goodsSkuSpecial/querySpecialGoodsSkuPage/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加首单产品
export const insertSpecialGoodsSku = (param,success,error) =>  _post("/market/shop/goodsSkuSpecial/insertSpecialGoodsSku", param,success,error) ;
//刪除特价产品
export const deleteSpecialGoodsSku = (param,success,error) =>  _post("/market/shop/goodsSkuSpecial/deleteSpecialGoodsSku", param,success,error) ;
//修改特价产品
export const updateSpecialGoodsSku = (param,success,error) =>  _post("/market/shop/goodsSkuSpecial/updateSpecialGoodsSku", param,success,error) ;



