<template>
  <el-row>
    <el-dialog
        title="回复配置"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="configDialog"
        @close="cancelAddBackConfigInfo"
    >
      <el-row class="config-container">
        <el-form ref="configInfo"  :rules="configRule"  :model="configData" label-width="120px">

          <el-form-item prop="order" label="回复顺序">
            <el-col :span="12">
              <el-input-number type="number" :min="1" :max="1000" v-model="configData.order" placeholder="回复顺序"></el-input-number>
            </el-col>
          </el-form-item>
          <el-form-item prop="openReply" label="回复类型">
            <el-col :span="12">
              <el-select v-model="configData.type" clearable placeholder="请选择" @change="typeChange()">
                <el-option
                    v-for="item in configTypeList"
                    :key="item.itemCode"
                    :label="item.itemName"
                    :value="item.itemCode">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
          <el-form-item v-if="configData.type === 1"  label="文本内容">
            <el-col :span="18">
              <el-input type="textarea" v-model="configData.value" ></el-input>
            </el-col>
          </el-form-item>
          <el-form-item v-if="configData.type === 2" label="图片名称">
            <el-col :span="12">
              <el-input type="text" v-model="configData.value" readonly></el-input>
            </el-col>
          </el-form-item>
          <el-form-item  v-if="configData.type === 2" label="图片上传">
            <el-upload
                style="padding-right: 10px"
                :action="localUploadUrl"
                name="photo"
                :multiple="false"
                :limit="1"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="imageTypeCheck">
              <el-button icon="el-icon-upload2"  type="primary">图片上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="configData.type === 3"  label="文件名称">
            <el-col :span="12">
              <el-input type="text" v-model="configData.value" readonly></el-input>
            </el-col>
          </el-form-item>
          <el-form-item  v-if="configData.type === 3" prop="value" label="文件上传">
            <el-upload
                style="padding-right: 10px"
                :action="localUploadUrl"
                name="photo"
                :multiple="false"
                :limit="1"
                :show-file-list="false"
                :on-success="uploadSuccess"
                :before-upload="fileTypeCheck">
              <el-button icon="el-icon-upload2"  type="primary">文件上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item v-if="configData.type === 4" prop="value" label="转发消息id">
            <el-col :span="12">
              <el-input type="text" v-model="configData.value" readonly></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddBackConfigInfo">取 消</el-button>
                    <el-button type="primary" @click="submitBackConfig('configInfo')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {saveControlConfig,queryWxShare} from "@/api/wxHelperLocal";
import {localUrl} from "@/config/env";
export default {
  props:['addBackConfigDialog','backConfig'],
  mounted(){
    this.configDialog = this.addBackConfigDialog;
    this.configData = this.backConfig;
    if(!this.configData.type){
      this.configData.type = 1;
    }
  },
  data(){
    return {
      configRule:{
        wxId:[
          { required: true, message: '请先选择需要配置的微信', trigger: 'blur' },
        ],
        type :[
          { required: true, message: '请选择消息类型', trigger: 'blur' },
        ],
        order:[
          { required: true, message: '请填写发送顺序', trigger: 'blur' },
        ]
      },
      localUploadUrl:localUrl+"/photoSingleUpload",
      configTypeList:[
        {"itemCode":1,"itemName":"文本","select":true},
        {"itemCode":2,"itemName":"图片"},
        {"itemCode":3,"itemName":"文件"},
        {"itemCode":4,"itemName":"小程序转发"}
      ],
      configData:{},
      configDialog:false
    }
  },
  methods:{
    uploadSuccess:function(){
      this.$message({type:"success",message:"上传成功",duration:1500})
      this.$forceUpdate();
    },
    typeChange:function (){
      if(this.configData.type === 4){
        queryWxShare(this.configData.wxId,success=>{
          if(!success.obj){
            this.$errorMessage("请先发送一条小程序给自己账号");
          }else {
            this.configData.value = success.obj.messageId;
          }
          this.$forceUpdate();
        })
      }else{
        this.configData.value = "";
        this.$forceUpdate();
      }
    },
    fileTypeCheck:function (file){
      let fileName =  file.name;
      if(fileName.endsWith('.png') || fileName.endsWith('.jpg')){
        this.$message({type:"error",message:'图片文件请选择图片发送',duration:1500});
        return false;
      }
      this.configData.value = file.name;
      return  true;
    },
    imageTypeCheck:function (file) {
      let fileName =  file.name;
      if(fileName.endsWith('.png') || fileName.endsWith('.jpg')){
        this.configData.value = file.name;
        return true;
      }
      this.$message({type:"error",message:'图片只支持png和jpg',duration:1500});
      return  false;
    },
    cancelAddBackConfigInfo:function (){
      this.$emit("cancelAddBackConfigInfo");
    },
    submitBackConfig:function (formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveControlConfig(this.configData,success=>{
            this.$message({type:"success",message:"修改回复配置成功",duration:1500});
            this.$emit("submitBackConfig");
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>