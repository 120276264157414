<template>
    <el-row>
        <el-upload
                :action="processUploadUrl"
                :limit="imageLimit"
                list-type="picture-card"
                :show-file-list="true"
                :on-remove="handleRemove"
                :on-preview="handlePictureCardPreview"
                :on-success="uploadSuccess"
                :file-list="initImageList"
                :before-upload="fileTypeCheck">
            <i slot="default" class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </el-row>
</template>

<script>
    import {baseUrl} from "@/config/env";
    export default {
        name: "image-upload",
        props: ['fileList','file','limit','type'],
        data() {
            let url = this.initUrl();
            return {
                dialogImageUrl: '',
                dialogVisible: false,
                disabled: false,
                processUploadUrl:url,
                imageList:[],
                imageLimit:3,
                initImageList:[],
            };
        },
        mounted(){
          if(this.fileList && this.fileList.length>0){
              this.fileList.forEach(item=>{
                  let obj = new Object();
                  obj.name = item.attachmentName;
                  obj.url = item.downloadUrl;
                  obj.response = new Object();
                  obj.response.obj = item;
                  this.initImageList.push(obj);
                  this.imageList.push(item);
              })
          }
          if(this.file){
              let obj = new Object();
              obj.name = this.file.attachmentName;
              obj.url = this.file.downloadUrl;
              obj.response = new Object();
              obj.response.obj = this.file;
              this.initImageList.push(obj);
              this.imageList.push(this.file);
          }
          if(this.limit){
              this.imageLimit=this.limit;
          }
        },
        methods: {
            initUrl:function(){
                if(this.type=='image'){
                    return baseUrl+'/market/manager/attachment/imageSingleUpload';
                }
                return baseUrl+'/market/manager/attachment/fileSingleUpload';
            },
            handleRemove(file) {
                this.imageList.forEach((item,index)=>{
                    if(item.id == file.response.obj.id ){
                        this.imageList.splice(index,1)
                    }
                });
                this.$emit("uploadSuccess",this.imageList)
            },
            uploadSuccess:function(data){
                this.$message({type:"success",message:"上传成功",duration:1500});
                this.imageList.push(data.obj);
                this.$emit("uploadSuccess",this.imageList)
            },
            fileTypeCheck:function (file) {
                if(this.imageList.length>3){
                    this.$message({type:"error",message:'最多支持3张图片',duration:1500});
                    return false;
                }
                let fileName =  file.name;
                if(fileName.endsWith('.png')){
                    return true;
                }
                if(fileName.endsWith('.jpg')){
                    return true;
                }
                this.$message({type:"error",message:'图片格式错误,支持png,jpg',duration:1500});
                return  false;
            },
            handlePictureCardPreview(imageInfo) {
                this.dialogImageUrl = imageInfo.url;
                this.dialogVisible = true;
            }
        }
    }
</script>

<style scoped>

</style>