<template>
    <el-row>
        <el-col>
            <el-button @click="sendVerification()" v-if="isSendAlready == false" :loading="loading" round>获取验证码
            </el-button>
            <el-button v-if="isSendAlready == true" disabled round>重新发送({{countdownSecond}})</el-button>
        </el-col>
    </el-row>
</template>

<script>
    import {sendVerificationCode} from '@/api/login';
    import {check} from '@/util/check';

    export default {
        name: "sendVerificationCode",
        props: ['userPhone', 'getCodeUrl'],
        data() {
            return {
                loading: false,
                isSendAlready: false,
                countdownSecond: 0
            }
        },
        methods: {
            sendVerification() {
                let success = check.checkPhone(this.userPhone);
                if (success) {
                    this.loading = true;
                    sendVerificationCode(this.getCodeUrl, {"userPhone": this.userPhone}, success => {
                        this.isSendAlready = true;
                        this.loading = false;
                        this.$message.success("验证码已发送至你的手机，请注意查收");
                        this.countdownSecond =this.getCountDownSecond( success.obj);
                        this.countDown();
                    }, error => {
                        this.loading = false;
                        this.isSendAlready = true;
                        this.countdownSecond =this.getCountDownSecond( error.obj);
                        this.countDown();
                    })
                }
            },
            countDown() {
                let _this = this;
                setTimeout(
                    function() {
                        _this.countdownSecond =  _this.countdownSecond-1;
                        if(_this.countdownSecond>0){
                            _this.countDown();
                        }else{
                            _this.isSendAlready = false;
                        }
                    }
                    ,
                    1000);
            },
            getCountDownSecond(param) {
                if (param) {
                    return Number(param);
                }
                return 60;
            }
        }

    }
</script>

<style scoped>

</style>