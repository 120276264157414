
import {_get,_post} from '../http/index';
//账号密码登录
export const  loginByPassword = (params,success,error) =>  _post("/market/manager/userLogin/manager/loginByPassword", params,success,error) ;
//获取验证码图片
export const refreshImgVerificationCode = (type,param,success,error) => _get("/market/manager/userLogin/manager/refreshImgVerificationCode/"+type, param,success,error) ;
//发送短信
export const sendVerificationCode= (url,params,success,error) =>  _post(url, params,success,error) ;
//发送短信登录
export const loginByVerificationCode = (params,success,error) =>  _post("/market/manager/userLogin/manager/loginByVerificationCode", params,success,error) ;
//退出登录
export const userLoginOut = (success,error) =>  _post("/market/manager/userLogin/manager/userLoginOut", {},success,error) ;
