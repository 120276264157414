<template>
  <el-row class="app-container">
     <el-row class="vip-type-list">
        <el-row class="vip-card" v-for="item in packageList" :key="item.type" @click.native="selectVip(item)">
          <el-row class="current-vip-card" v-if="currentPackage && currentPackage.type === item.type">
            <el-row class="vip-name">{{item.typeName}}</el-row>
            <el-row class="need-code"><em>￥</em>{{item.needIcon}}</el-row>
          </el-row>
          <el-row v-else>
            <el-row class="vip-name">{{item.typeName}}</el-row>
            <el-row class="need-code"><em>￥</em>{{item.needIcon}}</el-row>
          </el-row>
        </el-row>
     </el-row>
    <el-row>
    <el-row>
      <el-row class="need-code"><span class="code-title">剩余金币:</span><em>￥</em>{{currentIcon.iconNumber}}</el-row>
    </el-row>
    <el-row class="vip-submit">
      <el-button type="primary" @click="openVip">会员延期</el-button>
    </el-row>
      <el-row>
        <user-icon-detail></user-icon-detail>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
  import {queryWxHelperPackage,openWxHelperPackage,queryMyIcon} from "@/api/wxHelper";
  import userIconDetail from "@/views/wxHelper/userIconDetail.vue";
  export default {
    data(){
      return {
        packageList:[],
        currentPackage:{},
        currentIcon:{}
      }
    },
    mounted() {
      queryWxHelperPackage(success=>{
        if(success.obj && success.obj.length>0){
          this.currentPackage = success.obj[0];
          this.packageList = success.obj;
        }
      })
      this.myIcon();
    },
    methods:{
      selectVip:function (item){
        this.currentPackage = item;
      },
      myIcon:function (){
        queryMyIcon(success=>{
          this.currentIcon = success.obj;
        })
      },
      openVip:function (){
        if(this.currentPackage.needIcon>this.currentIcon.iconNumber){
          this.$errorMessage("金币不足,请充值");
          return;
        }
        openWxHelperPackage(this.currentPackage,success=>{
          this.$successMessage("开卡成功");
        })
      }
    },
    components:{
      userIconDetail
    }
  }
</script>


<style scoped>
  .vip-type-list{
    display: flex;
    justify-content: center;
  }

  .vip-card{
    width: 20%;
    margin: 10px;
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #666;
    cursor: pointer;
  }
  .current-vip-card{
    background: rgba(20, 166, 228, 0.6);
    border-radius: 10px;
  }

  .vip-name{
    text-align: center;
    margin-top: 25px;
    display: inline-block;
    width: 100%;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
    color: #333333;
    font-weight: 700;
  }
  .need-code{
    text-align: center;
    display: block;
    font-size: 40px;
    font-family: Arial;
    font-weight: 700;
    color: #ebcb6d;
  }
  .vip-submit{
    text-align: center;
    margin-top: 30px;
  }
  .code-title{
    font-size: 20px;
    color: black;
  }
</style>