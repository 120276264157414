import {_get,_post} from '../http/index';
//查询角色组别列表
export const findGroupPageList = (page,param,success,error) =>  _get("/market/manager/group/findGroupPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除组别
export const deleteGroup = (param,success,error) => _post("/market/manager/group/deleteGroup", param,success,error) ;
//修改组别
export const updateGroup = (param,success,error) => _post("/market/manager/group/updateGroup", param,success,error) ;
//添加组别
export const addGroup = (param,success,error) => _post("/market/manager/group/insertGroup", param,success,error) ;
//查询组别用户分页列表
export const findGroupUserPageList = (page,param,success,error) =>  _get("/market/manager/group/findGroupUserPageByGroupId/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//查询待添加到组别的用户分页列表
export const findAddGroupUserPageList = (page,param,success,error) =>  _get("/market/manager/group/findUserPageByGroupId/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加组别用户
export const addGroupUser = (param,success,error) =>  _post("/market/manager/group/addGroupUser", param,success,error) ;
//批量保存组别信息
export const submitGroupUser = (param,success,error) =>  _post("/market/manager/group/updateGroupUserData", param,success,error) ;
//查询组别角色分页列表
export const findGroupRolePageList = (page,param,success,error) =>  _get("/market/manager/group/findGroupRolePageByGroupId/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//查询待添加组别的角色分页列表
export const findAddGroupRolePageList = (page,param,success,error) =>  _get("/market/manager/group/findRolePageByGroupId/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加组别角色
export const addGroupRole = (param,success,error) =>  _post("/market/manager/group/addGroupRole", param,success,error) ;
//删除组别角色
export const deleteGroupRole = (param,success,error) =>  _post("/market/manager/group/deleteGroupRole", param,success,error) ;



