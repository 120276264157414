<template>
    <el-row>
        <el-row class="app-query-form">
            <el-form :inline="true" :model="appQueryParam" >
                <el-form-item label="应用名" label-width="80px">
                    <el-input v-model="appQueryParam.appManagementName" placeholder="应用名"></el-input>
                </el-form-item>
                <el-form-item label="应用文根" label-width="80px">
                    <el-input v-model="appQueryParam.appContext" placeholder="应用文根"></el-input>
                </el-form-item>
                <el-form-item  >
                  <el-button icon="el-icon-search" type="primary" @click="appListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="app-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addAppInfoClick">添加</el-button>
        </el-row>
        <el-row class="app-info-table">
            <el-table
             border
             :data="appList"
             :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="appManagementName" label="应用名"></el-table-column>
                <el-table-column prop="appContext" label="应用文根"></el-table-column>
                <el-table-column prop="appPort" label="端口号"></el-table-column>
                <el-table-column prop="appEnable" label="是否启用">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.appEnable"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                @change="appEnableUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="appRemark" label="备注"></el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作">
                    <template slot-scope="props">
                        <el-button plain  type="warning" size="mini" @click="appInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain  type="danger" size="mini" @click="appInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="app-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <add-app-info v-if="addAppDialog == true" :addAppDialog="addAppDialog" :appInfo="currentAppInfo" @editAppSuccess="editAppSuccess" @cancelAddAppInfo="cancelAddAppInfo" @addAppSuccess="addAppSuccess"></add-app-info>
    </el-row>
</template>

<script>
    import {findAppInfoPageList,deleteAppInfo,updateAppState} from '@/api/app';
    import addAppInfo from "@/views/app/addAppInfo";
    export default {
        name: "appListPage",
        data(){
            return {
                page:{
                  pageSize:15,
                  currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                currentAppInfo:{},
                appQueryParam:{},
                appList:[],
                addAppDialog:false
            }
        },
        mounted(){
            this.appListPageQuery();
        },
        methods:{
            appEnableUpdate:function($event,props){
                let data = props.row;
                updateAppState(data,success=>{
                    this.$message({type:"success",message:"修改成功",duration:1500});
                })
            },
            editAppSuccess:function(){
                this.addAppDialog=false;
            },
            appInfoUpdate:function(appInfo){
                this.currentAppInfo = appInfo;
                this.addAppDialog=true;
            },
            addAppInfoClick:function(){
                this.currentAppInfo = {};
                this.addAppDialog=true;
            },
            addAppSuccess:function(){
                this.addAppDialog=false;
                this.queryReset();
            },
            cancelAddAppInfo:function(){
                this.addAppDialog=false;
            },
            appListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findAppInfoPageList(this.page,this.appQueryParam,success=>{
                    this.appList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            queryReset:function () {
                this.appQueryParam={};
                this.appListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.appListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.appListPageQuery(true);
            },
            appInfoDelete:function (appInfo) {
                this.$confirm('是否删除应用 <span style="color: red">'+appInfo.appManagementName+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteAppInfo(appInfo,success=>{
                            this.$message({type:"success",message:"删除成功",duration:1500});
                            this.appList.forEach((item,index)=>{
                              if(item.appId == appInfo.appId){
                                  this.appList.splice(index,1);
                              }
                            });
                        })
                    })
                    .catch(() => {

                    })
            }
        },
        components:{
            addAppInfo
        }
    }
</script>

<style scoped>
.app-info-table{
    margin-top: 8px;
}
.app-table-page{
        margin-top: 8px;
}
</style>