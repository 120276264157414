<template>
 <el-row>
   <el-dialog
       :title="classifyInfo && classifyInfo.classifyId?'修改应用':'添加应用'"
       width="36%"
       :close-on-click-modal="false"
       :visible.sync="classifyDialog"
       @close="cancelAddClassifyInfo"
   >
     <el-row>
       <el-form ref="classifyInfoForm" :rules="classifyInfoRule" :model="classifyInfoDetail" label-width="80px">
         <el-form-item prop="classifyName" label="分类名称">
           <el-col :span="12">
             <el-input v-model="classifyInfoDetail.classifyName" placeholder="请输入分类名称"></el-input>
           </el-col>
         </el-form-item>
       </el-form>
     </el-row>
     <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddClassifyInfo">取 消</el-button>
                    <el-button type="primary" @click="addClassify('classifyInfoForm')">确 定</el-button>
            </span>
   </el-dialog>
 </el-row>
</template>

<script>
import {addClassifyInfo} from "@/api/stockClassify";

export default {
  name: "stockClassifyInfo",
  props:['addClassifyDialog','classifyInfo'],
  data(){
    return{
      classifyInfoDetail:{},
      classifyInfoRule:{
        classifyName:[
          { required: true, message: '分类名称不能为空', trigger: 'blur' },
        ]
      },
      classifyDialog:false
    }
  },
  mounted(){
    this.classifyDialog = this.addClassifyDialog;
    if(this.classifyInfo && this.classifyInfo.classifyId){
      this.classifyInfoDetail = this.classifyInfo;
    }
  },
  methods:{
    cancelAddClassifyInfo:function () {
      this.$emit("cancelAddClassifyInfo");
    },
    addClassify:function (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
         addClassifyInfo(this.classifyInfoDetail,success=>{
           if(this.classifyInfoDetail.classifyId){
              this.$successMessage('修改分组信息成功');
           }else{
              this.$successMessage('新增分组信息成功');
           }
              this.$emit("editClassifySuccess");
           });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>