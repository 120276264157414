<template>
  <el-row>
    <el-row>
      <el-button type="primary" @click="addPrinterInfo">添加</el-button>
    </el-row>
    <el-row class="printer-info"  v-for="(item,index) in printerList " :key="item.id" >
      <el-form class="printer-info-form" label-width="100px" :model="item">
          <el-form-item   label="打印机类型">
            <el-col>
              <el-select v-model="item.printerType" placeholder="请选择">
                <el-option
                    v-for="item in printerTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </el-col>
          </el-form-item>
        <el-form-item   label="打印机名称">
          <el-input v-model="item.printerName" placeholder="打印机名称" readonly></el-input>
        </el-form-item>
          <el-form-item   label="打印机编号">
              <el-input v-model="item.printerNo" placeholder="打印机编号"></el-input>
          </el-form-item>
          <el-form-item   label="打印机备注">
              <el-input type="textarea" v-model="item.remark" placeholder="打印机备注"></el-input>
          </el-form-item>
          <el-form-item   label="打印机开关">
              <el-switch
                  @change="printerEnable(item)"
                  v-model="item.enable"
                  active-text="开启"
                  active-value="1"
                  inactive-value="0"
                  inactive-text="关闭">
              </el-switch>
          </el-form-item>
      </el-form>
      <el-row class="printer-activity">
        <el-col :span="5" >
          <el-button plain type="primary" @click="savePrinterInfo(item)">保存</el-button>
        </el-col>
        <el-col :span="5"  v-if="item.enable != '1'" :offset="1">
          <el-button plain  type="danger" @click="deletePrinterInfo(item,index)">删除</el-button>
        </el-col>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import {getPrinterList,addPrinter,updatePrinter,enablePrinter,deletePrinter} from '@/api/printer';
import {cache} from "../../store/store";

export default {
  name: "printer",
  data(){
     return{
       shopInfo:{},
       printerList:[{printerType:'xyy'}],
       printerTypeList:[{label:'芯烨云打印机',value:'xyy'}]
     }
  },
  mounted() {
    this.initPrinterList();
  },
  methods:{
    initPrinterName:function (printerInfo){
      this.shopInfo = cache.getMemoryCache("currentShopInfo");
      if(this.shopInfo ){
        printerInfo.printerName = this.shopInfo.shopName;
      }
    },
    initPrinterList:function (){
      getPrinterList(success=>{
        if(success.obj && success.obj.length>0){
          this.printerList = success.obj;
        }
      })
    },
    printerEnable:function (printerInfo){
      if(printerInfo.id){
        enablePrinter(printerInfo,success=>{
          this.printerList.forEach(item=>{
            if(item.id != printerInfo.id){
              item.enable = '0';
            }
          })
          this.$successMessage('修改成功');
        })
      }
    },
    savePrinterInfo:function (printerInfo){
      if(!printerInfo.id){
        this.initPrinterName(printerInfo);
        addPrinter(printerInfo,success=>{
          printerInfo.id = success.obj.id;
          this.$successMessage('保存成功');
        })
        return;
      }
      updatePrinter(printerInfo,success=>{
        this.$successMessage('修改成功');
      })
    },
    addPrinterInfo:function (){
      let item = {printerType:'xyy'};
      this.initPrinterName(item);
      this.printerList.push(item);
    },
    deletePrinterInfo:function (item,index){
      if(!item.id){
        this.printerList.splice(index,1);
        return;
      }
      this.$confirm('是否删除打印机 <span style="color: red">'+item.printerName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deletePrinter(item,success=>{
              this.$successMessage('删除成功');
              this.printerList.splice(index,1);
            })
          })
          .catch(() => {

          })
    }
  }
}
</script>

<style scoped>
.printer-info{
  display: flex;
  align-items: center;
}
.printer-info-form{
  width: 32vw;
  padding: 10px 16px 0px 0px;
  margin: 2px;
  border: 1px dashed #F60;
  border-radius: 6px;
}
.printer-activity{
  width: 20vw;
  padding-left: 5px;
}
</style>