<template>
  <el-row>
    <el-dialog
        title="'基础配置'"
        width="36%"
        :close-on-click-modal="false"
        :visible.sync="configDialog"
        @close="cancelAddConfigInfo"
    >
      <el-row class="config-container">
        <el-form ref="configInfo" :rules="configRule" :model="configData" label-width="120px">
          <el-form-item prop="openPass" label="自动通过开关">
            <el-col :span="12">
              <el-switch
                  v-model="configData.openPass"
                  inactive-value="off"
                  active-value="on"
                  active-color="#13ce66"
                  inactive-color="#d2d2d2">
              </el-switch>
            </el-col>
          </el-form-item>
          <el-form-item prop="passDelayTime" label="通过延时">
            <el-col :span="12">
              <el-input-number type="number" :min="1" :max="30" v-model="configData.passDelayTime" placeholder="通过延时"></el-input-number>
            </el-col>
          </el-form-item>
          <el-form-item prop="openReply" label="自动回复开关">
            <el-col :span="12">
              <el-switch
                  v-model="configData.openReply"
                  inactive-value="off"
                  active-value="on"
                  active-color="#13ce66"
                  inactive-color="#d2d2d2">
              </el-switch>
            </el-col>
          </el-form-item>
          <el-form-item prop="replyDelayTime" label="回复延时 ">
            <el-col :span="12">
              <el-input-number type="number" :min="1" :max="30" v-model="configData.replyDelayTime" placeholder="回复延时"></el-input-number>
            </el-col>
          </el-form-item>
          <el-form-item prop="maxPassCount" label="每日通过最大人数 ">
            <el-col :span="12">
              <el-input-number type="number" :min="1" :max="500" v-model="configData.maxPassCount" placeholder="每日通过最大人数"></el-input-number>
            </el-col>
          </el-form-item>
          <el-form-item prop="namePrefix" label="修改备注前缀 ">
            <el-col :span="12">
              <el-input type="text" v-model="configData.namePrefix" placeholder="修改备注前缀"></el-input>
            </el-col>
          </el-form-item>
        </el-form>
      </el-row>
      <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelAddConfigInfo">取 消</el-button>
                    <el-button type="primary" @click="submitConfig('configInfo')">确 定</el-button>
            </span>
    </el-dialog>
  </el-row>
</template>

<script>
import {saveConfig} from "@/api/wxHelperLocal";
export default {
  props:['addBaseConfigDialog','baseConfig'],
  mounted(){
    this.configDialog = this.addBaseConfigDialog;
    this.configData = this.baseConfig;
  },
  data(){
    return {
      configData:{},
      configRule:{
        openPass:[
          { required: true, message: '自动通过开关不能为空', trigger: 'blur' },
        ],
        passDelayTime:[
          { required: true, message: '通过延时不能为空', trigger: 'blur' },
        ],
        openReply:[
          { required: true, message: '自动回复开关不能为空', trigger: 'blur' },
        ],
        replyDelayTime:[
          { required: true, message: '自动回复延时不能为空', trigger: 'blur' },
        ],
        maxPassCount:[
          { required: true, message: '最大通过数不能为空', trigger: 'blur' },
        ]
      },
      configDialog:false
    }
  },
  methods:{
    cancelAddConfigInfo:function (){
      this.$emit("cancelAddConfigInfo");
    },
    submitConfig:function (formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveConfig(this.configData,success=>{
            this.$message({type:"success",message:"修改基础配置成功",duration:1500});
            this.$emit("submitConfig");
          });
        } else {
          return false;
        }
      });
    }
  }
}
</script>

<style scoped>

</style>