<template>
  <el-row>
    <el-row class="notice-query-form">
      <el-form :inline="true" :model="noticeQueryParam" >
        <el-form-item label="标题" label-width="80px">
          <el-input v-model="noticeQueryParam.noticeTitle" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item label="类型" label-width="80px">
          <el-select v-model="noticeQueryParam.noticeType" clearable placeholder="请选择">
            <el-option
                v-for="item in lookupList"
                :key="item.itemCode"
                :label="item.itemName"
                :value="item.itemCode">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="noticeListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="notice-info-add">
      <el-button type="primary" icon="el-icon-plus" @click="addNoticeInfoClick">添加</el-button>
    </el-row>
    <el-row class="notice-info-table">
      <el-table
          border
          :data="noticeList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="noticeType" label="公告类型"  :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'NOTICE_TYPE')"></el-table-column>
        <el-table-column prop="noticeTitle" label="公告标题" ></el-table-column>
        <el-table-column prop="noticeText" label="公告内容"></el-table-column>
        <el-table-column prop="noticeOrder" label="公告顺序"></el-table-column>
        <el-table-column prop="noticeEnable" label="是否启用">
          <template slot-scope="props">
            <el-switch
                v-model="props.row.noticeEnable"
                inactive-value="0"
                active-value="1"
                active-color="#13ce66"
                @change="noticeEnableUpdate($event,props)"
                inactive-color="#d2d2d2">
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column prop="createdBy" label="创建者"></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
        <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column  label="操作">
          <template slot-scope="props">
            <el-button plain  type="warning" size="mini" @click="noticeInfoUpdate(props.row)">编辑</el-button>
            <el-button plain v-if="props.row.noticeEnable == '0'"  type="danger" size="mini" @click="noticeInfoDelete(props.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="notice-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <add-notice-info v-if="addNoticeDialog == true" :addNoticeDialog="addNoticeDialog" :noticeInfo="currentNoticeInfo" @editNoticeSuccess="editNoticeSuccess" @cancelAddNoticeInfo="cancelAddNoticeInfo" @addNoticeSuccess="addNoticeSuccess"></add-notice-info>
  </el-row>
</template>

<script>
import {findNoticeInfoPageList,deleteNoticeInfo,updateNoticeState} from '@/api/notice';
import addNoticeInfo from "@/shopViews/notice/addNoticeInfo";
export default {
  name: "noticeListPage",
  data(){
    return {
      page:{
        pageSize:15,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
      currentNoticeInfo:{},
      noticeQueryParam:{},
      noticeList:[],
      lookupList:[],
      addNoticeDialog:false
    }
  },
  mounted(){
    this.lookupList =  this.$lookupInfo('NOTICE_TYPE')
    this.noticeListPageQuery();
  },
  methods:{
    noticeEnableUpdate:function($event,props){
      let data = props.row;
      updateNoticeState(data,success=>{
        this.$message({type:"success",message:"修改成功",duration:1500});
      })
    },
    editNoticeSuccess:function(){
      this.addNoticeDialog=false;
    },
    noticeInfoUpdate:function(noticeInfo){
      this.currentNoticeInfo = noticeInfo;
      this.addNoticeDialog=true;
    },
    addNoticeInfoClick:function(){
      this.currentNoticeInfo = {};
      this.addNoticeDialog=true;
    },
    addNoticeSuccess:function(){
      this.addNoticeDialog=false;
      this.queryReset();
    },
    cancelAddNoticeInfo:function(){
      this.addNoticeDialog=false;
    },
    noticeListPageQuery:function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      findNoticeInfoPageList(this.page,this.noticeQueryParam,success=>{
        this.noticeList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    queryReset:function () {
      this.noticeQueryParam={};
      this.page={
        pageSize:15,
        currentPage:1
      };
      this.noticeListPageQuery();
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.noticeListPageQuery();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.noticeListPageQuery(true);
    },
    noticeInfoDelete:function (noticeInfo) {
      this.$confirm('是否删除公告 <span style="color: red">'+noticeInfo.noticeTitle+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteNoticeInfo(noticeInfo,success=>{
              this.$message({type:"success",message:"删除成功",duration:1500});
              this.noticeList.forEach((item,index)=>{
                if(item.noticeId == noticeInfo.noticeId){
                  this.noticeList.splice(index,1);
                }
              });
            })
          })
          .catch(() => {

          })
    }
  },
  components:{
    addNoticeInfo
  }
}
</script>

<style scoped>
.notice-info-table{
  margin-top: 8px;
}
.notice-table-page{
  margin-top: 8px;
}
</style>