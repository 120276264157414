import {_get,_post} from '../http/index';
//查询数据范围
export const queryDataRangeList = (success,error) => _get("/market/manager/dataRange/queryDataRangeList", {},success,error) ;
//分页查询数据范围列表
export const findDataRangePageList = (page,param,success,error) => _get("/market/manager/dataRange/findDataRangePageList/"+page.pageSize+"/"+page.currentPage, param,success,error) ;
//删除数据范围
export const deleteDataRange = (param,success,error) => _post("/market/manager/dataRange/deleteDataRange", param,success,error) ;
//插入数据范围
export const insertDataRange = (param,success,error) => _post("/market/manager/dataRange/insertDataRange", param,success,error) ;
//修改数据范围
export const updateDataRange = (param,success,error) => _post("/market/manager/dataRange/updateDataRange", param,success,error) ;



