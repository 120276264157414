<template>
  <el-row>
    <el-row class="shop-query-form">
      <el-form :inline="true" :model="shopQueryParam" >
        <el-form-item label="店铺名称" label-width="100px">
          <el-input v-model="shopQueryParam.shopName" placeholder="店铺名称"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button icon="el-icon-search" type="primary" @click="shopListPageQuery">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="shop-info-add">
      <el-button type="primary" icon="el-icon-plus" @click="addShopInfo">添加</el-button>
    </el-row>
    <el-row class="shop-info-table">
      <el-table
          border
          :data="shopList"
          :header-cell-style="{background:'#F3F4F7',color:'#555'}"
      >
        <el-table-column prop="shopName" label="店铺名称" width="160px"></el-table-column>
        <el-table-column prop="shopProvince" label="地址" width="200px">
          <template slot-scope="props">
            {{ props.row.shopProvince }}>{{ props.row.shopCity }}>{{ props.row.shopCounties }}
          </template>
        </el-table-column>
        <el-table-column prop="shopAddressDetail" label="明细地址" width="200px"></el-table-column>
        <el-table-column prop="shopServiceHotLine" label="服务热线"></el-table-column>
        <el-table-column prop="shopType" label="店铺类型"
                         :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'SHOP_TYPE')"></el-table-column>
        <el-table-column prop="shopApproveStatus" label="审批状态"
                         :formatter="(row, column,cellValue) => this.$lookupFormatter(row, column,cellValue, 'SHOP_APPROVE_STATUS')"></el-table-column>
        <el-table-column prop="createdBy" label="创建者"></el-table-column>
        <el-table-column prop="createdDate" label="创建时间" width="160px"
                         :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column prop="lastUpdatedBy" label="最后更新人"></el-table-column>
        <el-table-column prop="lastUpdatedDate" label="最后更新时间" width="160px"
                         :formatter="this.$formatTableCellDate"></el-table-column>
        <el-table-column label="操作" width="240px">
          <template slot-scope="props">
            <el-button v-if="dataHaveOperation(props.row.operationCode,1)" plain size="mini" @click="operatorInfoDetail(props.row)">详情</el-button>
            <el-button v-if="dataHaveOperation(props.row.operationCode,2)" plain type="warning" size="mini" @click="editShopInfo(props.row)">
              编辑
            </el-button>
            <el-button v-if="dataHaveOperation(props.row.operationCode,4)" plain type="danger" size="mini" @click="deleteShop(props.row)">删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-row>
    <el-row class="shop-table-page">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-sizes="[10,15,30,50,100]"
          :page-size="page.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageInfo.count">
      </el-pagination>
    </el-row>
    <add-shop v-if="addShopInfoDialog == true" :addShopInfoDialog="addShopInfoDialog" :shopInfo="currentShop"
              @cancelShop="cancelAddShop" @updateShopSuccess="cancelAddShop"
              @addShopSuccess="addShopSuccess"></add-shop>
    <el-drawer
            style="margin: auto;width: 70%;height:80%;"
            title="店铺详情"
            :visible.sync="showShopDetail"
            direction="ttb"
            size="100%">
      <el-row style="margin-left: 20px;overflow-x: hidden">
        <shop-detail v-if="showShopDetail" v-bind:shopQuery="currentShop"></shop-detail>
      </el-row>
    </el-drawer>
  </el-row>
</template>

<script>
import {findShopPageList, deleteShopInfo} from '@/api/shop'
import addShop from "@/views/shop/addShop";
import shopDetail from "@/views/shop/shopDetail";

export default {
  name: "shopListPage",
  data() {
    return {
      shopQueryParam: {
        shopName: ''
      },
      showShopDetail:false,
      shopList: [],
      page: {
        pageSize: 10,
        currentPage: 1
      },
      pageInfo: {
        count: 0,
        totalPage: 0
      },
      currentShop: {},
      addShopInfoDialog: false
    }
  },
  mounted() {
    this.shopListPageQuery();
  },
  methods: {
    addShopInfo: function () {
      this.addShopInfoDialog = true;
    },
    operatorInfoDetail:function(data){
      this.showShopDetail = true;
      this.currentShop = data;
    },
    editShopInfo:function (data){
      this.addShopInfoDialog = true;
      this.currentShop = data;
    },
    addShopSuccess: function () {
      this.addShopInfoDialog = false;
      this.queryReset();
    },
    cancelAddShop: function () {
      this.addShopInfoDialog = false;
    },
    shopListPageQuery: function (notResetCurrentPage) {
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      findShopPageList(this.page, this.shopQueryParam, success => {
        this.shopList = success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    handleSizeChange: function (val) {
      this.page.pageSize = val;
      this.shopListPageQuery();
    },
    handleCurrentChange: function (val) {
      this.page.currentPage = val;
      this.shopListPageQuery(true);
    },
    queryReset: function () {
      this.shopQueryParam = {};
      this.page = {
        pageSize: 10,
        currentPage: 1
      };
      this.shopListPageQuery();
    },
    deleteShop: function (info) {
      this.$confirm('是否删除门店 <span style="color: red;"> ' + info.shopName + ' </span>', '删除门店', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        dangerouslyUseHTMLString: true,
        type: 'warning'
      })
          .then(() => {
            deleteShopInfo(info, success => {
              this.$successMessage("删除成功");
              this.shopList.forEach((item, index) => {
                if (item.shopId == info.shopId) {
                  this.shopList.splice(index, 1);
                }
              })
            });
          })
          .catch(() => {
          })
    },
    dataHaveOperation: function (haveCode,needCode) {
      let code = needCode & haveCode;
      return code == needCode;
    }
  },
  components: {addShop,shopDetail}
}
</script>

<style scoped>
.shop-info-table {
  margin-top: 8px;
}

.shop-table-page {
  margin-top: 8px;
}
</style>