<template>
  <el-row>
    <el-row>
      <el-form ref="approveForm" :model="approveInfo" :rules="rules" size="medium" label-width="100px">
        <el-form-item label="审批意见" prop="field101">
          <template>
            <el-radio v-model="approveInfo.opinion" label="pass">同意</el-radio>
            <el-radio v-model="approveInfo.opinion" label="reject" >驳回</el-radio>
          </template>
        </el-form-item>
        <el-form-item label="备注信息" label-width="100px">
          <el-col span="12">
            <el-input type="textarea" v-model="approveInfo.remark" size="small" ></el-input>
          </el-col>
        </el-form-item>
        <el-form-item size="large">
          <el-button type="primary" @click="submitApprove">提交审批</el-button>
        </el-form-item>
      </el-form>
    </el-row>
  </el-row>
</template>

<script>
  import {approveTask} from '@/api/pending';
export default {
  name: "approve",
  props:['approveDetail'],
  data(){
    return{
      approveInfo:{
        opinion:'pass',
        remark:''
      },
      rules: {
        opinion: [
          {required: true, message: '审批意见不能为空', trigger: 'blur'},
        ]
      }
    }
  },
  methods:{
    submitApprove(){
      this.approveDetail.opinion = this.approveInfo.opinion;
      this.approveDetail.remark = this.approveInfo.remark;
      approveTask(this.getModule(this.approveDetail.modelKey),this.approveDetail,success=>{
          this.$successMessage("审批成功");
          this.$emit("approveSuccess")
      })
    },
    getModule:function (modelKey) {
      if(['shop_quit_process','shop_enter_process','goods_publish_process','withdrawal_process','goods_out_of_stock_process','purchase_order_enter_process','coupon_hand_out_process','apply_publish_process'].indexOf(modelKey) !== -1){
        return 'shop';
      }
      return 'manager'
    }
  }
}
</script>

<style scoped>

</style>