<template>
    <el-row class="goods-query-form">
      <el-form :inline="true" :model="queryGoodsParam" >
        <el-form-item label="商品名称" label-width="80px">
          <el-input v-model="queryGoodsParam.goodsName" placeholder="商品名称"></el-input>
        </el-form-item>
        <el-form-item label="发布状态" label-width="80px">
          <el-select v-model="queryGoodsParam.publishStatus" clearable placeholder="请选择">
            <el-option
                v-for="item in publishStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="品牌名称" label-width="80px">
          <el-select v-model="queryGoodsParam.brandId" clearable placeholder="请选择">
            <el-option
                v-for="item in brandList"
                :key="item.brandId"
                :label="item.brandName"
                :value="item.brandId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="类目名称" label-width="80px">
          <select-tree  ref="selectCategoryTree" placeholder="请选择类目"
                       :tree-data="goodsCategoryTree" :expandAll="true" :nodeConfig="{value:'categoryId',label:'categoryName'}"
          @changeChecked="goodsCategoryTreeChecked"></select-tree>
        </el-form-item>
        <el-form-item  >
          <el-button icon="el-icon-search" type="primary" @click="queryGoodsPageList">查询</el-button>
          <el-button @click="queryReset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-row class="goods-info-add">
        <el-button type="primary" icon="el-icon-plus" @click="addGoodsInfoClick">添 加</el-button>
        <el-button type="warning" icon="el-icon-check" @click="allocationToShop">分配门店</el-button>
      </el-row>
      <el-row class="goods-info-table">
        <el-table
                border
                :data="goodsList"
                :header-cell-style="{background:'#F3F4F7',color:'#555'}"
                @selection-change="goodsSelectionChange"
        >
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column prop="goodsMainPhotoPath" label="商品图片" width="120px">
            <template slot-scope="props">
              <el-image class="goods-main-image" fit="scale-down"  :src="props.row.goodsMainPhotoPath"></el-image>
            </template>
          </el-table-column>
          <el-table-column prop="goodsName" label="商品名称"></el-table-column>
          <el-table-column prop="goodsSubTitle" label="商品附属标题"></el-table-column>
          <el-table-column prop="categoryName" label="类目名称"></el-table-column>
          <el-table-column prop="brandName" label="品牌名称"></el-table-column>
          <el-table-column prop="goodsRemark" label="备注" ></el-table-column>
          <el-table-column  label="操作">
            <template slot-scope="props">
              <el-button plain  type="primary" size="mini" @click="editGoodsInfo(props.row)">编辑</el-button>
              <el-button plain  type="danger" size="mini" @click="goodsDelete(props.row)">删除</el-button>
              <el-button plain  type="primary" size="mini" @click="cancelGoodsShopAllocation(props.row)">取消门店</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row class="goods-table-page">
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="page.currentPage"
                :page-sizes="[10,15,30,50,100]"
                :page-size="page.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="pageInfo.count">
        </el-pagination>
      </el-row>
      <el-drawer
          :title="currentGoodsInfo.goodsId?editGoods?'编辑产品':'产品详情':'添加产品'"
          :visible.sync="addGoodsDrawer"
          direction="ttb"
          size="100%">
        <el-row style="margin-left: 20px;overflow-x: hidden">
          <goods-info v-if="this.addGoodsDrawer" :brand-list="brandList"
                      :goods-category-tree="goodsCategoryTree"
                      :photo-category-tree="photoCategoryTree"
                      :goods-info="currentGoodsInfo"
                      @closeGoodsInfo="closeGoodsInfo"></goods-info>
        </el-row>
      </el-drawer>
      <goods-shop-list-page v-if="goodsShopDialog == true"  :goodsShopDialog="goodsShopDialog" :type="type"
                            :shopQuery="shopQuery" @cancelGoodsShopList="cancelGoodsShopList" @checkGoodsShopList="checkGoodsShopList"></goods-shop-list-page>
    </el-row>
</template>

<script>
import {findGoodsBrand} from "@/api/brand";
import {findGoodsCategoryTreeList} from '@/api/goodsCategory';
import {findGoodsPageList,deleteGoods,allocationGoodsToShop,findGoodsDetailByEdit} from '@/api/goods';
import {findPhotoCategoryList} from '@/api/photoCategory'
import {deleteStockProduct} from '@/api/stock'
import SelectTree from "../../components/select-tree";
import goodsInfo from "@/views/goods/goodsInfo";
import goodsShopListPage from "@/views/goods/goodsShopListPage";
export default {
  name: "goodsListPage",
  data(){
    return {
      queryGoodsParam:{},
      publishStatusList:[
        {value:"0",label:"未发布"},
        {value:"1",label:"已发布"}
      ],
      currentGoodsInfo:{},
      editGoods:false,
      addGoodsDrawer:false,
      goodsShopDialog:false,
      type:'1',
      shopQuery:{},
      goodsList:[],
      goodsCategoryTree:[],
      photoCategoryTree:[],
      brandList:[],
      goodsSelectList:[],
      page:{
        pageSize:10,
        currentPage:1
      },
      pageInfo:{
        count:0,
        totalPage:0
      },
    }
  },
  mounted() {
    this.initGoodsBrand();
    this.initGoodsCategoryTree();
    this.initPhotoCategoryTree();
    this.queryGoodsPageList();
  },
  methods:{
    allocationToShop:function (){
      this.shopQuery.shopType='2';
      this.shopQuery.shopApproveStatus='800';
      this.type='1';
      this.shopQuery.goodsId=null;
      if(this.goodsSelectList && this.goodsSelectList.length>0){
        this.goodsShopDialog=true;
        return;
      }
      this.$errorMessage('请选择商品');
    },
    cancelGoodsShopAllocation:function (data){
      this.shopQuery.shopType='2';
      this.type='2';
      this.shopQuery.shopApproveStatus='800';
      this.shopQuery.goodsId=data.goodsId;
      this.goodsShopDialog=true;
    },
    cancelGoodsShopList:function (){
      this.goodsShopDialog=false;
    },
    goodsSelectionChange:function (val){
      this.goodsSelectList = val;
    },
    checkGoodsShopList:function (val){
      this.goodsShopDialog=false;
      if(val.length == 0){
        this.$warningMessage('没有分配店铺');
        return;
      }
      let shopIdList = [];
      val.forEach(item=>{
        shopIdList.push(item.shopId);
      });
      if(this.type=='1'){
        //分配门店
        let param = new Object();
        param.shopIdList = shopIdList;
        param.goodsInfoVoList = this.goodsSelectList;
        allocationGoodsToShop(param,success=>{
          this.$successMessage('分配成功')
        })
        return;
      }
      if(this.type == '2'){
        //取消分配
        let paramArray = []
        findGoodsDetailByEdit({goodsId:this.shopQuery.goodsId},success=>{
          if(success.goodsSkuVoList && success.goodsSkuVoList.length>0){
            success.goodsSkuVoList.forEach(item=>{
              shopIdList.forEach(shopItem=>{
                let param = new Object();
                param.skuId = item.skuId;
                param.shopId = shopItem;
                paramArray.push(param);
              })
            })
            deleteStockProduct(paramArray,success=>{
              this.$successMessage('取消分配成功')
            });
          }
        });
      }
      //取消分配门店
    },
    initPhotoCategoryTree:function (){
      findPhotoCategoryList(success=>{
        this.photoCategoryTree = success;
      });
    },
    editGoodsInfo:function (data){
      this.editGoods=true;
      this.addGoodsDrawer=true;
      this.currentGoodsInfo=data;
    },
    goodsInfoDetail:function (data){
      this.addGoodsDrawer=true;
      this.editGoods=false;
      this.currentGoodsInfo=data;

    },
    addGoodsInfoClick:function(){
      this.editGoods=true;
      this.addGoodsDrawer=true;
      this.currentGoodsInfo={};
    },
    closeGoodsInfo:function (data){
      this.addGoodsDrawer=false;
      this.editGoods=false;
      this.currentGoodsInfo={};
      if(data == 'save'){
        this.queryGoodsPageList();
      }
    },
    goodsDelete:function (data){
      this.$confirm('是否删除商品 <span style="color: red">'+data.goodsName+'</span>', '删除提示', {
        dangerouslyUseHTMLString:true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
          .then(() => {
            deleteGoods({goodsId:data.goodsId},success=>{
              this.$message({type:"success",message:"删除成功",duration:1500});
              this.goodsList.forEach((item,index)=>{
                if(item.goodsId == data.goodsId){
                  this.goodsList.splice(index,1);
                }
              });
            })
          })
          .catch(() => {

          })
    },
    queryGoodsPageList:function (notResetCurrentPage){
      if(!notResetCurrentPage){
        this.page.currentPage=1;
      }
      findGoodsPageList(this.page,this.queryGoodsParam,success=>{
        this.goodsList=success.data;
        this.pageInfo.count = success.count;
        this.pageInfo.totalPage = success.totalPage;
      });
    },
    goodsCategoryTreeChecked:function (checkList){
      if(checkList.length==0){
        this.queryGoodsParam.categoryIds=null;
        return;
      }
      this.queryGoodsParam.categoryIds=this.getIdStrings(checkList);
    },
    getIdStrings:function(checkList){
      let Ids = '';
      if(checkList && checkList.length>0){
        checkList.forEach(item=>{
          Ids=Ids+item+',';
        });
      }
      return Ids;
    },
    queryReset:function (){
      this.queryGoodsParam={};
      this.$refs.selectCategoryTree.clear();
      this.queryGoodsPageList();
    },
    initGoodsCategoryTree:function (){
      findGoodsCategoryTreeList(success=>{
        this.goodsCategoryTree = success;
      })
    },
    initGoodsBrand:function (){
      findGoodsBrand(success=>{
        this.brandList=success;
      })
    },
    handleSizeChange:function(val){
      this.page.pageSize = val;
      this.queryGoodsPageList();
    },
    handleCurrentChange:function(val){
      this.page.currentPage = val;
      this.queryGoodsPageList(true);
    }
  },
  components:{
    SelectTree,
    goodsInfo,
    goodsShopListPage
  }
}
</script>

<style scoped>
  .goods-main-image{
    width: 100px;
    height: 100px;
    border-radius: 6px
  }
  .goods-info-table{
    margin-top: 8px;
  }
  .goods-table-page{
    margin-top: 8px;
  }
</style>