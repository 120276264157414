import {_get,_post} from '../http/index';
//查询底图列表
export const  findBaseMapInfoPageList = (page,param,success,error) => _get("/market/manager/baseMap/findBaseMapPageList/"+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加底图
export const  addBaseMap  = (param,success,error) => _post("/market/manager/baseMap/addBaseMapConfiguration", param,success,error) ;
//修改底图
export const  updateBaseMap  = (param,success,error) => _post("/market/manager/baseMap/updateBaseMapConfiguration", param,success,error) ;
//设置默认底图
export const  updateIsDefaultMap  = (param,success,error) => _get("/market/manager/baseMap/updateIsDefaultMap", param,success,error) ;
//删除底图
export const  deleteBaseMapInfo  = (param,success,error) => _post("/market/manager/baseMap/deleteBaseMapConfiguration", param,success,error) ;