<template>
  <div id="app">
    <router-view v-if="loadComplete"></router-view>
  </div>
</template>

<script>

import {findCacheLookupConfig} from '@/api/lookup';
import {cache} from "@/store/store";
export default {
  name: 'App',
  data(){
    return{
      loadComplete:false
    }
  },
  mounted() {
     if(!this.isLoginHtml()){
       findCacheLookupConfig(success=>{
         cache.addMemoryCache("lookupInfo",success);
         this.loadComplete = true;
       },error=>{
         this.loadComplete = true;
       });
       return;
     }
    this.loadComplete = true;
  },
  methods:{
    isLoginHtml:function (){
      if(this.$route.path == '/login'){
        return true;
      }
      if(this.$route.path == '/shareStatistics'){
        return true;
      }
      return false;
    }
  },
  components: {

  }
}
</script>


<style>
  html,
  body {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: 0px;
    margin: 0px;
  }
  #app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    widows: 100%;
    height: 100%;
  }
  @charset "utf-8";
  ::-webkit-scrollbar
  {
    width: 5px;
  }

  /*定义滚动条轨道 内阴影+圆角*/
  ::-webkit-scrollbar-track
  {
    border-radius: 10px;
    background-color: rgba(0,0,0,0.1);
  }

  /*定义滑块 内阴影+圆角*/
  ::-webkit-scrollbar-thumb
  {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
    background-color: rgba(0,0,0,0.1);
  }
</style>