<template>
    <el-row>
        <el-row class="base-query-form">
            <el-form :inline="true" :model="baseMapQueryParam" >
                <el-form-item label="底图名称" label-width="80px">
                    <el-input v-model="baseMapQueryParam.baseMapName" placeholder="底图名称"></el-input>
                </el-form-item>
                <el-form-item  >
                    <el-button icon="el-icon-search" type="primary" @click="baseMapListPageQuery">查询</el-button>
                    <el-button @click="queryReset">重置</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row class="base-info-add">
            <el-button type="primary" icon="el-icon-plus" @click="addBaseMapInfoClick">添加</el-button>
        </el-row>
        <el-row class="base-info-table">
            <el-table
                    border
                    :data="baseMapList"
                    :header-cell-style="{background:'#F3F4F7',color:'#555'}"
            >
                <el-table-column prop="baseMapName" label="底图名称"></el-table-column>
                <el-table-column prop="downloadUrl" label="底图图片">
                    <template slot-scope="props">
                        <el-image :src="props.row.downloadUrl"></el-image>
                    </template>
                </el-table-column>
                <el-table-column prop="photoTransversePosition" label="底图x坐标"></el-table-column>
                <el-table-column prop="photoVerticalPosition" label="底图y坐标"></el-table-column>
                <el-table-column prop="photoScaling" label="底图缩放比例"></el-table-column>
                <el-table-column prop="isDefaultMap" label="是否默认底图">
                    <template slot-scope="props">
                        <el-switch
                                v-model="props.row.isDefaultMap"
                                inactive-value="0"
                                active-value="1"
                                active-color="#13ce66"
                                @change="baseMapEnableUpdate($event,props)"
                                inactive-color="#d2d2d2">
                        </el-switch>
                    </template>
                </el-table-column>
                <el-table-column prop="createdBy" label="创建者"></el-table-column>
                <el-table-column prop="createdDate" label="创建时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column prop="lastUpdatedBy" label="最后更新人" ></el-table-column>
                <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate"></el-table-column>
                <el-table-column  label="操作">
                    <template slot-scope="props">
                        <el-button plain  type="warning" size="mini" @click="baseMapInfoUpdate(props.row)">编辑</el-button>
                        <el-button plain  type="danger" size="mini" @click="baseMapInfoDelete(props.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
        <el-row class="base-table-page">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="page.currentPage"
                    :page-sizes="[10,15,30,50,100]"
                    :page-size="page.pageSize"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="pageInfo.count">
            </el-pagination>
        </el-row>
        <base-map-info v-if="addBaseMapDialog == true" :addBaseMapDialog="addBaseMapDialog" :baseMapInfo="currentBaseMapInfo" @editBaseMapSuccess="editBaseMapSuccess" @cancelAddBaseMapInfo="cancelAddBaseMapInfo" @addBaseMapSuccess="addBaseMapSuccess"></base-map-info>
    </el-row>
</template>

<script>
    import {findBaseMapInfoPageList,deleteBaseMapInfo,updateIsDefaultMap} from '@/api/baseMap';
   import baseMapInfo from "@/views/baseMap/baseMapInfo";
    export default {
        name: "baseMapListPage",
        data(){
            return {
                page:{
                    pageSize:15,
                    currentPage:1
                },
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                currentBaseMapInfo:{},
                baseMapQueryParam:{},
                baseMapList:[],
                addBaseMapDialog:false
            }
        },
        mounted(){
            this.baseMapListPageQuery();
        },
        methods:{
            baseMapEnableUpdate:function($event,props){
                let data = props.row;
                updateIsDefaultMap({baseMapId:data.baseMapId,isDefaultMap:data.isDefaultMap},success=>{
                    this.baseMapListPageQuery();
                    this.$message({type:"success",message:"设置成功",duration:1500});
                })
            },
            editBaseMapSuccess:function(){
                this.addBaseMapDialog=false;
                this.queryReset();
            },
            baseMapInfoUpdate:function(baseMapInfo){
                this.currentBaseMapInfo = baseMapInfo;
                this.addBaseMapDialog=true;
            },
            addBaseMapInfoClick:function(){
                this.currentBaseMapInfo = {};
                this.addBaseMapDialog=true;
            },
            addBaseMapSuccess:function(){
                this.addBaseMapDialog=false;
                this.queryReset();
            },
            cancelAddBaseMapInfo:function(){
                this.addBaseMapDialog=false;
            },
            baseMapListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                findBaseMapInfoPageList(this.page,this.baseMapQueryParam,success=>{
                    this.baseMapList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            queryReset:function () {
                this.baseMapQueryParam={};
                this.page={
                    pageSize:15,
                    currentPage:1
                };
                this.baseMapListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.baseMapListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.baseMapListPageQuery(true);
            },
            baseMapInfoDelete:function (baseMapInfo) {
                this.$confirm('是否删除底图 <span style="color: red">'+baseMapInfo.baseMapName+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteBaseMapInfo(baseMapInfo,success=>{
                            this.$message({type:"success",message:"删除成功",duration:1500});
                            this.baseMapList.forEach((item,index)=>{
                                if(item.baseMapId == baseMapInfo.baseMapId){
                                    this.baseMapList.splice(index,1);
                                }
                            });
                        })
                    })
                    .catch(() => {

                    })
            }
        },
        components:{
            baseMapInfo
        }
    }
</script>

<style scoped>
    .base-info-table{
        margin-top: 8px;
    }
    .base-table-page{
        margin-top: 8px;
    }
</style>