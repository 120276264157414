import {_get, _post} from "@/http";

//查询用户基本信息
export const getCurrentUser = (success,error) => _get("/market/manager/userLogin/manager/currentUser",{},success,error) ;
//查询用户详情
export const findUserInfo = (param,success,error) => _get("/market/manager/user/manager/findUser",param,success,error) ;
//查询用户权限列表
export const findUserRoleList = (param,success,error) => _get("/market/manager/user/manager/queryUserRoles",param,success,error) ;
//修改用户信息
export const updateUserInfo =(param,success,error) =>  _post("/market/manager/user/manager/editUser", param,success,error) ;
//校验短信验证码
export const checkVerificationCode =(param,success,error) =>  _post('/market/manager/user/setPassword/checkVerificationCode/'+param.sendVerificationCode, param,success,error) ;
//修改密码
export const updateUserPassword =(param,success,error) =>  _post('/market/manager/user/manager/updatePwdByVerificationCode', param,success,error) ;
//查询系统用户列表
export const findSystemUserPage =(page,param,success,error) =>  _get('/market/manager/user/manager/findUserPage/'+page.pageSize+'/'+page.currentPage, param,success,error) ;
//查询会员用户
export const queryMemberPage =(page,param,success,error) =>  _get('/market/shop/member/querySysMemberPage/'+page.pageSize+'/'+page.currentPage, param,success,error) ;
//删除系统用户
export const deleteSystemUser =(param,success,error) =>  _get('/market/manager/user/manager/deleteUser', param,success,error) ;
//添加用户
export const addSystemUser =(param,success,error) =>  _post('/market/manager/user/manager/addUser', param,success,error) ;
//查询用户角色列表
export const queryUserRoles =(param,success,error) =>  _get('/market/manager/user/manager/queryUserRoles', param,success,error) ;
//查询系统用户未绑定用户列表
export const  queryUnboundRoleList =(param,success,error) =>  _get('/market/manager/user/manager/queryUnboundRoleList', param,success,error) ;
//保存用户角色权限
export const  updateUserRoles =(param,success,error) =>  _post('/market/manager/user/manager/updateUserRoles', param,success,error) ;
//查询使用微信计数用户列表
export const findWxHelperUserPage =(page,param,success,error) =>  _get('/market/manager/user/manager/findWxHelperUserPage/'+page.pageSize+'/'+page.currentPage, param,success,error) ;
//添加用户
export const addWxHelperUser =(param,success,error) =>  _post('/market/manager/user/manager/addWxHelperUser', param,success,error) ;

