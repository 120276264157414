<template>
        <el-row>
            <el-row class="user-list-header">
                <template>
                    <el-tabs v-model="defaultUser" type="border-card" @tab-click="handleClick">
                        <el-tab-pane label="系统用户" name="systemUser">
                            <el-row>
                                <el-form :inline="true" :model="userQueryParam" >
                                    <el-form-item label="姓名" label-width="80px">
                                        <el-input v-model="userQueryParam.userRealName" placeholder="姓名"></el-input>
                                    </el-form-item>
                                    <el-form-item label="账号" label-width="80px">
                                        <el-input v-model="userQueryParam.userAccount" placeholder="账号"></el-input>
                                    </el-form-item>
                                    <el-form-item label="手机号" label-width="80px">
                                        <el-input v-model="userQueryParam.userPhone" placeholder="手机号"></el-input>
                                    </el-form-item>
                                    <el-form-item  >
                                        <el-button icon="el-icon-search" type="primary" @click="systemUserQuery">查询</el-button>
                                        <el-button @click="queryReset">重置</el-button>
                                    </el-form-item>
                                </el-form>
                            </el-row>
                            <el-row>
                               <addUser :user-type="'shop'" @addUserSuccess="addUserSuccess"></addUser>
                            </el-row>
                            <el-row class="user-table">
                                <el-table :data="systemUserList" border  :header-cell-style="{background:'#F3F4F7',color:'#555'}">
                                    <el-table-column prop="userAccount" label="账号" ></el-table-column>
                                    <el-table-column prop="userName" label="昵称" ></el-table-column>
                                    <el-table-column prop="userRealName" label="姓名" ></el-table-column>
                                    <el-table-column prop="userSex" label="性别" :formatter="formatterSex"></el-table-column>
                                    <el-table-column prop="userCardCipher" label="身份证号" ></el-table-column>
                                    <el-table-column prop="userPhoneCipher" label="手机号" ></el-table-column>
                                    <el-table-column prop="createdBy" label="创建人" ></el-table-column>
                                    <el-table-column prop="createdDate" label="创建时间"  :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                                    <el-table-column prop="lastUpdatedBy" label="最后更新人"   ></el-table-column>
                                    <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                                    <el-table-column prop="userRemark" label="备注" min-width="126px"></el-table-column>
                                    <el-table-column  label="操作" min-width="150px">
                                        <template slot-scope="props">
                                            <el-button size="mini" type="warning" @click="viewCurrentUserRole(props.row)" plain>角色</el-button>
                                            <el-button size="mini" type="primary" @click="viewCurrentUser(props.row)" plain>查看</el-button>
                                            <el-button @click="deleteSystemUser(props.row)" size="mini" type="danger" plain>删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row class="user-table-page">
                                <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="page.currentPage"
                                        :page-sizes="[10,15,30,50,100]"
                                        :page-size="page.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="pageInfo.count">
                                </el-pagination>
                            </el-row>
                        </el-tab-pane>
                        <el-tab-pane label="商家用户" name="shopUser">
                            <el-form :inline="true" :model="userQueryParam" >
                                <el-form-item label="姓名" label-width="80px">
                                    <el-input v-model="userQueryParam.userRealName" placeholder="姓名"></el-input>
                                </el-form-item>
                                <el-form-item label="账号" label-width="80px">
                                    <el-input v-model="userQueryParam.userAccount" placeholder="账号"></el-input>
                                </el-form-item>
                                <el-form-item label="手机号" label-width="80px">
                                    <el-input v-model="userQueryParam.userPhone" placeholder="手机号"></el-input>
                                </el-form-item>
                                <el-form-item >
                                    <el-button icon="el-icon-search" type="primary" @click="shopUserQuery">查询</el-button>
                                    <el-button @click="queryReset">重置</el-button>
                                </el-form-item>
                            </el-form>
                            <el-row class="user-table">
                                <el-table :data="shopUserList" border  :header-cell-style="{background:'#F3F4F7',color:'#555'}">
                                    <el-table-column prop="userAccount" label="账号" ></el-table-column>
                                    <el-table-column prop="userName" label="昵称" ></el-table-column>
                                    <el-table-column prop="userRealName" label="姓名" ></el-table-column>
                                    <el-table-column prop="userSex" label="性别" :formatter="formatterSex"></el-table-column>
                                    <el-table-column prop="userCardCipher" label="身份证号" ></el-table-column>
                                    <el-table-column prop="userPhoneCipher" label="手机号" ></el-table-column>
                                    <el-table-column prop="createdBy" label="创建人" ></el-table-column>
                                    <el-table-column prop="createdDate" label="创建时间"  :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                                    <el-table-column prop="lastUpdatedBy" label="最后更新人"   ></el-table-column>
                                    <el-table-column prop="lastUpdatedDate" label="最后更新时间" :formatter="this.$formatTableCellDate" width="160px"></el-table-column>
                                    <el-table-column prop="userRemark" label="备注" min-width="126px"></el-table-column>
                                    <el-table-column  label="操作" >
                                        <template slot-scope="props">
                                            <el-button size="mini" type="primary"  @click="viewCurrentUser(props.row)"  plain>查看</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-row>
                            <el-row class="user-table-page">
                                <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="page.currentPage"
                                        :page-sizes="[10,15,30,50,100]"
                                        :page-size="page.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="pageInfo.count">
                                </el-pagination>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </template>
            </el-row>
            <el-row>
                <el-row>
                    <el-dialog
                            v-if="viewSystemUserDialog"
                            title="用户详情"
                            width="40%"
                            :visible.sync="viewSystemUserDialog"
                    >
                        <user-info :userDetail="currentUserInfo" :select="true"></user-info>
                    </el-dialog>
                </el-row>
                <el-row>
                    <el-dialog
                            v-if="viewSystemUserRoleDialog"
                            title="用户权限"
                            width="46%"
                            :visible.sync="viewSystemUserRoleDialog"
                            :close-on-click-modal="false"
                    >
                        <user-role-list  :userId="currentUserInfo.userId" ></user-role-list>
                    </el-dialog>
                </el-row>
            </el-row>
        </el-row>
</template>

<script>
    import {findSystemUserPage,deleteSystemUser} from '@/api/user';
    import addUser from "@/views/user/addUser";
    import userInfo from "@/views/user/userInfo";
    import userRoleList from "@/views/user/userRoleList";
    export default {
        name: "userListPage",
        data(){
            return{
                defaultUser:'systemUser',
                userQueryParam:{},
                systemUserList:[],
                shopUserList:[],
                page:{
                  pageSize:15,
                  currentPage:1
                },
                pageInfo:{

                },
                currentUserInfo:{},
                viewSystemUserDialog:false,
                viewSystemUserRoleDialog:false,
            }
        },
        mounted(){
            this.systemUserQuery();
        },
        methods:{
            viewCurrentUserRole:function(data){
                this.$set(this.currentUserInfo,'userId',data.userId);
                this.$set(this.currentUserInfo,'dataToken',data.dataToken);
                this.viewSystemUserRoleDialog=true;
            },
            viewCurrentUser:function(data){
                this.$set(this.currentUserInfo,'userId',data.userId);
                this.$set(this.currentUserInfo,'dataToken',data.dataToken);
                this.viewSystemUserDialog=true;
            },
            systemUserQuery:function () {
                this.userQueryParam.userType = '001';
                findSystemUserPage(this.page,this.userQueryParam,result=>{
                    this.systemUserList = result.data;
                    this.pageInfo.totalPage= result.totalPage;
                    this.pageInfo.count= result.count;
                });
            },
            shopUserQuery:function () {
                this.userQueryParam.userType = '002';
                findSystemUserPage(this.page,this.userQueryParam,result=>{
                    this.shopUserList = result.data;
                    this.pageInfo.totalPage= result.totalPage;
                    this.pageInfo.count= result.count;
                });
            },
            handleClick:function(){
                this.queryReset();
            },
            formatterSex:function(row){
                if (row.userSex == 1) {
                    return '男'
                } else {
                    return '女'
                }
            },
            addUserSuccess:function(){
                this.queryReset();
            },
            queryReset:function () {
                this.page = {
                    pageSize:15,
                    currentPage:1
                };
                this.userQueryParam={}
                this.queryUserList();
            },
            queryUserList:function(notResetCurrentPage){
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                if(this.defaultUser == 'systemUser'){
                    this.systemUserQuery();
                }
                if(this.defaultUser == 'shopUser'){
                    this.shopUserQuery();
                }
            },
            handleSizeChange:function (val) {
                this.page.pageSize = val;
                this.queryUserList();
            },
            handleCurrentChange:function (val) {
                this.page.currentPage = val;
                this.queryUserList(true);
            },
            deleteSystemUser:function (row) {
                this.$confirm('是否删除 '+row.userRealName+' 该用户', '删除提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        deleteSystemUser({userId: row.userId},success=>{
                            this.$message({
                                type:'success',
                                message:'删除成功',
                                duration:1500
                            });
                            this.systemUserList.forEach((item,index)=>{
                                if(item.userId == row.userId){
                                    this.systemUserList.splice(index,1)
                                }
                            });
                            this.pageInfo.count = this.page.count-1;
                        })
                    })
                    .catch(() => {

                    })
            }
        },
        components:{
            addUser,
            userInfo,
            userRoleList
        }
    }
</script>

<style scoped>
    .info-center{
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .user-table{
        margin-top: 10px;
    }
    .user-table-page{
        margin-top: 10px;
    }
</style>