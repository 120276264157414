<template>
    <el-container>
        <el-row>
            <el-row class="photo-category-tree-action">
                <el-col :span="6">
                <el-button  type="primary" @click="savePhotoCategory()">保 存</el-button>
                </el-col>
                <el-col :span="16">
                    <el-input @change="currentPhotoCategoryUpdate" v-model="currentPhotoCategory.categoryName"
                              :disabled="!(currentPhotoCategory.id<=0 || this.imageList.length==0)"></el-input>
                </el-col>
            </el-row>
            <el-row class="photo-category-tree">
                <el-row class="photo-category-search">
                    <el-input
                            placeholder="输入关键字进行过滤"
                            v-model="photoCategoryFilter">
                    </el-input>
                </el-row>
                <el-tree
                        class="filter-tree"
                        :data="photoCategoryTree"
                        @node-click="selectPhotoCategory"
                        node-key="dictionaryId"
                        :expand-on-click-node="false"
                        highlight-current
                        :filter-node-method="filterNode"
                        ref="tree"
                >
                    <span class="photo-category-tree-node" slot-scope="{node, data }">
                        <span @click="expandPhotoCategoryNode(node)">{{ data.categoryName }}</span>
                    <span>
                      <el-button
                              type="text"
                              size="mini"
                              icon="el-icon-document-add"
                              style="font-size: 16px"
                              v-if="data.categoryId"
                              @click.stop="() => addChildren(node,data)">
                      </el-button>
                      <el-button
                              v-if="!data.children || data.children.length==0"
                              type="text"
                              size="mini"
                              icon="el-icon-delete"
                              style="font-size: 16px"
                              @click.stop="() => removeNode(node, data)">
                      </el-button>
                        </span>
                    </span>
                </el-tree>
            </el-row>
        </el-row>
        <el-row>
            <el-row class="photo-category-info-action">
                <el-upload
                        style="padding-right: 10px"
                        :action="zipUploadUrl"
                        name="photo"
                        :multiple="false"
                        :limit="1"
                        :show-file-list="false"
                        :on-success="uploadZipSuccess"
                        :before-upload="zipTypeCheck">
                <el-button icon="el-icon-upload"  type="primary" >上传图片压缩包(zip)</el-button>
                </el-upload>
                <el-upload
                        style="padding-right: 10px"
                            :action="imageUploadUrl"
                            name="photo"
                            :data="{categoryParentId:this.currentPhotoCategory.categoryParentId,categoryId:this.currentPhotoCategory.categoryId}"
                            :multiple="false"
                            :limit="1"
                            :show-file-list="false"
                            :on-success="uploadSuccess"
                            :before-upload="imageTypeCheck">
                    <el-button icon="el-icon-upload2"  type="primary">单张图片上传</el-button>
                </el-upload>
                <el-button  type="danger" @click="deletePhoto()">删除图片</el-button>
            </el-row>
            <el-row class="photo-category-info">
                <el-row>
                    <el-checkbox-group v-model="checkImageList">
                        <el-col class="image-card" v-for="item in imageList" :key="item.id" >
                            <el-checkbox :label="item.id" style="position: absolute;z-index: 1"></el-checkbox>
                            <el-image :src="item.downloadUrl" class="image-info" fit="scale-down" :preview-src-list="[item.downloadUrl]" />
                        </el-col>
                    </el-checkbox-group>
                </el-row>
            </el-row>
            <el-row class="image-table-page">
                <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="page.currentPage"
                        :page-sizes="[18]"
                        :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="pageInfo.count">
                </el-pagination>
            </el-row>
        </el-row>
    </el-container>
</template>

<script>
    import {findPhotoCategoryList,deletePhotoCategory,savePhotoCategory} from '@/api/photoCategory'
    import {getPhotoMaterialPageList,deletePhoto} from '@/api/photo';
    import {baseUrl} from "@/config/env";
    export default {
        name: "photoManage",
        watch: {
            photoCategoryFilter(val) {
                this.$refs.tree.filter(val);
            }
        },
        data(){
            return{
                checkImageList:[],
                photoCategoryTree:[],
                currentPhotoCategory:{},
                imageUploadUrl:baseUrl+'/market/manager/attachment/photoSingleUpload',
                zipUploadUrl:baseUrl+'/market/manager/attachment/photoZipUpload',
                photoCategoryFilter:'',
                updateList:[],
                insertList:[],
                imageList:[],
                pageInfo:{
                    count:0,
                    totalPage:0
                },
                page:{
                    pageSize:18,
                    currentPage:1
                },
                inertId:0
            }
        },
        mounted(){
            this.initPhotoCategoryList();
            this.photoListPageQuery();
        },
        methods:{
            initPhotoCategoryList:function(){
                findPhotoCategoryList(success=>{
                    this.photoCategoryTree = success;
                });
            },
            currentPhotoCategoryUpdate:function(){
                if(this.currentPhotoCategory.categoryId){
                    this.updateList.push(this.currentPhotoCategory);
                }
            },
            removeUpdateDate:function(data){
                this.updateList.forEach((item,index)=>{
                    if(item.id == data.id){
                        this.updateList.splice(index,1)
                    }
                });
                this.insertList.forEach((item,index)=>{
                    if(item.id == data.id){
                        this.insertList.splice(index,1)
                    }
                });
            },
            savePhotoCategory:function(){
                let allList = [];
                this.insertList.forEach(item=>{
                    allList.push(item);
                })
                this.updateList.forEach(item=>{
                    allList.push(item);
                });
                if(allList.length==0){
                    this.$errorMessage('没有需要保存的数据');
                }
                savePhotoCategory(allList,success=>{
                    this.insertList=[];
                    this.updateList=[];
                    this.initPhotoCategoryList();
                    this.$successMessage("保存成功");
                },error=>{
                    this.insertList=[];
                    this.updateList=[];
                    this.initPhotoCategoryList();
                });
            },
            deletePhoto:function(){
                if(this.checkImageList && this.checkImageList.length>0){
                    this.$confirm('是否删除选中图片', '删除提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    })
                        .then(() => {
                    deletePhoto(this.checkImageList,success=>{
                        this.$successMessage("删除成功");
                        this.checkImageList=[];
                        this.photoListPageQuery();
                    })
                 })
                        .catch(() => {

                })
                    return;
                }
                this.$errorMessage("请选择需要删除的图片");
            },
            photoListPageQuery:function (notResetCurrentPage) {
              if(!notResetCurrentPage){
                this.page.currentPage=1;
              }
                if(!this.currentPhotoCategory.categoryId && this.currentPhotoCategory.categoryParentId){
                    return;
                }
                getPhotoMaterialPageList(this.page,{categoryId:this.currentPhotoCategory.categoryId},success=>{
                    this.imageList = success.data;
                    this.pageInfo.count = success.count;
                    this.pageInfo.totalPage = success.totalPage;
                });
            },
            uploadZipSuccess:function(){
                this.$message({type:"success",message:"图片压缩包上传成功",duration:1500})
                this.photoListPageQuery();
            },
            uploadSuccess:function(){
                this.$message({type:"success",message:"图片上传成功",duration:1500})
                this.photoListPageQuery();
            },
            handleSizeChange:function(val){
                this.page.pageSize = val;
                this.photoListPageQuery();
            },
            handleCurrentChange:function(val){
                this.page.currentPage = val;
                this.photoListPageQuery(true);
            },
            filterNode(value, data) {
                if (!value){
                    return true;
                }
                return data.categoryName.indexOf(value) !== -1;
            },
            selectPhotoCategory:function (data) {
                this.currentPhotoCategory = data;
                this.photoListPageQuery();
            },
            expandPhotoCategoryNode:function(node){
                if(node.expanded){
                    node.expanded=false;
                }else {
                    node.expanded=true;
                }
            },
            addPhotoCategoryChild(tree,data){
                for(let index=0;index<tree.length;index++){
                    let item = tree[index];
                    if(item.categoryId == data.categoryParentId){
                        if(!item.children){
                            item.children = [];
                        }
                        item.children.unshift(data);
                    }
                    if(item.children && item.children.length>0){
                        this.addPhotoCategoryChild(item.children,data);
                    }
                }
            },
            addChildren:function (node,data) {
                this.currentPhotoCategory =this.getDefaultPhotoCategory(data);
                this.addPhotoCategoryChild(this.photoCategoryTree,this.currentPhotoCategory);
            },
            getDefaultPhotoCategory:function(data){
                const defaultDictionary= {id:this.inertId--,categoryParentId:-1,children: []};
                if(data){
                    defaultDictionary.categoryName = "图片分类名称";
                }
                if(data && data.categoryId){
                    defaultDictionary.categoryParentId=data.categoryId;
                }
                this.insertList.push(defaultDictionary)
                return defaultDictionary;
            },
            removeNode:function (node,data) {
                this.$confirm('是否删除分类<span style="color: red">'+data.categoryName+'</span>', '删除提示', {
                    dangerouslyUseHTMLString:true,
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                })
                    .then(() => {
                        this.removeUpdateDate(data);
                        if(!data.categoryId){
                            this.removeTreeNode(node,data);
                            return;
                        }
                        deletePhotoCategory(data,success=>{
                            this.$successMessage("删除成功");
                           this.removeTreeNode(node,data);
                        })
                    })
                    .catch(() => {

                    })
            },
            removeTreeNode:function(node,data){
                const parent = node.parent;
                const children = parent.data.children && parent.data.children.length>0?
                    parent.data.children: parent.data ;
                const index = children.findIndex(d => d.id === data.id);
                children.splice(index, 1);
                this.currentPhotoCategory = {};
            },
            imageTypeCheck:function (file) {
                if(!this.currentPhotoCategory.categoryParentId){
                    this.$errorMessage("请选择图片类目")
                    return  false;
                }
                let fileName =  file.name;
                if(fileName.endsWith('.png')|fileName.endsWith('.jpg')){
                    return true;
                }
                this.$message({type:"error",message:'图片只支持png和jpg',duration:1500});
                return  false;
            },
            zipTypeCheck:function (file) {
                let fileName =  file.name;
                if(fileName.endsWith('.zip')){
                    return true;
                }
                this.$message({type:"error",message:'只支持zip图片包',duration:1500});
                return  false;
            }
        }
    }
</script>
<style>
    .el-checkbox>.el-checkbox__label{
        display: none;
    }
</style>
<style scoped>
    .image-card{
        width: 11vw;
        height: 11vw;
        padding: 10px;
    }
    .image-info{
        width: 100%;
        height: 100%;
       border-radius: 8px;
    }
    .photo-category-tree-action{
        padding: 0px 0px 8px 10px;
    }
    .photo-category-info-action{
        padding: 0px 0px 8px 20px;
        display: flex;
    }
    .photo-category-tree{
        width: 18vw;
        height: 69vh;
        overflow: auto;
        border: 3px solid whitesmoke;
        padding: 10px;
        border-radius: 8px;
    }
    .photo-category-search{
        position: fixed;
        z-index: 1;
        width: 16VW;
    }
    .photo-category-info{
        justify-content: center;
        width: 70vw;
        height: 69vh;
        overflow: auto;
        border: 3px solid whitesmoke;
        margin: 0px 10px;
        border-radius: 8px;
    }
    .photo-category-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }
    .filter-tree{
        margin-top: 50px;
    }
    .image-table-page{
        padding-left: 10px;
    }
</style>